import React from 'react'

import { Card, Contact } from 'core'
import { Grid, GridItem } from 'layout'

import css from './style.scss'

const BuddyDetails = ({ user }) => (
  <Grid>
    <GridItem col='7/16'>
      <p className={css.buddyHeading}>Your Buddy</p>
      <Card>
        <Contact user={user} size='md' />
      </Card>
    </GridItem>
  </Grid>
)

export default BuddyDetails
