import { startSubmit, stopSubmit } from 'redux-form'
import {
  createClient, currentUserSelector, loadCurrentUser
} from 'auth'
import { handleError } from 'errors'
import { SUBSCRIPTION_SUCCESS } from './types'
import { FORM_NAME } from './constants'

export const memberMailchimpSubscribeModal = () => (dispatch, getState) => {
  dispatch(startSubmit(FORM_NAME))
  const state = getState()
  const client = createClient(state)

  const { id } = currentUserSelector(state)

  client.patch(`/v2/users/${id}`, { body: { flags: { newsletter_opt_in: true } } })
    .then(response => response.json())
    .then((updatedUser) => {
      dispatch(loadCurrentUser(updatedUser))
      dispatch({
        type: SUBSCRIPTION_SUCCESS
      })
    })
    .catch(err => {
      dispatch(stopSubmit(FORM_NAME))
      dispatch(handleError(err))
      return err
    })
}
