import { UnauthorizedError, ForbiddenError, apiResponse, schemas } from 'api'
import { catchError } from 'errors'
import createClient from './createClient'
import {
  AUTHENTICATION_FAILED,
  LOAD_AUTH_TOKEN,
  LOAD_CURRENT_USER,
  HYDRATION_ERROR
} from './types'
const { location } = window

export const loadAuthToken = (token) => ({
  type: LOAD_AUTH_TOKEN,
  token
})

export const loadCurrentUser = (user) => {
  return apiResponse({
    type: LOAD_CURRENT_USER,
    data: user,
    schema: schemas.user
  })
}

export const hydrateAuthentication = () => (dispatch, getState) => {
  return createClient(getState())
    .post('/auth/refresh')
    .then(response => response.json())
    .then(body => {
      const { token } = body
      dispatch(loadAuthToken(token))
      return createClient(getState())
        .get('/v2/users/current')
        .then(response => response.json())
        .then(user => {
          dispatch(loadCurrentUser(user))
        })
        .catch(error => {
          dispatch({ type: HYDRATION_ERROR, error })
        })
    })
    .catch(error => {
      if (error instanceof UnauthorizedError) {
        dispatch({ type: AUTHENTICATION_FAILED })
      } else if (error instanceof ForbiddenError) {
        return createClient(getState())
          .post('/auth/logout')
          .then(() => dispatch({ type: AUTHENTICATION_FAILED }))
      } else {
        dispatch({ type: HYDRATION_ERROR, error })
      }
    })
}

export const refreshAuthToken = () => (dispatch, getState) => {
  return createClient(getState())
    .post('/auth/refresh')
    .then(response => response.json())
    .then((body) => {
      const { token } = body
      dispatch(loadAuthToken(token))
    })
    .catch(err => {
      if (err instanceof UnauthorizedError) {
        location.reload()
      }
    })
}

export const logout = () => (dispatch, getState) => {
  return createClient(getState())
    .post('/auth/logout')
    .then(() => {
      location.href = '/'
    })
    .catch(catchError(dispatch))
}
