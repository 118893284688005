import { loadData, schemas } from 'api'
import { UPDATE_SEARCH_TERM } from './types'
import { institutionSearchUrlSelector } from './selectors'

/**
 * Updates the search term and makes the search query
 */

export const searchInstitutions = (props) => searchTerm => (dispatch, getState) => {
  dispatch({ type: UPDATE_SEARCH_TERM, searchTerm })

  const url = institutionSearchUrlSelector(props)(getState())

  if (url) {
    return dispatch(loadData({
      url,
      // ? Is this the right schema?
      schema: [schemas.institution]
    }))
  }
}
