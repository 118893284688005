import React from 'react'
import moment from 'moment-timezone'
import MediaQuery from 'react-responsive'

import { Button } from '../Button'
import { Card } from '../Card'
import { Contact } from '../Contact'
import { Heading } from '../Heading'
import { Link } from '../Link'
import { Grid, GridItem } from 'layout'
import { formatWebinarDate, formatTimeRange } from 'helpers'

import css from './style.scss'
import { breakpoints } from 'styles'

const { laptopMin, mobileMax, tabletMax } = breakpoints

const EventSingleFooter = (props) => (
  <Grid center={props.center}>
    <GridItem col='1/2' tablet1of3>
      <p className={css.eventType}>
        {props.eventType}
      </p>
    </GridItem>
    <MediaQuery maxWidth={laptopMin} minWidth={mobileMax}>
      <GridItem col='0' tablet1of3 />
    </MediaQuery>
    <GridItem col='1/2' tablet1of3>
      <Link to={props.to}>
        <Button size='tertiary' noMargin>View Details</Button>
      </Link>
      {props.registered ? <div className={css.registered}>* Registered</div> : null}
    </GridItem>
  </Grid>
)

EventSingleFooter.propTypes = {
  center: React.PropTypes.bool,
  eventType: React.PropTypes.string.isRequired,
  to: React.PropTypes.string.isRequired,
  registered: React.PropTypes.bool.isRequired
}

const eventLinks = {
  webinar: slug => `/webinars/${slug}`,
  session_sale: slug => `/sessions/${slug}`
}

const subtypeLabels = {
  course: 'Multi-Week Course',
  curriculum: 'Core Curriculum',
  guest: 'Guest Webinar',
  fsp: 'FSP Bootcamp',
  alumni: 'FSP Alumni',
  writing_challenge: '14-Day Writing Challenge'
}

const getSubtypeLabel = subtype => subtypeLabels[subtype]
const getEventLink = ({ type, slug }) => eventLinks[type](slug)

const EventSingle = (props) => {
  const typeLabel = getSubtypeLabel(props.subtype)
  const link = getEventLink(props)
  const eventDate = formatWebinarDate(props.start)
  const eventTime = props.showTimes
    ? formatTimeRange(props.start, props.end)
    : null
  const { registered } = props
  const isLive = props.type === 'webinar' && moment().isBetween(props.start, props.end, '[]')

  return (
    <Card>
      <Grid>
        <GridItem col='1/6' tablet1of1 mobile1of1>
          <p className={css.eventDate}>
            {eventDate}
          </p>
          {
            eventTime &&
              <p className={css.eventTime}>
                {eventTime}
              </p>
          }
          {
            isLive &&
              <p className={css.liveIndicator}>Live Now</p>
          }
        </GridItem>
        <GridItem col='3/6' tablet1of1 mobile1of1>
          <Heading size='h4' weight='semibold'>{props.title}</Heading>
          {props.facilitator && <Contact size='xs' user={props.facilitator} />}
        </GridItem>
        <GridItem col='2/6' tablet1of1 mobile1of1>
          <div className={css.eventRegister}>
            <MediaQuery
              children={<EventSingleFooter eventType={typeLabel} to={link} registered={registered} />}
              minWidth={laptopMin}
            />
            <MediaQuery
              children={<EventSingleFooter center eventType={typeLabel} to={link} registered={registered} />}
              maxWidth={tabletMax}
            />
          </div>
        </GridItem>
      </Grid>
    </Card>
  )
}

EventSingle.propTypes = {
  id: React.PropTypes.number.isRequired,
  type: React.PropTypes.oneOf(['webinar', 'session_sale']).isRequired,
  subtype: React.PropTypes.string.isRequired,
  title: React.PropTypes.string.isRequired,
  start: React.PropTypes.string.isRequired,
  end: React.PropTypes.string.isRequired,
  facilitator: React.PropTypes.object,
  showTimes: React.PropTypes.bool.isRequired,
  registered: React.PropTypes.bool.isRequired
}

EventSingle.defaultProps = {
  showTimes: false,
  registered: false
}

export default EventSingle
