import { namespacer } from 'helpers'

const namespace = namespacer('ACCOUNT')
export const SUBACCOUNT_EXPORT_REQUEST = namespace('SUBACCOUNT_EXPORT_REQUEST')
export const SUBACCOUNT_EXPORT_SUCCESS = namespace('SUBACCOUNT_EXPORT_SUCCESS')
export const SUBACCOUNT_EXPORT_FAILED = namespace('SUBACCOUNT_EXPORT_FAILED')
export const SUBACCOUNT_EXPORT_EXPIRED = namespace('SUBACCOUNT_EXPORT_EXPIRED')
export const INVOICE_EXPORT_REQUEST = namespace('INVOICE_EXPORT_REQUEST')
export const INVOICE_EXPORT_SUCCESS = namespace('INVOICE_EXPORT_SUCCESS')
export const INVOICE_EXPORT_FAILED = namespace('INVOICE_EXPORT_FAILED')
export const INVOICE_EXPORT_EXPIRED = namespace('INVOICE_EXPORT_EXPIRED')
