import {
  getFormValues,
  startSubmit,
  stopSubmit
} from 'redux-form'
import { push } from 'react-router-redux'

import { openModal } from 'modal'
import { createClient } from 'auth'
import { handleError } from 'errors'

import { FORM_NAME } from '../constants'
import { login } from '../actions'
import { WelcomeModal } from '../components/WelcomeModal'

export const submit = (props) => (dispatch, getState) => {
  const { institution } = props
  const values = getFormValues(FORM_NAME)(getState())
  dispatch(startSubmit(FORM_NAME))
  return createClient(getState()).post('/v2/users', {
    body: Object.assign({}, values, {
      institution_id: institution.id
    })
  })
    .then(() => dispatch(login(values.email, values.password)))
    .then(() => {
      dispatch(stopSubmit(FORM_NAME))
      dispatch(push('/dashboard'))
      dispatch(openModal(WelcomeModal))
    })
    .catch(err => {
      dispatch(stopSubmit(FORM_NAME))
      dispatch(handleError(err))
      return err
    })
}
