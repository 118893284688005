import { createClient, loadAuthToken, loadCurrentUser } from 'auth'

export const login = (email, password) => (dispatch, getState) => {
  return createClient(getState()).post('/auth/login', {
    body: { email, password }
  })
    .then(response => response.json())
    .then(body => {
      dispatch(loadAuthToken(body.token))
      return createClient(getState()).get('/v2/users/current')
    })
    .then(response => response.json())
    .then(user => {
      dispatch(loadCurrentUser(user))
    })
    /* Errors should be handled by the parent action */
}
