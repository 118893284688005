import { namespacer } from 'helpers'

const namespace = namespacer('CHAT')

/* Dispatched by action creators */
export const JOIN_CHAT_ROOM = namespace('JOIN_CHAT_ROOM')
export const LEAVE_CHAT_ROOM = namespace('LEAVE_CHAT_ROOM')
export const LOAD_CHAT_MESSAGES = namespace('LOAD_CHAT_MESSAGES')
export const CREATE_CHAT_MESSAGE = namespace('CREATE_CHAT_MESSAGE')

/* Dispatched by socket handlers */
export const RECEIVE_USER_LIST = namespace('RECEIVE_USER_LIST')
export const RECEIVE_USER_JOINED = namespace('RECEIVE_USER_JOINED')
export const RECEIVE_USER_LEAVE = namespace('RECEIVE_USER_LEAVE')
export const RECEIVE_CHAT_MESSAGE = namespace('RECEIVE_CHAT_MESSAGE')
export const RECEIVE_DELETE_CHAT_MESSAGE = namespace('RECEIVE_DELETE_CHAT_MESSAGE')
