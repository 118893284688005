import { namespacer } from 'helpers'

const namespace = namespacer('WEBINAR')

export const WEBINAR_COMPLETE_REQUEST = namespace('WEBINAR_COMPLETE_REQUEST')
export const WEBINAR_COMPLETE_SUCCESS = namespace('WEBINAR_COMPLETE_SUCCESS')
export const WEBINAR_COMPLETE_FAIL = namespace('WEBINAR_COMPLETE_FAIL')
export const WEBINAR_START_REQUEST = namespace('WEBINAR_START_REQUEST')
export const WEBINAR_START_SUCCESS = namespace('WEBINAR_START_SUCCESS')
export const WEBINAR_START_FAIL = namespace('WEBINAR_START_FAIL')
export const WEBINAR_REGISTER_REQUEST = namespace('WEBINAR_REGISTER_REQUEST')
export const WEBINAR_REGISTER_SUCCESS = namespace('WEBINAR_REGISTER_SUCCESS')
export const WEBINAR_REGISTER_FAIL = namespace('WEBINAR_REGISTER_FAIL')
export const WEBINAR_UNREGISTER_REQUEST = namespace('WEBINAR_UNREGISTER_REQUEST')
export const WEBINAR_UNREGISTER_SUCCESS = namespace('WEBINAR_UNREGISTER_SUCCESS')
export const WEBINAR_UNREGISTER_FAIL = namespace('WEBINAR_UNREGISTER_FAIL')
export const WEBINAR_REFRESH_REQUEST = namespace('WEBINAR_REFRESH_REQUEST')
export const WEBINAR_REFRESH_SUCCESS = namespace('WEBINAR_REFRESH_SUCCESS')
export const WEBINAR_REFRESH_FAIL = namespace('WEBINAR_REFRESH_FAIL')
export const REFRESH_CORE_CURRICULUM = namespace('REFRESH_CORE_CURRICULUM')
