import { OPEN_MODAL, CLOSE_MODAL } from './types'

export const openModal = (component, props = {}) => ({
  type: OPEN_MODAL,
  component,
  props
})

export const closeModal = () => ({
  type: CLOSE_MODAL
})
