import { startSubmit, stopSubmit } from 'redux-form'
import { browserHistory } from 'react-router'

import {
  createClient,
  currentUserMembershipSelector,
  loadCurrentUser
} from 'auth'
import { discountCodesSelector } from 'order-summary'
import { openModal } from 'modal'
import {
  createStripeChargeToken,
  billingAddressSelector,
  handlePaymentError
} from 'stripe'

import MembershipRenewalSuccessModal from './MembershipRenewalSuccessModal'
import { RENEWAL_FORM_NAME as FORM_NAME } from './constants'
import { getMembershipRenewalOrderSummary } from './selectors'

export const renew = (props) => (dispatch, getState) => {
  const state = getState()
  const formAddress = billingAddressSelector(FORM_NAME)(state)
  const discountCodes = discountCodesSelector(FORM_NAME)(state)
  const orderSummary = getMembershipRenewalOrderSummary(state, props)

  const client = createClient(state)

  const updatePromises = [
    Promise.resolve(
      orderSummary.total > 0
        ? createStripeChargeToken(props.stripeElements.cardNumber)
        : null
    )
      .then(chargeToken => {
        const body = {
          stripe_charge_token: chargeToken ? chargeToken.id : undefined,
          payerDetails: formAddress
        }

        if (discountCodes) {
          body.discount_codes = discountCodes
        }

        return client.post('/v2/users/current/memberships', { body })
      })
  ]

  dispatch(startSubmit(FORM_NAME))
  return Promise.all(updatePromises)
    // reload current user to update billing address / membership in a single action
    .then(() => client.get('/v2/users/current'))
    .then(response => response.json())
    .then(updatedUser => {
      dispatch(loadCurrentUser(updatedUser))
      dispatch(stopSubmit(FORM_NAME))
      browserHistory.push('/')
      const updatedMembership = currentUserMembershipSelector(getState())
      dispatch(openModal(MembershipRenewalSuccessModal, {
        membershipExpiry: updatedMembership.expires_at
      }))
    })
    .catch(err => dispatch(handlePaymentError(err, FORM_NAME)))
}
