import React from 'react'
import { connect } from 'react-redux'
import { compact, find, get } from 'lodash'

import { Avatar } from '../Avatar'
import { Combo, ComboFirst, ComboLast } from 'layout'
import { pnlOptionsSelector } from 'settings'

import css from './style.scss'

/**
 * The `<Contact />` component can be used to generate a user's avatar, name and post nominal letters.
 * The user's role is an optional element and will only be shown for the `lg` size of element.
 */

export const Contact = (props) => {
  const { user, pnlOptions } = props

  const pnlOption = get(find(pnlOptions, { value: user.post_nominal_letters }), 'name', user.post_nominal_letters)
  const pnl = pnlOption || ''
  const classes = [
    css.contact,
    props.size ? css[props.size] : css.sm,
    props.nameItalic ? css.nameItalic : null,
    props.testimonial ? css.testimonial : null
  ]

  const name = (
    <span className={css.name}>{`${user.name || ''}${pnl ? ',' : ''} ${pnl}`}</span>
  )

  const role = (
    <span className={css.role}>{user.role || ''}</span>
  )

  let identity

  if (props.namePriority) {
    identity = (
      <div>
        <p className={css.bold}>{name}</p>
        {role}
      </div>
    )
  } else if (props.byline) {
    identity = (
      <p>by <span className={css.byline}>{name}</span></p>
    )
  } else if (props.testimonial) {
    identity = (
      <div>
        <p className={css.bold}>{name}</p>
        <p className={css.role}>{role}</p>
        <p className={css.institution}>{user.institution}</p>
      </div>
    )
  } else {
    identity = (
      <div>
        <p className={css.bold}>{role}</p>
        {name}
      </div>
    )
  }

  const comboPosition = !props.testimonial

  return (
    <div className={compact(classes).join(' ')}>
      <Combo middle={comboPosition}>
        <ComboFirst>
          <Avatar user={user} size={props.size} />
        </ComboFirst>
        <ComboLast center={props.center}>
          {identity}
        </ComboLast>
      </Combo>
    </div>
  )
}

Contact.propTypes = {
  /**
   * The user to generate an <Contact /> element for. Expected shape:
   */
  user: React.PropTypes.shape({
    name: React.PropTypes.string,
    avatar_url: React.PropTypes.string,
    role: React.PropTypes.string,
    institution: React.PropTypes.string,
    post_nominal_letters: React.PropTypes.string
  }).isRequired,
  /**
   * Available Sizes: xs, sm, md, lg
   */
  size: React.PropTypes.string,
  /**
   * If true, allows `<Contact />` to be center aligned properly on the vertical axis
   */
  center: React.PropTypes.bool,
  /**
   * If true, reverses the display order + styling of name / role
   */
  namePriority: React.PropTypes.bool,
  /**
   * If true, sets a "by" statement beside author's name
   */
  byline: React.PropTypes.bool,
  /**
   * If true, contact name will be font-style: italic and font-family: freight
   */
  nameItalic: React.PropTypes.bool,
  /**
   * If true, will style to match Contacts in <Testimonial /> page-block
   */
  testimonial: React.PropTypes.bool,
  pnlOptions: React.PropTypes.arrayOf(React.PropTypes.object)
}

export default connect(state => ({
  pnlOptions: pnlOptionsSelector(state)
}))(Contact)
