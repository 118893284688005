import React, { PropTypes } from 'react'
import { noop } from 'lodash'
import css from './style.scss'

import { SpinnerIcon } from 'icons'

const DiscountCodeButton = ({ onClick, loading, disabled }) => {
  return (
    <button
      className={css.discountCodeButton}
      type='button'
      onClick={onClick}
      disabled={disabled}
    >
      Apply {loading && (
        <div className={css.spinner}>
          <SpinnerIcon />
        </div>
    )}
    </button>
  )
}

DiscountCodeButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

DiscountCodeButton.defaultProps = {
  loading: false,
  disabled: false,
  onClick: noop
}

export default DiscountCodeButton
