import Immutable from 'seamless-immutable'
import { values } from 'lodash'
import { filterActions } from 'redux-ignore'

import * as types from './types'
const { EDIT_FORM, CLOSE_FORM } = types

const initialState = Immutable({
  activeForm: null
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_FORM:
      return state.set('activeForm', action.form)
    case CLOSE_FORM:
      return state.set('activeForm', null)
    default:
      return state
  }
}

export default filterActions(reducer, values(types))
