import React from 'react'
import MediaQuery from 'react-responsive'

import { Contact, Link } from 'core'
import { ChevronLink } from 'icons'
import { CMSSection } from '../components'

import css from './style.scss'
import { breakpoints } from 'styles'

const { phabletMax, phabletMaxPlusOne } = breakpoints

const CYCLE_INTERVAL = 10000

const trimArray = (testimonials) => {
  return testimonials.slice(0, 3)
}

class Testimonials extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      activeIndex: 0,
      testimonials: trimArray(this.props.testimonials)
    }
  }

  componentWillReceiveProps (newProps) {
    if (newProps.testimonials) {
      this.setState({ testimonials: trimArray(newProps.testimonials) })
    }
  }

  componentDidMount () {
    this.startInterval()
  }

  componentWillUnmount () {
    this.clearInterval()
  }

  startInterval () {
    this.interval = setInterval(() => this.cycleActive(), CYCLE_INTERVAL)
  }

  clearInterval () {
    clearInterval(this.interval)
  }

  changeActive (index) {
    this.setState({ activeIndex: index })
    this.clearInterval()
    this.startInterval()
  }

  cycleActive () {
    const { activeIndex } = this.state
    const next = (activeIndex === this.state.testimonials.length - 1) ? 0 : activeIndex + 1

    this.setState({ activeIndex: next })
  }

  render () {
    const currentTestimonial = this.state.testimonials[this.state.activeIndex]

    const {
      testimonial,
      cite_name: name,
      cite_position: role,
      cite_institution: institution,
      cite_avatar: avatarUrl
    } = currentTestimonial

    return (
      <CMSSection
        title={this.props.title}
        subtitle={this.props.subtitle}
      >
        <div className={css.wrapper}>
          <MediaQuery minWidth={phabletMaxPlusOne}>
            <span className={css.quote}>“</span>
          </MediaQuery>
          <div className={css.testimonial}>
            <p>{testimonial}</p>
            <MediaQuery maxWidth={phabletMax}>
              <div className={css.inlineCite}>
                <Contact
                  size='lg'
                  center
                  testimonial
                  user={{
                    name,
                    role,
                    institution,
                    avatar_url: avatarUrl
                  }}
                />
              </div>
            </MediaQuery>
          </div>
          <div className={css.cites}>
            {
              this.state.testimonials.map((testimonial, index) => {
                const active = index === this.state.activeIndex ? css.active : ''
                const {
                  cite_name: name,
                  cite_position: role,
                  cite_institution: institution,
                  cite_avatar: avatarUrl
                } = testimonial

                return (
                  <div key={index} onClick={() => this.changeActive(index)} className={css.cite}>
                    <MediaQuery minWidth={phabletMaxPlusOne}>
                      <div className={`${css.citeNav} ${active}`}>
                        <Contact
                          size='lg'
                          testimonial
                          user={{
                            name,
                            role,
                            institution,
                            avatar_url: avatarUrl
                          }}
                        />
                      </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={phabletMax}>
                      <div
                        className={`${css.dots} ${active}`}
                      />
                    </MediaQuery>
                  </div>
                )
              })
            }
          </div>
          {
            (this.props.cta_url && this.props.cta_label) &&
              <div className={css.linkWrapper}>
                <Link to={this.props.cta_url} orange>
                  {this.props.cta_label} <ChevronLink color='orange' />
                </Link>
              </div>
          }
        </div>
      </CMSSection>
    )
  }
}

Testimonials.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  cta_url: React.PropTypes.string,
  cta_label: React.PropTypes.string,
  testimonials: React.PropTypes.arrayOf(React.PropTypes.shape({
    testimonial: React.PropTypes.string.isRequired,
    cite_name: React.PropTypes.string.isRequired,
    cite_position: React.PropTypes.string,
    cite_institution: React.PropTypes.string,
    cite_avatar: React.PropTypes.string
  })).isRequired
}

export default Testimonials
