import { push } from 'react-router-redux'
import qs from 'qs'

import { apiResponse, schemas } from 'api'
import { createClient, currentUserInstitutionIdSelector } from 'auth'
import { handleError } from 'errors'

import {
  LOAD_RESERVATIONS_SUCCESS,
  CLEAR_RESERVATIONS
} from './types'

export const loadReservations = sessionSale => (dispatch, getState) => {
  const state = getState()
  const institutionId = currentUserInstitutionIdSelector(state)

  return createClient(getState())
    .get(`/v2/session_sales/${sessionSale.id}/reservations?${qs.stringify({
      filters: { institution_id: institutionId }
    })}`)
    .then(res => res.json())
    .then(reservations => {
      dispatch(apiResponse({
        type: LOAD_RESERVATIONS_SUCCESS,
        data: reservations,
        schema: [schemas.sessionReservation]
      }))
    })
    .catch(error => {
      dispatch(handleError(error))
      dispatch(push(`/sessions/${sessionSale.slug}`))
    })
}

export const clearReservations = () => ({ type: CLEAR_RESERVATIONS })
