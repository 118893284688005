import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { filter } from 'lodash'
import { institutionalMembershipSupportEmail } from 'config'

import { Link } from 'core'
import { Form, FormSection } from 'form'

import { SelectInstitutionUser } from './select-institution-user'
import { ReservationList } from './reservation-list'
import { sessionAssignmentsAreAvailable } from './helpers'
import SubmitSection from '../components/SubmitSection'
import NoticeBox from '../components/NoticeBox'
import { reservationsSelector } from '../selectors'
import { priceOfReservations } from '../helpers'

const Assign = props => {
  const { sessionSale, reservations } = props
  const assignedReservations = filter(reservations, 'user_id')
  const assignmentsAreAvailable = sessionAssignmentsAreAvailable(sessionSale)

  return (
    // eslint-disable-next-line react/jsx-handler-names
    <Form onFocus={props.onFocus} onSubmit={props.continue}>
      <FormSection>
        <SelectInstitutionUser assignmentsAreAvailable={assignmentsAreAvailable} />
        <NoticeBox>
          <p>
            In order to assign a seat to a faculty member, that faculty member must
            have an active NCFDD membership. If you are unable to locate a specific
            faculty member, they may need to set up a membership. If you have any
            questions, please reach out to us
            at <Link to={`mailto:${institutionalMembershipSupportEmail}`} type='text'>{institutionalMembershipSupportEmail}</Link>
          </p>
        </NoticeBox>
        <ReservationList assignmentsAreAvailable={assignmentsAreAvailable} />
      </FormSection>
      <SubmitSection
        price={
          assignedReservations.length > 0
            ? priceOfReservations(assignedReservations)
            : null
        }
        buttonText='Done'
      />
    </Form>
  )
}

Assign.propTypes = {
  reservations: PropTypes.arrayOf(PropTypes.shape({
    user_id: PropTypes.number
  })).isRequired
}

export default connect(
  createStructuredSelector({
    reservations: reservationsSelector
  })
)(Assign)
