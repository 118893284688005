import React from 'react'
import { compact } from 'lodash'

import { Section } from 'layout'

import css from './style.scss'

const ArticleBody = (props) => {
  const { body, left, proxima } = props
  const classes = [
    css.article,
    left ? css.left : null,
    proxima ? css.proxima : null
  ]

  return (
    <Section narrow>
      <div
        className={compact(classes).join(' ')}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </Section>
  )
}

ArticleBody.propTypes = {
  body: React.PropTypes.string.isRequired,
  /* If true, left-aligns text (necessary override when using CMSSection) */
  left: React.PropTypes.bool,
  /* If true, sets body text to proxima font-family */
  proxima: React.PropTypes.bool
}

export default ArticleBody
