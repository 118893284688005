export const ORDER_FORM_NAME = 'orderPayment'

export const INVOICE_PAYMENT_FIELD_NAMES = [
  'invoice_institution',
  'address_line_1',
  'address_line_2',
  'city',
  'state',
  'postal_code',
  'country',
  'invoice_billing_email',
  'invoice_billing_name'
]
