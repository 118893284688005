import { getFormValues, startSubmit, stopSubmit } from 'redux-form'
import { browserHistory } from 'react-router'

import {
  createClient,
  loadCurrentUser
} from 'auth'
import { handleError } from 'errors'

import { MEMBERSHIP_TYPE_FORM } from './constants'
import { MEMBERSHIP_TYPE_SUCCESS } from './types'

export const submitSwitchToInstitution = () => (dispatch, getState) => {
  dispatch(startSubmit(MEMBERSHIP_TYPE_SUCCESS))
  const state = getState()
  const client = createClient(state)

  const formValues = getFormValues(MEMBERSHIP_TYPE_FORM)(state)
  const { institution } = formValues
  const body = { institution_id: institution }

  client.post('/v2/users/registration/institution', { body })
    .then(() => client.get('/v2/users/current'))
    .then(response => response.json())
    .then(updatedUser => {
      dispatch(loadCurrentUser(updatedUser))
      return browserHistory.push('/account')
    })
    .catch(err => {
      dispatch(stopSubmit(MEMBERSHIP_TYPE_FORM))
      dispatch(handleError(err))
    })
}
