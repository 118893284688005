import { createSelector, createStructuredSelector } from 'reselect'
import { get, mapValues } from 'lodash'
import { denormalize } from 'normalizr'

const apiQueriesSelector = state => get(state, 'api.queries', {})
export const entitiesSelector = (state) => get(state, 'api.entities', {})

export const querySelector = ({ urlSelector, schema }) => createSelector(
  apiQueriesSelector,
  entitiesSelector,
  urlSelector,
  (queries, entities, url) => {
    const query = queries[url] || {}
    const { result, loading = false, error, totalCount } = query

    let data
    if (result) {
      data = schema
        ? denormalize(result, schema, entities)
        : result
    }

    return {
      url,
      data,
      loading,
      error,
      totalCount
    }
  }
)

/**
 * Takes a load HOC config object and selects
 * the state of each of the queries
 */

export const queriesSelector = loadConfig => {
  return createStructuredSelector(mapValues(loadConfig, querySelector))
}
