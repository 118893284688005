import React from 'react'

import { Button, Card, Heading, Link } from 'core'
import { Grid, GridItem, Spacer } from 'layout'

const EventsEmpty = () => (
  <Card>
    <Spacer size='md' />
    <Grid middle>
      <GridItem col='1/2' mobile2of3>
        <Heading center size='h4' weight='light'>
          You haven’t registered for any events just yet
        </Heading>
        <Grid middle>
          <GridItem col='1/2' tablet1of2 mobile1of1>
            <Link to='/events'>
              <Button size='secondary' noMargin>Browse Events</Button>
            </Link>
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
    <Spacer size='md' />
  </Card>
)

export default EventsEmpty
