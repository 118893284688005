import { startSubmit, stopSubmit } from 'redux-form'
import { browserHistory } from 'react-router'

import {
  createClient,
  currentUserSelector,
  hydrateAuthentication
} from 'auth'
import { login } from 'registration/actions'
import { openModal } from 'modal'
import {
  createStripeChargeToken,
  billingAddressSelector,
  handlePaymentError
} from 'stripe'

import ConfirmModal from './ConfirmModal'
import { WAITLIST_FORM_NAME } from '../constants'
import { registrationFormCompleteSelector, registrationFormSelector } from 'session-sales/individual-reservations/selectors'
import { BadRequestError } from 'api'
import { SET_USER_REGISTRATION_ERROR } from 'session-sales/individual-reservations/types'

export const joinWaitlist = (sessionSale, stripeElements) => (dispatch, getState) => {
  const stateWhenSubmitting = getState()

  const currentUser = currentUserSelector(stateWhenSubmitting)
  const registrationInfo = registrationFormSelector(WAITLIST_FORM_NAME)(stateWhenSubmitting)
  const registrationFormComplete = registrationFormCompleteSelector(WAITLIST_FORM_NAME)(stateWhenSubmitting)
  const formAddress = billingAddressSelector(WAITLIST_FORM_NAME)(stateWhenSubmitting)

  let registerPromise = Promise.resolve()
  let newMembershipCreated = false
  if (!currentUser && registrationFormComplete) {
    registerPromise = createClient(stateWhenSubmitting).post('/v2/users', {
      body: {
        first_name: registrationInfo.registration_first_name,
        last_name: registrationInfo.registration_last_name,
        email: registrationInfo.registration_email,
        password: registrationInfo.registration_password,
        institution_id: registrationInfo.registration_institution_id,
        session_sale_id: sessionSale?.id
      }
    })
      .then(() => {
        newMembershipCreated = true
        return dispatch(login(registrationInfo.registration_email, registrationInfo.registration_password))
      })
  }

  return registerPromise.then(() => {
    const client = createClient(getState())

    const updatePromises = [
      createStripeChargeToken(stripeElements.cardNumber)
        .then(chargeToken => {
          return client.post(`/v2/session_sales/${sessionSale.id}/waitlisters`, {
            body: {
              newMembershipCreated,
              stripe_charge_token: chargeToken.id,
              payerDetails: formAddress
            }
          })
        })
    ]

    dispatch(startSubmit(WAITLIST_FORM_NAME))
    return Promise.all(updatePromises)
      .then(() => {
        dispatch(hydrateAuthentication())
        dispatch(stopSubmit(WAITLIST_FORM_NAME))
        browserHistory.push(`/sessions/${sessionSale.slug}`)
        dispatch(openModal(ConfirmModal, {
          title: sessionSale.title,
          price: sessionSale.waitlist_price
        }))
      })
      .catch(err => dispatch(handlePaymentError(err, WAITLIST_FORM_NAME)))
  }).catch(err => {
    dispatch(stopSubmit(WAITLIST_FORM_NAME))
    if (err instanceof BadRequestError) {
      return err.response.json()
        .then(body => {
          if (body?.errors) {
            dispatch({
              type: SET_USER_REGISTRATION_ERROR,
              errors: body.errors
            })
            document.getElementById('session-sale-new-user-registration-form')?.scrollIntoView({
              behavior: 'smooth'
            })
          }
        })
    } else {
      dispatch(handlePaymentError(err, WAITLIST_FORM_NAME))
    }
  })
}
