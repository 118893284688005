import { denormalize } from 'normalizr'
import Raven from 'raven-js'

import { schemas } from 'api'
import { LOAD_CURRENT_USER } from 'auth/types'
import { sentryUrl } from 'config'

import { UNHANDLED_ERROR } from './types'

const shouldReportErrors = !!sentryUrl

if (shouldReportErrors) {
  Raven.config(sentryUrl).install()
}

export default store => next => action => {
  if (action.error) {
    console && console.error && console.error(action.error)
  }

  if (!shouldReportErrors) {
    return next(action)
  }

  switch (action.type) {
    case LOAD_CURRENT_USER:
      const { id, email } = denormalize(action.result, schemas.user, action.entities) // eslint-disable-line no-case-declarations
      Raven.setUserContext({ id, email })
      break
    case UNHANDLED_ERROR:
      // if a custom error message was set, consider the error gracefully handled
      if (!action.message) {
        Raven.captureException(action.error)
      }
      break
  }

  return next(action)
}
