import React from 'react'
import InvoiceIcon from './assets/invoice.svg'
import CreditCardIcon from './assets/creditcard.svg'

const SvgIcon = ({ active, icon: Icon }) => (
  <Icon style={{
    verticalAlign: 'middle',
    margin: '0 6px 2px',
    fill: active ? 'black' : '#a1a1a1'
  }}
  />
)

const Tab = ({ active, onClick, children }) => (
  <button
    type='button'
    onClick={onClick}
    style={{
      display: 'block',
      width: '100%',
      height: '100%',
      fontWeight: 'bold',
      fontSize: '12px',
      cursor: 'pointer',
      color: active ? 'black' : '#a1a1a1',
      backgroundColor: '#fafafa',
      border: 'none',
      borderBottom: active ? '4px solid black' : '4px solid #fafafa'
    }}
  >
    {children}
  </button>
)

const InvoiceRequestSelector = (props) => {
  const { onChange, value: invoiceRequested } = props.input

  return (
    <div style={{ display: 'flex', height: '60px', borderBottom: '1px solid #dedede' }}>
      <Tab active={!invoiceRequested} onClick={() => onChange(false)}>
        <SvgIcon active={!invoiceRequested} icon={CreditCardIcon} />
        Pay Now by Credit Card
      </Tab>
      <Tab active={invoiceRequested} onClick={() => onChange(true)}>
        <SvgIcon active={invoiceRequested} icon={InvoiceIcon} />
        Request an Invoice
      </Tab>
    </div>
  )
}

export default InvoiceRequestSelector
