import React from 'react'
import { connect } from 'react-redux'
import ReactModal from 'react-modal'
import { compact } from 'lodash'

import { closeModal } from '../actions'

import { CancelWhiteIcon, CancelIcon } from 'icons'
import css from './style.scss'

const Modal = (props) => {
  const modalClasses = [
    props.borderlessModal ? css.borderlessModal : css.modal,
    props.disableOverflowY ? '' : css.overflowY
  ]

  const buttonClasses = [
    css.closeButton,
    props.cancelBtnSize ? css[`btn${props.cancelBtnSize}`] : css.btnmedium,
    props.cancelBtnCornerPosition ? css[`btncorner${props.cancelBtnCornerPosition}`] : css.btncornermedium
  ]
  return (
    <ReactModal
      isOpen
      overlayClassName={css.overlay}
      className={compact(modalClasses).join(' ')}
      onRequestClose={props.onRequestClose || props.closeModal}
      contentLabel='Modal'
    >
      <button
        className={compact(buttonClasses).join(' ')}
        type='button'
        onClick={props.onRequestClose || props.closeModal}
      >
        {props.cancelBtnColor && props.cancelBtnColor === 'white' && <CancelWhiteIcon />}
        {props.cancelBtnColor && props.cancelBtnColor === 'black' && <CancelIcon />}
        {!props.cancelBtnColor && <CancelIcon />}
      </button>
      <div className={css.overflowModalContent}>
        {props.children}
      </div>
    </ReactModal>
  )
}

Modal.propTypes = {
  /**
   * set to enable borderless modal
   * disabled by default
   */
  borderlessModal: React.PropTypes.bool,
  /**
   * Available values: white, black
   * default value: black
   */
  cancelBtnColor: React.PropTypes.string,
  /**
   * Available values: small, medium
   * default value: medium
   */
  cancelBtnSize: React.PropTypes.string,
  /**
   * Available values: close, medium
   * default value: medium
   */
  cancelBtnCornerPosition: React.PropTypes.string,
  /**
   * set to disable overflow Y
   * enabled by default
   */
  disableOverflowY: React.PropTypes.bool
}

export default connect(null, { closeModal })(Modal)
