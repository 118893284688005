import React from 'react'
import { withRouter } from 'react-router'
import { compact } from 'lodash'

import { Link } from 'core'

import css from './style.scss'

export const WizardProgress = (props) => {
  const numberOfSteps = props.steps.length
  const style = {
    width: `${100 / numberOfSteps}%`
  }

  const { router: { location } } = props
  const currentStepNum = location.query.step

  return (
    <ol className={css.wizardProgress}>
      {props.steps.map((step, index) => {
        const isEarlierStep = index < currentStepNum

        const classNames = compact([
          css.wizardProgressItem,
          isEarlierStep ? css.earlierStep : null,
          !isEarlierStep ? css.disabled : null
        ]).join(' ')

        return (
          <li key={index} className={classNames} style={style}>
            <Link
              to={{
                pathname: location.pathname,
                query: Object.assign({}, location.query, { step: index + 1 })
              }}
              className={css.wizardLink}
              activeClassName={css.wizardLinkActive}
            >
              {step.name}
              <div className={css.wizardCircleWrapper}>
                <div className={css.wizardCircle} />
              </div>
              <hr className={css.wizardLineLeft} />
              <hr className={css.wizardLineRight} />
            </Link>
          </li>
        )
      })}
    </ol>
  )
}

const { PropTypes } = React

WizardProgress.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string
  })).isRequired,
  router: PropTypes.object.isRequired
}

export default withRouter(WizardProgress)
