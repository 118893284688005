import React from 'react'
import { Button } from 'core'

const CompleteButton = props => {
  if (props.isCompleted) {
    return (
      <Button
        size='primary'
        color='green'
        noMargin
        disabled
        success
      >
        You're done!
      </Button>
    )
  } else {
    return (
      <Button
        size='primary'
        noMargin
        onClick={props.complete} // eslint-disable-line react/jsx-handler-names
        isLoading={props.isLoading}
      >
        I'm done
      </Button>
    )
  }
}

CompleteButton.propTypes = {
  isCompleted: React.PropTypes.bool.isRequired,
  isLoading: React.PropTypes.bool.isRequired,
  complete: React.PropTypes.func.isRequired
}

export default CompleteButton
