import React from 'react'
import { Field } from 'redux-form'
import { connect } from 'react-redux'

import { Heading } from 'core'
import { Input, Label, validators } from 'form'
import { Grid, GridItem } from 'layout'
import { addressFormPropsSelector } from 'address-form/selectors'
import { AddressForm } from 'address-form'

const InvoiceRequestForm = (props) => {
  const { form } = props

  return (
    <div>
      <Heading size='h6'>Billing Information</Heading>
      <Grid>
        <GridItem col='1/2' tablet1of2 mobile1of1>
          <Label htmlFor='invoice_billing_name'>Billing Contact Name*</Label>
          <Field
            name='invoice_billing_name'
            component={Input}
            validate={validators.required('Billing Contact Name is required')}
          />
        </GridItem>
        <GridItem col='1/2' tablet1of2 mobile1of1>
          <Label htmlFor='invoice_institution'>Institution</Label>
          <Field
            name='invoice_institution'
            component={Input}
          />
        </GridItem>
        <GridItem col='1/1' tablet1of1 mobile1of1>
          <Label htmlFor='invoice_billing_email'>
            <div>Billing Contact Email*</div>
            <div>This email will receive a copy of the invoice</div>
          </Label>
          <Field
            name='invoice_billing_email'
            component={Input}
            validate={[
              validators.required('Billing Contact email is required'),
              validators.email('Not a valid email address')
            ]}
          />
        </GridItem>
      </Grid>
      <AddressForm form={form} prefix='invoice_' />
      <p>
        <Label>
          <Field
            name='confirm_funding'
            type='checkbox'
            component='input'
            validate={validators.required('Funding confirmation is required')}
          />
          By completing this form, I confirm that I have secured funding for the amount listed above and would like NCFDD to process my registration and provide me with an invoice for payment. I understand that my registration is final and that the NCFDD cannot offer transfers, cancelations, or refunds after submitting this form. The invoice will be due within 30 days of submitting this form.*
        </Label>
      </p>
    </div>
  )
}

export default connect(
  (state, props) => addressFormPropsSelector(props.form)
)(InvoiceRequestForm)
