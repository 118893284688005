import React from 'react'

import CollectionSingle from './CollectionSingle'

import { Grid, GridItem } from 'layout'

const CardCollection = (props) => {
  let { collection } = props

  collection = (
    collection.map((single, index) => (
      <GridItem key={index} col={props.col} phablet1of1 mobile1of1>
        <CollectionSingle
          {...single}
          count={index}
          spacing={props.spacing}
        />
      </GridItem>
    )))

  return (
    <Grid stretch>
      {collection}
    </Grid>
  )
}

CardCollection.propTypes = {
  collection: React.PropTypes.array.isRequired,
  /**
   * How many columns to generate, passed down to `<GridItem />`.
   * Syntax: `col='x/y'`. Will be inherited at tablet view. Mobile default is 1-up.
   */
  col: React.PropTypes.string.isRequired,
  /**
   * Passed down to each card if true
   */
  spacing: React.PropTypes.bool
}

export default CardCollection
