/* This solution is an application of this approach:
 * https://www.smashingmagazine.com/2015/07/designing-simple-pie-charts-with-css/
 * Big Ups to @thegrandpoobah (Sahab) for the math?!
 */
import React from 'react'

import css from './style.scss'
import { colors } from 'styles'

const { greyTextMed, navyPrimary, orangePrimary } = colors

export const percentageToTransformDeg = (value) => {
  // Taking our percentage value and translating to a degree
  // We're using a negative value to keep 0 as our starting point
  let turn = (-value / 100) * 360

  if (value < 50) {
    // Reversing the direction of turn + re-orient w/ 180 as starting point
    turn = 180 + turn
  }

  return turn
}

const PieChart = ({ value, color }) => {
  value = parseInt(value)

  const colorMapping = {
    grey: greyTextMed,
    navy: navyPrimary,
    orange: orangePrimary
  }

  const pieStyle = {
    backgroundColor: colorMapping[color]
  }

  const halfStyle = {
    transform: `rotate(${percentageToTransformDeg(value)}deg)`,
    backgroundColor: value >= 50 ? colorMapping[color] : 'white'
  }

  return (
    <div className={css.pieContainer} style={pieStyle}>
      {/*
        * Ideally, this would have been a pseudo element, but we
        * can't apply inline styles to a pseudo element 🙃
        */}
      <div className={css.pieHalf} style={halfStyle} />
      <div className={css.center}>
        <div className={css.value}>
          {value}%
        </div>
      </div>
    </div>
  )
}

PieChart.propTypes = {
  value: React.PropTypes.string.isRequired,
  color: React.PropTypes.string
}

PieChart.defaultProps = {
  color: 'orange'
}

export default PieChart
