import React from 'react'
import { connect } from 'react-redux'
import { compact } from 'lodash'
import MediaQuery from 'react-responsive'

import { Button, Link } from 'core'
import { logout, isAuthenticatedSelector, isIAHSelector, isAdminSelector } from 'auth'
import { Grid, GridItem } from 'layout'
import { ncfddLogoOnDark } from 'images'
import { openLoginModal } from 'login-modal'
import CloseIcon from './CloseIcon.svg'

import { closeNavigation } from '../../actions'
import css from './style.scss'
import menuCss from './menu.scss'
import MobileMenuItem from './MobileMenuItem'
import myAccountMenu from '../../myAccountMenu'

const { tabletMenuBreakpoint, mobileMenuMax } = {
  tabletMenuWidth: '622px',
  tabletMenuBreakpoint: '692px',
  mobileMenuMax: '691px'
}

const JoinButton = ({ closeNavigation }) => (
  <Link to='/join' className={css.joinButton} onClick={closeNavigation}>
    <Button size='primary' noMargin>
      Become a member
    </Button>
  </Link>
)

export const MobileMenu = props => {
  const {
    isAuthenticated,
    isOpen,
    sections,
    closeNavigation,
    openLoginModal,
    logout,
    isIAH,
    isAdmin
  } = props

  const classNames = [
    css.container,
    isOpen ? css.isOpen : null
  ]

  return (
    <div className={compact(classNames).join(' ')}>
      <div className={css.screen} onClick={closeNavigation} />
      <button
        type='button'
        className={css.closeButton}
        onClick={closeNavigation}
      >
        <CloseIcon />
      </button>
      <div className={css.mobileMenu}>
        <button
          type='button'
          className={css.closeButtonInline}
          onClick={closeNavigation}
        >
          <CloseIcon />
          Close Menu
        </button>
        <div className={css.mobileMenuHeader}>
          <MediaQuery minWidth={tabletMenuBreakpoint}>
            <Grid>
              <GridItem col='1/2'>
                <Link to='/' onClick={closeNavigation}>
                  <img
                    src={ncfddLogoOnDark}
                    alt='National Center for Faculty Development & Diversity'
                    className={css.logo}
                  />
                </Link>
              </GridItem>
              <GridItem col='1/2'>
                {!isAuthenticated && <JoinButton {...props} />}
              </GridItem>
            </Grid>
          </MediaQuery>
          <MediaQuery maxWidth={mobileMenuMax}>
            {!isAuthenticated && <JoinButton {...props} />}
          </MediaQuery>
        </div>
        <ul className={menuCss.menu}>
          {sections.map((section, index) => (
            <MobileMenuItem {...section} key={index} onNavigation={closeNavigation} />
          ))}
          {isAuthenticated && (
            <MobileMenuItem
              name='My Account'
              to='/account'
              children={myAccountMenu(isIAH, isAdmin)}
              onNavigation={closeNavigation}
            />
          )}
          <li>
            {isAuthenticated
              ? (
                <button className={css.logOutButton} onClick={logout}>
                  Logout
                </button>)
              : (
                <button
                  className={css.logInButton} onClick={() => {
                    closeNavigation()
                    openLoginModal({ redirectToDashboard: false })
                  }}
                >
                  Login
                </button>)}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default connect(
  state => ({
    isAuthenticated: isAuthenticatedSelector(state),
    isIAH: isIAHSelector(state),
    isAdmin: isAdminSelector(state),
    isOpen: state.header.navIsOpen
  }),
  { closeNavigation, openLoginModal, logout }
)(MobileMenu)
