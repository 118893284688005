import React from 'react'
import { compact } from 'lodash'

import css from './style.scss'

const ComboLast = (props) => {
  const classes = [
    css.last,
    props.center ? css.center : ''
  ]

  return (
    <div className={compact(classes).join(' ')}>
      {props.children}
    </div>
  )
}

ComboLast.propTypes = {
  children: React.PropTypes.any.isRequired,
  /**
   * In passing center, it allows us to center-align combo element,
   * as combolast naturally takes up 100%
   */
  center: React.PropTypes.bool
}

export default ComboLast
