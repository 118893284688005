import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  hydrateAuthentication,
  authIsHydratedSelector,
  authHydrationFailedSelector
} from 'auth'
import {
  loadSettings,
  settingsDataSelector,
  settingsLoadFailedSelector
} from 'settings'
import { LoadingScreen } from 'loading-screen'

import ServerIsDownPage from './ServerIsDownPage'

export class Bootstrap extends Component {
  componentDidMount () {
    this.props.loadSettings()
    this.props.hydrateAuthentication()
  }

  componentDidUpdate () {
    const cssStyleElement = document.querySelector('#ncfdd_global_css')
    if (this.props?.settings?.global_css && !cssStyleElement) {
      const style = document.createElement('style')
      style.id = 'ncfdd_global_css'
      style.innerHTML = this.props.settings.global_css
      document.head.appendChild(style)
    }
  }

  render () {
    const { children, authIsHydrated, settings, authHydrationFailed, settingsLoadFailed } = this.props

    if (settings && authIsHydrated) {
      return <div>{children}</div>
    } else if (authHydrationFailed || settingsLoadFailed) {
      return <ServerIsDownPage />
    } else {
      return (
        <LoadingScreen />
      )
    }
  }
}

Bootstrap.propTypes = {
  children: PropTypes.node,
  settings: PropTypes.object,
  authIsHydrated: PropTypes.bool,
  authHydrationFailed: PropTypes.bool,
  settingsLoadFailed: PropTypes.bool
}

const mapStateToProps = createStructuredSelector({
  authIsHydrated: authIsHydratedSelector,
  authHydrationFailed: authHydrationFailedSelector,
  settings: settingsDataSelector,
  settingsLoadFailed: settingsLoadFailedSelector
})

const mapDispatchToProps = {
  hydrateAuthentication,
  loadSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Bootstrap
)
