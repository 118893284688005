import React from 'react'

import { Spacer } from 'layout'

import { vimeoFormatter, youtubeFormatter } from './videoFormatters'

import { ArticleBody } from '../ArticleBody'
import { CMSSection } from '../components'

import css from './style.scss'

const RichtextAndVideo = (props) => {
  const { title, subtitle, body, video_provider: provider } = props

  let url

  if (provider === 'vimeo') {
    url = vimeoFormatter(props.video_embed)
  } else if (provider === 'youtube') {
    url = youtubeFormatter(props.video_embed)
  }

  return (
    <CMSSection
      title={title}
      subtitle={subtitle}
    >
      <ArticleBody
        left
        proxima
        body={body}
      />
      <div className={css.wrapper}>
        <Spacer size='sm' />
        <div className={css.video}>
          <iframe
            src={url}
            frameBorder='0'
            scrolling='no'
            allowFullScreen
          />
        </div>
      </div>
    </CMSSection>
  )
}

RichtextAndVideo.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  body: React.PropTypes.string,
  video_provider: React.PropTypes.string.isRequired,
  video_embed: React.PropTypes.string.isRequired
}

export default RichtextAndVideo
