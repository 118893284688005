import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import ui from 'redux-ui'
import { findIndex } from 'lodash'
import moment from 'moment'
import { push } from 'react-router-redux'

import {
  PageWithSidebar,
  PageMain,
  PageSidebar,
  Tabs
} from 'layout'
import {
  joinRoom,
  leaveRoom,
  postMessage,
  chatRoomEventsSelector
} from 'chat'
import { applyHOCs } from 'helpers'
import { isAuthenticatedSelector } from 'auth'

import { registerForWebinar, unregisterForWebinar, completeWebinar, startWebinar } from './actions'

import { ChatInactive } from './components/ChatInactive'
import { VideoHeader } from './components/VideoHeader'
import WebinarDescription from './components/WebinarDescription'
import { WebinarResources } from './components/WebinarResources'
import WebinarBio from './components/WebinarBio'
import WebinarChat from './components/WebinarChat'
import RelatedCurriculumWebinars from './components/RelatedCurriculumWebinars'
import RelatedGuestWebinars from './components/RelatedGuestWebinars'
import RelatedCourseWebinars from './components/RelatedCourseWebinars'

class WebinarPage extends React.Component {
  componentDidMount () {
    if (!this.props.isAuthenticated) return

    window.addEventListener('beforeunload', this.leaveChatRoom)

    // force rerender once the webinar starts to show the chat section
    if (moment().isBefore(this.props.webinar.start_at)) {
      const timeRemaining = moment(this.props.webinar.start_at).diff(new Date())
      this.webinarStartTimeout = setTimeout(this.forceUpdate.bind(this), timeRemaining)
    }

    // force rerender once the webinar is over to hide chat section
    if (moment().isBefore(this.props.webinar.end_at)) {
      const timeRemaining = moment(this.props.webinar.end_at).diff(new Date())
      this.webinarCompleteTimeout = setTimeout(this.forceUpdate.bind(this), timeRemaining)
    }
  }

  componentWillUnmount () {
    if (!this.props.isAuthenticated) return

    this.leaveChatRoom()
    window.removeEventListener('beforeunload', this.leaveChatRoom)

    if (this.webinarStartTimeout) {
      clearTimeout(this.webinarStartTimeout)
    }
    if (this.webinarCompleteTimeout) {
      clearTimeout(this.webinarCompleteTimeout)
    }
  }

  leaveChatRoom = () => {
    this.props.leaveChatRoom()
  }

  goToLibrary = () => {
    this.props.push('/library')
  }

  goToHomepage = () => {
    this.props.push('/')
  }

  render () {
    const { webinar, isAuthenticated } = this.props

    const now = moment()
    const chatIsActive = isAuthenticated &&
      !webinar.chat_disabled &&
      now.isSameOrAfter(webinar.start_at) &&
      now.isBefore(webinar.end_at)

    const sections = [
      {
        name: 'description',
        component: <WebinarDescription description={webinar.description} />
      }, {
        name: 'chat',
        component:
          chatIsActive
            ? <WebinarChat
                chatEvents={this.props.chatEvents}
                postMessage={this.props.postChatMessage}
              />
            : <ChatInactive
                start={this.props.webinar.start_at}
                end={this.props.webinar.end_at}
                isDisabled={webinar.chat_disabled}
              />
      }, {
        name: 'resources',
        component: <WebinarResources id={webinar.id} resources={webinar.resources} />
      }
    ]

    if (webinar.facilitator) {
      sections.push({
        name: 'facilitator bio',
        component: <WebinarBio bio={webinar.facilitator.about_me} />
      })
    }

    let sidebarComponent

    if (webinar.type === 'course') {
      sidebarComponent = <RelatedCourseWebinars currentWebinarId={webinar.id} series_id={webinar.series_id} />
    } else if (webinar.type === 'curriculum') {
      sidebarComponent = <RelatedCurriculumWebinars currentWebinarId={webinar.id} />
    } else {
      sidebarComponent = <RelatedGuestWebinars currentWebinarId={webinar.id} />
    }

    return (
      <div>
        <VideoHeader
          webinar={webinar}
          register={this.props.onRegisterForWebinar} // eslint-disable-line react/jsx-handler-names
          unregister={this.props.onUnregisterForWebinar} // eslint-disable-line react/jsx-handler-names
          isUnregistering={this.props.isUnregistering}
          isRegistering={this.props.isRegistering}
          complete={this.props.onCompleteWebinar} // eslint-disable-line react/jsx-handler-names
          isCompleting={this.props.isCompleting}
          isAuthenticated={this.props.isAuthenticated}
          started={this.props.onStartWebinar} // eslint-disable-line react/jsx-handler-names
        />
        <PageWithSidebar>
          <PageMain>
            <Tabs
              sections={sections}
              onSelectedIndex={(index) => {
                if (isAuthenticated && index === findIndex(sections, { name: 'chat' })) {
                  this.props.onOpenedChatTab()
                }
              }}
            />
          </PageMain>
          <PageSidebar>
            {sidebarComponent}
          </PageSidebar>
        </PageWithSidebar>
      </div>
    )
  }
}

WebinarPage.propTypes = {
  chatEvents: React.PropTypes.arrayOf(React.PropTypes.object),
  postChatMessage: React.PropTypes.func.isRequired,
  leaveChatRoom: React.PropTypes.func.isRequired,
  push: React.PropTypes.func.isRequired,
  // Passed down to <VideoHeader />,
  onRegisterForWebinar: React.PropTypes.func,
  onUnRegisterForWebinar: React.PropTypes.func,
  isRegistering: React.PropTypes.bool,
  webinar: React.PropTypes.object.isRequired,
  onOpenedChatTab: React.PropTypes.func,
  onCompleteWebinar: React.PropTypes.func,
  onStartWebinar: React.PropTypes.func,
  isCompleting: React.PropTypes.bool,
  isAuthenticated: React.PropTypes.bool.isRequired
}

const roomName = webinar => `webinar-${webinar.id}`

const mapStateToProps = createSelector(
  (state, ownProps) => chatRoomEventsSelector(roomName(ownProps.webinar))(state),
  (state) => state.webinars.isRegistering,
  (state) => state.webinars.isUnregistering,
  (state) => state.webinars.isCompleting,
  (state) => isAuthenticatedSelector(state),
  (chatEvents, isRegistering, isUnregistering, isCompleting, isAuthenticated) => ({
    chatEvents,
    isRegistering,
    isUnregistering,
    isCompleting,
    isAuthenticated
  })
)

const mapDispatchToProps = (dispatch, ownProps) => ({
  postChatMessage: (message) => {
    dispatch(postMessage(message, roomName(ownProps.webinar)))
  },
  onOpenedChatTab: () => {
    if (!ownProps.ui.hasOpenedChat) {
      dispatch(joinRoom(roomName(ownProps.webinar)))
      ownProps.updateUI({ hasOpenedChat: true })
    }
  },
  leaveChatRoom: () => {
    dispatch(leaveRoom(roomName(ownProps.webinar)))
  },
  onRegisterForWebinar: () => {
    dispatch(registerForWebinar(ownProps.webinar.id, ownProps.webinar.slug))
  },
  onUnregisterForWebinar: () => {
    dispatch(unregisterForWebinar(ownProps.webinar.id, ownProps.webinar.slug))
  },
  onCompleteWebinar: () => {
    dispatch(completeWebinar(ownProps.webinar.id))
  },
  onStartWebinar: () => {
    dispatch(startWebinar(ownProps.webinar.id))
  },
  push
})

export default applyHOCs([
  connect(mapStateToProps, mapDispatchToProps),
  ui({
    state: {
      hasOpenedChat: false
    }
  })
])(WebinarPage)
