import React from 'react'

import Modal from './Modal'

import css from './style.scss'

const FailureModal = ({ children }) => {
  return (
    <Modal>
      <div className={css.modalWrapper}>
        <div className={css.content}>
          {children}
        </div>
      </div>
    </Modal>
  )
}

FailureModal.propTypes = {
  children: React.PropTypes.node
}

export default FailureModal
