import React from 'react'
import { compact } from 'lodash'
import { Link } from 'react-router'

import Card from '../Card'
import { Button } from '../../Button'
import { Heading } from '../../Heading'
import { Combo, ComboFirst, ComboLast } from 'layout'
import { Tick } from 'icons'

/* Styles live ./_collection.scss  */
import css from '../style.scss'

const CollectionSingle = (props) => {
  const classes = [
    props.spacing ? css.spacing : ''
  ]

  const countClasses = [
    css.countCircle,
    props.complete ? css.countCircleComplete : ''
  ]

  return (
    <div className={compact(classes).join(' ')}>
      <Card fullHeight>
        <Combo middle wide>
          <ComboFirst>
            <div className={compact(countClasses).join(' ')}>
              {props.complete
                ? <Tick />
                : <span>{props.count + 1}</span>}
            </div>
          </ComboFirst>
          <ComboLast>
            <div className={css.headingCollection}>
              <Heading size='h5' noMargin>{props.title}</Heading>
            </div>
            {React.createElement(
              props.watching ? 'div' : Link,
              {
                className: css.watchButton,
                to: props.watching ? undefined : props.url
              },
              (
                <div className={css.watchButton}>
                  <Button size='tertiary' noMargin disabled={props.watching}>
                    Watch Now
                  </Button>
                </div>
              )
            )}
          </ComboLast>
        </Combo>
      </Card>
    </div>
  )
}

CollectionSingle.propTypes = {
  count: React.PropTypes.number.isRequired,
  title: React.PropTypes.string.isRequired,
  id: React.PropTypes.number.isRequired,
  url: React.PropTypes.string.isRequired,
  watching: React.PropTypes.bool,
  complete: React.PropTypes.bool,
  /**
   * Inherited from parent. Sets a margin-bottom equivalent to Grid
   * gutters to space elements out equally.
   */
  spacing: React.PropTypes.bool
}

export default CollectionSingle
