import Immutable from 'seamless-immutable'
import { values } from 'lodash'
import { filterActions } from 'redux-ignore'

import * as types from './types'
const { OPEN_MODAL, CLOSE_MODAL } = types

const initialState = Immutable({
  modal: null
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      state = state.set('modal', {
        component: action.component, props: action.props
      })
      break
    case CLOSE_MODAL:
      state = state.set('modal', null)
      break
  }

  return state
}

export default filterActions(reducer, values(types))
