import React from 'react'
import MediaQuery from 'react-responsive'

import { Button, Card, Heading, Link, Avatar } from 'core'
import { Grid, GridItem, Spacer } from 'layout'

import { breakpoints } from 'styles'

const { phabletMax } = breakpoints

const MondayMotivator = (props) => (
  <Card spacing spaceBetween color='blue'>
    <Avatar user={props.author} size='xl' />
    <Heading size='h4' weight='semibold'>{props.content.title}</Heading>
    <Heading size='h6' noMargin weight='light'>
      by {props.author.name}
    </Heading>
    <MediaQuery maxWidth={phabletMax}>
      <Spacer size='xs' />
    </MediaQuery>
    <Grid>
      <GridItem col='1/2' tablet2of3 mobile2of3>
        <Link to={`/monday-motivator/${props.content.slug}`}>
          <Button noMargin size='secondary'>Read More</Button>
        </Link>
      </GridItem>
    </Grid>
  </Card>
)

MondayMotivator.propTypes = {
  content: React.PropTypes.shape({
    title: React.PropTypes.string.isRequired
  }).isRequired,
  author: React.PropTypes.shape({
    name: React.PropTypes.string.isRequired,
    avatar_url: React.PropTypes.string.isRequired
  })
}

export default MondayMotivator
