import React from 'react'

import css from './style.scss'

const PageSidebar = (props) => (
  <aside className={css.sidebar}>
    <div className={css.tableOverride}>
      {props.children}
    </div>
  </aside>
)

export default PageSidebar
