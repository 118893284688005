import React from 'react'
import { connect } from 'react-redux'
import { Modal, closeModal } from 'modal'
import { mailchimpEmbedSelector } from 'settings'

import css from './style.scss'
import { trackFormSubmit } from 'analytics'
import { trackFormStart } from 'analytics/form'

class SubscribeModal extends React.Component {
  componentDidMount () {
    this.form.addEventListener('submit', () => {
      trackFormSubmit({ formName: 'Subscribe footer' })
      setTimeout(this.props.closeModal, 0)
    })
    this.form.addEventListener('focus', () => {
      trackFormStart({ formName: 'Subscribe footer' })
    })
  }

  render () {
    const { mailchimpEmbedCode } = this.props

    return (
      <Modal>
        <div
          className={css.subscribeModal}
          ref={element => {
            this.form = element && element.querySelector('form')
          }}
          dangerouslySetInnerHTML={{ __html: mailchimpEmbedCode }}
        />
      </Modal>
    )
  }
}

SubscribeModal.propTypes = {
  mailchimpEmbedCode: React.PropTypes.string.isRequired,
  closeModal: React.PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  mailchimpEmbedCode: mailchimpEmbedSelector(state)
})

const mapDispatchToProps = {
  closeModal
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeModal)
