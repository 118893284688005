import React from 'react'

import {
  SuccessModal,
  ModalHeading,
  ModalSubheading
} from 'modal'

const alumText = `We will be reaching out before the program officially
  begins to collect more information regarding your group preferences for
  the upcoming session. Be sure to add Bootcamp@ncfdd.org to
  your address book to ensure that you receive our emails.`
const writingText = `We will be reaching out the day before the challenge
  officially begins with more information regarding how to log in, track
  your writing time and connect with the community. Be sure to add
  14DayChallenge@ncfdd.org to your address book to ensure that
  you receive our emails.`

const NonFSPConfirmModal = ({ sessionSale }) => {
  const text = sessionSale.type === 'writing_challenge'
    ? writingText
    : alumText

  return (
    <SuccessModal>
      <ModalHeading>
        Thank you for joining the {sessionSale.title}
      </ModalHeading>

      <ModalSubheading>What happens next?</ModalSubheading>
      <p>{text}</p>
    </SuccessModal>
  )
}

export default NonFSPConfirmModal
