import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { filter, orderBy } from 'lodash'

import { reservationsSelector } from '../../selectors'
import ReservationListItem from './ReservationListItem'
import css from './style.scss'

export const ReservationList = ({ reservations, assignmentsAreAvailable }) => {
  const assigned = filter(reservations, 'user_id')

  return (
    <div>
      {assignmentsAreAvailable && (
        <p className={css.participantCount}>
          You've added {assigned.length} of {reservations.length} participants
        </p>
      )}
      <ol className={css.reservationList}>
        {orderBy(assigned, ['updated_at', 'asc'])
          .map(reservation => (
            <ReservationListItem
              key={reservation.id}
              reservation={reservation}
              removeDisabled={!assignmentsAreAvailable}
            />
          ))}
      </ol>
    </div>
  )
}

ReservationList.propTypes = {
  reservations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    user_id: PropTypes.number
  })).isRequired,
  assignmentsAreAvailable: PropTypes.bool
}

export default connect(
  createStructuredSelector({
    reservations: reservationsSelector
  })
)(ReservationList)
