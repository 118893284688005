import React from 'react'
import { compact } from 'lodash'

import css from './style.scss'

/**
 * The `<Card />` component is a simple wrapper that sets all passed content in
 * an element with a white background, border and some padding applied. A background
 * image can also be passed, which will override the white background and generate
 * a blue screen over the background image.
 */
const Card = (props) => {
  const classes = [
    css.card,
    props.img ? css.background : '',
    props.noShadow ? css.noShadow : '',
    props.fullHeight ? css.fullHeight : '',
    props.spacing ? css.spacing : '',
    props.spacingMobile ? css.spacingMobile : '',
    props.spaceBetween ? css.spaceBetween : '',
    props.color ? css[props.color] : ''
  ]

  const style = {}

  /* Safe-check to only set background-image property if image passed;
   * else, inline background-image rule overrides CSS background gradient.
   */
  if (props.img) {
    style.backgroundImage = `url(${props.img})`
  }

  return (
    <div
      className={compact(classes).join(' ')}
      style={style}
    >
      {props.children}
    </div>
  )
}

Card.propTypes = {
  children: React.PropTypes.node.isRequired,
  /**
   * Optional prop for cards w/ background images
   */
  img: React.PropTypes.string,
  /**
   * When true, removes default box shadow on Card
   */
  noShadow: React.PropTypes.bool,
  /**
   * When true, sets height of card to be 100% -- required parent
   * Grid to have `stretch` prop present :^)
   */
  fullHeight: React.PropTypes.bool,
  /**
   * When true, sets a margin on card equivalent to that of
   * the Grid gutter
   */
  spacing: React.PropTypes.bool,
  /**
   * When true, sets a margin on card equivalent to that of
   * the section padding in mobile contexts
   */
  spacingMobile: React.PropTypes.bool,
  /**
   * When true, sets justify-content: space-between; on card
   * to space elements apart equally
   */
  spaceBetween: React.PropTypes.bool,
  /**
   * When passed, sets a background-color on card.
   * Available values: blue
   */
  color: React.PropTypes.string
}

export default Card
