import React from 'react'

import { Button, Card, Link } from 'core'
import { Grid, GridItem } from 'layout'

import { CMSSection } from '../components'

import css from './style.scss'

const Banner = (props) => {
  const { title, subtitle, copy, cta_url: ctaUrl, cta_label: ctaLabel } = props

  return (
    <CMSSection
      title={title}
      subtitle={subtitle}
    >
      <Card>
        <div className={css.wrapper}>
          <Grid center>
            <GridItem col='2/3' phablet1of1 mobile1of1>
              <div className={css.copy}>
                {copy}
              </div>
            </GridItem>
            <GridItem col='1/3' phablet1of1 mobile1of1>
              <div className={css.button}>
                <Link to={ctaUrl}>
                  <Button noMargin size='secondary'>
                    {ctaLabel}
                  </Button>
                </Link>
              </div>
            </GridItem>
          </Grid>
        </div>
      </Card>
    </CMSSection>
  )
}

Banner.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  copy: React.PropTypes.string.isRequired,
  cta_url: React.PropTypes.string.isRequired,
  cta_label: React.PropTypes.string.isRequired
}

export default Banner
