import React from 'react'

import css from './style.scss'

const FieldGroup = (props) => (
  <div className={css.fieldGroup}>
    {props.children}
  </div>
)

FieldGroup.propTypes = {
  children: React.PropTypes.node
}

export default FieldGroup
