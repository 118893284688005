import React from 'react'
import WebinarSingle from './WebinarSingle'

const WebinarCollection = (props) => {
  let { collection } = props

  collection = (
    collection.map((single, index) => {
      return (
        <WebinarSingle
          key={index}
          slug={single.slug}
          type={single.type}
          title={single.title}
          start={single.start_at}
          facilitator={single.facilitator}
        />
      )
    })
  )

  return (
    <div>
      {collection}
    </div>
  )
}

WebinarCollection.propTypes = {
  collection: React.PropTypes.array.isRequired
}

export default WebinarCollection
