import React from 'react'

import { load, schemas } from 'api'
import { CardBootcamp, CardWriteNow } from './components/Cards'

const NextSessionSaleCard = ({ activeGroups, featuredSession, loadStatus }) => {
  if (loadStatus.featuredSession.loading || !activeGroups) {
    return null
  }

  if (activeGroups.length > 0) {
    return <CardWriteNow session={activeGroups[0].session} />
  }

  if (!featuredSession) {
    return <p style={{ padding: '0 16px' }}>There are no upcoming bootcamps.</p>
  }

  const { target } = featuredSession

  return <CardBootcamp sessionSale={target} />
}

NextSessionSaleCard.propTypes = {
  activeGroups: React.PropTypes.array,
  upcomingSessionSales: React.PropTypes.array,
  loadStatus: React.PropTypes.object.isRequired
}

export default load({
  activeGroups: {
    urlSelector: () => '/v2/users/current/groups',
    schema: [schemas.group]
  },
  featuredSession: {
    urlSelector: () => '/v2/events/featured_session',
    schema: schemas.event
  }
})(NextSessionSaleCard)
