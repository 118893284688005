
/**
 * Helper to create a wrapper component with an optional
 * options object
 *
 * Using to create a wrapper:
 * ```js
 * export default buildWrapper((options = {}) => {
 *   return Wrapper({})
 * })
 * ```
 *
 * Using the wrapper:
 * ```js
 * Wrapper(WrappedComponent) // no options
 * Wrapper(options)(WrappedComponent) // with options
 * ```
 */

export default (generateWrapper) => (options) => {
  if (typeof options === 'object') {
    return generateWrapper(options)
  } else {
    return generateWrapper()(options)
  }
}
