import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'

import { MAX_VAR_CHAR_LENGTH } from 'consts'
import { Label, Input, Select, validators } from 'form'
import { Grid, GridItem } from 'layout'

import { addressFormPropsSelector } from './selectors'

export const AddressForm = (props) => {
  const {
    prefix = '',
    countryOptions,
    countryPostalPattern,
    countryPostal,
    stateOptions,
    stateLabel
  } = props

  const field = name => `${prefix}${name}`

  /* eslint-disable camelcase */
  return (
    <Grid>
      <GridItem col='1/2' tablet1of2 mobile1of1>
        <Label htmlFor={field('address_line_1')}>Address Line 1*</Label>
        <Field
          name={field('address_line_1')}
          component={Input}
          validate={validators.required('Your address is required')}
          maxLength={MAX_VAR_CHAR_LENGTH}
        />
      </GridItem>
      <GridItem col='1/2' tablet1of2 mobileHide />

      <GridItem col='1/2' tablet1of2 mobile1of1>
        <Label htmlFor={field('address_line_2')}>Address Line 2</Label>
        <Field
          name={field('address_line_2')}
          component={Input}
          maxLength={MAX_VAR_CHAR_LENGTH}
        />
      </GridItem>
      <GridItem col='1/2' tablet1of2 mobileHide />

      <GridItem col='1/2' tablet1of2 mobile1of1>
        <Label htmlFor={field('city')}>City*</Label>
        <Field
          name={field('city')}
          component={Input}
          validate={validators.required('Your city is required')}
          maxLength={MAX_VAR_CHAR_LENGTH}
        />
      </GridItem>
      <GridItem col='1/2' tablet1of2 mobileHide />

      <GridItem col='1/2' tablet1of2 mobile1of1>
        <Label htmlFor={field('country')}>Country*</Label>
        <Field
          name={field('country')}
          component={Select}
          options={countryOptions.map(({ country_name, country_abbr }) => ({ // eslint-disable-line camelcase
            name: country_name,
            value: country_abbr
          }))}
          validate={validators.required('Your country is required')}
        />
      </GridItem>
      <GridItem col='1/2' tablet1of2 mobileHide />

      <GridItem col='1/4' tablet1of2 mobile1of2>
        <Label htmlFor={field('state')}>{`${stateLabel}*`}</Label>
        <Field
          name={field('state')}
          component={(field) => {
            if (stateOptions.length > 0) {
              return <Select {...field} />
            } else {
              return <Input {...field} />
            }
          }}
          options={stateOptions.map(({ state_abbr }) => ({
            name: state_abbr,
            value: state_abbr
          }))}
          validate={validators.stateSelected(`Your ${stateLabel} is required`)}
        />
      </GridItem>
      <GridItem col='0/0' tablet1of2 mobileHide />

      <GridItem col='1/4' tablet1of2 mobile1of2>
        <Label htmlFor={field('postal_code')}>{`${countryPostal}*`}</Label>
        <Field
          name={field('postal_code')}
          component={Input}
          validate={[
            validators.postalCode(countryPostalPattern, `You must enter a valid ${countryPostal}`),
            validators.required(`Your ${countryPostal} is required`)
          ]}
        />
      </GridItem>
    </Grid>
  )
}

AddressForm.propTypes = {
  /* Optional prefix to enable mutliple instances within a form */
  prefix: React.PropTypes.string,
  /*
   * VIP -- Very Imporant Prop
   * Passed from redux-form via PaymentForm
   */
  form: React.PropTypes.string.isRequired,
  /* Received from countriesSelector */
  countryOptions: React.PropTypes.arrayOf(React.PropTypes.shape({
    country_abbr: React.PropTypes.string.isRequired,
    country_name: React.PropTypes.string.isRequired
  })).isRequired,
  /* mapStatetoProps via addressFormPropsSelector */
  countryPostal: React.PropTypes.string.isRequired,
  countryPostalPattern: React.PropTypes.object,
  stateOptions: React.PropTypes.arrayOf(React.PropTypes.shape({
    state_abbr: React.PropTypes.string.isRequired
  })),
  stateLabel: React.PropTypes.string.isRequired
}

export default connect(
  (state, props) => addressFormPropsSelector(props.form, props.prefix)
)(AddressForm)
