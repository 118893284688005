import React from 'react'
import css from './style.scss'

const DiscountCodeInput = props => {
  return (
    <input
      type='text'
      className={css.discountCodeInput}
      {...props.input}
    />
  )
}

export default DiscountCodeInput
