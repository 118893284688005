import { createSelector } from 'reselect'
import moment from 'moment'
import { renewalReminderTimeUntilExpiry } from 'config'

import { currentUserMembershipSelector } from 'auth'

const { duration, units } = renewalReminderTimeUntilExpiry

export const shouldRemindToRenewSelector = createSelector(
  currentUserMembershipSelector,
  (membership) => {
    if (!membership) return false
    if (!membership.expires_at) return false
    return moment().isBetween(
      moment(membership.expires_at).subtract(duration, units), moment(membership.expires_at)
    )
  }
)
