import React, { PropTypes } from 'react'

import { formatPercent, formatPrice } from 'helpers'

import css from './style.scss'

const formatDiscountValue = (effectiveDiscount) => {
  return effectiveDiscount.flat_amount
    ? formatPrice(effectiveDiscount.flat_amount)
    : formatPercent(effectiveDiscount.percent_amount)
}

const renderDiscountRow = (effectiveDiscount) => (
  <tr key={`discount-${effectiveDiscount.id}`}>
    <td>
      Promo {effectiveDiscount.code}: {formatDiscountValue(effectiveDiscount)}
    </td>
    <td>
      {formatPrice(effectiveDiscount.effective_discount_amount)}
    </td>
  </tr>
)

export const OrderSummaryTable = ({ subtotal, effectiveDiscounts, discountAmount, total }) => {
  const hasDiscount = discountAmount > 0

  return (
    <table className={css.wrapper}>
      <tbody>
        {hasDiscount && [
          <tr key='subtotal'>
            <td>
              Sub Total:
            </td>
            <td>
              {formatPrice(subtotal)}
            </td>
          </tr>,
          ...effectiveDiscounts.map((effectiveDiscount, i) => renderDiscountRow(effectiveDiscount, i))
        ]}
        <tr className={css.total}>
          <td>
            {hasDiscount && 'Final '}Total:
          </td>
          <td>
            {formatPrice(total)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export const OrderSummaryPropTypes = {
  subtotal: PropTypes.number.isRequired,
  effectiveDiscounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    effective_discount_amount: PropTypes.number.isRequired,
    flat_amount: PropTypes.number,
    percent_amount: PropTypes.number
  })).isRequired,
  discountAmount: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

OrderSummaryTable.propTypes = OrderSummaryPropTypes

export default OrderSummaryTable
