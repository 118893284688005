import {
  getFormValues,
  startSubmit,
  stopSubmit
} from 'redux-form'
import { push } from 'react-router-redux'
import { forceStep } from 'form-wizard'

import { openModal } from 'modal'
import { createClient } from 'auth'
import { discountCodesSelector } from 'order-summary'
import {
  createStripeChargeToken,
  billingAddressSelector,
  handlePaymentError,
  transactionErrorSelector
} from 'stripe'

import { FORM_NAME } from '../constants'
import { login } from '../actions'
import { WelcomeModal } from '../components/WelcomeModal'
import steps from './steps'
import { getIndividualRegistrationOrderSummary } from './selectors'

export const submit = (props) => (dispatch, getState) => {
  const state = getState()
  const values = getFormValues(FORM_NAME)(state)
  const billingAddress = billingAddressSelector(FORM_NAME)(state)
  const discountCodes = discountCodesSelector(FORM_NAME)(state)
  const orderSummary = getIndividualRegistrationOrderSummary(state, props)

  dispatch(startSubmit(FORM_NAME))
  return Promise.resolve(
    orderSummary.total > 0
      ? createStripeChargeToken(props.stripeElements.cardNumber)
      : null
  )
    .then(chargeToken => {
      const { membershipPlan } = props
      const body = {
        ...values,
        ...billingAddress,
        membership_plan_id: membershipPlan.id,
        stripe_charge_token: chargeToken ? chargeToken.id : undefined
      }

      if (discountCodes) {
        body.discount_codes = discountCodes
      }

      return createClient(getState()).post('/v2/users', { body })
    })
    .then(() => dispatch(login(values.email, values.password)))
    .then(() => {
      dispatch(stopSubmit(FORM_NAME))
      dispatch(push('/dashboard'))
      dispatch(openModal(WelcomeModal))
    })
    .catch(err => {
      dispatch(handlePaymentError(err, FORM_NAME))
        ?.then(() => {
          if (transactionErrorSelector(getState(), { form: FORM_NAME })) {
            // go to bottom of "payment" step to view error
            dispatch(forceStep(steps.findIndex(s => s && s.name === 'Payment') + 1))
            window.scrollTo(0, document.body.scrollHeight)
          }
        })
      return err
    })
}
