import React, { PropTypes } from 'react'

import css from './style.scss'

const Notice = ({ children }) => (
  <aside className={css.notice}>
    {children}
  </aside>
)

Notice.propTypes = {
  children: PropTypes.node
}

export default Notice
