import { actionTypes } from 'redux-form'

export default (state, action) => {
  if (!state) return state

  if (action.type === actionTypes.CHANGE && action.meta.field === 'country') {
    return ({
      ...state,
      values: {
        ...state.values,
        state: undefined
      },
      fields: {
        ...state.fields,
        state: undefined
      }
    })
  }

  return state
}
