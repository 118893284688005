import React from 'react'
import moment from 'moment'

import {
  SuccessModal,
  ModalHeading
} from 'modal'

const formatDate = date => moment(date).format('MMM D, YYYY')

const MembershipRenewalSuccessModal = ({ membershipExpiry }) => {
  return (
    <SuccessModal>
      <ModalHeading>
        Your membership has been renewed
      </ModalHeading>
      <p>
        You can now access all of the NCFDD membership content.
        Your membership will expire on {formatDate(membershipExpiry)}.
      </p>
    </SuccessModal>
  )
}

export default MembershipRenewalSuccessModal
