import React from 'react'
import { connect } from 'react-redux'

import { currentUserSelector } from 'auth'
import { BannerNotice } from 'core'

import { stopImpersonating } from './actions'
import css from './style.scss'

export const ImpersonationNotification = ({ stopImpersonating, user }) => {
  let bannerMarkup
  if (user && user.is_impersonated) {
    bannerMarkup = (
      <BannerNotice>
        You are currently impersonating <b>{user.email}</b>.
        <button
          type='button'
          className={css.stopImpersonatingButton}
          onClick={stopImpersonating}
        >
          (Stop impersonating)
        </button>
      </BannerNotice>
    )
  } else {
    return null
  }

  return bannerMarkup
}

ImpersonationNotification.propTypes = {
  user: React.PropTypes.object,
  stopImpersonating: React.PropTypes.func.isRequired
}

export default connect(
  (state) => ({
    user: currentUserSelector(state)
  }),
  { stopImpersonating }
)(ImpersonationNotification)
