import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import { currentUserSelector } from 'auth'
import { applyHOCs } from 'helpers'
import { Avatar, Button } from 'core'

import { saveAvatar } from '../actions'
import { AVATAR_FORM_NAME } from '../constants'
import css from './style.scss'

class AvatarForm extends React.Component {
  render () {
    const { user, saveAvatar, submitting } = this.props

    const FileInput = ({
      input: {
        value: omitValue,
        onChange,
        ...inputProps
      },
      meta: omitMeta,
      ...props
    }) => (
      <input
        style={{ display: 'none' }}
        onChange={e => {
          const file = e.target.files[0]
          if (file) {
            saveAvatar(file)
          }
        }}
        type='file'
        ref={(input) => { this.fileInput = input }}
        {...inputProps}
        {...props}
      />
    )

    return (
      <form>
        <div className={css.avatar}>
          <Avatar user={user} size='avatarUpload' />
        </div>
        <div className={css.button}>
          <Button
            size='secondary'
            onClick={() => { this.fileInput.click() }}
            disabled={submitting}
            isLoading={submitting}
          >
            Upload New Photo
          </Button>
          <Field
            name='avatar'
            component={FileInput}
          />
        </div>
      </form>
    )
  }
}

AvatarForm.propTypes = {
  user: React.PropTypes.object.isRequired,
  saveAvatar: React.PropTypes.func.isRequired,
  submitting: React.PropTypes.bool
}

export default applyHOCs([
  reduxForm({
    form: AVATAR_FORM_NAME
  }),
  connect(state => ({
    user: currentUserSelector(state) || { avatar_url: '' }
  }), { saveAvatar })
])(AvatarForm)
