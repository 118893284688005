import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { createStructuredSelector } from 'reselect'

import { isAuthenticatedSelector } from 'auth'
import { Button } from 'core'
import { ErrorPage, ErrorHeading, ErrorButton } from 'error-page'
import { openLoginModal } from 'login-modal'
import { PageHead } from 'page/PageHead'

export const ForbiddenPage = ({ authenticated, openLoginModal, push, pageType }) => (
  <ErrorPage>
    <PageHead />
    <ErrorHeading>This {pageType} requires an NCFDD membership</ErrorHeading>
    {authenticated
      ? (
        <ErrorButton onClick={() => push('/renew-membership')}>Renew My Membership</ErrorButton>
        )
      : (
        <div style={{ textAlign: 'center' }}>
          <ErrorButton onClick={() => push('/join')}>Join Now</ErrorButton>
          <Button
            size='secondary'
            outline
            onClick={() => openLoginModal({ redirectToDashboard: false })}
          >
            Sign In
          </Button>
        </div>
        )}
  </ErrorPage>
)

ForbiddenPage.propTypes = {
  authenticated: React.PropTypes.bool,
  openLoginModal: React.PropTypes.func.isRequired,
  push: React.PropTypes.func.isRequired,
  pageType: React.PropTypes.string
}

ForbiddenPage.defaultProps = {
  pageType: 'page'
}

export default connect(
  createStructuredSelector({
    authenticated: isAuthenticatedSelector
  }),
  { openLoginModal, push }
)(ForbiddenPage)
