import React from 'react'
import truncate from 'lodash/truncate'

import { Heading, Link } from 'core'

import css from '../style.scss'

const truncateOptions = {
  length: 200,
  separator: /,?\s+/
}

const ResultSingle = ({ result }) => (
  <div>
    <div className={css.title}>
      {linkify(result,
        <Heading
          size='h5'
          freight
          underline
          noMargin
          className
        >
          {result.title}
        </Heading>
      )}
      <p className={css.resultType}>{resultType(result)}</p>
    </div>
    <p className={css.excerpt}>
      {excerpt(result.content)}
    </p>
  </div>
)

const excerpt = text => truncate(text, truncateOptions)

const linkify = (result, children) => {
  const path = linkPath(result)
  return path ? <Link to={path}>{children}</Link> : children
}

const linkPath = result => {
  switch (result.target_type) {
    case 'webinar':
      return `/webinars/${result.slug}`
    case 'session_sale':
      return `/sessions/${result.slug}`
    case 'cms_page':
      return `/${result.slug}`
    case 'post':
      switch (result.subtype) {
        case 'monday_motivator':
          return `/monday-motivator/${result.slug}`
        case 'news':
          return `/news/${result.slug}`
      }
  }
}

const resultType = result => {
  switch (result.target_type) {
    case 'webinar':
      switch (result.subtype) {
        case 'guest':
          return 'Guest Webinar'
        case 'curriculum':
          return 'Core Curriculum Webinar'
        case 'course':
          return 'Multi-Week Course Webinar'
      }
      break
    case 'session_sale':
      switch (result.subtype) {
        case 'fsp':
          return 'Faculty Success Program'
        case 'alumni':
          return 'Alumni Session'
        case 'writing_challenge':
          return '14-Day Writing Challenge'
      }
      break
    case 'cms_page':
      return 'Page'
    case 'post':
      switch (result.subtype) {
        case 'monday_motivator':
          return 'Monday Motivator'
        case 'news':
          return 'News'
      }
  }
}

ResultSingle.propTypes = {
  result: React.PropTypes.shape({
    target_type: React.PropTypes.string.isRequired,
    target_id: React.PropTypes.number.isRequired,
    subtype: React.PropTypes.string,
    title: React.PropTypes.string.isRequired,
    content: React.PropTypes.string
  })
}

export default ResultSingle
