import React, { PropTypes } from 'react'

import { Form } from '../Form'
import { Section } from 'layout'
import css from './style.scss'

/**
 * Can be used within a FormPage to create a container with a border
 * If onSubmit is passed in, will render a <Form onSubmit> as the root element
 */

const FormContainer = ({ children, onSubmit, name }) => {
  return React.createElement(
    onSubmit ? Form : 'div',
    {
      className: css.outerWrapper,
      onSubmit: onSubmit || undefined,
      name
    },
    <Section>
      <div className={css.wrapper}>
        {children}
      </div>
    </Section>
  )
}

FormContainer.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func
}

export default FormContainer
