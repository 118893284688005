import React from 'react'
import { Provider } from 'react-redux'
import { Router, browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

import { Bootstrap } from 'bootstrap'
import routes from './routes'

const App = (props) => {
  const { store } = props
  const history = syncHistoryWithStore(browserHistory, store)

  return (
    <Provider store={store}>
      <Bootstrap>
        <Router key={Math.random()} history={history}>
          {routes}
        </Router>
      </Bootstrap>
    </Provider>
  )
}

export default App
