import { adminUrl } from 'config'

const adminItems = [
  { name: 'Administration', slug: adminUrl, target: '_blank' }
]

const items = [
  { name: 'Account Profile', slug: '/account/profile' },
  { name: 'Payments', slug: '/account/payments' }
]

const iahItems = [
  { name: 'Institution Profile', slug: '/account/institution-profile' },
  { name: 'Institution Payments', slug: '/account/institution-payments' },
  { name: 'Institution Metrics', slug: '/account/institution-metrics' },
  { name: 'Institution Subaccounts', slug: '/account/institution-subaccounts' }
]

export default (isIAH, isAdmin) => {
  const menuItems = []

  if (isAdmin) {
    menuItems.push(...adminItems)
  }

  menuItems.push(...items)

  if (isIAH) {
    menuItems.push(...iahItems)
  }

  return menuItems
}
