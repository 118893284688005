import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Heading } from 'core'
import { Section } from 'layout'
import { ChevronBlueRight } from 'icons'
import { currentUserSelector } from 'auth'

import { openMemberMailchimpSubscribeModal } from './components/MemberMailchimpSubscribeModal'

import css from './UserBanner.scss'

const timeOfDay = (time) => {
  if (time <= 11) {
    return 'morning'
  } else if (time >= 12 && time <= 16) {
    return 'afternoon'
  } else {
    return 'evening'
  }
}

const getHour = () => moment().format('HH')

export const UserBanner = (props) => {
  let bannerMsg = `Good ${timeOfDay(props.getHour ? props.getHour() : getHour())}`

  if (props.user && props.user.name) {
    bannerMsg += `, ${props.user.name}`
  }

  const newsletterOptIn = props && props.user && props.user.flags && props.user.flags.newsletter_opt_in

  return (
    <div className={css.banner}>
      <Section>
        <Heading size='h4' weight='thin' noMargin>
          <div className={css.wrap}>
            <div>
              {bannerMsg}
            </div>
            {!newsletterOptIn && (
              <div>
                <div className={css.subscribeContainer}>
                  <div className={css.subscribeText}>
                    <div>
                      Get helpful updates in your inbox.&nbsp;<a href='javascript:void(0)' onClick={() => props.openMemberMailchimpSubscribeModal()}>Subscribe here</a>
                    </div>
                    <div className={css.chevronWrapper}><a href='javascript:void(0)' onClick={() => props.openMemberMailchimpSubscribeModal()}><ChevronBlueRight /></a></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Heading>
      </Section>
    </div>
  )
}

UserBanner.propTypes = {
  user: React.PropTypes.object,
  openMemberMailchimpSubscribeModal: React.PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: currentUserSelector(state)
})

const mapDispatchToProps = { openMemberMailchimpSubscribeModal }

export default connect(mapStateToProps, mapDispatchToProps)(UserBanner)
