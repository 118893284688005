import React, { PropTypes } from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import moment from 'moment'

import { load, schemas } from 'api'
import { isIAHSelector } from 'auth'
import { applyHOCs } from 'helpers'
import { HeaderSecondary } from 'page-blocks'

export const InstitutionMembershipNotActivePage = ({ institution, membership, isIAH }) => {
  if (!institution) return null

  const { primary_account_holder: primaryAccountHolder } = institution
  const formattedDate = moment(membership.activated_at).format('MMM D, YYYY')
  const title = `Your institution's membership has not yet activated, it activates on ${formattedDate}`

  let subtitle
  if (isIAH) {
    subtitle = `Contact NCFDD to activate the membership for ${institution.name}.`
  } else if (primaryAccountHolder) {
    subtitle = `
      Contact your institution's representative,
      ${primaryAccountHolder.name} (${primaryAccountHolder.email}),
      about activating the membership for ${institution.name}.
    `
  } else {
    subtitle = `Contact ${institution.name} to activate the membership.`
  }

  return (
    <div>
      <HeaderSecondary
        title={title}
        subtitle={subtitle}
      />
    </div>
  )
}

InstitutionMembershipNotActivePage.propTypes = {
  institution: PropTypes.shape({
    name: PropTypes.string.isRequired,
    primary_account_holder: PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })
  })
}

export default applyHOCs([
  connect(
    createStructuredSelector({
      isIAH: isIAHSelector
    })
  ),
  load({
    institution: {
      urlSelector: (state, { membership }) => {
        return `/v2/institutions/${membership.member_id}`
      },
      schema: schemas.institution
    }
  })
])(InstitutionMembershipNotActivePage)
