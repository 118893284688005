import React from 'react'
import ui from 'redux-ui'

import css from './style.scss'

export class Tabs extends React.Component {
  componentDidMount () { this.props.onSelectedIndex(0) }
  render () {
    return (
      <div>
        <div className={css.tabs}>
          <ul>
            {
              this.props.sections.map((section, index) => (
                <li
                  key={index}
                  className={this.props.ui.selectedIndex === index ? css.active : ''}
                  onClick={() => {
                    this.props.updateUI({ selectedIndex: index })
                    if (this.props.onSelectedIndex) {
                      this.props.onSelectedIndex(index)
                    }
                  }}
                >
                  {section.name}
                </li>
              ))
            }
          </ul>
        </div>
        <div className={css.body}>
          {this.props.sections[this.props.ui.selectedIndex].component}
        </div>
      </div>
    )
  }
}

Tabs.propTypes = {
  onSelectedIndex: React.PropTypes.func,
  sections: React.PropTypes.arrayOf(React.PropTypes.shape({
    name: React.PropTypes.string,
    component: React.PropTypes.element
  })).isRequired,
  ui: React.PropTypes.object.isRequired,
  updateUI: React.PropTypes.func.isRequired
}

export default ui({
  state: {
    selectedIndex: 0
  }
})(Tabs)
