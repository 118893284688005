import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { createSelector } from 'reselect'

import { applyHOCs, getDisplayName } from 'helpers'

import { closeForm } from './actions'
import { activeFormSelector } from './selectors'

/**
 * Wraps a component with a reduxForm
 * Passes editing flag to indicate if the form is being edited
 * On unmount, will cancel the form if it's being edited
 */

export const formGroup = WrappedComponent => {
  class FormGroup extends Component {
    componentWillUnmount () {
      if (this.props.editing) {
        this.props.closeForm()
      }
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  FormGroup.propTypes = {
    editing: PropTypes.bool,
    closeForm: PropTypes.func.isRequired
  }

  FormGroup.displayName = `FormGroup(${getDisplayName(WrappedComponent)})`

  return FormGroup
}

export default (options = {}) => WrappedComponent => {
  const {
    form: FORM_NAME,
    initialValuesSelector = state => ({}),
    validate
  } = options

  return applyHOCs([
    reduxForm({
      enableReinitialize: true,
      form: FORM_NAME,
      validate
    }),
    connect(
      createSelector(
        initialValuesSelector,
        activeFormSelector,
        (initialValues, activeForm) => ({
          initialValues,
          editing: activeForm === FORM_NAME
        })
      ),
      dispatch => ({
        closeForm: () => dispatch(closeForm(FORM_NAME))
      })
    )
  ])(formGroup(WrappedComponent))
}
