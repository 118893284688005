import React from 'react'

import { Button } from 'core'

const ErrorButton = ({ onClick, children }) => (
  <div style={{ textAlign: 'center' }}>
    <Button
      size='primary'
      onClick={onClick}
    >
      {children}
    </Button>
  </div>
)

ErrorButton.propTypes = {
  onClick: React.PropTypes.func.isRequired,
  children: React.PropTypes.node
}

export default ErrorButton
