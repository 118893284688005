import React from 'react'
import css from './style.scss'

export default ({ children }) => {
  const withSubtitle = children.length > 1
  const classes = withSubtitle ? [css.title, css.withSubtitle] : [css.title]
  return (
    <div className={classes.join(' ')}>
      {children}
    </div>
  )
}
