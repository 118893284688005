export const trackFormSubmit = ({ formName } = {}) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      // named form_submission to distinguish from GA4 form_submit event,
      // turning off Enhanced Event Measurement for forms did not turn off form_submit events.
      // This will be mapped to GA4 form_submit event in GTM and result in more reliable form_submit tracking data
      event: 'form_submission',
      form_name: formName
    })
  }
}

export const trackFormStart = ({ formName } = {}) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      // named form_started to distinguish from GA4 form_start event,
      // turning off Enhanced Event Measurement for forms did not turn off form_start events.
      // This will be mapped to GA4 form_start event in GTM and result in more reliable form_start tracking data
      event: 'form_started',
      form_name: formName
    })
  }
}
