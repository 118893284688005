import React from 'react'
import { compact } from 'lodash'

import css from './style.scss'

const Section = (props) => {
  const classes = [
    css.sectionWrapper,
    props.mobileNoPadding ? css.mobileNoPadding : '',
    props.tabletNoPadding ? css.tabletNoPadding : '',
    props.narrow ? css.narrow : ''
  ]

  return (
    <section className={compact(classes).join(' ')}>
      {props.children}
    </section>
  )
}

Section.propTypes = {
  /**
   * If true, removes internally managed padding at mobile breakpoint.
   * Note that child components must manage their own
   * padding and spacing from edge of window.
   */
  mobileNoPadding: React.PropTypes.bool,
  /**
   * If true, removes internally managed padding at tablet breakpoint.
   * Note that child components must manage their own
   * padding and spacing from edge of window.
   */
  tabletNoPadding: React.PropTypes.bool,
  /**
   * If true, sets max-width of content to 740px at >= laptop.
   * Used in text-heavy layouts (articles, news, Monday Motivator) to shrink
   * the max-width in order to improve ease of readability for the user.
   */
  narrow: React.PropTypes.bool
}

export default Section
