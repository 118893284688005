import React, { PropTypes } from 'react'

import { Grid, GridItem, Section, Spacer } from 'layout'
import { Page } from 'page'
import { HeaderTertiary } from 'page-blocks'

import css from './style.scss'
import AccountNavigation from './navigation/AccountNavigation'

const Account = ({ children }) => {
  return (
    <Page greyBg noPageHead>
      <HeaderTertiary title='Account' />
      <div className={css.container}>
        <Section>
          <Grid>
            <GridItem col='1/4' tablet1of1 mobile1of1>
              <AccountNavigation />
            </GridItem>
            <GridItem col='3/4' tablet1of1 mobile1of1>
              {children}
            </GridItem>
          </Grid>
          <Spacer size='sm' />
        </Section>
      </div>
    </Page>
  )
}

Account.propTypes = {
  children: PropTypes.node
}

export default Account
