import React from 'react'
import qs from 'qs'

import { load, schemas } from 'api'
import { CardWebinar } from './components/Cards'

const NextWebinarCard = ({ upcomingWebinars, loadStatus }) => {
  if (loadStatus.upcomingWebinars.loading) {
    return null
  }

  if (upcomingWebinars.length === 0) {
    return <p style={{ padding: '0 16px' }}>There are no upcoming events.</p>
  }

  return <CardWebinar webinar={upcomingWebinars[0]} />
}

NextWebinarCard.propTypes = {
  upcomingWebinars: React.PropTypes.array,
  loadStatus: React.PropTypes.object.isRequired
}

export default load({
  upcomingWebinars: {
    urlSelector: () => `/v2/webinars?${qs.stringify({
      filters: {
        upcoming: true,
        limit: 1
      },
      order: 'asc'
    })}`,
    schema: [schemas.webinar]
  }
})(NextWebinarCard)
