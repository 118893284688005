import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import SelectInstitution from 'select-institution'

import { Label, LabelHelper, Input, Radio, Select, validators } from 'form'

import FieldGroup from '../../components/FieldGroup'
import {
  genderOptionsSelector,
  ethnicityOptionsSelector,
  titleOptionsSelector,
  disciplineOptionsSelector,
  disciplineClassificationOptionsSelector
} from 'settings'

export const gender = {
  name: 'gender',
  FieldGroup: connect(
    state => ({ genderOptions: genderOptionsSelector(state) })
  )((props) => (
    <FieldGroup>
      <Label htmlFor={gender.name}>How do you describe your gender identity?</Label>
      <LabelHelper>Type or select the term(s) that best represent you here</LabelHelper>
      <Field
        name={gender.name}
        component={Select}
        validate={validators.required('Gender is required')}
        options={props.genderOptions}
        multi
        allowCustom
      />
    </FieldGroup>
  ))
}

export const ethnicities = {
  name: 'ethnicities',
  FieldGroup: connect(
    state => ({ ethnicityOptions: ethnicityOptionsSelector(state) })
  )((props) => (
    <FieldGroup>
      <Label htmlFor={ethnicities.name}>How do you describe your racial identity?</Label>
      <LabelHelper>Type or select the term(s) that best represent you here</LabelHelper>
      <Field
        name={ethnicities.name}
        component={Select}
        validate={validators.required('Ethnicity is required')}
        options={props.ethnicityOptions}
        multi
        allowCustom
      />
    </FieldGroup>
  ))
}

export const title = {
  name: 'title',
  FieldGroup: connect(
    state => ({ titleOptions: titleOptionsSelector(state) })
  )((props) => (
    <FieldGroup>
      <Label htmlFor={title.name}>Your Title</Label>
      <Field
        name={title.name}
        component={Select}
        validate={validators.required('Your title is required')}
        options={props.titleOptions}
      />
    </FieldGroup>
  ))
}

export const tenured = {
  name: 'flags.tenured',
  FieldGroup: () => (
    <FieldGroup>
      <Label htmlFor={tenured.name}>Are you tenured?</Label>
      <Field
        name={tenured.name}
        component={Radio}
        parse={value => value === 'yes'}
        format={value => value ? 'yes' : 'no'}
        options={[
          { name: 'Yes', value: 'yes' },
          { name: 'No', value: 'no' }
        ]}
      />
    </FieldGroup>
  )
}

export const discipline = {
  name: 'discipline',
  FieldGroup: connect(
    state => ({ disciplineOptions: disciplineOptionsSelector(state) })
  )((props) => (
    <FieldGroup>
      <Label htmlFor={discipline.name}>Discipline</Label>
      <LabelHelper>Select from the options or enter a custom discipline</LabelHelper>
      <Field
        name={discipline.name}
        component={Select}
        validate={validators.required('Discipline is required')}
        options={props.disciplineOptions}
        allowCustom
      />
    </FieldGroup>
  ))
}

// eslint-disable-next-line
export const discipline_classification = {
  name: 'discipline_classification',
  FieldGroup: connect(
    state => ({ disciplineClassificationOptions: disciplineClassificationOptionsSelector(state) })
  )((props) => (
    <FieldGroup>
      <Label htmlFor={discipline_classification.name}>Discipline Classification</Label>
      <LabelHelper>Based on the following classifications of instructional programs, which do you feel most closely represents your current field of study or focus?</LabelHelper>
      <Field
        name={discipline_classification.name}
        component={Select}
        validate={validators.required('Discipline Classification is required')}
        options={props.disciplineClassificationOptions}
      />
    </FieldGroup>
  ))
}

export const university = {
  name: 'university',
  FieldGroup: () => (
    <FieldGroup>
      <Label htmlFor={university.name}>University</Label>
      <LabelHelper>What is your college/university?</LabelHelper>
      <Field
        name={university.name}
        component={SelectInstitution}
        validate={validators.required('University is required')}
      />
    </FieldGroup>
  )
}

export const department = {
  name: 'department',
  FieldGroup: () => (
    <FieldGroup>
      <Label htmlFor={department.name}>Department</Label>
      <Field
        name={department.name}
        component={Input}
        type='text'
        validate={validators.required('Department is required')}
      />
    </FieldGroup>
  )
}
