import React, { PropTypes } from 'react'
import { connect } from 'react-redux'

import {
  currentUserMembershipSelector,
  currentUserIsActiveMemberSelector
} from 'auth'
import { applyHOCs } from 'helpers'
import { page } from 'page'

import InstitutionMembershipNotActivePage from './InstitutionMembershipNotActivePage'
import NoMembershipPage from '../membership-expired/NoMembershipPage'

export const MembershipNotActivePage = (props) => {
  if (!props.membership) {
    return <NoMembershipPage {...props} />
  }

  return <InstitutionMembershipNotActivePage {...props} />
}

MembershipNotActivePage.propTypes = {
  membership: PropTypes.shape({
    member_type: PropTypes.string.isRequired
  })
}

export default applyHOCs([
  page({
    redirectSelector: (state, { membership }) => {
      if (currentUserIsActiveMemberSelector(state)) {
        return '/'
      }
    }
  }),
  connect(
    state => ({
      membership: currentUserMembershipSelector(state)
    })
  )
])(MembershipNotActivePage)
