import React from 'react'
import moment from 'moment'

import { load, schemas } from 'api'
import { Heading } from 'core'
import { Section, SectionOverride } from 'layout'

import { Hero } from './components/Hero'

import assetUrl from './assets/featured-session.png'

export const Featured = ({ featuredSession, loadStatus }) => {
  if (loadStatus.featuredSession.loading ||
    !featuredSession ||
    featuredSession.target.type === 'writing_challenge') {
    return null
  }

  const sessionSale = featuredSession.target
  const headingText = sessionSale.type === 'fsp'
    ? 'Faculty Success Program'
    : 'FSP Alumni'
  const heroComponent = getHeroComponent(sessionSale)

  return (
    <Section mobileNoPadding>
      <SectionOverride>
        <Heading size='h4' weight='light'>
          {headingText}
        </Heading>
        {heroComponent}
      </SectionOverride>
    </Section>
  )
}

const getHeroComponent = sessionSale => {
  const heroText = getHeroText(sessionSale)

  const tertiaryCta = {
    label: 'Find Out More >',
    url: `/sessions/${sessionSale.slug}`
  }

  let primaryCta, secondaryCta

  if (sessionSale.type === 'fsp') {
    primaryCta = {
      label: 'Making the Ask',
      url: '/making-the-ask-fsp'
    }

    secondaryCta = {
      label: 'FSP Preview Webinar',
      url: '/fsp-preview-webinar'
    }
  }

  return (
    <Hero
      img={assetUrl}
      title={heroText}
      primaryCta={primaryCta}
      secondaryCta={secondaryCta}
      tertiaryCta={tertiaryCta}
    />
  )
}

const getHeroText = sessionSale => {
  const maxSet = sessionSale.max_attendees === 0 || sessionSale.max_attendees > 0
  if (!maxSet || sessionSale.num_reservations_available > 0) {
    if (sessionSale.pre_registration_end_at) {
      if (moment().isBefore(sessionSale.pre_registration_end_at)) {
        return `Early Registration Now Open for the ${sessionSale.title}`
      }
    }

    return `Registration Now Open for the ${sessionSale.title}`
  } else {
    return `The ${sessionSale.title} is currently at full capacity. Registration for the waitlist is now open.`
  }
}

export default load({
  featuredSession: {
    urlSelector: () => '/v2/events/featured_session',
    schema: schemas.event
  }
})(Featured)
