import Immutable from 'seamless-immutable'

import {
  SET_USER_REGISTRATION_ERROR,
  CLEAR_USER_REGISTRATION_ERROR,
  SET_USER_REGISTRATION_SELECTED_INSTITUTION
} from './types'

const initialState = Immutable.from({})

const reducersByType = {
  [SET_USER_REGISTRATION_ERROR]: (state, action) => {
    return state.set('errors', action.errors)
  },

  [SET_USER_REGISTRATION_SELECTED_INSTITUTION]: (state, action) => {
    return state.set('selectedInstitution', action.institution)
  },

  [CLEAR_USER_REGISTRATION_ERROR]: state => {
    return initialState
  }
}

const reducer = (state = initialState, action) => {
  const reducer = reducersByType[action.type]
  return reducer ? reducer(state, action) : state
}

export default reducer
