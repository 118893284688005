import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { isEmpty, get, find } from 'lodash'

import { load, schemas } from 'api'
import { Page } from 'page'
import { applyHOCs } from 'helpers'
import { HeaderTertiary } from 'page-blocks'
import { EventCollection } from 'core'
import { Section, Spacer } from 'layout'

import EventsEmptyState from './EventsEmptyState'
import EventsFilters from './EventsFilters'

const eventTypes = [
  {
    type: 'curriculum',
    target: 'webinar',
    label: 'Core Curriculum'
  }, {
    type: 'guest',
    target: 'webinar',
    label: 'Guest Webinars'
  }, {
    type: 'course',
    target: 'webinar',
    label: 'Multi-Week Courses'
  }, {
    type: 'fsp',
    target: 'session_sale',
    label: 'Bootcamps'
  }
]

const filterEvents = (events, type = null) => {
  if (!type) return events

  const eventType = find(eventTypes, { type })

  if (!eventType) return events

  return events.filter(event => {
    return event.type === eventType.target
  }).filter(event => {
    return event.type !== 'webinar' || event.target.type === type
  })
}

const EventsPage = ({ activeType, events, loadStatus, location }) => {
  let eventsComponent

  if (loadStatus.events.loading) {
    eventsComponent = null
  } else {
    events = filterEvents(events, activeType)

    if (isEmpty(events)) {
      const typeLabel = activeType
        ? get(find(eventTypes, { type: activeType }), 'label')
        : null
      eventsComponent = <EventsEmptyState type={typeLabel} />
    } else {
      eventsComponent = <EventCollection range collection={events} />
    }
  }

  const onDropdownChange = (type) => {
    const newLocation = {
      ...location,
      query: {
        ...location.query,
        type
      }
    }

    if (newLocation.query.type === null) {
      delete newLocation.query.type
    }

    browserHistory.push(newLocation)
  }

  return (
    <Page title='Events'>
      <HeaderTertiary title='Events Calendar' />
      <EventsFilters
        types={eventTypes}
        activeType={activeType}
        onDropdownChange={onDropdownChange}
      />
      <Section mobileNoPadding>
        {eventsComponent}
        <Spacer size='md' />
      </Section>
    </Page>
  )
}

EventsPage.propTypes = {
  events: React.PropTypes.arrayOf(React.PropTypes.object),
  activeType: React.PropTypes.string,
  loadStatus: React.PropTypes.shape({
    events: React.PropTypes.shape({
      loading: React.PropTypes.bool
    }).isRequired
  }).isRequired
}

export default applyHOCs([
  connect(
    (state, props) => ({
      activeType: props.location.query.type
    })
  ),
  load({
    events: {
      urlSelector: () => '/v2/events',
      schema: [schemas.event]
    }
  })
])(EventsPage)
