import React from 'react'
import { createSelector } from 'reselect'

import { load, schemas } from 'api'
import { currentUserInstitutionIdSelector } from 'auth'

import AccountHeading from '../components/AccountHeading'
import OrderTable from '../components/OrderTable'
import { PageHead } from 'page'

const InstitutionPaymentsPage = ({ orders, loadStatus }) => {
  if (loadStatus.orders.loading) {
    return null
  }

  if (orders.length === 0) {
    return <p>Your institution does not have any payments.</p>
  }

  return (
    <div>
      <PageHead title='Institution Payments' />
      <AccountHeading>
        Payments
      </AccountHeading>
      <OrderTable orders={orders} />
    </div>
  )
}

export default load({
  orders: {
    urlSelector: createSelector(
      currentUserInstitutionIdSelector,
      institutionId => `/v2/institutions/${institutionId}/orders`
    ),
    schema: [schemas.order]
  }
})(InstitutionPaymentsPage)
