import React, { PropTypes } from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { load, schemas } from 'api'
import { isIAHSelector } from 'auth'
import { applyHOCs } from 'helpers'
import { HeaderSecondary } from 'page-blocks'
import { Link } from 'core'
import css from './style.scss'

export const InstitutionMembershipExpiredPage = ({ institution, isIAH }) => {
  if (!institution) return null

  const { primary_account_holder: primaryAccountHolder } = institution

  let subtitle
  if (isIAH) {
    subtitle = `Contact NCFDD to renew the membership for ${institution.name}.`
  } else if (primaryAccountHolder) {
    subtitle = `
      Contact your institution's representative,
      ${primaryAccountHolder.name} (${primaryAccountHolder.email}),
      about renewing the membership for ${institution.name}.
    `
  } else {
    subtitle = `Contact ${institution.name} to renew the membership.`
  }

  return (
    <div>
      <HeaderSecondary
        title="Your institution's membership has expired"
        subtitle={subtitle}
      />
      <div className={css.link}>
        <Link
          to='account/membership-type'
          orange
        >
          Switch your account to a personal individual membership.
        </Link>
      </div>
    </div>
  )
}

InstitutionMembershipExpiredPage.propTypes = {
  institution: PropTypes.shape({
    name: PropTypes.string.isRequired,
    primary_account_holder: PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })
  })
}

export default applyHOCs([
  connect(
    createStructuredSelector({
      isIAH: isIAHSelector
    })
  ),
  load({
    institution: {
      urlSelector: (state, { membership }) => {
        return `/v2/institutions/${membership.member_id}`
      },
      schema: schemas.institution
    }
  })
])(InstitutionMembershipExpiredPage)
