import { paymentIsCompleteSelector } from 'stripe'
import Payment from './Payment'

import { FORM_NAME } from '../../constants'

export default {
  name: 'Payment',
  component: Payment,
  isValidSelector: paymentIsCompleteSelector(FORM_NAME)
}
