import { get, reject, without, values } from 'lodash'
import { filterActions } from 'redux-ignore'
import Immutable from 'seamless-immutable'

import * as types from './types'
const {
  RECEIVE_USER_LIST,
  RECEIVE_USER_JOINED,
  RECEIVE_USER_LEAVE,
  RECEIVE_CHAT_MESSAGE,
  RECEIVE_DELETE_CHAT_MESSAGE,
  LOAD_CHAT_MESSAGES
} = types

const initialState = Immutable({
  rooms: {}
})

/**
State structure
{
  rooms: {
    1: {
      activeUserIds: [1, 2, 3],
      events: [
        { type, id }
      ]
    }
  }
}
*/

const reducer = (state = initialState, action) => {
  const pathToRoom = ['rooms', action.room]
  const pathToActiveUserIds = pathToRoom.concat('activeUserIds')
  const pathToEvents = pathToRoom.concat('events')

  const addActiveUserId = (id) => {
    const activeUserIds = get(state, pathToActiveUserIds, [])
    const withNewUserId = activeUserIds.concat(id)
    state = state.setIn(pathToActiveUserIds, withNewUserId)
  }

  const removeActiveUserId = (id) => {
    const activeUserIds = get(state, pathToActiveUserIds, [])
    const withoutUserId = without(activeUserIds, id)
    state = state.setIn(pathToActiveUserIds, withoutUserId)
  }

  const replaceEvents = (events) => {
    state = state.setIn(pathToEvents, events)
  }

  const pushEvent = (message) => {
    const events = get(state, pathToEvents, [])
    const withNewMessage = events.concat(message)
    state = state.setIn(pathToEvents, withNewMessage)
  }

  const removeChatMessage = (id) => {
    const existingEvents = get(state, pathToEvents, [])
    const withoutChatMessage = reject(existingEvents, { type: 'chatMessage', id })
    state = state.setIn(pathToEvents, withoutChatMessage)
  }

  switch (action.type) {
    case (RECEIVE_USER_LIST):
      state = state.setIn(pathToActiveUserIds, action.result)
      break
    case (RECEIVE_USER_JOINED):
      addActiveUserId(action.result)
      pushEvent({ type: 'userJoined', id: action.result })
      break
    case (RECEIVE_USER_LEAVE):
      removeActiveUserId(action.id)
      pushEvent({ type: 'userLeft', id: action.id })
      break
    case (RECEIVE_CHAT_MESSAGE):
      pushEvent({ type: 'chatMessage', id: action.result })
      break
    case (RECEIVE_DELETE_CHAT_MESSAGE):
      removeChatMessage(action.id)
      break
    case (LOAD_CHAT_MESSAGES):
      replaceEvents(action.result.map(id => {
        return { type: 'chatMessage', id }
      }))
      break
  }

  return state
}

export default filterActions(reducer, values(types))
