import React from 'react'
import { last } from 'lodash'
import moment from 'moment'

import { Heading } from '../Heading'
import { SectionOverride } from 'layout'

import EventSingle from './EventSingle'

const findRange = (collection) => {
  const firstItem = collection[0]
  const firstDate = moment(firstItem.target.start_at)
  const lastItem = last(collection)
  const lastDate = moment(lastItem.target.start_at)
  let range

  if (firstDate.isSame(lastDate, 'year')) {
    range = `${firstDate.format('YYYY')}`
  } else {
    range = `${firstDate.format('YYYY')} - ${lastDate.format('YYYY')}`
  }

  return range
}

const EventCollection = (props) => {
  let { collection, range } = props
  let rangeCopy

  if (range && collection) {
    rangeCopy = findRange(collection)
  }

  collection = (
    collection.map((single, index) => {
      const { target } = single

      if (single.type === 'session_sale') {
        return (
          <EventSingle
            key={index}
            id={target.id}
            type={single.type}
            subtype={target.type}
            title={target.title}
            slug={target.slug}
            start={target.advertised_start_date}
            end={target.advertised_end_date}
            registered={target.registration_status === 'reserved'}
          />
        )
      } else if (single.type === 'webinar') {
        return (
          <EventSingle
            key={index}
            id={target.id}
            type={single.type}
            subtype={target.type}
            title={target.title}
            slug={target.slug}
            start={target.start_at}
            end={target.end_at}
            facilitator={target.facilitator}
            registered={target.registered}
            showTimes
          />
        )
      } else {
        return null
      }
    })
  )

  return (
    <div>
      {
        range &&
          <SectionOverride>
            <Heading>{rangeCopy}</Heading>
          </SectionOverride>
      }
      {collection}
    </div>
  )
}

EventCollection.propTypes = {
  collection: React.PropTypes.arrayOf(React.PropTypes.shape({
    type: React.PropTypes.string.isRequired,
    target: React.PropTypes.object.isRequired
  })).isRequired,
  range: React.PropTypes.bool
}

export default EventCollection
