import React from 'react'

import { Spacer } from 'layout'

import { ArticleBody } from '../ArticleBody'
import { CMSSection } from '../components'

import css from './style.scss'

const RichtextAndImage = (props) => {
  const { title, subtitle, body, img_url: imgUrl, image_caption: caption } = props
  return (
    <CMSSection
      title={title}
      subtitle={subtitle}
    >
      {body && (
        <ArticleBody
          left
          proxima
          body={body}
        />
      )}
      <div className={css.wrapper}>
        {imgUrl && <Spacer size='xs' />}
        {
          imgUrl &&
            <img src={imgUrl} alt={caption} className={css.img} />
        }
        {
          (imgUrl && caption) &&
            <caption className={css.caption}>{caption}</caption>
        }
      </div>
    </CMSSection>
  )
}

RichtextAndImage.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  body: React.PropTypes.string,
  img_url: React.PropTypes.string,
  caption: React.PropTypes.string
}

export default RichtextAndImage
