import React from 'react'

import { Card, Heading } from 'core'
import { Spacer } from 'layout'

const EventsEmptyState = ({ type }) => (
  <Card>
    <Spacer size='md' />
    <Heading center size='h4' weight='light' noMargin>
      Sorry, there are currently no upcoming {type}. Check back soon!
    </Heading>
    <Spacer size='md' />
  </Card>
)

EventsEmptyState.propTypes = {
  type: React.PropTypes.string
}

EventsEmptyState.defaultProps = {
  type: 'events'
}

export default EventsEmptyState
