import React from 'react'

import { Grid, GridItem } from 'layout'
import { Heading } from 'core'
import { Form, FormSection } from 'form'

import * as fields from './fields'
import ContinueSection from '../../components/ContinueSection'

const PersonalProfessional = props => {
  return (
    <Form
      name={props.getStepFormName && props.getStepFormName()}
      onSubmit={e => {
        props.continue(e)
      }}
    >
      <FormSection>
        <Heading size='h6'>Personal Information</Heading>
        <Grid>
          <GridItem col='2/3' mobile1of1 tablet1of1>
            <fields.gender.FieldGroup />
          </GridItem>
          <GridItem col='2/3' mobile1of1 tablet1of1>
            <fields.ethnicities.FieldGroup />
          </GridItem>
          <GridItem col='1/3' mobileHide />
        </Grid>
      </FormSection>
      <FormSection>
        <Heading size='h6'>Professional Information</Heading>
        <Grid>
          <GridItem col='2/3' mobile1of1 tablet1of1>
            <Grid>
              <GridItem col='2/3' mobile1of1 tablet1of1>
                <fields.title.FieldGroup />
              </GridItem>
              <GridItem col='1/3' mobile1of1 tablet1of1>
                <fields.tenured.FieldGroup />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem col='1/3' mobileHide tabletHide />
          <GridItem col='2/3' mobile1of1 tablet1of1>
            <fields.university.FieldGroup />
          </GridItem>
          <GridItem col='1/3' mobileHide tabletHide />
          <GridItem col='2/3' mobile1of1 tablet1of1>
            <fields.discipline.FieldGroup />
            <fields.discipline_classification.FieldGroup />
          </GridItem>
          <GridItem col='1/3' mobileHide tabletHide />
          <GridItem col='1/2' mobileHide />
          <GridItem col='2/3' mobile1of1 tablet1of1>
            <fields.department.FieldGroup />
          </GridItem>
        </Grid>
      </FormSection>
      <ContinueSection {...props} />
    </Form>
  )
}

export default PersonalProfessional
