import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { pick } from 'lodash'

import { currentUserSelector } from 'auth'
import { applyHOCs } from 'helpers'
import { Input, Select, Label, validators, Form } from 'form'
import { formGroup, formGroupButtons } from 'form-group'
import { Grid, GridItem } from 'layout'
import { ethnicityOptionsSelector, genderOptionsSelector, pnlOptionsSelector } from 'settings'

import { savePersonalDetails } from './actions'
import { PERSONAL_DETAILS_FORM_NAME, analyticsFormName } from './constants'

const FormGroupButtons = formGroupButtons({ form: PERSONAL_DETAILS_FORM_NAME })

const PersonalDetailsForm = ({ editing, genderOptions, pnlOptions, ethnicityOptions, save }) => {
  return (
    <Form
      name={analyticsFormName(PERSONAL_DETAILS_FORM_NAME)}
      onSubmit={save}
    >
      <Grid>
        <GridItem col='2/5' tablet1of2 mobile1of2>
          <Label htmlFor='first_name'>First Name</Label>
          <Field
            name='first_name'
            component={Input}
            validate={validators.required('Your first name is required')}
            disabled={!editing}
          />
        </GridItem>
        <GridItem col='2/5' tablet1of2 mobile1of2>
          <Label htmlFor='last_name'>Last Name</Label>
          <Field
            name='last_name'
            component={Input}
            validate={validators.required('Your last name is required')}
            disabled={!editing}
          />
        </GridItem>
        <GridItem col='1/5' tablet1of3 mobile1of2>
          <Label htmlFor='post_nominal_letters'>Designation</Label>
          <Field
            name='post_nominal_letters'
            component={Select}
            options={pnlOptions}
            disabled={!editing}
          />
        </GridItem>
        <GridItem col='1/3' tablet2of3 mobile1of2>
          <Label htmlFor='phone_number'>Phone Number</Label>
          <Field
            name='phone_number'
            component={Input}
            validate={validators.required('Your phone number is required')}
            disabled={!editing}
          />
        </GridItem>
        <GridItem col='1/3' mobile1of2>
          <Label htmlFor='nickname'>Nickname</Label>
          <Field
            name='nickname'
            component={Input}
            disabled={!editing}
          />
        </GridItem>
        <GridItem col='1/3' mobile1of2>
          <Label htmlFor='gender'>Gender</Label>
          <Field
            name='gender'
            component={Select}
            validate={validators.required('Gender is required')}
            options={genderOptions}
            allowCustom
            multi
            disabled={!editing}
          />
        </GridItem>
        <GridItem col='1/3' tablet1of2 mobile1of1>
          <Label htmlFor='ethnicities'>Ethnicity</Label>
          <Field
            name='ethnicities'
            component={Select}
            validate={validators.required('Ethnicity is required')}
            options={ethnicityOptions}
            allowCustom
            multi
            disabled={!editing}
          />
        </GridItem>
        <GridItem col='1/1'>
          <Grid>
            <GridItem col='2/5' tablet1of2 mobile1of1>
              <FormGroupButtons form={PERSONAL_DETAILS_FORM_NAME} />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Form>
  )
}

export default applyHOCs([
  connect(
    state => ({
      genderOptions: genderOptionsSelector(state),
      pnlOptions: pnlOptionsSelector(state),
      ethnicityOptions: ethnicityOptionsSelector(state)
    }),
    { save: savePersonalDetails }
  ),
  formGroup({
    form: PERSONAL_DETAILS_FORM_NAME,
    initialValuesSelector: state => {
      return pick(currentUserSelector(state), [
        'first_name',
        'last_name',
        'post_nominal_letters',
        'phone_number',
        'nickname',
        'gender',
        'ethnicities'
      ])
    }
  })
])(PersonalDetailsForm)
