import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import MediaQuery from 'react-responsive'
import css from './style.scss'

import { currentUserSelector, isIAHSelector, isAdminSelector, logout } from 'auth'
import { Avatar, Link } from 'core'
import { ChevronDownIcon } from 'icons'
import { breakpoints } from 'styles'

import myAccountMenu from '../../../myAccountMenu'

const { tabletMin } = breakpoints

export const MyAccountMenu = ({ user, isIAH, isAdmin, logout }) => {
  return (
    <div key='my_account_menu' className={css.myAccountMenu}>
      <Avatar user={user} size='xs' />
      <Link to='/account'>
        My Account
      </Link>
      <ChevronDownIcon />
      <div className={css.myAccountSubmenu}>
        <ul>
          {myAccountMenu(isIAH, isAdmin).map(({ name, slug, target = '_self', tabletUp }, index) => (
            <MediaQuery minWidth={tabletUp ? tabletMin : 0} component='li' key={index}>
              <Link to={slug} target={target}>
                {name}
              </Link>
            </MediaQuery>
          ))}
          <li>
            <button
              type='button'
              onClick={logout}
            >
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

MyAccountMenu.propTypes = {
  user: PropTypes.object.isRequired,
  isIAH: PropTypes.bool,
  logout: PropTypes.func.isRequired
}

export default connect(
  createStructuredSelector({
    user: currentUserSelector,
    isIAH: isIAHSelector,
    isAdmin: isAdminSelector
  }),
  { logout }
)(MyAccountMenu)
