import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { pick } from 'lodash'

import { load, schemas } from 'api'
import { currentUserSelector } from 'auth'
import { Button } from 'core'
import { Select, validators } from 'form'
import { applyHOCs } from 'helpers'
import { Grid, GridItem } from 'layout'
import { titleOptionsSelector } from 'settings'

import { BUDDY_REQUEST_FORM } from './constants'
import { submitBuddyRequest } from './actions'
import css from './style.scss'

export const BuddyRequestForm = props => {
  const {
    buddyRequests,
    submitBuddyRequest,
    invalid,
    submitSucceeded,
    loadStatus,
    titleOptions
  } = props

  if (loadStatus.buddyRequests.loading) {
    return null
  }

  if (buddyRequests.length || submitSucceeded) {
    return (
      <p className={css.secondaryExplainer}>
        Your buddy match request has been sent.
        We'll send you an email to inform you of your new buddy.
      </p>
    )
  }

  return (
    <div>
      <p className={css.secondaryExplainer}>
        In order to buddy up with another member, we first request that you
        confirm your title so we can match someone of a similar status.
      </p>
      <form
        onSubmit={e => {
          e.preventDefault()
          submitBuddyRequest()
        }}
      >
        <Grid>
          <GridItem col='1/1'>
            <Grid>
              <GridItem col='1/3'>
                <Field
                  name='title'
                  placeholder='Select your title'
                  component={Select}
                  validate={validators.required('You must enter your title to request a buddy')}
                  options={titleOptions}
                />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem col='1/1'>
            <Grid>
              <GridItem col='1/3'>
                <Button
                  type='submit'
                  size='primary'
                  noMargin
                  disabled={invalid}
                >
                  Request a buddy
                </Button>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </form>
    </div>
  )
}

BuddyRequestForm.propTypes = {
  // load
  buddyRequests: PropTypes.array,
  loadStatus: PropTypes.object.isRequired,
  // connect
  titleOptions: PropTypes.array.isRequired,
  submitBuddyRequest: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  // redux-form
  invalid: PropTypes.bool,
  submitSucceeded: PropTypes.bool
}

const mapStateToProps = state => ({
  titleOptions: titleOptionsSelector(state),
  initialValues: pick(currentUserSelector(state), 'title')
})

const mapDispatchToProps = ({
  submitBuddyRequest
})

export default applyHOCs([
  reduxForm({
    form: BUDDY_REQUEST_FORM,
    enableReinitialize: true
  }),
  connect(mapStateToProps, mapDispatchToProps),
  load({
    buddyRequests: {
      urlSelector: () => '/v2/buddy_requests',
      schema: [schemas.buddyRequest]
    }
  })
])(BuddyRequestForm)
