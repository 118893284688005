import React from 'react'
import { compact } from 'lodash'

import ChevronDownIcon from '../chevron-down.svg'

import css from './style.scss'

const ChevronLink = ({ color }) => {
  const classes = [
    css.chevron,
    color ? css[color] : css.white
  ]

  return (
    <div className={compact(classes).join(' ')}>
      <ChevronDownIcon />
    </div>
  )
}

ChevronLink.propTypes = {
  // Sets path stroke. Available values: "white", "orange", "grey".
  color: React.PropTypes.string
}

export default ChevronLink
