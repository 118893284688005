import { getFormSyncErrors, getFormAsyncErrors } from 'redux-form'
import { createSelector } from 'reselect'
import { pick } from 'lodash'

export const fieldErrorsSelectorFactory = (FORM_NAME, fieldNames) => createSelector(
  getFormSyncErrors(FORM_NAME),
  getFormAsyncErrors(FORM_NAME),
  (syncErrors, asyncErrors) => {
    return pick(Object.assign({}, syncErrors, asyncErrors), fieldNames)
  }
)
