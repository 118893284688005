import { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { replace } from 'react-router-redux'

import { legacySiteUrl } from 'config'
import { redirectsSelector } from 'settings'

export class Redirector extends Component {
  state = { externalRedirecting: false }

  checkForRedirects (props) {
    const { pathname, search } = props.location
    const pathnameWithoutSlash = pathname.substring(1)

    const path = `${pathnameWithoutSlash}${search}`.toLowerCase()

    if (path.startsWith('invoice/default.asp')) {
      this.setState({ externalRedirecting: true })
      window.location.href = `${legacySiteUrl}/${path}`
    } else if (props.redirects[path]) {
      const redirect = props.redirects[path]
      if (redirect.match(/^(https?:\/\/)/)) {
        this.setState({ externalRedirecting: true })
        window.location.href = redirect
      } else {
        this.props.replace(`/${redirect}`)
      }
    }
  }

  componentWillMount () {
    this.checkForRedirects(this.props)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.location.key !== this.props.location.key) {
      this.checkForRedirects(nextProps)
    }
  }

  render () {
    // external redirects continue running the script for a bit, showing a 404 page
    // for this reason, we should prevent rendering anything until the browser
    // completes the location change
    if (this.state.externalRedirecting) {
      return null
    }

    return this.props.children
  }
}

Redirector.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
  redirects: PropTypes.object.isRequired,
  replace: PropTypes.func.isRequired
}

export default connect(
  state => ({ redirects: redirectsSelector(state) }),
  { replace }
)(Redirector)
