import { getFormValues } from 'redux-form'
import { createSelector } from 'reselect'
import { get } from 'lodash'

export const discountsSelector = form => createSelector(
  getFormValues(form),
  values => get(values, 'discounts', null)
)

export const discountCodesSelector = form => createSelector(
  discountsSelector(form),
  discounts => discounts && discounts.map(discount => discount.code)
)
