import { startSubmit, stopSubmit, getFormValues } from 'redux-form'
import { UnauthorizedError, ForbiddenError } from 'api'
import { createClient, loadAuthToken, loadCurrentUser } from 'auth'
import { handleError } from 'errors'

import { FORM_NAME } from './constants'

export const login = () => (dispatch, getState) => {
  const state = getState()
  const { email, password } = getFormValues(FORM_NAME)(state)
  dispatch(startSubmit(FORM_NAME))
  return createClient(state)
    .post('/auth/login', { body: { email, password } })
    .then((response) => response.json())
    .then((body) => {
      dispatch(loadAuthToken(body.token))
      return createClient(getState()).get('/v2/users/current')
        .then((response) => response.json())
        .then((user) => {
          dispatch(loadCurrentUser(user))
          dispatch(stopSubmit(FORM_NAME))
        })
    })
    .catch((err) => {
      if (err instanceof UnauthorizedError) {
        dispatch(stopSubmit(FORM_NAME, {
          _error: 'Your username or password is incorrect'
        }))
      } else if (err instanceof ForbiddenError) {
        dispatch(stopSubmit(FORM_NAME, {
          _error: 'require_confirmation'
        }))
      } else {
        dispatch(stopSubmit(FORM_NAME))
        dispatch(handleError(err))
      }
      return err
    })
}
