import React from 'react'

import { Grid, GridItem } from 'layout'

import { CMSSection } from '../components'
import SingleCaption from './SingleCaption'

const IllustrationAndCaption = (props) => (
  <CMSSection
    title={props.title}
    subtitle={props.subtitle}
  >
    <Grid stretch middle>
      {props.captions.map((caption, index) => (
        <GridItem col='1/3' mobile1of1 tablet1of2 key={index}>
          <SingleCaption {...caption} />
        </GridItem>
      ))}
    </Grid>
  </CMSSection>
)

IllustrationAndCaption.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  captions: React.PropTypes.arrayOf(React.PropTypes.shape({
    caption_title: React.PropTypes.string.isRequired,
    caption_copy: React.PropTypes.string.isRequired,
    caption_illustration: React.PropTypes.string.isRequired
  })).isRequired
}

export default IllustrationAndCaption
