import React from 'react'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { isEmpty, get, find, toInteger, pick } from 'lodash'
import qs from 'qs'

import { load, schemas } from 'api'
import { applyHOCs, paginationPageCount, paginationQueryBuilder } from 'helpers'

import { Pagination, WebinarCollection } from 'core'
import { SpinnerIcon } from 'icons'
import { Section, Spacer } from 'layout'
import { HeaderTertiary } from 'page-blocks'
import { Page } from 'page'

import css from './components/style.scss'

import { WebinarCategories } from './components/WebinarCategories'
import WebinarsEmptyState from './components/WebinarsEmptyState'
import WebinarFilters from './components/WebinarFilters'

const webinarTypes = [
  {
    type: 'guest',
    label: 'Guest Webinars'
  }, {
    type: 'course',
    label: 'Multi-Week Courses'
  }
]

const REQUEST_LIMIT = 10

const WebinarsPage = ({ activeType, activeCategory, webinars, categories, location, loadStatus }) => {
  const { totalCount, loading } = loadStatus.webinars
  let webinarsComponent = null

  if (loading) {
    webinarsComponent = (
      <div>
        <Spacer size='sm' />
        <SpinnerIcon className={css.loadingSpinner} />
        <Spacer size='lg' />
      </div>
    )
  } else if (isEmpty(webinars)) {
    const typeLabel = activeType
      ? get(find(webinarTypes, { type: activeType }), 'label', null)
      : null
    webinarsComponent = <WebinarsEmptyState type={typeLabel} />
  } else {
    webinarsComponent = <WebinarCollection collection={webinars} />
  }

  const onDropdownChange = (queryName, queryValue) => {
    const newLocation = {
      ...location,
      query: {
        ...location.query,
        // To reset Pagination to first page
        page: 1,
        [queryName]: queryValue
      }
    }

    if (newLocation.query[queryName] === null) {
      delete newLocation.query[queryName]
    }

    browserHistory.push(newLocation)
  }

  return (
    <Page title='Library'>
      <HeaderTertiary title='The Library' />
      <WebinarFilters
        types={webinarTypes}
        activeType={activeType}
        onFilterChange={(type) => onDropdownChange('type', type)}
      >
        {categories && (
          <WebinarCategories
            categories={categories}
            activeCategory={toInteger(location.query.category_id)}
            onCategoryUpdated={(id) => onDropdownChange('category_id', id)}
          />
        )}
      </WebinarFilters>
      <Section mobileNoPadding>
        <Spacer size='xs' />
        {webinarsComponent}
        <Spacer size='xs' />
        <Pagination
          pages={paginationPageCount(totalCount, REQUEST_LIMIT)}
        />
        <Spacer size='md' />
      </Section>
    </Page>
  )
}

WebinarsPage.propTypes = {
  webinars: React.PropTypes.arrayOf(React.PropTypes.object),
  activeType: React.PropTypes.string
}

export default applyHOCs([
  connect(
    (state, { location }) => ({
      activeType: location.query.type,
      activeCategory: location.query.category
    })
  ),
  load({
    webinars: {
      urlSelector: (state, props) => {
        const { query } = props.location
        const { page } = query
        const filters = Object.assign({}, pick(query, 'type', 'category_id'), { past: true })

        if (!filters.type) {
          filters.type = ['guest', 'course']
        }

        const url = qs.stringify(paginationQueryBuilder(page, REQUEST_LIMIT, { filters }))
        return `/v2/webinars?${url}`
      },
      schema: [schemas.webinar]
    },
    categories: {
      urlSelector: () => '/v2/categories',
      schema: [schemas.category]
    }
  })
])(WebinarsPage)
