import React, { PropTypes } from 'react'

import { Link } from 'core'

const MembershipRenewalLink = ({ memberType }) => {
  if (memberType === 'user') {
    return (
      <Link orange to='/renew-membership'>
        Renew
      </Link>
    )
  } else {
    return null
  }
}

MembershipRenewalLink.propTypes = {
  memberType: PropTypes.string.isRequired
}

export default MembershipRenewalLink
