import React from 'react'
import { SpinnerIcon } from 'icons'

import css from './style.scss'

export default () => (
  <div className={css.loadingScreen}>
    <SpinnerIcon className={css.loadingSpinner} />
  </div>
)
