import React from 'react'

import Modal from './Modal'

import css from './style.scss'

const SuccessModal = ({ children }) => {
  return (
    <div className={css.modalWrapper}>
      <Modal>
        <div className={css.successModal}>
          <div className={css.content}>
            {children}
          </div>
        </div>
      </Modal>
    </div>
  )
}

SuccessModal.propTypes = {
  children: React.PropTypes.node
}

export default SuccessModal
