import React, { PropTypes } from 'react'
import { createSelector } from 'reselect'
import qs from 'qs'
import { connect } from 'react-redux'
import { find, get } from 'lodash'

import { load, schemas } from 'api'
import { Contact, Pagination } from 'core'
import { Spacer } from 'layout'
import { paginationPageCount, paginationQueryBuilder, applyHOCs } from 'helpers'
import { currentUserInstitutionIdSelector } from 'auth'
import { titleOptionsSelector } from 'settings'

import css from './style.scss'
import Download from './Download'
import AccountHeading from '../components/AccountHeading'
import { PageHead } from 'page'

const REQUEST_LIMIT = 20

export const InstitutionSubaccountsPage = ({ loadStatus, users, institutionId, titleOptions }) => {
  if (!users) return null

  const { totalCount } = loadStatus.users

  return (
    <div className={css.wrapper}>
      <PageHead title='Institution Subaccounts' />
      <AccountHeading>Subaccounts</AccountHeading>
      <table className={css.subaccountList}>
        <thead>
          <tr>
            <th>User</th>
            <th>Title</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => {
            const title = user.title ? get(find(titleOptions, { value: user.title }), 'name', user.title) : ''
            return (
              <tr key={user.id}>
                <td>
                  <Contact user={user} />
                </td>
                <td>
                  {title}
                </td>
                <td>
                  {user.email}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <Spacer size='xs' />

      {totalCount > users.length && (
        <div>
          <Pagination
            pages={paginationPageCount(loadStatus.users.totalCount, REQUEST_LIMIT)}
            proxima
          />
          <Spacer size='xs' />
        </div>
      )}

      <Download institutionId={institutionId} />
    </div>
  )
}

InstitutionSubaccountsPage.propTypes = {
  loadStatus: PropTypes.shape({
    users: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      totalCount: PropTypes.number
    }).isRequired
  }).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    avatar_url: PropTypes.string,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    post_nominal_letters: PropTypes.string
  })),
  institutionId: PropTypes.number.isRequired,
  titleOptions: PropTypes.array
}

export default applyHOCs([
  connect(state => ({
    titleOptions: titleOptionsSelector(state),
    institutionId: currentUserInstitutionIdSelector(state)
  })),
  load({
    users: {
      urlSelector: createSelector(
        currentUserInstitutionIdSelector,
        (state, props) => props.location.query.page,
        (institutionId, page) => {
          const query = paginationQueryBuilder(page, REQUEST_LIMIT)
          return `/v2/institutions/${institutionId}/users?${qs.stringify(query)}`
        }
      ),
      schema: [schemas.user]
    }
  })
])(InstitutionSubaccountsPage)
