import ReactGA from 'react-ga4'
import { ga as gaConfig } from 'config'

ReactGA.initialize(gaConfig.ga4TrackingId, {
  gaOptions: {
    send_page_view: false
  },
  gtagOptions: {
    send_page_view: false
  }
})

export default ReactGA
