import React from 'react'

import css from './style.scss'

const Input = ({ invalid, input, meta: { touched, error } }) => {
  return (
    <div>
      <input
        className={touched && error && 'inputError'}
        type='text'
        {...input}
      />
      {touched && error && <span className={css.errorMessageBox}>{error}</span>}
    </div>
  )
}

export default Input
