import { apiResponse, schemas } from 'api'
import { createClient } from 'auth'
import { handleError } from 'errors'

import { LOAD_RESERVATION } from '../../types'

export const unassignUser = reservationId => (dispatch, getState) => {
  return createClient(getState())
    .patch(`/v2/session_reservations/${reservationId}`, {
      body: {
        user_id: null
      }
    })
    .then(res => res.json())
    .then(updatedReservation => {
      dispatch(apiResponse({
        type: LOAD_RESERVATION,
        data: updatedReservation,
        schema: schemas.sessionReservation
      }))
    })
    .catch(err => dispatch(handleError(err)))
}
