import React, { PropTypes } from 'react'
import { load, schemas } from 'api'
import RelatedWebinars from './RelatedWebinars'

export const RelatedCurriculumWebinars = ({ coreCurriculum, currentWebinarId }) => {
  if (!coreCurriculum) {
    return null
  }

  const related = coreCurriculum.map(item => {
    return {
      ...item.webinar,
      complete: item.is_completed
    }
  })

  return (
    <RelatedWebinars
      related={related}
      title='Full Core Curriculum'
      currentWebinarId={currentWebinarId}
    />
  )
}

RelatedCurriculumWebinars.propTypes = {
  related: PropTypes.array,
  currentWebinarId: PropTypes.number.isRequired
}

export default load({
  coreCurriculum: {
    urlSelector: (state, props) => '/v2/core_curriculum',
    schema: [schemas.coreCurriculumWebinar]
  }
})(RelatedCurriculumWebinars)
