import { find, get } from 'lodash'
import { createSelector } from 'reselect'

export const settingsDataSelector = state => get(state, 'settings.data')

export const settingsLoadFailedSelector = state => !!state.settings.loadError

export const homepageSlugSelector = createSelector(
  settingsDataSelector,
  (settings) => settings.homepage_slug
)

export const genderOptionsSelector = createSelector(
  settingsDataSelector,
  (settings) => settings.genders.asMutable()
)

export const ethnicityOptionsSelector = createSelector(
  settingsDataSelector,
  (settings) => settings.ethnicities.asMutable()
)

export const pnlOptionsSelector = createSelector(
  settingsDataSelector,
  (settings) => settings.post_nominal_letters.asMutable()
)

export const titleOptionsSelector = createSelector(
  settingsDataSelector,
  (settings) => settings.titles.asMutable()
)

export const disciplineOptionsSelector = createSelector(
  settingsDataSelector,
  (settings) => settings.disciplines.asMutable()
)

export const disciplineClassificationOptionsSelector = createSelector(
  settingsDataSelector,
  (settings) => settings.discipline_classifications.asMutable()
)

export const countriesSelector = createSelector(
  settingsDataSelector,
  (settings) => settings.countries.asMutable()
)

export const USStatesSelector = createSelector(
  settingsDataSelector,
  (settings) => find(settings.countries, { country_abbr: 'US' }).country_states
)

export const servicesMenuSelector = createSelector(
  settingsDataSelector,
  (settings) => settings.services_menu
)

export const aboutUsMenuSelector = createSelector(
  settingsDataSelector,
  (settings) => settings.about_us_menu
)

export const resourcesMenuSelector = createSelector(
  settingsDataSelector,
  settings => settings.resources_menu
)

export const mailchimpEmbedSelector = createSelector(
  settingsDataSelector,
  settings => settings.mailchimp_embed_code
)

export const redirectsSelector = createSelector(
  settingsDataSelector,
  settings => settings.redirects
)
