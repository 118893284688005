import React from 'react'
import { Button, Heading } from 'core'
import { openLoginModal } from 'login-modal'
import { connect } from 'react-redux'
import { Grid, GridItem } from 'layout'
import { Label, validators, Input } from 'form'
import { Field } from 'redux-form'
import { registrationFormSelector, userRegistrationErrorsSelector } from './selectors'
import { entries, upperFirst } from 'lodash'
import css from './style.scss'
import SelectInstitution from 'select-institution/SelectInstitution'
import { SET_USER_REGISTRATION_SELECTED_INSTITUTION } from './types'

const LoginOrCreateAccountForm = ({ openLoginModal, userRegistrationErrors, setSelectedInstitution, formValues }) => {
  return (
    <div>
      <h6 style={{ marginBottom: '10px', marginTop: '10px' }}>Log into your account to register</h6>
      <Button onClick={() => openLoginModal({ redirectToDashboard: false, refresh: true })}>Login</Button>
      <h6 style={{ marginBottom: '10px', marginTop: '5px' }}>or create an account to register</h6>
      <Grid id='session-sale-new-user-registration-form'>
        <GridItem col='1/2' tablet1of2 mobile1of1>
          <Label htmlFor='registration_first_name'>First Name*</Label>
          <Field
            name='registration_first_name'
            component={Input}
            hideMeta
            validate={validators.required()}
          />
        </GridItem>
        <GridItem col='1/2' tablet1of2 mobile1of1>
          <Label htmlFor='registration_last_name'>Last Name*</Label>
          <Field
            name='registration_last_name'
            component={Input}
            hideMeta
            validate={validators.required()}
          />
        </GridItem>
        <GridItem col='1/2' tablet1of2 mobile1of1>
          <Label htmlFor='registration_email'>Email*</Label>
          <Field
            name='registration_email'
            component={Input}
            hideMeta
            validate={validators.required()}
          />
        </GridItem>
        <GridItem col='1/2' tablet1of2 mobile1of1>
          <Label htmlFor='registration_password'>Password*</Label>
          <Field
            name='registration_password'
            type='password'
            component={Input}
            hideMeta
            validate={validators.required()}
          />
        </GridItem>
      </Grid>
      {userRegistrationErrors && entries(userRegistrationErrors).map(([field, errors], i) => (
        <div key={i}>
          {errors.map((e, j) => (
            <p key={j} className={css.errorText}>{upperFirst(field)} {e}</p>
          ))}
        </div>
      ))}
      <div style={{ marginTop: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Field name='registration_is_institution_membership' type='checkbox' component='input' />
          <p style={{ margin: '0', marginLeft: '5px' }}>My institution has an NCFDD membership.</p>
        </div>
        {formValues.registration_is_institution_membership && (
          <div style={{ marginTop: '10px' }}>
            <Heading size='h6' noMargin>Select your institution</Heading>
            <p style={{ marginTop: '10px', marginBottom: '10px', fontStyle: 'italic' }}>
              If you do not see your institution in the drop-down options,
              your institution may not currently have an NCFDD membership.
              You can still register without selecting this option.
            </p>
            <Field
              name='registration_institution_id'
              component={SelectInstitution}
              onInstitutionSelect={setSelectedInstitution}
              membersOnly
              hideIDoNotSeeInstitution
              hideMeta
              valueSelector={institution => institution.id}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default connect((state, ownProps) => ({
  userRegistrationErrors: userRegistrationErrorsSelector(ownProps.form)(state),
  formValues: registrationFormSelector(ownProps.form)(state)
}), (dispatch) => ({
  openLoginModal: (props) => dispatch(openLoginModal(props)),
  setSelectedInstitution: (institution) => dispatch({ type: SET_USER_REGISTRATION_SELECTED_INSTITUTION, institution })
}))(LoginOrCreateAccountForm)
