import React, { PropTypes } from 'react'

import { formatPrice } from 'helpers'

import {
  SuccessModal,
  ModalHeading,
  ModalSubheading
} from 'modal'

const WaitlistConfirmModal = ({ price, title }) => {
  return (
    <SuccessModal>
      <ModalHeading>
        Thank you for joining the Wait List
      </ModalHeading>

      <ModalSubheading>What happens next?</ModalSubheading>
      <p>
        Thank you for joining the waitlist for our upcoming {title} session.
        If a spot becomes available, we’ll notify you directly and you can
        decide if you want to accept the position or not.
      </p>
      <p>
        If you claim an open spot, we will deduct the {formatPrice(price)} deposit
        you’ve already put down from the overall cost.
      </p>
    </SuccessModal>
  )
}

WaitlistConfirmModal.propTypes = {
  price: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
}

export default WaitlistConfirmModal
