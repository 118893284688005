import React from 'react'

import css from './style.scss'

/**
 * A context-specific component that handles padding w/ Section.
 * For use w/ Sections w/ mobileNoPadding applied; wrapping
 * any child element(s) of Section that shouldn't run full-width.
 */

const SectionOverride = (props) => (
  <div className={css.sectionOverride}>
    {props.children}
  </div>
)

SectionOverride.propTypes = {
  children: React.PropTypes.node
}

export default SectionOverride
