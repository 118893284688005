import React, { PropTypes } from 'react'
import { createSelector } from 'reselect'

import { load, schemas } from 'api'
import { currentUserInstitutionIdSelector } from 'auth'
import { Card } from 'core'

import AccountHeading from '../components/AccountHeading'
import InstitutionBillingAddressForm from './InstitutionBillingAddressForm'
import { PageHead } from 'page'

const InstitutionProfilePage = ({ institution, loadStatus }) => {
  return (
    <div>
      <PageHead title='Institution Profile' />
      <AccountHeading>
        Billing Address
      </AccountHeading>
      <Card spacing>
        <InstitutionBillingAddressForm institution={institution} />
      </Card>
    </div>
  )
}

InstitutionProfilePage.propTypes = {
  institution: PropTypes.object
}

export default load({
  institution: {
    urlSelector: createSelector(
      currentUserInstitutionIdSelector,
      institutionId => `/v2/institutions/${institutionId}`
    ),
    schema: schemas.institution
  }
})(InstitutionProfilePage)
