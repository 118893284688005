import React, { PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import { Button } from 'core'

import { SESSION_REGISTRATION_BUTTON_FORM_NAME } from '../../constants'
import css from './style.scss'

const RegisterButton = ({ children, disabled, onClick, submitting }) => {
  return (
    <div className={css.buttonContainer}>
      <Button
        type='button'
        size='primary'
        disabled={disabled || submitting}
        isLoading={submitting}
        onClick={() => onClick && onClick()}
      >
        {children}
      </Button>
    </div>
  )
}

RegisterButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool // from redux-form
}

RegisterButton.defaultProps = {
  disabled: false
}

export default reduxForm({
  form: SESSION_REGISTRATION_BUTTON_FORM_NAME
})(RegisterButton)
