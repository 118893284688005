import compact from 'lodash/compact'
import { formValueSelector, getFormValues } from 'redux-form'
import { createSelector } from 'reselect'

import { invoiceFormCompleteSelector } from 'invoice-request-form'
import { discountsSelector, calculateOrderSummary } from 'order-summary'
import { paymentIsCompleteSelector } from 'stripe'

import { INDIVIDUAL_RESERVATION_FORM_NAME } from '../constants'
import { pick } from 'lodash'
import { currentUserSelector } from 'auth'

export const getIndividualReservationOrderSummary = createSelector(
  (_, props) => props.sessionSale,
  discountsSelector(INDIVIDUAL_RESERVATION_FORM_NAME),
  (sessionSale, discounts) => {
    const allDiscounts = compact([].concat(discounts, sessionSale.discount))
    return calculateOrderSummary(sessionSale.reservation_price, allDiscounts)
  }
)

export const invoiceRequestedSelector = state => (
  formValueSelector(INDIVIDUAL_RESERVATION_FORM_NAME)(state, 'request_invoice')
)

export const registrationFormSelector = (formName) => createSelector(
  getFormValues(formName),
  (formValues) => ({
    ...pick(formValues, [
      'registration_first_name',
      'registration_last_name',
      'registration_email',
      'registration_password',
      'registration_is_institution_membership',
      'registration_institution_id'
    ])
  })
)
export const registrationFormCompleteSelector = (formName) => state => {
  const registrationForm = registrationFormSelector(formName)(state)
  return !!(registrationForm?.registration_first_name &&
    registrationForm?.registration_last_name &&
    registrationForm?.registration_email &&
    registrationForm?.registration_password)
}

export const userRegistrationErrorsSelector = (formName) => state => {
  const formValues = getFormValues(formName)(state)
  const registrationEmail = formValues?.registration_email
  const selectedInstitution = state?.individualReservations?.selectedInstitution

  let registrationEmailIsInstitutionEmail = true
  if (registrationEmail && selectedInstitution) {
    registrationEmailIsInstitutionEmail = false
    for (const domain of selectedInstitution.email_domains) {
      // regex on the domain
      const domainRegex = domain?.charAt(0) === '@'
        ? new RegExp(`${domain}$`, 'i')
        : new RegExp(`@${domain}$`, 'i')
      registrationEmailIsInstitutionEmail = domainRegex.test(registrationEmail)
      if (registrationEmailIsInstitutionEmail) {
        break
      }
    }
  }
  return {
    ...(state?.individualReservations?.errors || {}),
    ...(registrationEmailIsInstitutionEmail ? {} : { email: ['must be your institution email address'] })
  }
}

export const formIsCompleteSelector = createSelector(
  currentUserSelector,
  registrationFormCompleteSelector(INDIVIDUAL_RESERVATION_FORM_NAME),
  invoiceRequestedSelector,
  invoiceFormCompleteSelector(INDIVIDUAL_RESERVATION_FORM_NAME),
  paymentIsCompleteSelector(INDIVIDUAL_RESERVATION_FORM_NAME),
  (currentUser, registrationFormComplete, invoiceRequested, invoiceFormComplete, paymentComplete) => {
    return (!!currentUser || registrationFormComplete) && (
      invoiceRequested
        ? invoiceFormComplete
        : paymentComplete
    )
  }
)
