export const INVOICE_RECIPIENT_FIELD_NAMES = [
  'invoice_institution',
  'invoice_address_line_1',
  'invoice_address_line_2',
  'invoice_city',
  'invoice_state',
  'invoice_postal_code',
  'invoice_country',
  'invoice_billing_email',
  'invoice_billing_name'
]

export const INVOICE_REQUIRED_FIELD_NAMES = [
  ...INVOICE_RECIPIENT_FIELD_NAMES, 'confirm_funding'
]
