import React from 'react'

import css from '../style.scss'

const Input = props => {
  return (
    <input
      className={css.input}
      type='text'
      {...props.input}
    />
  )
}

export default Input
