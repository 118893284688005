import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { Section } from 'layout'

import css from './style.scss'

const ErrorPage = ({ children }) => {
  return (
    <Section>
      <div className={css.errorPageContainer}>
        {children}
      </div>
    </Section>
  )
}

ErrorPage.propTypes = {
  children: React.PropTypes.node
}

export default connect(null, { push })(ErrorPage)
