import React from 'react'
import moment from 'moment-timezone'
import { browserHistory } from 'react-router'

import { Button, Contact, Heading } from 'core'
import { formatTimeRange, formatWebinarDate } from 'helpers'
import { Section } from 'layout'

import CompleteButton from './CompleteButton'
import RegisterButton from './RegisterButton'

import css from './style.scss'
import Vimeo from '@u-wave/react-vimeo'
import Youtube from '@u-wave/react-youtube'

const extractVideoID = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  if (match && match[7].length === 11) {
    return match[7]
  }
}

/**
 * The `<VideoHeader />` component generates a header
 * of the specified event object passed down to it.
 * It is exclusively used for our single Event pages.
 */

const VideoHeader = (props) => {
  const { webinar, isAuthenticated } = props
  const {
    title,
    video_url: url,
    registered,
    completed_at: completedAt,
    type,
    start_at: start,
    end_at: end,
    facilitator,
    status
  } = webinar
  const completed = !!completedAt
  const videoStates = {
    notStarted: true,
    started: false,
    finished: false,
    duration: 0
  }

  // Set the video states based on previous watch data
  if (status === 'COMPLETED') {
    videoStates.notStarted = false
    videoStates.started = true
    videoStates.finished = true
  } else if (status === 'STARTED') {
    videoStates.notStarted = true
    videoStates.started = true
  }

  // Used for YouTube calculations

  /* Safe-check in case facilitator user account gets deleted */
  if (facilitator) {
    facilitator.role = 'Facilitator'
  }

  let date, times, displayType

  switch (type) {
    case 'course':
      displayType = 'Course'
      break
    case 'curriculum':
      displayType = 'Core Curriculum'
      break
    case 'guest':
      displayType = 'Guest Webinar'
      break
    default:
      displayType = 'Event'
      break
  }

  if (start) {
    date = formatWebinarDate(start)
  }

  if (start && end) {
    times = formatTimeRange(start, end)
  }

  const now = moment()
  const showRegister = start && moment(start).isAfter(now)
  const showComplete = start && moment(start).isSameOrBefore(now)

  /**
   * Get the Required Video Player
   * @param provider
   * @returns {JSX.Element}
   */
  const genVideoContainer = (provider) => {
    switch (provider) {
      case 'vimeo':
        return (
          <div className={css.video}>
            <Vimeo
              playsInline
              video={url}
              autoplay={false}
              speed
              controls
              responsive
              onTimeUpdate={(e) => handleVideoTime(e)}
            />
          </div>
        )
      case 'youtube':
        return (
          <div className={css.video}>
            <Youtube
              playsInline
              video={extractVideoID(url)}
              autoplay={false}
              onReady={() => handleYoutubeVideoReady()}
              modestBranding
              showRelatedVideos={false}
              annotations={false}
              allowFullscreen
            />
          </div>
        )
      default:
        return (
          <div className={css.video}>
            <iframe
              src={url}
              allowFullScreen
            />
          </div>
        )
    }
  }

  const videoContent = genVideoContainer(webinar.video_provider)

  /**
   * Handle YouTube Video Ready
   */
  const handleYoutubeVideoReady = () => {
    // Grab Iframe containing YouTube Player
    const iframeWindow = document.getElementsByTagName('iframe')[0].contentWindow

    // Watch messages from IFrame
    window.addEventListener('message', (e) => {
      // Gate unwanted events
      if (e.source !== iframeWindow) return

      const data = JSON.parse(e.data)

      // The Duration is only in the first message, grab it while we have the chance
      if (
        data.event === 'infoDelivery' &&
        data.info &&
        data.info.duration &&
        !videoStates.duration
      ) {
        videoStates.duration = data.info.duration
      }

      if (
        data.event === 'infoDelivery' &&
        data.info &&
        data.info.currentTime &&
        videoStates.duration
      ) {
        if (Math.floor(data.info.currentTime) > 10 && videoStates.started === false) {
          handleVideoStarted()
        } else if ((data.info.currentTime / videoStates.duration) > 0.89 && videoStates.finished === false) {
          handleVideoFinished()
        }
      }
    })
  }

  /**
   * Handle Vimeo Video Time
   * @param e
   */
  const handleVideoTime = (e) => {
    const { percent, seconds } = e

    if (seconds > 10 && videoStates.started === false) {
      handleVideoStarted()
    } else if (percent > 0.89 && videoStates.finished === false) {
      handleVideoFinished()
    }
  }

  /**
   * Handle Video Started
   */
  const handleVideoStarted = () => {
    // Set States
    videoStates.started = true
    videoStates.notStarted = false

    props.started()
  }

  /**
   * Handle Video Finished
   */
  const handleVideoFinished = () => {
    // Set States
    videoStates.finished = true

    props.complete()
  }

  const authenticatedButtonContent = (
    <span className={css.button}>
      {
        showRegister &&
          <RegisterButton
            register={props.register}
            unregister={props.unregister}
            isRegistered={registered}
            isLoading={props.isRegistering || props.isUnregistering}
          />
      }
      {
        showComplete &&
          <CompleteButton
            complete={props.complete}
            isCompleted={completed}
            isLoading={props.isCompleting}
          />
      }
    </span>
  )

  const publicButtonContent = (
    <span className={`${css.button} ${css.publicButton}`}>
      <Button
        size='primary'
        noMargin
        onClick={handleJoinButtonClicked}
      >
        Become a member
      </Button>
    </span>
  )

  const VideoSection = ({ video, title, cta, description, footer }) => {
    return (
      <div className={css.details}>
        <div className={css.mobileVideoHeader}>
          <div>
            {title}
          </div>
          <div className={css.mobileCTA}>
            {cta}
          </div>
        </div>
        {video}
        <div className={css.rightDetailSection}>
          <div>
            {title}
          </div>
          <div>
            {description}
          </div>
          <div>
            {cta}
          </div>
          <div>
            {footer}
          </div>
        </div>
        <div className={css.mobileVideoFooter}>
          {description && (
            <div className={css.mobileVideoFooterSection}>
              {description}
            </div>
          )}
          {footer && (
            <div className={css.mobileVideoFooterSection}>
              {footer}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={css.wrapper}>
      <Section>
        <Heading size='h6' weight='thin'>{displayType}</Heading>
        <Heading size='h2' freight weight='medium'>{title}</Heading>
      </Section>
      <Section mobileNoPadding>
        {
          isAuthenticated
            ? <VideoSection
                video={videoContent}
                title={(
                  <div>
                    {
                    date &&
                      <p className={css.date}>{date}</p>
                  }
                    {
                    times &&
                      <p className={css.time} size='h5'>{times}</p>
                  }
                  </div>)}
                cta={authenticatedButtonContent}
                description={facilitator && <Contact size='lg' user={facilitator} nameItalic />}
                footer={(
                  <div>
                    <p className={css.contactUs}>Contact us</p>
                    <p className={css.email}>webinar@ncfdd.org</p>
                  </div>
                )}
              />
            : <VideoSection
                video={videoContent}
                title={(
                  <Heading size='h4' weight='500' freight noMargin center>
                    Join the NCFDD today
                  </Heading>
                )}
                cta={publicButtonContent}
                description={(
                  <p>
                    The NCFDD serves members from almost every discipline and representing a broad range of colleges and
                    universities globally, join today and start transforming your personal academic life.
                  </p>
                )}
              />
        }
      </Section>
    </div>
  )
}

const handleJoinButtonClicked = () => browserHistory.push('/join')

VideoHeader.propTypes = {
  webinar: React.PropTypes.shape({
    id: React.PropTypes.number,
    type: React.PropTypes.string,
    title: React.PropTypes.string,
    start_at: React.PropTypes.string,
    end_at: React.PropTypes.string,
    video_embed_url: React.PropTypes.string,
    facilitator: React.PropTypes.shape({
      name: React.PropTypes.string,
      avatar_url: React.PropTypes.string,
      title: React.PropTypes.string,
      role: React.PropTypes.string
    })
  }).isRequired,
  register: React.PropTypes.func.isRequired,
  unregister: React.PropTypes.func.isRequired,
  isRegistering: React.PropTypes.bool.isRequired,
  isUnregistering: React.PropTypes.bool.isRequired,
  complete: React.PropTypes.func.isRequired,
  started: React.PropTypes.func.isRequired,
  isCompleting: React.PropTypes.bool.isRequired,
  isAuthenticated: React.PropTypes.bool.isRequired
}

export default VideoHeader
