import { createSelector } from 'reselect'
import qs from 'qs'
import { find } from 'lodash'

import { querySelector, schemas } from 'api'
import { currentUserInstitutionIdSelector } from 'auth'

import { reservationsSelector } from '../../selectors'

const searchTermSelector = state => state.institutionReservations.userSearchTerm

export const institutionUserSearchUrlSelector = createSelector(
  currentUserInstitutionIdSelector,
  searchTermSelector,
  (institutionId, searchTerm) => {
    if (!searchTerm) return null

    return `/v2/institutions/${institutionId}/users?${
      qs.stringify({
        limit: 6,
        filters: {
          fullname_ilike: searchTerm
        }
      })
    }`
  }
)

export const userSearchSelector = createSelector(
  querySelector({
    urlSelector: institutionUserSearchUrlSelector,
    schema: [schemas.user]
  }),
  reservationsSelector,
  (searchQuery, reservations) => {
    const users = searchQuery.data || []

    return {
      loading: searchQuery.loading,
      options: users
        .filter(user => !find(reservations, { user_id: user.id }))
        .map(user => ({
          value: user.id,
          label: `${user.name} (${user.email})`
        }))
    }
  }
)
