import React from 'react'
import { compact } from 'lodash'

import { InputWrapper } from '../InputWrapper'
import css from './style.scss'

/**
 * Controlled input component. Is compatible with redux-form Field
 */
const Input = (props) => {
  const classNames = [
    css.input,
    props.globalError ? css.globalError : null,
    props.className
  ]

  const style = {}

  if (props.maxInputWidth) {
    style.maxWidth = props.maxInputWidth
  }

  return (
    <InputWrapper hideMeta={!!props.hideMeta} {...props.meta}>
      <input
        id={props.id}
        style={style}
        className={compact(classNames).join(' ')}
        {...props.input}
        type={props.type}
        maxLength={props.maxLength}
        autoComplete={props.disableAutocomplete ? 'off' : 'on'}
        // for type='number'
        max={props.max}
        min={props.min}
        disabled={props.disabled}
        placeholder={props.placeholder}
      />
    </InputWrapper>
  )
}

Input.propTypes = {
  id: React.PropTypes.string,
  input: React.PropTypes.object,
  meta: React.PropTypes.object,
  type: React.PropTypes.string,
  disabled: React.PropTypes.bool,
  maxLength: React.PropTypes.number,
  min: React.PropTypes.number,
  max: React.PropTypes.number,
  maxInputWidth: React.PropTypes.oneOfType([
    React.PropTypes.number,
    React.PropTypes.string
  ]),
  disableAutocomplete: React.PropTypes.bool,
  globalError: React.PropTypes.string
}

Input.defaultProps = {
  type: 'text',
  disableAutoComplete: false,
  disabled: false,
  placeholder: null
}

export default Input
