import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { createSelector, createStructuredSelector } from 'reselect'
import { UserAuthWrapper } from 'redux-auth-wrapper'

import { load, schemas } from 'api'
import {
  currentUserAddressSelector,
  loadingCurrentUserSelector
} from 'auth'
import { Button, Heading } from 'core'
import { OrderSummaryTable, OrderSummaryPropTypes, DiscountCodeForm } from 'order-summary'
import { FormContainer, FormSection, FormRule } from 'form'
import { applyHOCs } from 'helpers'
import { Spacer } from 'layout'
import { page, pageLoadSelectors } from 'page'
import {
  PaymentForm,
  paymentIsCompleteSelector,
  withStripeElements
} from 'stripe'

import { register } from './actions'
import { REGISTRATION_FORM_NAME as FORM_NAME } from './constants'
import { getMembershipRegistrationOrderSummary } from './selectors'

export const MembershipRegistrationPage = props => {
  const {
    register,
    paymentIsComplete,
    membershipPlan,
    stripeElements,
    submitting,
    orderSummary
  } = props

  return (
    <FormContainer onSubmit={register}>
      <FormSection grey>
        <Heading size='h6' freight center noMargin>
          NCFDD Membership - 1 Year
        </Heading>
      </FormSection>
      <FormSection>
        <Heading size='h6'>Order Summary</Heading>
        <OrderSummaryTable {...orderSummary} />
        <Spacer size='xs' />
        <DiscountCodeForm
          parentForm={FORM_NAME}
          productId={membershipPlan.product_id}
        />
        {orderSummary.total > 0 && [
          <FormRule key='form-rule' />,
          <PaymentForm
            key='payment-form'
            form={FORM_NAME}
            stripeElements={stripeElements}
          />
        ]}
      </FormSection>
      <FormSection>
        <Button
          type='submit'
          size='primary'
          noMargin
          disabled={(orderSummary.total > 0 && !paymentIsComplete) || submitting}
          isLoading={submitting}
        >
          Complete My Membership
        </Button>
      </FormSection>
    </FormContainer>
  )
}

MembershipRegistrationPage.propTypes = {
  register: PropTypes.func.isRequired,
  paymentIsComplete: PropTypes.bool,
  membershipPlan: PropTypes.shape({
    product_id: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired
  }).isRequired,
  stripeElements: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  orderSummary: PropTypes.shape(OrderSummaryPropTypes).isRequired
}

export default applyHOCs([
  connect(
    createStructuredSelector({
      paymentIsComplete: paymentIsCompleteSelector(FORM_NAME),
      initialValues: currentUserAddressSelector,
      orderSummary: getMembershipRegistrationOrderSummary
    }),
    (dispatch, props) => ({
      register: () => {
        return dispatch(register(props))
      }
    })
  ),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true
  }),
  withStripeElements(),
  UserAuthWrapper({
    authenticatingSelector: loadingCurrentUserSelector,
    authSelector: createStructuredSelector({
      loading: loadingCurrentUserSelector
    }),

    predicate: ({ loading }) => {
      return !loading && true
    },

    allowRedirectBack: false,
    failureRedirectPath: '/'
  }),
  page({
    layout: 'form',
    ...pageLoadSelectors('membershipPlan'),
    loadingSelector: createSelector(
      loadingCurrentUserSelector,
      (state, props) => props.loadStatus.membershipPlan.loading,
      (loadingCurrentUser, loadingMembershipPlan) => {
        return loadingCurrentUser || loadingMembershipPlan
      }
    )
  }),
  load({
    membershipPlan: {
      urlSelector: createSelector(
        (state, props) => props.params.membershipPlanId,
        (planId) => {
          if (!planId) return null
          return `/v2/membership_plans/${planId}`
        }
      ),
      schema: schemas.membershipPlan
    }
  })
])(MembershipRegistrationPage)
