import { batchActions } from 'redux-batched-actions'
import { startSubmit, stopSubmit, formValueSelector } from 'redux-form'
import { map, take } from 'lodash'

import { apiResponse, schemas } from 'api'
import { createClient } from 'auth'
import { handleError } from 'errors'
import { openModal } from 'modal'

import ConfirmModal from '../ConfirmModal'
import { INSTITUTION_RESERVATION_FORM_NAME } from '../../constants'
import {
  CREATE_RESERVATIONS_SUCCESS,
  DELETE_RESERVATIONS_SUCCESS,
  REFRESH_SESSION_SALE
} from '../types'
import { reservationsSelector } from '../selectors'
import { orderByDeletionPriority } from './helpers'

export const reserveInstitutionSpots = props => (dispatch, getState) => {
  const { sessionSale } = props

  const state = getState()

  const reservations = reservationsSelector(state)
  const count = formValueSelector(INSTITUTION_RESERVATION_FORM_NAME)(state, 'count')
  const numExistingReservations = reservations.length

  if (count === numExistingReservations) {
    props.continue()
    return Promise.resolve()
  }

  dispatch(startSubmit(INSTITUTION_RESERVATION_FORM_NAME))
  const client = createClient(state)

  let updateReservations

  if (count > numExistingReservations) {
    updateReservations = client
      .post(`/v2/session_sales/${sessionSale.id}/reservations`, {
        body: {
          type: 'institution',
          count: count - numExistingReservations
        }
      })
      .then(res => res.json())
      .then(newReservations => {
        return apiResponse({
          type: CREATE_RESERVATIONS_SUCCESS,
          data: newReservations,
          schema: [schemas.sessionReservation]
        })
      })
  } else {
    const byDeletionPriority = orderByDeletionPriority(reservations)
    const reservationsToDelete = take(byDeletionPriority, numExistingReservations - count)
    const reservationIdsToDelete = map(reservationsToDelete, 'id')

    updateReservations = Promise.all(reservationIdsToDelete.map(reservationId => {
      return client.del(`/v2/session_reservations/${reservationId}`)
    }))
      .then(() => {
        return {
          type: DELETE_RESERVATIONS_SUCCESS,
          deletedIds: reservationIdsToDelete
        }
      })
  }

  return updateReservations
    // update session sale to refresh num_institution_reservations_available
    .then(updateAction => {
      return client.get(`/v2/session_sales/${sessionSale.id}`)
        .then(res => res.json())
        .then((updatedSessionSale) => {
          return dispatch(batchActions([
            updateAction,
            apiResponse({
              type: REFRESH_SESSION_SALE,
              data: updatedSessionSale,
              schema: schemas.sessionSale
            })
          ]))
        })
    })
    .then(() => {
      dispatch(stopSubmit(INSTITUTION_RESERVATION_FORM_NAME))
      props.continue()

      // only show modal when creating first reservations
      if (numExistingReservations === 0) {
        dispatch(openModal(ConfirmModal, {
          deadlineDate: sessionSale.pre_registration_end_at
        }))
      }
    })
    .catch(err => {
      dispatch(stopSubmit(INSTITUTION_RESERVATION_FORM_NAME))
      dispatch(handleError(err))
      return err
    })
}
