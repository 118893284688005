import Immutable from 'seamless-immutable'
import { values } from 'lodash'
import { filterActions } from 'redux-ignore'

import * as types from './types'
const {
  SUBACCOUNT_EXPORT_SUCCESS,
  SUBACCOUNT_EXPORT_FAILED,
  SUBACCOUNT_EXPORT_EXPIRED,
  SUBACCOUNT_EXPORT_REQUEST,
  INVOICE_EXPORT_SUCCESS,
  INVOICE_EXPORT_FAILED,
  INVOICE_EXPORT_REQUEST,
  INVOICE_EXPORT_EXPIRED
} = types

const INITIAL_STATE = Immutable({})

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUBACCOUNT_EXPORT_REQUEST:
      return state.merge({
        subaccountExport: {
          data: null,
          isLoading: true
        }
      })
    case SUBACCOUNT_EXPORT_SUCCESS:
      return state.merge({
        subaccountExport: {
          data: action.data,
          isLoading: false
        }
      })
    case SUBACCOUNT_EXPORT_EXPIRED:
    case SUBACCOUNT_EXPORT_FAILED:
      return state.merge({
        subaccountExport: {
          data: null,
          isLoading: false
        }
      })
    case INVOICE_EXPORT_REQUEST:
      return state.merge({
        invoiceExport: {
          [action.orderId]: {
            data: null,
            isLoading: true
          }
        }
      })
    case INVOICE_EXPORT_SUCCESS:
      return state.merge({
        invoiceExport: {
          [action.orderId]: {
            data: action.data,
            isLoading: false
          }
        }
      })
    case INVOICE_EXPORT_EXPIRED:
    case INVOICE_EXPORT_FAILED:
      return state.merge({
        invoiceExport: {
          [action.orderId]: {
            data: null,
            isLoading: false
          }
        }
      })
    default:
      return state
  }
}

export default filterActions(reducer, values(types))
