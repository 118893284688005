import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { forEach, get, mapValues } from 'lodash'

import { isAuthenticatedSelector } from 'auth/selectors'
import { getDisplayName } from 'helpers'

import { loadData } from './actions'
import { queriesSelector } from './selectors'

/**
 * Config options:
 *   [key]: {
 *     urlSelector (state, props)
 *     schema
 *     errorHandler (error, dispatch) - optional
 *   }
 *
 * Props passed to component:
 *   [key]: apiResponse | undefined
 *   loadStatus: {
 *     [key]: {
 *       url - the url that was selected for the GET
 *       loading - if loading for the first time for this component
 *       reloading - if subsequent load (triggered by a different loader)
 *     }
 *   }
 */

export const load = (loadConfig = {}) => WrappedComponent => {
  class Loader extends Component {
    constructor (props) {
      super(props)
      this.state = mapValues(loadConfig, (config, key) => ({
        receivedInitialData: false
      }))
    }

    forEachQuery (handler) { forEach(this.props.queries, handler) }

    /**
     * Load fresh data on mount
     */
    componentDidMount () {
      this.forEachQuery(({ url }, key) => {
        if (!url) return

        if (!this.props.shouldSkipForKey?.(key)) {
          this.props.loadData({
            url,
            schema: loadConfig[key].schema,
            errorHandler: loadConfig[key].errorHandler || null
          })
        }
      })
    }

    /**
     * Reload data if url or auth state changes
     */
    componentDidUpdate (prevProps) {
      const newlyAuthenticated = this.props.isAuthenticated && !prevProps.isAuthenticated
      this.forEachQuery(({ url }, key) => {
        const urlChanged = url && url !== prevProps.queries[key].url

        // Reload resources when going from unauthenticated to authenticated.
        // TODO: to limit scope I'm restricting this to resources the wrapper
        // already tried to load, but failed with a 403. In theory the API may
        // respond with a reduced set of details for anonymous users rather than
        // generating an error, so it would be worth letting people tweak this
        // behaviour to refresh in other circumstances.
        const lastLoadWasForbidden = get(this.props.queries[key], 'error.response.status') === 403
        const authedAfterForbidden = newlyAuthenticated && lastLoadWasForbidden

        if ((urlChanged || authedAfterForbidden) && !this.props.shouldSkipForKey?.(key)) {
          this.props.loadData({
            url,
            schema: loadConfig[key].schema,
            errorHandler: loadConfig[key].errorHandler || null
          })
        }
      })
    }

    /**
     * Set flag after initial data load
     */
    componentWillReceiveProps (nextProps) {
      this.forEachQuery((query, key) => {
        if (this.props.queries[key].loading && !nextProps.queries[key].loading) {
          this.setState({ [key]: { receivedInitialData: true } })
        }

        if (this.props.queries[key].url !== nextProps.queries[key].url) {
          this.setState({ [key]: { receivedInitialData: false } })
        }
      })
    }

    render () {
      const { queries, loadStatus: existingLoadStatus, ...otherProps } = this.props

      return (
        <WrappedComponent
          {...otherProps}
          {...mapValues(queries, 'data')}
          loadStatus={{
            ...existingLoadStatus,
            ...mapValues(queries, (query, key) => {
              const { url, loading: queryIsLoading, error, totalCount } = query
              const { receivedInitialData } = this.state[key]
              return {
                url,
                loading: !receivedInitialData && !!url,
                reloading: queryIsLoading && receivedInitialData,
                totalCount,
                error
              }
            })
          }}
        />
      )
    }
  }

  Loader.propTypes = {
    queries: PropTypes.object.isRequired,
    loadData: PropTypes.func.isRequired
  }

  Loader.displayName = `Loader(${getDisplayName(WrappedComponent)})`

  return Loader
}

export default (loadConfig = {}) => WrappedComponent => {
  const mapStateToProps = createStructuredSelector({
    queries: queriesSelector(loadConfig),
    isAuthenticated: isAuthenticatedSelector,
    shouldSkipForKey: (state) => (key) => {
      if (loadConfig?.[key]?.skipSelector) {
        return loadConfig?.[key]?.skipSelector(state, key)
      }
    }
  })

  return connect(mapStateToProps, { loadData })(
    load(loadConfig)(WrappedComponent)
  )
}
