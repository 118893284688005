import { PropTypes } from 'react'
import { reduxForm, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import { size } from 'lodash'

import { load, schemas } from 'api'
import { formWizardFactory } from 'form-wizard'
import { withStripeElements } from 'stripe'
import { applyHOCs, applyPropTypes } from 'helpers'
import { page, pageLoadSelectors } from 'page'

import { submit } from './actions'
import { FORM_NAME } from '../constants'
import steps from './steps'
import { getIndividualRegistrationOrderSummary } from './selectors'

const IndividualRegistrationPage = formWizardFactory({
  steps,
  getStepFormName: ({ step }) => {
    return step ? `INDIVIDUAL MEMBER JOIN - ${step.name}` : 'INDIVIDUAL MEMBER JOIN'
  },
  completeAction: submit
})

IndividualRegistrationPage.propTypes = {
  membershipPlan: PropTypes.object.isRequired
}

const formValuesSelector = getFormValues(FORM_NAME)

export default applyHOCs([
  reduxForm({ form: FORM_NAME }),
  connect((state, props) => {
    const orderSummary = getIndividualRegistrationOrderSummary(state, props)

    return {
      orderSummary,
      // form values may be initialized from localstorage.
      // in this case, don't set initial values
      initialValues: size(formValuesSelector(state)) > 0
        ? undefined
        : { newsletter_opt_in: true }
    }
  }),
  withStripeElements(),
  page({
    layout: 'form',
    ...pageLoadSelectors('membershipPlan')
  }),
  load({
    membershipPlan: {
      urlSelector: (state, props) => {
        return `/v2/membership_plans/${props.params.membershipPlanId}`
      },
      schema: schemas.membershipPlan
    }
  }),
  applyPropTypes({
    params: PropTypes.shape({
      membershipPlanId: PropTypes.string.isRequired
    }).isRequired
  })
])(IndividualRegistrationPage)
