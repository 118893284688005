import React from 'react'

import { load, schemas } from 'api'
import { applyHOCs } from 'helpers'
import { Spacer } from 'layout'
import { page, pageLoadSelectors } from 'page'
import { HeaderTertiary } from 'page-blocks'

import CoreCurriculumBlock from './CoreCurriculumBlock'

const CurriculumContainer = (props) => {
  return (
    <div>
      <HeaderTertiary title='The Core Curriculum' />
      <Spacer size='sm' />
      <CoreCurriculumBlock
        coreCurriculumWebinars={props.coreCurriculumWebinars}
      />
      <Spacer size='sm' />
    </div>
  )
}

CurriculumContainer.propTypes = {
  coreCurriculumWebinars: React.PropTypes.arrayOf(React.PropTypes.object).isRequired
}

export default applyHOCs([
  page(pageLoadSelectors('coreCurriculumWebinars')),
  load({
    coreCurriculumWebinars: {
      urlSelector: () => '/v2/core_curriculum',
      schema: [schemas.coreCurriculumWebinar]
    }
  })
])(CurriculumContainer)
