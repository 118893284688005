import { createSelector } from 'reselect'
import qs from 'qs'
import { querySelector, schemas } from 'api'

const searchTermSelector = state => state.institutionSearch.searchTerm

export const institutionSearchUrlSelector = (props) => createSelector(
  searchTermSelector,
  searchTerm => {
    if (!searchTerm) return null

    const baseUrl = props?.membersOnly ? '/v2/institutions' : '/v2/universities'

    return `${baseUrl}?${qs.stringify({
      limit: 6,
      filters: {
        name_ilike: searchTerm
      }
    })}`
  }
)

export const institutionSearchSelector = (props) => createSelector(
  querySelector({
    urlSelector: institutionSearchUrlSelector(props),
    schema: [schemas.institution]
  }),
  searchQuery => {
    const institutions = searchQuery.data || []

    return {
      loading: searchQuery.loading,
      options: institutions.map((institution) => ({
        label: institution.name,
        value: props.valueSelector ? props.valueSelector?.(institution) : institution.name
      })),
      getInstitutionForValue (value) {
        return institutions.find(institution => (props.valueSelector?.(institution) || institution.name) === value)
      }
    }
  }
)
