import React from 'react'
import { writeNowUrl } from 'config'

import { Button, Card, Heading, Link } from 'core'

import css from './style.scss'

import assetUrl from './assets/ladyLaptop.jpg'

const CardWriteNow = ({ session }) => {
  return (
    <div className={css.cardWrapper}>
      <Card img={assetUrl}>
        <div className={css.dashboard}>
          <div className={css.headingBootcamp}>
            <Heading size='h3' weight='thin' center noMargin>
              Your Current Bootcamp Session
            </Heading>
          </div>
          <p className={css.detailsWhite}>{session.name}</p>
          <Link to={writeNowUrl} target='_blank' fullWidth>
            <Button size='secondary' noMargin>
              Go To WriteNow
            </Button>
          </Link>
        </div>
      </Card>
    </div>
  )
}

CardWriteNow.propTypes = {
  session: React.PropTypes.shape({
    name: React.PropTypes.string
  }).isRequired
}

export default CardWriteNow
