import React, { PropTypes } from 'react'

import { Button } from 'core'
import { FormSection } from 'form'
import { Grid, GridItem } from 'layout'
import { formatPrice } from 'helpers'

import css from './style.scss'

const SubmitSection = ({ price, buttonText, disabled, loading }) => (
  <FormSection>
    <Grid center>
      <GridItem col='1/2' mobile1of1>
        {price && (
          <div>
            <div className={css.totalAmountText}>
              Total:
            </div>
            <div className={css.price}>
              {formatPrice(price)}
            </div>
          </div>
        )}
      </GridItem>
      <GridItem col='1/2' mobile1of1>
        <Button
          type='submit'
          size='primary'
          noMargin
          disabled={disabled}
          isLoading={loading}
        >
          {buttonText}
        </Button>
      </GridItem>
    </Grid>
  </FormSection>
)

SubmitSection.propTypes = {
  price: PropTypes.number,
  buttonText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
}

export default SubmitSection
