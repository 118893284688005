/* global Stripe */
import { stripeKey } from 'config'

const stripe = Stripe(stripeKey)

export const createStripeElements = () => {
  const elements = stripe.elements()

  const style = {
    base: {
      // Added an intense font-stack in case users have other local fonts
      fontFamily: "'Proxima-Nova', 'proxima-nova', 'Open Sans', 'Helvetica', 'Arial', sans-serif",
      color: '#505050',
      '::placeholder': {
        color: '#FFF'
      }
    },
    invalid: {
      color: '#505050'
    }
  }

  return {
    cardNumber: elements.create('cardNumber', { style }),
    cardExpiry: elements.create('cardExpiry', { style }),
    cardCvc: elements.create('cardCvc', { style })
  }
}

export const createStripeChargeToken = (stripeElement) => {
  return stripe.createToken(stripeElement)
    .then((result) => {
      if (result.token) {
        return result.token
      } else if (result.error) {
        // Stripe will *always* return a result, even in the case of an error
        return Promise.reject(result.error)
      }
    })
}
