import moment from 'moment-timezone'

const formatTime = (date) => moment(date).format('h:mma')

const zone = ((zn) => {
  if (/E(S|D)T/.test(zn)) {
    zn = 'ET'
  } else if (/P(S|D)T/.test(zn)) {
    zn = 'PT'
  }
  return zn
})(moment.tz(moment.tz.guess()).zoneAbbr())

const formatDateRange = (start, end) => {
  return `${formatTime(start)} - ${formatTime(end)} ${zone}`
}

export default formatDateRange
