import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { writeNowUrl } from 'config'

import { load, schemas } from 'api'
import { applyHOCs } from 'helpers'

import RegisterButton from '../components/RegisterButton'
import SessionSaleHeader from '../components/SessionSaleHeader'
import SaleStateText from '../components/SaleStateText'

import { register } from './actions'
import { formatCloseDate, getDateState, participatingInSession } from '../helpers'
import { isAuthenticatedSelector } from 'auth'
import { openLoginModal } from 'login-modal'
import { openLoginOrCreateAccountSessionSaleModal } from './LoginOrCreateAccountSessionSaleModal'

export const NonFSPHeader = ({ register, sessionSale, activeGroups }) => {
  let headingText
  let registerButton
  let registrationEndDate

  const participating = participatingInSession(sessionSale, activeGroups)
  const dateState = getDateState(
    sessionSale.open_registration_start_at,
    sessionSale.open_registration_end_at
  )

  if (participating) {
    headingText = sessionSale.title
    registerButton = (
      <RegisterButton onClick={() => window.open(writeNowUrl)}>
        Go to WriteNow
      </RegisterButton>
    )
  } else if (dateState === 'after') {
    headingText = `Registration for the ${sessionSale.title} is finished`
    registrationEndDate = `Registration closed on ${formatCloseDate(sessionSale.open_registration_end_at)}`
  } else {
    headingText = `Registration for the ${sessionSale.title}`
    registrationEndDate = `Registration closes on ${formatCloseDate(sessionSale.open_registration_end_at)}`
    if (sessionSale.registration_status === 'none') {
      registerButton = (
        <RegisterButton onClick={register}>
          Register now
        </RegisterButton>
      )
    } else if (sessionSale.registration_status === 'reserved') {
      registerButton = (
        <RegisterButton disabled>
          You are registered
        </RegisterButton>
      )
    }
  }

  return (
    <SessionSaleHeader sessionSale={sessionSale} title={headingText}>
      {registerButton}
      {registrationEndDate && (
        <SaleStateText>{registrationEndDate}</SaleStateText>
      )}
    </SessionSaleHeader>
  )
}

NonFSPHeader.propTypes = {
  register: PropTypes.func.isRequired,
  sessionSale: PropTypes.shape({
    id: PropTypes.number.isRequired,
    session_id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    registration_status: PropTypes.string.isRequired,
    open_registration_start_at: PropTypes.string.isRequired,
    open_registration_end_at: PropTypes.string.isRequired,
    advertised_start_date: PropTypes.string.isRequired,
    advertised_end_date: PropTypes.string.isRequired
  }).isRequired,
  activeGroups: PropTypes.arrayOf(PropTypes.shape({
    session: PropTypes.shape({
      id: PropTypes.number.isrequired
    }).isRequired
  }))
}

export default applyHOCs([
  connect(
    state => ({
      isAuthenticated: isAuthenticatedSelector(state)
    }),
    (dispatch, props) => ({
      register: () => {
        if (props.isAuthenticated) {
          dispatch(register(props.sessionSale))
        } else {
          if (props.sessionSale.type === 'alumni') {
            dispatch(openLoginModal({
              redirectToDashboard: false
            }))
          } else {
            dispatch(openLoginOrCreateAccountSessionSaleModal({ sessionSale: props.sessionSale }))
          }
        }
      }
    })
  ),
  load({
    activeGroups: {
      urlSelector: () => '/v2/users/current/groups',
      schema: [schemas.group],
      skipSelector: (state) => {
        return !isAuthenticatedSelector(state)
      }
    }
  })
])(NonFSPHeader)
