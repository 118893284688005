import React from 'react'
import moment from 'moment'
import { truncate } from 'lodash'

import { Grid, GridItem } from 'layout'

import { Button } from '../Button'
import { Contact } from '../Contact'
import { Heading } from '../Heading'
import { Link } from '../Link'

import css from './style.scss'

const truncateOptions = {
  length: 200,
  separator: /,?\s+/
}

const PostSingle = (props) => {
  const { url, title, date, author, type, plaintextBody, mondayMotivator } = props
  const formattedDate = moment(date).format('MM/DD/YYYY')
  return (
    <div className={css.single}>
      <Heading size='h4' freight noMargin>
        {title}
      </Heading>
      {!mondayMotivator &&
        <p className={css.date}>{formattedDate}</p>}
      {
        type === 'news' && plaintextBody && <p>{truncate(plaintextBody, truncateOptions)}</p>
      }
      <div className={css.footer}>
        <Grid center>
          {
            author && type === 'news' &&
              <GridItem col='3/4' mobile1of1>
                <div className={css.author}>
                  <Contact user={author} byline />
                </div>
              </GridItem>
          }
          <GridItem col='1/4' mobile1of1>
            <Link to={url}>
              <Button noMargin size='secondary'>Read More</Button>
            </Link>
          </GridItem>
        </Grid>
      </div>
    </div>
  )
}

PostSingle.propTypes = {
  url: React.PropTypes.string.isRequired,
  title: React.PropTypes.string.isRequired,
  date: React.PropTypes.string.isRequired,
  excerpt: React.PropTypes.string,
  author: React.PropTypes.object,
  type: React.PropTypes.string,
  mondayMotivator: React.PropTypes.bool
}

export default PostSingle
