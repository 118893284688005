import React, { PropTypes } from 'react'

import { ErrorFlashRoot } from 'errors'
import { ModalRoot } from 'modal'
import { installGTM } from 'analytics/gtm'

class Root extends React.Component {
  componentDidMount () {
    installGTM()
  }

  render () {
    const { children } = this.props
    return (
      <div>
        {children}
        <ErrorFlashRoot />
        <ModalRoot />
      </div>
    )
  }
}

Root.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired
}

export default Root
