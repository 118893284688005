import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { createSelector } from 'reselect'

import { load, schemas, NotFoundError } from 'api'
import {
  currentUserAddressSelector,
  currentUserInstitutionIdSelector,
  loadingCurrentUserSelector
} from 'auth'
import { Button, Heading } from 'core'
import { FormContainer, FormSection, FormRule, PriceSubtotal } from 'form'
import { applyHOCs, applyPropTypes } from 'helpers'
import { page } from 'page'
import {
  BillingContactPaymentForm,
  paymentIsCompleteSelector,
  withStripeElements
} from 'stripe'

import { ORDER_FORM_NAME as FORM_NAME } from './constants'
import { makePayment } from './actions'
import { isInstitutionOrderSelector, institutionAddressSelector } from './selectors'
import { orderIsPayable } from '../helpers'
import { PageHead } from 'page/PageHead'

export const OrderPaymentForm = (props) => {
  const {
    order,
    submitting,
    paymentIsComplete,
    makePayment,
    stripeElements
  } = props

  return (
    <FormContainer onSubmit={makePayment}>
      <PageHead title={`Invoice ${order.invoice_id}`} />
      <FormSection grey>
        <Heading size='h6' freight left noMargin>
          Invoice #{order.invoice_id}
        </Heading>
        <Heading size='h6' freight left noMargin>
          {order.description}
        </Heading>
      </FormSection>
      <FormSection>
        <Heading size='h6'>Order Summary</Heading>
        <PriceSubtotal
          title='Balance Remaining'
          price={order.order_balance}
          navy
        />
        <FormRule />
        <BillingContactPaymentForm
          form={FORM_NAME}
          stripeElements={stripeElements}
        />
      </FormSection>
      <FormSection>
        <Button
          type='submit'
          size='primary'
          noMargin
          disabled={!paymentIsComplete || submitting}
          isLoading={submitting}
        >
          Submit Payment
        </Button>
      </FormSection>
    </FormContainer>
  )
}

OrderPaymentForm.propTypes = {
  order: PropTypes.shape({
    description: PropTypes.string,
    order_balance: PropTypes.number.isRequired
  }).isRequired,
  submitting: PropTypes.bool,
  paymentIsComplete: PropTypes.bool,
  makePayment: PropTypes.func.isRequired,
  stripeElements: PropTypes.object.isRequired
}

export default applyHOCs([
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true
  }),
  connect(
    createSelector(
      paymentIsCompleteSelector(FORM_NAME),
      (state, { institution, order }) => {
        if (isInstitutionOrderSelector(state, { order })) {
          return institutionAddressSelector(state, { institution })
        } else {
          return currentUserAddressSelector(state)
        }
      },
      (paymentIsComplete, address) => ({
        paymentIsComplete,
        initialValues: address
      })
    ),
    (dispatch, props) => ({
      makePayment: () => dispatch(makePayment(props))
    })
  ),
  withStripeElements(),
  page({
    layout: 'form',
    noPageHead: true,
    loadingSelector: (state, { loadStatus }) => {
      return loadStatus.order.loading ||
             loadingCurrentUserSelector(state) ||
             loadStatus.institution.loading
    },
    notFoundSelector: (state, { loadStatus }) => {
      return loadStatus.order.error instanceof NotFoundError
    },
    unknownErrorSelector: (state, { loadStatus }) => {
      return loadStatus.order.error || loadStatus.institution.error
    },
    redirectSelector: (state, { order }) => {
      if (order && !orderIsPayable(order)) {
        const isAnon = window.location.href.includes(order.anon_url_uuid)
        if (isAnon) {
          return '/anon/payment-complete'
        } else if (isInstitutionOrderSelector(state, { order })) {
          return '/account/institution-payments'
        } else if (isAnon) {
          return '/anon/payment-complete'
        } else {
          return '/account/payments'
        }
      }
    }
  }),
  load({
    institution: {
      urlSelector: (state, { order }) => {
        if (!order) return null

        if (loadingCurrentUserSelector(state)) {
          return null
        }

        if (!isInstitutionOrderSelector(state, { order })) {
          return null
        }

        const isAnon = window.location.href.includes(order.anon_url_uuid)
        if (isAnon) {
          return null
        }

        return `/v2/institutions/${currentUserInstitutionIdSelector(state)}`
      },
      schema: schemas.institution
    }
  }),
  load({
    order: {
      urlSelector: (state, props) => (
        props.params.orderUuid ? `/v2/anon/orders/${props.params.orderUuid}` : `/v2/orders/${props.params.orderId}`
      ),
      schema: schemas.order
    }
  }),
  applyPropTypes({
    params: PropTypes.shape({
      orderId: PropTypes.string,
      orderUuid: PropTypes.string
    }).isRequired
  })
])(OrderPaymentForm)
