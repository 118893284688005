import { namespacer } from 'helpers'

const namespace = namespacer('FSP_RESERVATION_MGMT')

export const LOAD_RESERVATIONS_SUCCESS = namespace('LOAD_RESERVATIONS_SUCCESS')
export const CLEAR_RESERVATIONS = namespace('CLEAR_RESERVATIONS')
export const CREATE_RESERVATIONS_SUCCESS = namespace('CREATE_RESERVATIONS_SUCCESS')
export const DELETE_RESERVATIONS_SUCCESS = namespace('DELETE_RESERVATIONS_SUCCESS')
export const REFRESH_SESSION_SALE = namespace('REFRESH_SESSION_SALE')

export const UPDATE_USER_SEARCH_TERM = namespace('UPDATE_USER_SEARCH_TERM')
export const LOAD_RESERVATION = namespace('LOAD_RESERVATION')
