import { push, replace } from 'react-router-redux'
import { toInteger } from 'lodash'

const selectLocation = (state) => state.routing.locationBeforeTransitions
const selectCurrentStep = (state) => toInteger(selectLocation(state).query.step)

export const forceStep = step => (dispatch, getState) => {
  dispatch(replace({
    ...selectLocation(getState()),
    query: { step }
  }))
}

export const goToNextStep = () => (dispatch, getState) => {
  const currentStepNum = selectCurrentStep(getState())
  dispatch(push({
    ...selectLocation(getState()),
    query: { step: currentStepNum + 1 }
  }))
  window.scrollTo(0, 0)
}

export const handleContinue = (props, options) => dispatch => {
  const { currentStepNum } = props
  const {
    steps,
    completeAction
  } = options

  const isLastStep = currentStepNum === steps.length

  if (isLastStep) {
    return dispatch(completeAction(props))
  } else {
    return dispatch(goToNextStep())
  }
}
