import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { createSelector } from 'reselect'
import moment from 'moment'

import { load, schemas } from 'api'
import { currentUserAddressSelector, currentUserSelector } from 'auth'
import { Button, Heading, Notice } from 'core'
import { FormContainer, FormSection, FormRule, PriceSubtotal } from 'form'
import { applyHOCs, formatPrice, selectSlug } from 'helpers'
import { page, pageLoadSelectors } from 'page'
import {
  PaymentForm,
  paymentIsCompleteSelector,
  withStripeElements
} from 'stripe'

import { WAITLIST_FORM_NAME as FORM_NAME } from '../constants'
import { joinWaitlist } from './actions'
import LoginOrCreateAccountForm from 'session-sales/individual-reservations/LoginOrCreateAccountForm'
import { registrationFormCompleteSelector } from 'session-sales/individual-reservations/selectors'

export const WaitlistFormPage = (props) => {
  const {
    sessionSale,
    submitting,
    formComplete,
    joinWaitlist,
    stripeElements,
    currentUser
  } = props

  const { waitlist_price: price } = sessionSale

  return (
    <FormContainer name={`SESSION JOIN WAITLIST - ${sessionSale.slug}`} onSubmit={joinWaitlist}>
      <FormSection grey>
        <Heading size='h6' freight center noMargin>
          Waiting List Deposit
        </Heading>
      </FormSection>
      <FormSection>
        <Notice>
          We request that all members who are interested in joining
          the waiting list for the upcoming bootcamp pay a deposit of {formatPrice(price)}.
          If you are accepted to the program, we will deduct it from the
          overall price.
        </Notice>
        <Heading size='h6'>Order Summary</Heading>

        <div style={{ marginBottom: '20px' }}>
          {currentUser
            ? <Heading size='h6' weight='medium'>Registration For: {currentUser.name}</Heading>
            : <LoginOrCreateAccountForm form={FORM_NAME} />}
        </div>

        <PriceSubtotal
          title='Waiting List Deposit'
          price={price}
          navy
        />
        <FormRule />
        <PaymentForm
          form={FORM_NAME}
          stripeElements={stripeElements}
        />
      </FormSection>
      <FormSection>
        <Button
          type='submit'
          size='primary'
          noMargin
          disabled={!formComplete}
          isLoading={submitting}
        >
          Submit Payment
        </Button>
      </FormSection>
    </FormContainer>
  )
}

WaitlistFormPage.propTypes = {
  sessionSale: PropTypes.shape({
    title: PropTypes.string.isRequired,
    waitlist_price: PropTypes.number.isRequired
  }).isRequired,
  joinWaitlist: PropTypes.func.isRequired,
  stripeElements: PropTypes.object.isRequired,
  paymentIsComplete: PropTypes.bool
}

export const canJoinWaitlist = sessionSale => {
  const duringOpenRegistration = moment().isBetween(
    sessionSale.open_registration_start_at,
    sessionSale.open_registration_end_at,
    '[)'
  )
  if (!duringOpenRegistration) {
    return false
  } else if (sessionSale.registration_status !== 'none') {
    return false
  } else if (sessionSale.num_reservations_available > 0) {
    return false
  } else {
    return true
  }
}

export default applyHOCs([
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true
  }),
  connect(
    createSelector(
      currentUserSelector,
      paymentIsCompleteSelector(FORM_NAME),
      registrationFormCompleteSelector(FORM_NAME),
      currentUserAddressSelector,
      (currentUser, paymentIsComplete, registrationFormComplete, address) => {
        return {
          formComplete: paymentIsComplete && (currentUser || registrationFormComplete),
          currentUser,
          initialValues: address
        }
      }
    ),
    (dispatch, props) => ({
      joinWaitlist: () => dispatch(joinWaitlist(props.sessionSale, props.stripeElements))
    })
  ),
  withStripeElements(),
  page({
    layout: 'form',
    ...pageLoadSelectors('sessionSale'),
    redirectSelector: (state, { sessionSale }) => {
      if (sessionSale && !canJoinWaitlist(sessionSale)) {
        return `/sessions/${sessionSale.slug}`
      }
    }
  }),
  load({
    sessionSale: {
      urlSelector: (state, props) => {
        return `/v2/session_sales/slug/${props.slug}`
      },
      schema: schemas.sessionSale
    }
  }),
  selectSlug()
])(WaitlistFormPage)
