import { get, set } from 'lodash'

/**
 * Warning:
 * Because this populates the initial state on app load,
 * any paths in here can not have breaking state structure
 * changes until state versioning is supported.
 */

const paths = [
  'form.registration.values'
]

export default (state) => {
  const slicedState = {}
  paths.forEach((path) =>
    set(slicedState, path, get(state, path))
  )
  return slicedState
}
