import React from 'react'
import qs from 'qs'

import { load, schemas } from 'api'
import { PostCollection } from 'core'
import { applyHOCs, paginationPageCount, paginationQueryBuilder } from 'helpers'
import { page } from 'page'
import { HeaderTertiary } from 'page-blocks'

const url = '/news'
const REQUEST_LIMIT = 4

const buildUrl = (post) => {
  return `${url}/${post.slug}`
}

const NewsRoute = props => {
  const { totalCount } = props.loadStatus.posts

  return (
    <div>
      <HeaderTertiary title='Latest News' />
      <PostCollection
        collection={props.posts || []}
        buildUrl={buildUrl}
        pages={paginationPageCount(totalCount, REQUEST_LIMIT)}
      />
    </div>
  )
}

export default applyHOCs([
  page({ title: 'News' }),
  load({
    posts: {
      urlSelector: (state, props) => {
        const { page } = props.location.query
        const queries = {
          filters: {
            type: 'news'
          }
        }

        return `/v2/posts?${qs.stringify(paginationQueryBuilder(page, REQUEST_LIMIT, queries))}`
      },
      schema: [schemas.post]
    }
  })
])(NewsRoute)
