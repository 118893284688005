import React, { PropTypes } from 'react'

import { Footer } from 'footer'
import { ImpersonationNotification } from 'impersonation-notification'
import FormPageHeader from './FormPageHeader'
import { PageHead } from 'page/PageHead'

const FormPage = ({ children, showFooter, title: pageTitle, noPageHead }) => {
  return (
    <div>
      {!noPageHead && (
        <PageHead title={pageTitle} />
      )}
      <ImpersonationNotification />
      <FormPageHeader />
      {children}
      {showFooter && <Footer />}
    </div>
  )
}

FormPage.propTypes = {
  children: PropTypes.node,
  showFooter: PropTypes.bool.isRequired
}

FormPage.defaultProps = {
  showFooter: true
}

export default FormPage
