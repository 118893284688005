import React, { PropTypes } from 'react'
import { Section } from 'layout'
import { PageBlockRenderer } from 'core/components/PageBlockRenderer'

const CMSPage = (props) => {
  const {
    body = '',
    header_block: headerBlock = null,
    blocks = []
  } = props

  return (
    <div>
      {headerBlock && <PageBlockRenderer blocks={[headerBlock]} />}

      {
        body &&
          <Section>
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </Section>
      }

      <PageBlockRenderer blocks={blocks} />
    </div>
  )
}

CMSPage.propTypes = {
  body: PropTypes.string,
  blocks: PropTypes.array,
  header_block: PropTypes.shape({
    block_type: PropTypes.string.isRequired,
    block_content: PropTypes.object.isRequired
  })
}

export default CMSPage
