import React, { PropTypes } from 'react'

import { Section } from 'layout'
import { Heading } from 'core'

import css from './style.scss'

const CenterTextWithTitle = ({ title, text }) => (
  <Section>
    <div className={css.wrapper}>
      <Heading size='h2'>
        {title}
      </Heading>
      <p className={css.text}>
        {text}
      </p>
    </div>
  </Section>
)

CenterTextWithTitle.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default CenterTextWithTitle
