import React from 'react'
import MediaQuery from 'react-responsive'

import { Button, Heading, Link } from 'core'
import { Grid, GridItem } from 'layout'

import css from '../style.scss'
import { breakpoints } from 'styles'

const { laptopMin, tabletMax } = breakpoints

const FooterContactUs = () => {
  const copy = 'Questions, Comments, or Concerns?'

  return (
    <div className={css.contactUs}>
      <MediaQuery maxWidth={tabletMax}>
        <Heading size='h4' weight='medium' freight center>
          {copy}
        </Heading>
      </MediaQuery>
      <MediaQuery minWidth={laptopMin}>
        <Heading size='h4' weight='medium' freight center>
          {copy}
        </Heading>
      </MediaQuery>
      <Grid middle>
        <GridItem col='1/5' tablet1of3 mobile1of2>
          <Link to='/contact-us'>
            <Button size='secondary' outline noMargin>
              Contact Us
            </Button>
          </Link>
        </GridItem>
      </Grid>
    </div>
  )
}

export default FooterContactUs
