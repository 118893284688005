import React, { PropTypes } from 'react'

import { Section, SectionOverride } from 'layout'

import InstitutionLandingContact from '../institution-landing-page/sections/InstitutionLandingContact'
import InstitutionLandingForward from '../institution-landing-page/sections/InstitutionLandingForward'
import { load, schemas } from 'api'

import css from './style.scss'

const ForwardMessage = (props) => {
  const { user, loadStatus } = props

  if (loadStatus.user.loading || !user || !user.institution_relation || !user.institution_relation.institution_primary_account_holder) {
    return null
  }

  return (
    <div>
      <Section>
        <SectionOverride className={css.sectionWrapper}>
          <InstitutionLandingContact id={props.id} user={user.institution_relation.institution_primary_account_holder} />
          <InstitutionLandingForward institution={user.institution_relation} />
        </SectionOverride>
      </Section>
    </div>
  )
}

ForwardMessage.propTypes = {
  // load
  user: PropTypes.object,
  loadStatus: PropTypes.object.isRequired
}

export default load({
  user: {
    urlSelector: () => {
      return '/v2/users/current'
    },
    schema: schemas.user
  }
})(ForwardMessage)
