import React from 'react'

import { load, schemas } from 'api'

import BuddyDetails from './BuddyDetails'
import BuddyRequestForm from './BuddyRequestForm'
import css from './style.scss'
import { PageHead } from 'page/PageHead'

export const BuddyMatchPage = ({ buddy, loadStatus }) => {
  if (loadStatus.buddy.loading) {
    return null
  }

  if (buddy) {
    return (
      <div>
        <PageHead title='Buddy Match' />
        <p className={css.explainer}>
          Be sure to look at the other "sent to" email address in order to connect with your buddy. If at any point you need to end the buddy match, simply email Buddies@ncfdd.org. You will then be able to request a new buddy match.
        </p>
        <BuddyDetails user={buddy} />
      </div>
    )
  }

  return (
    <div>
      <PageHead title='Buddy Match' />
      <p className={css.explainer}>
        To be matched with a writing accountability buddy, please indicate your title below so that we can match you with a buddy of similar rank/title. We match buddies as we receive requests, and once you've been matched, you'll receive an email notification with instructions to get started.
      </p>
      <BuddyRequestForm />
    </div>
  )
}

export default load({
  buddy: {
    urlSelector: () => '/v2/users/current/buddy',
    schema: schemas.user
  }
})(BuddyMatchPage)
