import { createClient, loadAuthToken } from 'auth'
import { handleError } from 'errors'

export const stopImpersonating = () => (dispatch, getState) => {
  return createClient(getState())
    .post('/auth/stop_impersonating')
    .then(res => res.json())
    .then(({ token }) => {
      dispatch(loadAuthToken(token))
      window.location.reload()
    })
    .catch(err => dispatch(handleError(err)))
}
