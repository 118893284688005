import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { ErrorPage, ErrorHeading, ErrorButton } from 'error-page'
import { PageHead } from 'page/PageHead'

const NotFoundPage = ({ push }) => (
  <ErrorPage>
    <PageHead />
    <ErrorHeading>404 - Page Not Found</ErrorHeading>
    <ErrorButton onClick={() => push('/')}>Go to the homepage</ErrorButton>
  </ErrorPage>
)

NotFoundPage.propTypes = {
  push: React.PropTypes.func.isRequired
}

export default connect(null, { push })(NotFoundPage)
