export const calculateOrderSummary = (price = 0, discounts) => {
  const subtotal = price
  const effectiveDiscounts = getEffectiveDiscounts(price, discounts)
  const discountAmount = getDiscountAmount(effectiveDiscounts)

  return {
    subtotal,
    effectiveDiscounts,
    discountAmount,
    total: getTotal(subtotal, discountAmount)
  }
}

const getEffectiveDiscounts = (price, discounts) => {
  if (!discounts || discounts.length === 0) return []

  const effectiveDiscounts = []

  const flatDiscounts = discounts.filter(discount => discount.flat_amount)
  const percentDiscounts = discounts.filter(discount => discount.percent_amount)

  let amountOwed = price
  for (const flatDiscount of flatDiscounts) {
    const effectiveDiscountAmount = Math.min(amountOwed, flatDiscount.flat_amount)
    effectiveDiscounts.push({
      ...flatDiscount,
      effective_discount_amount: effectiveDiscountAmount
    })
    amountOwed = amountOwed - effectiveDiscountAmount
  }

  for (const percentDiscount of percentDiscounts) {
    const effectivePercentDiscount = Math.min(percentDiscount.percent_amount, 100)
    const effectiveDiscountAmount = amountOwed * effectivePercentDiscount / 100
    effectiveDiscounts.push({
      ...percentDiscount,
      effective_discount_amount: effectiveDiscountAmount
    })
    amountOwed = amountOwed - effectiveDiscountAmount
  }

  return effectiveDiscounts
}

const getDiscountAmount = (effectiveDiscounts) => {
  if (effectiveDiscounts.length === 0) return 0
  return effectiveDiscounts.reduce((amount, discount) => amount + discount.effective_discount_amount, 0)
}

const getTotal = (subtotal, discountAmount = 0) => {
  return discountAmount > 0
    ? Math.max(subtotal - discountAmount, 0)
    : subtotal
}
