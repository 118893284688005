import Immutable from 'seamless-immutable'
import { values } from 'lodash'
import { filterActions } from 'redux-ignore'

import * as types from './types'
const { LOAD_SETTINGS_SUCCESS, LOAD_SETTINGS_ERROR } = types

const initialState = Immutable.from({})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SETTINGS_SUCCESS:
      state = state.set('data', Immutable.from(action.settings))
      break
    case LOAD_SETTINGS_ERROR:
      state = state.set('loadError', action.error)
      break
  }

  return state
}

export default filterActions(reducer, values(types))
