import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, getFormSubmitErrors } from 'redux-form'

import { applyHOCs, applyPropTypes } from 'helpers'
import { Label, validators } from 'form'
import { Grid, GridItem } from 'layout'

import { applyDiscount } from './actions'
import { DISCOUNT_CODE_FORM_NAME as FORM_NAME } from './constants'
import DiscountCodeInput from './DiscountCodeInput'
import DiscountCodeButton from './DiscountCodeButton'
import DiscountCodeError from './DiscountCodeError'

/**
 * Takes a parentForm and productId as props. If a valid promo
 * code for that product has been submitted, it will set the
 * discount form value of the parentForm
 */

export const DiscountCodeForm = props => {
  const { invalid, submitting, applyDiscount, submitErrors = {} } = props
  return (
    <Grid>
      <GridItem col='1/1'>
        <Label>Promo Code</Label>
      </GridItem>
      <GridItem col='1/4' tablet1of3 mobile1of2>
        <Field
          name='code'
          component={DiscountCodeInput}
          validate={validators.required()}
          disabled={submitting}
        />
      </GridItem>
      <GridItem col='1/4' tablet1of3 mobile1of2>
        <DiscountCodeButton
          loading={submitting}
          disabled={invalid || submitting}
          onClick={applyDiscount}
        />
      </GridItem>
      <GridItem col='1/2' tablet1of3 mobileHide />
      <GridItem col='1/1'>
        <DiscountCodeError>
          {submitErrors.code}
        </DiscountCodeError>
      </GridItem>
    </Grid>
  )
}

DiscountCodeForm.propTypes = {
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  applyDiscount: PropTypes.func,
  submitErrors: PropTypes.object
}

export default applyHOCs([
  reduxForm({
    form: FORM_NAME
  }),
  connect(
    state => ({
      submitErrors: getFormSubmitErrors(FORM_NAME)(state)
    }),
    (dispatch, { parentForm, productId }) => ({
      applyDiscount: () => {
        dispatch(applyDiscount(parentForm, productId))
      }
    })
  ),
  applyPropTypes({
    parentForm: PropTypes.string.isRequired,
    productId: PropTypes.number.isRequired
  })
])(DiscountCodeForm)
