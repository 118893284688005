import React, { PropTypes } from 'react'
import moment from 'moment'

import { institutionalMembershipSupportEmail } from 'config'
import { Link } from 'core'
import { Spacer } from 'layout'

import {
  SuccessModal,
  ModalHeading,
  ModalSubheading
} from 'modal'

const DATE_FORMAT = 'dddd, MMMM Do, YYYY'

const InstitutionConfirmModal = ({ deadlineDate }) => {
  const formattedDate = moment(deadlineDate).format(DATE_FORMAT)
  return (
    <SuccessModal>
      <ModalHeading>
        Great! Thank you for reserving your spots.
      </ModalHeading>

      <ModalSubheading>Next Steps</ModalSubheading>
      <p>
        By {formattedDate}, please submit the names, email addresses,
        and ranks of the sponsored participants. At that time, any
        unclaimed seats will be released to the public.
      </p>
      <p>
        When we receive the sponsored participants' information,
        we will register them and generate one invoice for payment.
      </p>
      <Spacer size='xs' />
      <ModalSubheading>Submitting the names to us</ModalSubheading>
      <p>
        You can manage the candidates using the assignment tool once this dialog is dismissed.
        Or, if you prefer, you can email their full name, email address, and current rank directly to us
        at <Link orange to={`mailto:${institutionalMembershipSupportEmail}`}>{institutionalMembershipSupportEmail}</Link>.
      </p>
    </SuccessModal>
  )
}

InstitutionConfirmModal.propTypes = {
  deadlineDate: PropTypes.string.isRequired
}

export default InstitutionConfirmModal
