import React from 'react'

import { Button, Card, Heading, Link } from 'core'
import { Grid, GridItem } from 'layout'

import css from './style.scss'
import { formatPrice } from './helpers'

const IndividualPlanCard = ({ name, membershipPlanId, price, embedded }) => {
  const targetUrl = embedded ? `/register-membership/${membershipPlanId}` : `/register/${membershipPlanId}`
  return (
    <div className={css.cardWrapper}>
      <Card>
        <div className={css.cardContainer}>
          <Grid center>
            <GridItem col='1/3' mobile1of2>
              <Heading size='h5' freight weight='medium' noMargin>
                {name}
              </Heading>
            </GridItem>
            <GridItem col='1/3' mobile1of2>
              <div className={css.individualPricing}>
                <span className={css.individualPricingPrice}>
                  {formatPrice(price)}
                </span>
                <span className={css.pricingSubText}>
                  /year
                </span>
              </div>
            </GridItem>
            <GridItem col='1/3' mobile1of1>
              <div className={css.buttonWrapper}>
                <Link to={targetUrl}>
                  <Button size='secondary' noMargin>
                    Sign Me Up
                  </Button>
                </Link>
              </div>
            </GridItem>
          </Grid>
        </div>
      </Card>
    </div>
  )
}

export default IndividualPlanCard
