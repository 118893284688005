import { schema } from 'normalizr'

const { Entity } = schema

export const membership = new schema.Entity('memberships')
export const membershipPlan = new schema.Entity('membership_plans')
export const user = new Entity('users', {
  membership
})
export const chatMessage = new Entity('chat_messages', {
  user
})
export const institution = new Entity('institutions', {
  primary_account_holder: user
})
export const course = new Entity('courses')
export const curriculum = new Entity('curriculums')
export const webinar = new Entity('webinars', {
  facilitator: user,
  course,
  curriculum
})
export const coreCurriculumWebinar = new Entity('core_curriculum_webinars', {
  webinar
})
export const order = new schema.Entity('orders')
export const orderItem = new schema.Entity('order_items')
export const sessionSale = new schema.Entity('session_sales', {
  institution_reservation_order: order
})
export const sessionReservation = new schema.Entity('session_reservations', {
  order_item: orderItem,
  user
})
export const session = new Entity('sessions')
export const group = new Entity('groups', { session })
export const event = new schema.Entity('events', {
  target: new schema.Union({
    webinar,
    session_sale: sessionSale
  }, (value, parent) => parent.type)
})
export const media = new schema.Entity('media')
export const mediaTarget = new schema.Entity('media_targets', {
  media
})
export const post = new Entity('posts', {
  author: user
}, {
  idAttribute: 'slug'
})
export const category = new schema.Entity('categories')
export const cmsPage = new schema.Entity('cms_pages', {}, {
  idAttribute: 'slug'
})
export const cmsFixedPage = new schema.Entity('cms_fixed_pages', {}, {
  idAttribute: 'page'
})
export const buddyRequest = new schema.Entity('buddy_requests')
export const discount = new schema.Entity('discounts')
