import React, { PropTypes } from 'react'
import { load, schemas } from 'api'
import qs from 'qs'
import RelatedWebinars from './RelatedWebinars'

export const RelatedGuestWebinars = ({ related, currentWebinarId }) => {
  if (!related) {
    return null
  }

  related = related
    .filter(webinar => webinar.id !== currentWebinarId)
    .map(webinar => ({
      ...webinar,
      complete: webinar.completed_at !== null
    }))

  return <RelatedWebinars related={related} title='Related Webinars' />
}

RelatedGuestWebinars.propTypes = {
  related: PropTypes.array,
  currentWebinarId: PropTypes.number.isRequired
}

export default load({
  related: {
    urlSelector: () => `/v2/webinars?${
      qs.stringify({
        limit: 5,
        filters: {
          type: 'guest'
        }
      })
    }`,
    schema: [schemas.webinar]
  }
})(RelatedGuestWebinars)
