import {
  formValueSelector,
  startAsyncValidation,
  stopAsyncValidation
} from 'redux-form'
import { get, mapValues } from 'lodash'

import { createClient } from 'auth'
import { handleError } from 'errors'

import { FORM_NAME } from '../../constants'

const errorMessageMapping = (props) => {
  const { institution } = props

  const errorMappings = {
    email: {
      'already exists': 'A user with this email already exists'
    }
  }

  if (institution) {
    errorMappings.email['is not a valid subdomain'] = `Please use your ${institution.name} email address`
  }

  return errorMappings
}

export const validateAndContinue = props => (dispatch, getState) => {
  const { currentUser, institution } = props

  if (currentUser) {
    props.continue()
    return
  }

  const state = getState()
  const query = {
    email: formValueSelector(FORM_NAME)(state, 'email'),
    institution_id: institution ? institution.id : undefined
  }

  dispatch(startAsyncValidation(FORM_NAME))
  return createClient(state)
    .get('/v2/users/validate', { query })
    .then(response => response.json())
    .then(body => {
      return mapValues(body.errors, (errors, param) => {
        return get(errorMessageMapping(props), [param, errors[0]], errors[0])
      })
    })

    .then(errors => {
      dispatch(stopAsyncValidation(FORM_NAME, errors))
      if (Object.keys(errors).length === 0) {
        props.continue()
      } else {
        return new Error('Validation failed') // to prevent form tracking
      }
    })
    .catch(err => {
      dispatch(stopAsyncValidation(FORM_NAME))
      dispatch(handleError(err))
      return err
    })
}
