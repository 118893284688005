import React from 'react'
import ui from 'redux-ui'
import { sum } from 'lodash'

import { Link } from 'core'
import { ChevronDownIcon } from 'icons'
import css from './menu.scss'

const SUBMENU_LI_HEIGHT = 50
const SUBMENU_MENU_LI_HEIGHT = 36

const calculateSubmenuHeight = menuItems => {
  const childHeights = menuItems.map(child => {
    return SUBMENU_LI_HEIGHT + calculateSubmenuMenuHeight(child.children)
  })
  return `${sum(childHeights)}px`
}

const calculateSubmenuMenuHeight = children => {
  if (!children) return 0
  return SUBMENU_MENU_LI_HEIGHT * children.length
}

export const MobileMenuItem = ({ name, to, children, onNavigation, ui, updateUI }) => (
  <li className={ui.submenuOpen ? css.submenuOpen : ''}>
    {children && children.length > 0
      ? [
        <button
          className={css.submenuButton}
          onClick={() => updateUI({ submenuOpen: !ui.submenuOpen })}
          key='button'
        >
          <span>{name}</span>
          <ChevronDownIcon className={css.submenuArrow} />
        </button>,
        <ul
          className={css.submenu}
          key='submenu'
          style={{
            // CSS transition to open submenu requires hardcoded height
            // so the most accurate way is to calculate inline
            // http://bit.ly/1NXuqJS
            height: calculateSubmenuHeight(children)
          }}
        >
          {children.map(({ name, slug, target = '_self', children }, index) => (
            <li key={index}>
              <Link to={`${slug}`} onClick={onNavigation} target={target}>
                {name}
              </Link>
              {children && (
                <ul className={css.submenuMenu}>
                  {children.map(({ name, slug, target = '_self' }, index) => (
                    <li key={index}>
                      <Link to={`${slug}`} onClick={onNavigation} target={target}>
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
        ]
      : (
        <Link to={to} onClick={onNavigation}>
          {name}
        </Link>
        )}
  </li>
)

export default ui({
  state: { submenuOpen: false }
})(MobileMenuItem)
