import React from 'react'
import { Helmet } from 'react-helmet'

// PageHead allows you to set the title of the page and other metadata if necessary
// there should only be one per route
export const PageHead = ({ title }) => {
  return (
    <Helmet>
      <meta property='og:title' content={`${title ? `${title} | ` : ''}NCFDD`} />
      <title>{`${title ? `${title} | ` : ''}NCFDD`}</title>
      <meta name='page-loaded' content='true' />
    </Helmet>
  )
}
