import React, { PropTypes } from 'react'

import { loadFixedPageBlocks } from 'cms'
import { applyHOCs } from 'helpers'
import { Page } from 'page'
import { PageBlockRenderer } from 'core/components/PageBlockRenderer'

export const ContactPage = (props) => {
  const { fixedPageBlocks = [] } = props
  return (
    <Page>
      <PageBlockRenderer blocks={fixedPageBlocks} />
    </Page>
  )
}

ContactPage.propTypes = {
  fixedPageBlocks: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default applyHOCs([
  loadFixedPageBlocks({
    pageSelector: () => 'contact_page'
  })
])(ContactPage)
