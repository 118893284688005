import React, { PropTypes } from 'react'
import { find, sortBy } from 'lodash'

import { load, schemas } from 'api'
import { CardCurriculum } from './components/Cards'

const NextCurriculumCard = ({ coreCurriculum, loadStatus }) => {
  if (loadStatus.coreCurriculum.loading) {
    return null
  }

  const coreCurriculumWebinar = find(sortBy(coreCurriculum, 'position'), {
    is_completed: false
  })

  if (!coreCurriculumWebinar) {
    return <p style={{ padding: '0 16px' }}>You have completed all of the core curriculum webinars.<a href='/core-curriculum' style={{ padding: '0 0 0 5px' }}>Click here to revisit the Core Curriculum.</a></p>
  }

  const { webinar, position } = coreCurriculumWebinar
  return <CardCurriculum webinar={webinar} position={position} />
}

NextCurriculumCard.propTypes = {
  coreCurriculum: PropTypes.arrayOf(PropTypes.shape({
    webinar: PropTypes.object.isRequired,
    position: PropTypes.number.isRequired
  }))
}

export default load({
  coreCurriculum: {
    urlSelector: () => '/v2/core_curriculum',
    schema: [schemas.coreCurriculumWebinar]
  }
})(NextCurriculumCard)
