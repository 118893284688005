import React, { PropTypes } from 'react'

import { Avatar, Link } from 'core'
import { Section } from 'layout'
import { SettingOptionName } from 'settings'

import css from './style.scss'

const InstitutionLandingContact = (props) => {
  const { user } = props

  return (
    <Section tabletNoPadding mobileNoPadding>
      <div className={css.landingContactContainer}>
        <div className={`${css.contactSection} ${css.mainContactSection}`}>
          <div className={css.contactAvatarWrapper}>
            <Avatar
              size='xl'
              user={{
                name: user.name,
                avatar_url: user.avatar_url,
                email: user.email
              }}
            />
          </div>
          <label className={css.contactSectionLabel}>
            Account Admin
          </label>
          {user.name}{user.post_nominal_letters && (
            <span>, <SettingOptionName type='pnl' value={user.post_nominal_letters} /></span>
          )}
          <br />
          <span style={{ color: '#0117DE', textDecoration: 'underline', cursor: 'pointer' }}>
            <Link to={`mailto:${user.email}`}>{user.email}</Link>
          </span>
        </div>
      </div>
    </Section>
  )
}

InstitutionLandingContact.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar_url: PropTypes.string.isRequired,
    title: PropTypes.string
  }).isRequired
}

export default InstitutionLandingContact
