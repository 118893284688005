import React from 'react'
import { compact } from 'lodash'

import css from './style.scss'

const HeroHeader = (props) => {
  const screenClassNames = [
    props.blueScreen ? css.blueScreen : null,
    props.darken ? css.darken : null
  ]

  return (
    <div
      className={css.heroHeader}
      style={{ backgroundImage: `url('${props.imageUrl}')` }}
    >
      <div className={compact(screenClassNames).join(' ')} />
      {props.children}
    </div>
  )
}

HeroHeader.propTypes = {
  children: React.PropTypes.node,
  blueScreen: React.PropTypes.bool,
  imageUrl: React.PropTypes.string.isRequired
}

export default HeroHeader
