import React, { Component } from 'react'

import { getDisplayName } from 'helpers'
import { createStripeElements } from './stripe'

export const withStripeElements = WrappedComponent => {
  class WithStripe extends Component {
    constructor (props) {
      super(props)
      this.stripeElements = createStripeElements()
    }

    render () {
      return (
        <WrappedComponent stripeElements={this.stripeElements} {...this.props} />
      )
    }
  }

  WithStripe.displayName = `Loader(${getDisplayName(WrappedComponent)})`

  return WithStripe
}

export default () => (WrappedComponent) => withStripeElements(WrappedComponent)
