import {
  LOAD_CURRENT_USER
} from 'auth'

import ga4 from './ga4'
import { schemas } from 'api'
import { ga as gaConfig } from 'config'
import { denormalize } from 'normalizr'

const setUserData = action => {
  const user = denormalize(action.result, schemas.user, action.entities)

  const ga4Fields = {
    userId: user.id,
    user_properties: {
      // institution id
      [gaConfig.institutionIdDimension]: user.institution_relation
        ? user.institution_relation.institution_id
        : 'n/a',
      // institution name
      [gaConfig.institutionNameDimension]: user.institution_relation
        ? user.institution_relation.institution_name
        : 'n/a'
    }
  }

  ga4.set(ga4Fields)
}

export default store => next => action => {
  switch (action.type) {
    case LOAD_CURRENT_USER:
      setUserData(action)
      break
  }

  return next(action)
}
