import Account from './Account'
import { isEmpty, map } from 'lodash'

import { fieldErrorsSelectorFactory } from 'form'
import { FORM_NAME } from '../../constants'
import * as fields from './fields'

const fieldErrorsSelector = fieldErrorsSelectorFactory(FORM_NAME, map(fields, 'name'))

export default {
  name: 'Account',
  component: Account,
  isValidSelector: state => isEmpty(fieldErrorsSelector(state))
}
