import React from 'react'
import { openModal, Modal } from 'modal'
import LoginOrCreateAccountForm from 'session-sales/individual-reservations/LoginOrCreateAccountForm'
import { applyHOCs } from 'helpers'
import { reduxForm } from 'redux-form'
import css from './style.scss'
import { INDIVIDUAL_RESERVATION_FORM_NAME } from 'session-sales/constants'
import { Button } from 'core'
import { connect } from 'react-redux'
import { createAccountThenRegister } from './actions'

export const openLoginOrCreateAccountSessionSaleModal = (props) => openModal(LoginOrCreateAccountSessionSaleModal, props)

const _LoginOrCreateAccountSessionSaleModal = ({ register }) => {
  return (
    <Modal>
      <div className={css.loginOrCreateAccountSesssionSaleModalContainer}>
        <form onSubmit={e => {
          e.preventDefault()
          register()
        }}
        >
          <LoginOrCreateAccountForm form={INDIVIDUAL_RESERVATION_FORM_NAME} />
          <div style={{ marginTop: '20px' }}>
            <Button type='submit'>Register</Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

const LoginOrCreateAccountSessionSaleModal = applyHOCs([
  reduxForm({
    form: INDIVIDUAL_RESERVATION_FORM_NAME
  }),
  connect(
    null,
    (dispatch, props) => ({
      register: () => {
        dispatch(createAccountThenRegister(props.sessionSale))
      }
    })
  )
])(_LoginOrCreateAccountSessionSaleModal)
