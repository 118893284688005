import React from 'react'
import { compact } from 'lodash'

import { InputWrapper } from '../InputWrapper'
import css from './style.scss'

/**
 * Controlled textarea component. Is compatible with redux-form Field
 */
const TextArea = (props) => {
  const classNames = [
    css.textarea,
    props.globalError ? css.globalError : null
  ]

  const style = {}

  if (props.maxTextAreaWidth) {
    style.maxWidth = props.maxTextAreaWidth
  }

  if (props.height) {
    style.height = props.height
  }

  return (
    <InputWrapper {...props.meta}>
      <textarea
        style={style}
        className={compact(classNames).join(' ')}
        {...props.input}
        maxLength={props.maxLength}
        disabled={props.disabled}
        placeholder={props.placeholder}
      />
    </InputWrapper>
  )
}

TextArea.propTypes = {
  textarea: React.PropTypes.object,
  meta: React.PropTypes.object,
  disabled: React.PropTypes.bool,
  height: React.PropTypes.string,
  maxLength: React.PropTypes.number,
  maxInputWidth: React.PropTypes.oneOfType([
    React.PropTypes.number,
    React.PropTypes.string
  ]),
  globalError: React.PropTypes.string
}

TextArea.defaultProps = {
  disableAutoComplete: false,
  disabled: false,
  placeholder: null
}

export default TextArea
