import React from 'react'
import { compact, get } from 'lodash'
import MediaQuery from 'react-responsive'

import { Button, Heading, Link } from 'core'
import { Grid, GridItem, Spacer } from 'layout'

import css from './style.scss'
import { breakpoints } from 'styles'

const { mobileMax } = breakpoints

const Hero = (props) => {
  const classes = [
    css.hero,
    props.inline ? css.inline : ''
  ]

  const primaryLabel = get(props, 'primaryCta.label')
  const primaryUrl = get(props, 'primaryCta.url')
  const secondaryLabel = get(props, 'secondaryCta.label')
  const secondaryUrl = get(props, 'secondaryCta.url')
  const tertiaryLabel = get(props, 'tertiaryCta.label')
  const tertiaryUrl = get(props, 'tertiaryCta.url')

  return (
    <div
      className={compact(classes).join(' ')}
      style={{ backgroundImage: `url(${props.img})` }}
    >
      <Grid>
        <GridItem col='2/3' tablet2of3 mobile1of1>
          <div className={css.heading}>
            <Heading size='h1' weight='thin'>
              {props.title}
            </Heading>
            {
              props.tertiaryCta &&
                <Link to={tertiaryUrl} sans>
                  {tertiaryLabel}
                </Link>
            }
          </div>
        </GridItem>
        <GridItem col='1/3' tablet2of3 mobile1of1 />
        <GridItem col='1/2' tablet1of2 mobile1of1>
          {
            props.primaryCta &&
              <div>
                <Link to={primaryUrl}>
                  <Button size='primary' noMargin>{primaryLabel}</Button>
                </Link>
                <MediaQuery maxWidth={mobileMax}>
                  <Spacer size='sm' />
                </MediaQuery>
              </div>
          }
        </GridItem>
        <GridItem col='1/2' tablet1of2 mobile1of1>
          {
            props.secondaryCta &&
              <Link to={secondaryUrl}>
                <Button size='primary' noMargin>{secondaryLabel}</Button>
              </Link>
          }
        </GridItem>
      </Grid>
    </div>
  )
}

Hero.propTypes = {
  img: React.PropTypes.string.isRequired,
  title: React.PropTypes.string.isRequired,
  primaryCta: React.PropTypes.shape({
    label: React.PropTypes.string,
    url: React.PropTypes.string
  }),
  secondaryCta: React.PropTypes.shape({
    label: React.PropTypes.string,
    url: React.PropTypes.string
  }),
  tertiaryCta: React.PropTypes.shape({
    label: React.PropTypes.string,
    url: React.PropTypes.string
  }),
  inline: React.PropTypes.bool
}

export default Hero
