import React from 'react'
import { compact } from 'lodash'

import css from './style.scss'

const HorizontalRule = (props) => {
  const classes = [
    css.horizontalRule,
    props.full ? css.full : '',
    props.orange ? css.orange : '',
    props.dashed ? css.dashed : ''
  ]

  return (
    <hr className={compact(classes).join(' ')} />
  )
}

HorizontalRule.propTypes = {
  full: React.PropTypes.bool,
  orange: React.PropTypes.bool,
  dashed: React.PropTypes.bool
}

export default HorizontalRule
