import moment from 'moment'

export const formatCloseDate = date => moment(date).format('MMMM D, YYYY')

export const getDateState = (start, end) => {
  const now = moment()
  if (now.isBefore(start)) {
    return 'before'
  } else if (now.isSameOrAfter(end)) {
    return 'after'
  } else {
    return 'during'
  }
}

export const participatingInSession = (sessionSale, activeGroups = []) => {
  return activeGroups.some(group => group.session.id === sessionSale.session_id)
}
