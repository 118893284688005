import React from 'react'
import { compact } from 'lodash'

import css from './style.scss'

/**
 * The <Combo /> element is a templating element that wraps
 * two pieces of content that need to leverage contextual alignment
 * between themselves -- for example, an avatar image and user name
 * vertically aligned. <Combo /> expects two children -- <ComboFirst />
 * and <ComboLast /> to function properly.
 *
 * When used normally, <ComboFirst /> will take up a determined amount
 * of width, and <ComboLast /> will take up the remainder. When reverse
 * is applied to <Combo />, that order switches.
 */
const Combo = (props) => {
  const classes = [
    css.combo,
    props.reverse ? css.reverse : null,
    props.middle ? css.middle : null,
    props.bottom ? css.bottom : null,
    props.wide ? css.wide : null,
    props.mobileReset ? css.mobileReset : null
  ]
  return (
    <div className={compact(classes).join(' ')}>
      {props.children}
    </div>
  )
}

Combo.propTypes = {
  /* Expects `<ComboFirst />` and `<ComboLast />` */
  children: React.PropTypes.array.isRequired,
  /* If true, reverses the width order of `<ComboFirst />` + `<ComboLast />` */
  reverse: React.PropTypes.bool,
  /* If true, vertically aligns the content to middle */
  middle: React.PropTypes.bool,
  /* If true, vertically aligns content to bottom / baseline */
  bottom: React.PropTypes.bool,
  /* If true, sets a wide gutter between `<ComboFirst />` and `<ComboLast />` */
  wide: React.PropTypes.bool,
  /* If true, removes all rules to default to standard block layout at mobile */
  mobileReset: React.PropTypes.bool
}

export default Combo
