import React from 'react'
import { Heading, CardCollection } from 'core'

const RelatedWebinars = props => {
  const webinars = props.related.map(webinar => {
    return {
      ...webinar,
      url: `/webinars/${webinar.slug}`,
      watching: webinar.id === props.currentWebinarId
    }
  })

  if (!webinars.length) return null
  return (
    <div>
      <Heading size='h5' weight='light'>
        {props.title}
      </Heading>
      <CardCollection col='1/1' collection={webinars} spacing />
    </div>
  )
}

RelatedWebinars.propTypes = {
  related: React.PropTypes.array.isRequired,
  title: React.PropTypes.string.isRequired,
  currentWebinarId: React.PropTypes.number
}

export default RelatedWebinars
