import React, { PropTypes } from 'react'
import { connect } from 'react-redux'

import {
  currentUserMembershipSelector,
  currentUserIsActiveMemberSelector
} from 'auth'
import { applyHOCs } from 'helpers'
import { page } from 'page'

import NoMembershipPage from './NoMembershipPage'
import InstitutionMembershipExpiredPage from './InstitutionMembershipExpiredPage'
import IndividualMembershipExpiredPage from './IndividualMembershipExpiredPage'

export const MembershipExpiredPage = (props) => {
  if (!props.membership) {
    return <NoMembershipPage {...props} />
  } else if (props.membership.member_type === 'institution') {
    return <InstitutionMembershipExpiredPage {...props} />
  } else {
    return <IndividualMembershipExpiredPage {...props} />
  }
}

MembershipExpiredPage.propTypes = {
  membership: PropTypes.shape({
    member_type: PropTypes.string.isRequired
  })
}

export default applyHOCs([
  page({
    redirectSelector: (state, { membership }) => {
      if (currentUserIsActiveMemberSelector(state)) {
        return '/'
      }
    }
  }),
  connect(
    state => ({
      membership: currentUserMembershipSelector(state)
    })
  )
])(MembershipExpiredPage)
