import React from 'react'

import { Link } from 'core'
import { fspSupportEmail } from 'config'

import {
  FailureModal,
  ModalHeading,
  ModalSubheading
} from 'modal'

const OfferFailureModal = ({ errorMessage }) => {
  return (
    <FailureModal>
      <ModalHeading>
        We were unable to register you in the program.
      </ModalHeading>

      <ModalSubheading>
        {errorMessage}
      </ModalSubheading>

      <p>
        If you have any questions regarding reserving your spot in the program,
        please contact <Link to={`mailto:${fspSupportEmail}`}>{fspSupportEmail}</Link>.
      </p>
    </FailureModal>
  )
}

OfferFailureModal.propTypes = {
  errorMessage: React.PropTypes.string.isRequired
}

export default OfferFailureModal
