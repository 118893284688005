import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { push } from 'react-router-redux'

import { load, schemas } from 'api'
import { requireIAH } from 'auth'
import { applyHOCs, selectSlug } from 'helpers'
import { page, pageLoadSelectors } from 'page'
import { formWizardFactory } from 'form-wizard'

import { loadReservations, clearReservations } from './actions'
import { reservationsSelector } from './selectors'
import reserve from './reserve'
import assign from './assign'
import { get } from 'lodash'

const InstitutionReservationWizard = formWizardFactory({
  steps: [reserve, assign],
  getStepFormName: ({ props, step }) => {
    return `SESSION REGISTER INSTITUTION - ${get(props, 'sessionSale.slug')} - ${get(step, 'name')}`
  },
  completeAction: ({ sessionSale }) => push(`/sessions/${sessionSale.slug}`)
})

class InstitutionReservationContainer extends Component {
  componentDidMount () {
    this.props.loadReservations(this.props.sessionSale)
  }

  componentWillUnmount () {
    this.props.clearReservations()
  }

  render () {
    const { reservations, sessionSale } = this.props

    if (!reservations) {
      return null
    }

    return (
      <InstitutionReservationWizard
        sessionSale={sessionSale}
        reservations={reservations}
      />
    )
  }
}

export default applyHOCs([
  connect(
    createStructuredSelector({
      reservations: reservationsSelector
    }),
    { loadReservations, clearReservations }
  ),
  page({
    layout: 'form',
    ...pageLoadSelectors('sessionSale')
  }),
  load({
    sessionSale: {
      urlSelector: (state, props) => {
        return `/v2/session_sales/slug/${props.slug}`
      },
      schema: schemas.sessionSale
    }
  }),
  selectSlug(),
  requireIAH
])(InstitutionReservationContainer)
