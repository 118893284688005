import React from 'react'
import moment from 'moment'

import { Avatar } from 'core'
import { Combo, ComboFirst, ComboLast } from 'layout'

import css from './style.scss'

const ChatSingle = (props) => {
  const { chatEvent: event } = props
  return (
    <li className={css.single}>
      <Combo middle>
        <ComboFirst>
          <Avatar size='md' user={event.user} />
        </ComboFirst>
        <ComboLast>
          <p>
            <span className={css.name}>
              {event.user.nick_or_name}
            </span>
            <span className={css.timestamp}>
              {moment(event.chatMessage.created_at).fromNow()}
            </span>
          </p>
          <p className={css.message}>{event.chatMessage.body}</p>
        </ComboLast>
      </Combo>
    </li>
  )
}

ChatSingle.propTypes = {
  chatEvent: React.PropTypes.shape({
    chatMessage: React.PropTypes.shape({
      body: React.PropTypes.string.isRequired,
      created_at: React.PropTypes.string.isRequired
    }),
    user: React.PropTypes.shape({
      nick_or_name: React.PropTypes.string.isRequired,
      avatar_url: React.PropTypes.string.isRequired
    })
  })
}

export default ChatSingle
