import React, { PropTypes } from 'react'
import { compact } from 'lodash'

import { Header } from 'header'
import { Footer } from 'footer'
import { ImpersonationNotification } from 'impersonation-notification'

import css from './style.scss'
import { PageHead } from './PageHead'

const Page = ({ children, greyBg, showFooter, title: pageTitle, noPageHead }) => {
  const classNames = [
    greyBg ? css.greyBg : ''
  ]

  return (
    <div className={compact(classNames).join(' ')}>
      {!noPageHead && (
        <PageHead title={pageTitle} />
      )}
      <ImpersonationNotification />
      <Header />
      {children}
      {showFooter && <Footer />}
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node,
  showFooter: PropTypes.bool.isRequired,
  greyBg: PropTypes.bool
}

Page.defaultProps = {
  showFooter: true
}

export default Page
