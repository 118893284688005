import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'

import { Label, LabelHelper, Input, Select, validators } from 'form'
import {
  MAX_NAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  MAX_EMAIL_LENGTH
} from 'consts'
import { pnlOptionsSelector } from 'settings'

import FieldGroup from '../../components/FieldGroup'

export const firstName = {
  name: 'first_name',
  FieldGroup: () => (
    <FieldGroup>
      <Label htmlFor={firstName.name}>First Name</Label>
      <Field
        name={firstName.name}
        component={Input}
        validate={validators.required('Your first name is required')}
        type='text'
        maxLength={MAX_NAME_LENGTH}
      />
    </FieldGroup>
  )
}

export const lastName = {
  name: 'last_name',
  FieldGroup: () => (
    <FieldGroup>
      <Label htmlFor={lastName.name}>Last Name</Label>
      <Field
        validate={validators.required('Your last name is required')}
        name={lastName.name}
        component={Input}
        type='text'
        maxLength={MAX_NAME_LENGTH}
      />
    </FieldGroup>
  )
}

export const postNominalLetters = {
  name: 'post_nominal_letters',
  FieldGroup: connect(
    state => ({ pnlOptions: pnlOptionsSelector(state) })
  )((props) => (
    <FieldGroup>
      <Label htmlFor={postNominalLetters.name}>Designation</Label>
      <Field
        name={postNominalLetters.name}
        component={Select}
        options={props.pnlOptions}
      />
    </FieldGroup>
  ))
}

export const nickname = {
  name: 'nickname',
  FieldGroup: () => (
    <FieldGroup>
      <Label htmlFor={nickname.name}>Nickname (Optional)</Label>
      <LabelHelper>Complete this if you want to hide your identity when in the platform</LabelHelper>
      <Field
        name={nickname.name}
        component={Input}
        type='text'
        maxLength={MAX_NAME_LENGTH}
      />
    </FieldGroup>
  )
}

export const email = {
  name: 'email',
  FieldGroup: () => (
    <FieldGroup>
      <Label htmlFor={email.name}>Your Email</Label>
      <Field
        name={email.name}
        component={Input}
        type='text'
        maxLength={MAX_EMAIL_LENGTH}
        validate={[
          validators.required('Your email is required'),
          validators.email('You have not provided a valid email address')
        ]}
      />
    </FieldGroup>
  )
}

export const emailConfirmation = {
  name: 'email_confirmation',
  FieldGroup: () => (
    <FieldGroup>
      <Label htmlFor={emailConfirmation.name}>Confirm Your Email</Label>
      <Field
        name={emailConfirmation.name}
        component={Input}
        type='text'
        validate={[
          validators.required('Email confirmation is required'),
          validators.equalToField('email', 'Confirmation must match your email')
        ]}
      />
    </FieldGroup>
  )
}

export const phoneNumber = {
  name: 'phone_number',
  FieldGroup: () => (
    <FieldGroup>
      <Label htmlFor={phoneNumber.name}>Your Phone Number</Label>
      <Field
        name={phoneNumber.name}
        component={Input}
        validate={validators.required('Your phone number is required')}
        type='text'
      />
    </FieldGroup>
  )
}

export const password = {
  name: 'password',
  FieldGroup: () => (
    <FieldGroup>
      <Label htmlFor={password.name}>Create a Password</Label>
      <Field
        name={password.name}
        component={Input}
        type='password'
        maxLength={MAX_PASSWORD_LENGTH}
        validate={[
          validators.required('Your password is required'),
          validators.minLength(MIN_PASSWORD_LENGTH, `Your password must be at least ${MIN_PASSWORD_LENGTH} characters`)
        ]}
      />
    </FieldGroup>
  )
}

export const passwordConfirmation = {
  name: 'password_confirmation',
  FieldGroup: () => (
    <FieldGroup>
      <Label htmlFor={passwordConfirmation.name}>Confirm Password</Label>
      <Field
        name={passwordConfirmation.name}
        component={Input}
        type='password'
        validate={[
          validators.required('Password confirmation is required'),
          validators.equalToField('password', 'Your passwords do not match')
        ]}
      />
    </FieldGroup>
  )
}
