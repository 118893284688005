import { browserHistory } from 'react-router'
import get from 'lodash/get'

import { BadRequestError } from 'api'
import { handleError } from 'errors'
import { createClient } from 'auth'
import { openModal } from 'modal'

import OfferSuccessModal from './OfferSuccessModal'
import OfferFailureModal from './OfferFailureModal'

const genericTokenErrorMessage = 'Please ensure you followed the link in your invitation email.'
const defaultErrorMessage = 'We could not register you for this session.'
const errorMessageMapping = {
  'Invalid offer token': genericTokenErrorMessage,
  'Invalid waitlister status': genericTokenErrorMessage,
  'Session sale mismatch': genericTokenErrorMessage,
  'Offer expired': 'This invitation has expired.'
}

const getErrorMessage = error => get(errorMessageMapping, error.message, defaultErrorMessage)

export const acceptOffer = (sessionSale, token) => (dispatch, getState) => {
  return createClient(getState())
    .post(`/v2/session_sales/${sessionSale.id}/reservations`, {
      body: {
        type: 'accept_offer',
        offer_token: token
      }
    })
    .then(() => {
      browserHistory.replace(`/sessions/${sessionSale.slug}`)
      dispatch(openModal(OfferSuccessModal, { sessionSale }))
    })
    .catch(err => {
      if (err instanceof BadRequestError) {
        return err.response.json()
          .then(body => {
            const errorMessage = getErrorMessage(body)
            dispatch(openModal(OfferFailureModal, { errorMessage }))
          }, error => {
            dispatch(handleError(error))
          })
      } else {
        return dispatch(handleError(err))
      }
    })
}
