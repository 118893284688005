import { get, pick } from 'lodash'
import { denormalize } from 'normalizr'
import { createSelector } from 'reselect'
import moment from 'moment'
import * as schemas from 'api/schemas'
import { entitiesSelector } from 'api/selectors'

export const invalidStatuses = {
  notActive: 'NOT_ACTIVE',
  expired: 'EXPIRED'
}

export const REQUIRED_USER_PROFILE_FIELDS = [
  'first_name',
  'last_name',
  'gender',
  'title',
  'discipline',
  'discipline_classification',
  'department',
  'university',
  'ethnicities',
  'phone_number'
]

const authState = (state) => state.auth

export const authTokenSelector = createSelector(
  authState,
  auth => auth.token || null
)

export const authIsHydratedSelector = createSelector(
  authState,
  auth => get(auth, 'hydrated', false)
)

export const authHydrationFailedSelector = createSelector(
  authState,
  auth => !!auth.hydrationError
)

export const isAuthenticatedSelector = createSelector(
  authState,
  auth => auth.token !== undefined && auth.token !== null
)

export const currentUserSelector = createSelector(
  state => state.auth.userId,
  entitiesSelector,
  (id, entities) => id ? denormalize(id, schemas.user, entities) : null
)

export const isIAHSelector = createSelector(
  currentUserSelector,
  (currentUser) => {
    return get(currentUser, 'institution_relation.is_account_holder', false)
  }
)

export const isAdminSelector = createSelector(
  currentUserSelector,
  (currentUser) => {
    return get(currentUser, 'is_admin', false)
  }
)

export const currentUserInstitutionIdSelector = createSelector(
  currentUserSelector,
  (currentUser) => {
    return get(currentUser, 'institution_relation.institution_id', null)
  }
)

export const loadingCurrentUserSelector = createSelector(
  authState,
  (auth) => !!auth.token && auth.userId === undefined
)

export const currentUserAddressSelector = createSelector(
  currentUserSelector,
  (currentUser) => pick(currentUser, [
    'address_line_1',
    'address_line_2',
    'city',
    'state',
    'postal_code',
    'country'
  ])
)

export const currentUserFlagsSelector = createSelector(
  currentUserSelector,
  (currentUser) => {
    const flags = get(currentUser, 'flags', {})
    return flags
  }
)

export const currentUserMembershipSelector = createSelector(
  currentUserSelector,
  (currentUser) => {
    return get(currentUser, 'membership', null)
  }
)

export const currentUserIsActiveMemberSelector = state => {
  const currentUser = currentUserSelector(state)
  if (!currentUser) return false
  if (currentUser.is_admin) return true

  const membership = currentUserMembershipSelector(state)
  if (!membership) return false
  if (!membership.expires_at) return false
  return moment().isBefore(membership.expires_at)
}

export const currentMembershipInvalidType = state => {
  const membership = currentUserMembershipSelector(state)
  let invalidStatus = false

  if (!membership) return false

  if (moment().isBefore(membership.activated_at)) {
    invalidStatus = invalidStatuses.notActive
  } else if (moment().isAfter(membership.expires_at)) {
    invalidStatus = invalidStatuses.expired
  }

  return invalidStatus
}

export const currentUserHasCompleteProfileSelector = createSelector(
  currentUserSelector,
  currentUser => {
    if (!currentUser) return false

    return REQUIRED_USER_PROFILE_FIELDS.every(field => !!currentUser[field]) && currentUser.ethnicities.length > 0 && currentUser.gender.length > 0
  }
)
