import React from 'react'

import { Link } from 'core'
import { facebookLogo, linkedInLogo, twitterLogo } from 'images'

import css from '../style.scss'

const FooterSocial = () => {
  const facebookUrl = 'https://www.facebook.com/NCFDD'
  const twitterUrl = 'https://twitter.com/NCFDD'
  const linkedInUrl = 'https://www.linkedin.com/company/national-center-for-faculty-development-&-diversity'

  return (
    <div className={css.social}>
      <Link target='_blank' to={facebookUrl}>
        <img src={facebookLogo} alt='Facebook Logo' />
      </Link>
      <Link target='_blank' to={twitterUrl}>
        <img src={twitterLogo} alt='Twitter Logo' />
      </Link>
      <Link target='_blank' to={linkedInUrl}>
        <img src={linkedInLogo} alt='LinkedIn Logo' />
      </Link>
    </div>
  )
}

export default FooterSocial
