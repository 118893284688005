import React, { PropTypes } from 'react'
import getDisplayName from './getDisplayName'

/**
 * Higher-order component to grab props.params.slug and map it to
 * props.slug, lowercased
 */

export default (options = {}) => {
  const { paramName = 'slug' } = options

  return WrappedComponent => {
    const WithSlugSelected = props => {
      return <WrappedComponent {...props} slug={props.params[paramName].toLowerCase()} />
    }

    WithSlugSelected.propTypes = {
      params: PropTypes.shape({
        [paramName]: PropTypes.string.isRequired
      }).isRequired
    }

    WithSlugSelected.displayName = `WithSlugSelected(${getDisplayName(WrappedComponent)})`

    return WithSlugSelected
  }
}
