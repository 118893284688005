import uniqBy from 'lodash/uniqBy'
import {
  startSubmit,
  stopSubmit,
  change,
  reset,
  formValueSelector
} from 'redux-form'

import { NotFoundError } from 'api'
import { createClient } from 'auth'
import { handleError } from 'errors'

import { DISCOUNT_CODE_FORM_NAME as FORM_NAME } from './constants'

export const applyDiscount = (parentForm, productId) => (dispatch, getState) => {
  dispatch(startSubmit(FORM_NAME))

  const state = getState()
  const code = formValueSelector(FORM_NAME)(state, 'code')
  const discounts = formValueSelector(parentForm)(state, 'discounts') || []
  return createClient(state)
    .get(`/v2/products/${productId}/discounts/${code}`)
    .then(res => res.json())
    .then(discount => {
      dispatch(reset(FORM_NAME))
      const updatedDiscounts = uniqBy([...discounts, discount], 'id')
      dispatch(change(parentForm, 'discounts', updatedDiscounts))
    })
    .catch(err => {
      if (err instanceof NotFoundError) {
        dispatch(stopSubmit(FORM_NAME, { code: 'Invalid promo code' }))
      } else {
        dispatch(stopSubmit(FORM_NAME))
        dispatch(handleError(err))
      }
    })
}
