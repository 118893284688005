import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { pick } from 'lodash'

import { addressFormPropsSelector } from 'address-form'
import { currentUserSelector } from 'auth'
import { MAX_VAR_CHAR_LENGTH } from 'consts'
import { applyHOCs } from 'helpers'
import { Input, Select, Label, validators, Form } from 'form'
import { formGroup, formGroupButtons } from 'form-group'
import { Grid, GridItem } from 'layout'

import { saveUserBillingAddress } from './actions'
import { USER_BILLING_ADDRESS_FORM_NAME, analyticsFormName } from './constants'

const FormGroupButtons = formGroupButtons({ form: USER_BILLING_ADDRESS_FORM_NAME })

/* Because 🐞: https://github.com/erikras/redux-form/issues/2588 */
const validate = (values, { stateLabel }) => {
  const errors = {}

  if (values.country === 'US' || values.country === 'CA') {
    if (!values.state) {
      errors.state = `Your institution's ${stateLabel} is required`
    }
  }

  return errors
}

const UserBillingAddressForm = (props) => {
  const {
    editing,
    save,
    countryOptions,
    countryPostalPattern,
    countryPostal,
    stateOptions,
    stateLabel
  } = props

  /* eslint-disable camelcase */
  return (
    <Form
      name={analyticsFormName(USER_BILLING_ADDRESS_FORM_NAME)}
      onSubmit={save}
    >
      <Grid>
        <GridItem col='1/2' mobile1of1>
          <Label htmlFor='address_line_1'>Address Line 1</Label>
          <Field
            name='address_line_1'
            component={Input}
            validate={validators.required('Your address is required')}
            disabled={!editing}
            maxLength={MAX_VAR_CHAR_LENGTH}
          />
        </GridItem>
        <GridItem col='1/2' tabletHide mobileHide />

        <GridItem col='1/2' mobile1of1>
          <Label htmlFor='address_line_2'>Address Line 2</Label>
          <Field
            name='address_line_2'
            component={Input}
            disabled={!editing}
            maxLength={MAX_VAR_CHAR_LENGTH}
          />
        </GridItem>
        <GridItem col='1/2' tabletHide mobileHide />

        <GridItem col='1/2' tablet1of1 mobile1of1>
          <Label htmlFor='city'>City</Label>
          <Field
            name='city'
            component={Input}
            validate={validators.required('Your city is required')}
            disabled={!editing}
            maxLength={MAX_VAR_CHAR_LENGTH}
          />
        </GridItem>
        <GridItem col='1/2' tabletHide mobileHide />

        <GridItem col='1/2' tablet1of1 mobile1of1>
          <Label htmlFor='state'>Country</Label>
          <Field
            name='country'
            component={Select}
            options={countryOptions.map(({ country_name, country_abbr }) => ({
              name: country_name,
              value: country_abbr
            }))}
            validate={validators.required('Your country is required')}
            disabled={!editing}
          />
        </GridItem>
        <GridItem col='1/2' tabletHide mobileHide />

        <GridItem col='1/4' tablet1of3 mobile1of2>
          <Label htmlFor='state'>{stateLabel}</Label>
          <Field
            name='state'
            component={(field) => {
              if (stateOptions.length > 0) {
                return <Select {...field} />
              } else {
                return <Input {...field} />
              }
            }}
            options={stateOptions.map(({ state_abbr }) => ({
              name: state_abbr,
              value: state_abbr
            }))}
            disabled={!editing}
          />
        </GridItem>
        <GridItem col='1/4' tablet1of3 mobile1of2>
          <Label htmlFor='postal_code'>{countryPostal}</Label>
          <Field
            name='postal_code'
            component={Input}
            validate={[
              validators.postalCode(countryPostalPattern, `You must enter a valid ${countryPostal}`),
              validators.required(`Your ${countryPostal} is required`)
            ]}
            disabled={!editing}
          />
        </GridItem>
        <GridItem col='1/2' tablet1of3 mobileHide />

        <GridItem col='2/5' tablet1of2 mobile1of1>
          <FormGroupButtons form={USER_BILLING_ADDRESS_FORM_NAME} />
        </GridItem>
      </Grid>
    </Form>
  )
}

export default applyHOCs([
  formGroup({
    form: USER_BILLING_ADDRESS_FORM_NAME,
    initialValuesSelector: state => {
      return pick(currentUserSelector(state), [
        'address_line_1',
        'address_line_2',
        'city',
        'state',
        'country',
        'postal_code'
      ])
    },
    validate
  }),
  connect(
    addressFormPropsSelector(USER_BILLING_ADDRESS_FORM_NAME),
    { save: saveUserBillingAddress }
  )
])(UserBillingAddressForm)
