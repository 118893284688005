import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { requestOrderInvoiceExport } from '../actions'
import css from './style.scss'

const DownloadInvoice = ({ orderId, requestDownload, invoiceExport }) => {
  const isLoading = get(invoiceExport, [orderId, 'isLoading']) || false

  return (
    <div>
      <button
        className={css.downloadButton}
        onClick={requestDownload}
        disabled={isLoading}
      >
        Download PDF
      </button>
    </div>
  )
}

DownloadInvoice.propTypes = {
  orderId: React.PropTypes.number.isRequired,
  requestDownload: React.PropTypes.func.isRequired,
  invoiceExport: React.PropTypes.object
}

const mapStateToProps = state => ({
  invoiceExport: state.account.invoiceExport
})

export default connect(mapStateToProps, (dispatch, ownProps) => ({
  requestDownload: () => {
    dispatch(requestOrderInvoiceExport(ownProps.orderId))
  }
}))(DownloadInvoice)
