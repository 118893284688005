import React from 'react'

import { load, schemas } from 'api'
import { Heading } from 'core'
import { Section } from 'layout'

import { CoreCurriculumBlock } from 'core-curriculum'

const CurriculumContainer = (props) => {
  return (
    <div>
      <Section>
        <Heading size='h4' weight='light'>
          The Core Curriculum
        </Heading>
      </Section>
      <CoreCurriculumBlock
        coreCurriculumWebinars={props.coreCurriculumWebinars || []}
      />
    </div>
  )
}

CurriculumContainer.propTypes = {
  coreCurriculumWebinars: React.PropTypes.arrayOf(React.PropTypes.object)
}

export default load({
  coreCurriculumWebinars: {
    urlSelector: () => '/v2/core_curriculum',
    schema: [schemas.coreCurriculumWebinar]
  }
})(CurriculumContainer)
