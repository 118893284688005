import React, { PropTypes } from 'react'
import { browserHistory } from 'react-router'
import { createSelector } from 'reselect'

import { load, schemas } from 'api'
import { currentUserMembershipSelector } from 'auth'
import { formatPrice } from 'helpers'

import { timeUntilExpiry } from './helpers'
import { NotificationBanner } from '../components/NotificationBanner'

export const UserRenewalBanner = props => {
  const { membership, membershipPlan } = props

  if (!membershipPlan) return null

  return (
    <NotificationBanner
      message={`
        Your NCFDD membership will expire ${timeUntilExpiry(membership)}.
        For ${formatPrice(membershipPlan.price)}, you can renew your membership for an additional year.
      `}
      buttonOptions={{
        onClick: () => browserHistory.push('/renew-membership'),
        children: 'Renew My Membership'
      }}
    />
  )
}

UserRenewalBanner.propTypes = {
  membership: PropTypes.shape({
    expires_at: PropTypes.string.isRequired
  }).isRequired,
  membershipPlan: PropTypes.shape({
    price: PropTypes.number.isRequired
  })
}

export default load({
  membershipPlan: {
    urlSelector: createSelector(
      currentUserMembershipSelector,
      (membership) => `/v2/membership_plans/${membership.membership_plan_id}`
    ),
    schema: schemas.membershipPlan
  }
})(UserRenewalBanner)
