import React from 'react'
import ReactSelect, { Creatable } from 'react-select'
import { difference, isArray, map, compact, noop } from 'lodash'

import 'react-select/dist/react-select.css'

import { InputWrapper } from '../InputWrapper'
import css from './style.scss'

/**
 * Controlled select component. Is compatible with redux-form Field
 */
const Select = (props) => {
  const SelectComponent = props.allowCustom ? Creatable : ReactSelect

  const classes = [
    css.wrapper,
    props.grey ? css.grey : null
  ]

  /**
   * Fix bug that occurs when initial value is a custom value
   * https://github.com/JedWatson/react-select/issues/1520
   */
  const pushValue = (value) => props.options.push({ name: value, value })
  if (isArray(props.input.value)) {
    const missingValues =
      difference(props.input.value, map(props.options, 'value'))
    missingValues.forEach(pushValue)
  } else if (
    props.input.value &&
    !props.options.some((option) => option.value === props.input.value)
  ) {
    props.options.push({
      name: props.input.value,
      value: props.input.value
    })
  }

  return (
    <div className={compact(classes).join(' ')}>
      <InputWrapper {...props.meta} hideMeta={props.hideMeta}>
        <SelectComponent
          labelKey='name'
          value={props.input.value}
          onBlur={props.input.onBlur ? (e) => props.input.onBlur(props.input.value) : noop}
          onChange={(option) => {
            let newValue
            if (!option) {
              newValue = null
            } else if (props.multi) {
              newValue = option.map(o => o.value)
            } else {
              newValue = option.value
            }
            props.input.onChange(newValue)
          }}
          options={props.options}
          multi={!!props.multi}
          placeholder={props.placeholder || null}
          /**
          * Added space because of bug
          * https://github.com/JedWatson/react-select/issues/1466
          */
          promptTextCreator={(label) => label + ' '}
          disabled={props.disabled}
        />
      </InputWrapper>
    </div>
  )
}

Select.propTypes = {
  options: React.PropTypes.arrayOf(React.PropTypes.shape({
    name: React.PropTypes.string,
    value: React.PropTypes.any
  })).isRequired,
  allowCustom: React.PropTypes.bool,
  multi: React.PropTypes.bool,
  placeholder: React.PropTypes.node,
  input: React.PropTypes.object,
  meta: React.PropTypes.object,
  hideMeta: React.PropTypes.bool,
  disabled: React.PropTypes.bool,
  /* Allows for alternative styling for Select, used in filter dropdowns */
  grey: React.PropTypes.bool
}

Select.defaultProps = {
  hideMeta: false,
  disabled: false
}

export default Select
