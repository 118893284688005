import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { compact } from 'lodash'

import { Avatar } from 'core'

import { unassignUser } from './actions'
import css from './style.scss'
import RemoveButton from './RemoveButton'

const ReservationListItemCell = ({ children, className = '' }) => (
  <div className={compact([css.cell, className]).join(' ')}>
    {children}
  </div>
)

export const ReservationListItem = ({ reservation, unassign, removeDisabled }) => (
  <li className={css.reservationListItem}>
    <ReservationListItemCell className={css.avatar}>
      <Avatar user={reservation.user} size='sm' lightFill />
    </ReservationListItemCell>
    <ReservationListItemCell className={css.name}>
      {reservation.user.name} <span className={css.email}>{reservation.user.email}</span>
    </ReservationListItemCell>
    {!removeDisabled && (
      <ReservationListItemCell className={css.removeButtonWrapper}>
        <RemoveButton onClick={unassign} />
      </ReservationListItemCell>
    )}
  </li>
)

ReservationListItem.propTypes = {
  reservation: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  unassign: PropTypes.func.isRequired,
  removeDisabled: PropTypes.bool
}

export default connect(
  null,
  (dispatch, props) => ({
    unassign: () => dispatch(unassignUser(props.reservation.id))
  })
)(ReservationListItem)
