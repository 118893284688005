import React from 'react'

import { Heading } from 'core'

const ModalHeading = ({ children }) => (
  <Heading size='h3'>
    {children}
  </Heading>
)

ModalHeading.propTypes = {
  children: React.PropTypes.node
}

export default ModalHeading
