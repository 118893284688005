import React from 'react'
import MediaQuery from 'react-responsive'

import { Heading, Button } from 'core'
import { Section, Combo, ComboFirst, ComboLast } from 'layout'

import css from './style.scss'
import { breakpoints } from 'styles'

const { tabletMax, laptopMin } = breakpoints

const NotificationBanner = (props) => {
  const message = (
    <Heading noMargin size='h6'>
      {props.message}
    </Heading>
  )

  const button = props.buttonOptions && (
    <Button
      className={css.button}
      noMargin
      {...props.buttonOptions}
    />
  )

  return (
    <div className={css.notification}>
      <MediaQuery maxWidth={tabletMax}>
        <Section>
          {message}
          <div className={css.button}>
            {button}
          </div>
        </Section>
      </MediaQuery>
      <MediaQuery minWidth={laptopMin}>
        <Section>
          {props.buttonOptions
            ? (
              <Combo reverse>
                <ComboFirst>{message}</ComboFirst>
                <ComboLast className={css.button}>
                  <div className={css.button}>
                    {button}
                  </div>
                </ComboLast>
              </Combo>)
            : message}
        </Section>
      </MediaQuery>
    </div>
  )
}

NotificationBanner.propTypes = {
  /**
   * The NotificationBanner takes a string which will display a description
   * of the notification and an optional actionable element.
   */
  message: React.PropTypes.node.isRequired,
  buttonOptions: React.PropTypes.object
}

export default NotificationBanner
