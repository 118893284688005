import React, { PropTypes, Component } from 'react'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'

import { Button, Heading } from 'core'
import { FormContainer, FormSection } from 'form'
import { FormPage } from 'page'

import css from './style.scss'

import { confirmEmail } from './actions'

export const ConfirmEmailPage = ({ emailConfirmed, goToDashboard }) => {
  return (
    <FormPage>
      <FormContainer>
        <FormSection>
          {emailConfirmed === true && (
            <div>
              <Heading size='h3' weight='semibold' center>Thank you for confirming your email.</Heading>
              <p className={css.noticeSubtext}>You can now access all the benefits of your membership.</p>
              <div className={css.buttonWrapper}>
                <Button size='primary' onClick={goToDashboard}>Go to dashboard</Button>
              </div>
            </div>
          )}
          {emailConfirmed === false && (
            <div>
              <Heading size='h3' weight='semibold' center>Oops, something went wrong.</Heading>
              <p className={css.noticeSubtext}>Please follow the link in your email to confirm your account.</p>
            </div>
          )}
        </FormSection>
      </FormContainer>
    </FormPage>
  )
}

ConfirmEmailPage.propTypes = {
  emailConfirmed: PropTypes.bool,
  goToDashboard: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  emailConfirmed: state.emailConfirmation.emailConfirmed
})

export default connect(mapStateToProps, { confirmEmail })(
  class ConfirmEmailPageContainer extends Component {
    componentWillMount () {
      this.props.confirmEmail(this.props.location.query.token)
    }

    render () {
      const { emailConfirmed } = this.props
      const goToDashboard = () => browserHistory.push('/dashboard')

      return <ConfirmEmailPage goToDashboard={goToDashboard} emailConfirmed={emailConfirmed} />
    }
  }
)
