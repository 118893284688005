import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { Field, reduxForm, hasSubmitSucceeded } from 'redux-form'
import { createSelector } from 'reselect'
import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from 'consts'

import { Button, Heading, Link } from 'core'
import { Input, Label, validators } from 'form'
import { applyHOCs } from 'helpers'
import { Page } from 'page'
import { Section, Spacer } from 'layout'

import { FORM_NAME_RESET } from './constants'
import { resetPassword } from './actions'

export class CreatePassword extends React.Component {
  redirectWithoutToken (token) {
    if (!token) {
      browserHistory.push('/forgot-password')
    }
  }

  componentWillMount () {
    this.redirectWithoutToken(this.props.token)
  }

  render () {
    const { createPassword, hasCreatedPassword, token, error } = this.props

    return (
      <Page>
        <Section narrow>
          <Spacer size='md' />
          <Heading size='h4' center>Create your password</Heading>
          {
            !hasCreatedPassword &&
              <div>
                <p>
                  To complete your account activation, create a new password.
                  Once you have created your password, you can sign in to your account.
                </p>
                <Spacer size='xs' />
              </div>
          }
          {
            error &&
              <div>
                <p>
                  There was an error creating your password. {error} Try <Link to='/forgot-password' orange>requesting a new password reset token</Link>.
                </p>
                <Spacer size='xs' />
              </div>
          }
          {
            !hasCreatedPassword &&
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  createPassword(token)
                }}
              >
                <Label htmlFor='password'>Password</Label>
                <Field
                  name='password'
                  component={Input}
                  type='password'
                  maxLength={MAX_PASSWORD_LENGTH}
                  validate={[
                    validators.required('A password is required'),
                    validators.minLength(MIN_PASSWORD_LENGTH, `Your password must be at least ${MIN_PASSWORD_LENGTH} characters`)
                  ]}
                />
                <Label htmlFor='password'>Confirm Password</Label>
                <Field
                  name='password_confirmation'
                  component={Input}
                  type='password'
                  maxLength={MAX_PASSWORD_LENGTH}
                  validate={[
                    validators.required('Confirming your password is required'),
                    validators.equalToField('password', 'Your passwords do not match')
                  ]}
                />
                <Spacer size='xs' />
                <Button
                  type='submit'
                  size='primary'
                  disabled={this.props.submitting || this.props.invalid}
                  isLoading={this.props.submitting}
                >
                  Create Password
                </Button>
              </form>
          }
          {
            hasCreatedPassword &&
              <div>
                <p>
                  Your password has been successfully created! You can now <Link to='login' orange>sign in to your account</Link> with your new password.
                </p>
              </div>
          }
          <Spacer size='md' />
        </Section>
      </Page>
    )
  }
}

CreatePassword.propTypes = {
  /* mapStateToProps */
  token: React.PropTypes.string,
  hasCreatedPassword: React.PropTypes.bool,
  /* mapDispatchToProps */
  createPassword: React.PropTypes.func
}

const mapStateToProps = createSelector(
  (state, ownProps) => ownProps.router.location.query.token,
  hasSubmitSucceeded(FORM_NAME_RESET),
  (token, hasCreatedPassword) => ({
    token,
    hasCreatedPassword
  })
)

export default applyHOCs([
  connect(
    mapStateToProps,
    { createPassword: resetPassword }
  ),
  reduxForm({ form: FORM_NAME_RESET })
])(CreatePassword)
