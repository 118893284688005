import React from 'react'

import { Heading, Link } from 'core'
import { ChevronLink } from 'icons'

import { CMSSection } from '../components'

import css from './style.scss'

const ContentGrid = (props) => {
  const { title, subtitle, featured, grid_items: gridItems } = props

  return (
    <CMSSection
      title={title}
      subtitle={subtitle}
    >
      <div className={css.grid}>
        <div className={`${css.gridSquare} ${css.gridText}`}>
          <Heading size='h4' color='white' freight weight='medium'>
            {featured}
          </Heading>
        </div>
        {
          gridItems.map((item, index) => {
            let copyMarkup

            const copy = (
              <span className={item.item_url ? css.link : null}>
                {item.item_copy}
              </span>
            )

            if (item.item_url) {
              copyMarkup = (
                <Link to={item.item_url}>
                  {copy} <ChevronLink />
                </Link>
              )
            } else {
              copyMarkup = copy
            }

            return (
              <div
                key={index}
                className={css.gridSquare}
                style={{ backgroundImage: `url(${item.item_img})` }}
              >
                <p>
                  {copyMarkup}
                </p>
              </div>
            )
          })
        }
      </div>
    </CMSSection>
  )
}

ContentGrid.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  featured: React.PropTypes.string.isRequired,
  grid_items: React.PropTypes.arrayOf(React.PropTypes.shape({
    item_copy: React.PropTypes.string.isRequired,
    item_img: React.PropTypes.string.isRequired,
    item_url: React.PropTypes.string
  })).isRequired
}

export default ContentGrid
