import { isEmpty, mapKeys, pick } from 'lodash'
import { getFormValues } from 'redux-form'
import { createSelector } from 'reselect'
import { fieldErrorsSelectorFactory } from 'form'

import {
  INVOICE_RECIPIENT_FIELD_NAMES,
  INVOICE_REQUIRED_FIELD_NAMES
} from './constants'

const removePrefix = field => field.replace(/^invoice_/, '')

export const invoiceRecipientSelector = form => createSelector(
  getFormValues(form),
  formValues => mapKeys(
    pick(formValues, INVOICE_RECIPIENT_FIELD_NAMES),
    (val, key) => removePrefix(key)
  )
)

export const invoiceFormCompleteSelector = form => createSelector(
  fieldErrorsSelectorFactory(form, INVOICE_REQUIRED_FIELD_NAMES),
  fieldErrors => isEmpty(fieldErrors)
)
