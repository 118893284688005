import React from 'react'

import { Section } from '../Section'

import css from './style.scss'

const PageMain = (props) => (
  <main className={css.main}>
    <Section tabletNoPadding>
      {props.children}
    </Section>
  </main>
)

export default PageMain
