/* global Primus */

import { pick } from 'lodash'

import { primusUrl } from 'config'
import { apiResponse, schemas } from 'api'
import { currentUserSelector } from 'auth'

import {
  RECEIVE_USER_LIST,
  RECEIVE_USER_JOINED,
  RECEIVE_USER_LEAVE,
  RECEIVE_CHAT_MESSAGE,
  RECEIVE_DELETE_CHAT_MESSAGE,
  JOIN_CHAT_ROOM,
  LEAVE_CHAT_ROOM,
  CREATE_CHAT_MESSAGE
} from './types'

/**
 * - Manages a primus client
 * - Receives incoming socket messages and dispatches reducer-destined actions
 * - Receives incoming actions and writes to the socket
 */

export default store => {
  const { dispatch, getState } = store

  const primus = new Primus(primusUrl)

  const socketMessageHandlers = {
    sparkList: data => {
      dispatch(apiResponse({
        type: RECEIVE_USER_LIST,
        schema: [schemas.user],
        data: data.sparkList.map(spark => spark.user),
        room: data.room
      }))
    },

    sparkJoin: data => {
      dispatch(apiResponse({
        type: RECEIVE_USER_JOINED,
        schema: schemas.user,
        data: data.spark.user,
        room: data.room
      }))
    },

    sparkLeave: data => {
      if (data.spark && data.spark.user && data.spark.user.id) {
        dispatch({
          type: RECEIVE_USER_LEAVE,
          room: data.room,
          id: data.spark.user.id
        })
      }
    },

    say: data => {
      dispatch(apiResponse({
        type: RECEIVE_CHAT_MESSAGE,
        schema: schemas.chatMessage,
        room: data.room,
        data: pick(data, [
          'id',
          'room',
          'body',
          'user',
          'created_at',
          'updated_at'
        ])
      }))
    },

    deleteMessage: data => {
      dispatch({
        type: RECEIVE_DELETE_CHAT_MESSAGE,
        room: data.room,
        id: data.id
      })
    }
  }

  const socketActionHandlers = {
    [JOIN_CHAT_ROOM]: action => {
      primus.write({
        action: 'join',
        room: action.room,
        user: currentUserSelector(getState())
      })
    },

    [LEAVE_CHAT_ROOM]: action => {
      primus.write({
        action: 'leave',
        room: action.room
      })
    },

    [CREATE_CHAT_MESSAGE]: action => {
      const { chatMessage } = action
      primus.write({
        action: 'say',
        ...pick(chatMessage, [
          'id',
          'room',
          'body',
          'user',
          'created_at',
          'deleted_at'
        ])
      })
    }
  }

  primus.on('data', data => {
    if (socketMessageHandlers[data.action]) {
      socketMessageHandlers[data.action](data)
    }
  })

  return next => action => {
    if (socketActionHandlers[action.type]) {
      socketActionHandlers[action.type](action)
    } else {
      return next(action)
    }
  }
}
