import React from 'react'

import { Heading } from 'core'
import { Grid, GridItem, Section, SectionOverride } from 'layout'

import NextCurriculumCard from './NextCurriculumCard'
import NextWebinarCard from './NextWebinarCard'
import NextSessionSaleCard from './NextSessionSaleCard'

export const Learning = props => {
  const visitedDashboard = 'Start'

  return (
    <Section mobileNoPadding>
      <SectionOverride>
        <Heading size='h4' weight='light'>
          <span>{visitedDashboard}</span> Learning
        </Heading>
      </SectionOverride>
      <Grid stretch>
        <GridItem col='1/3' tablet1of1 mobile1of1>
          <SectionOverride>
            <Heading size='h5' weight='thin'>
              Core Curriculum
            </Heading>
          </SectionOverride>
          <NextCurriculumCard />
        </GridItem>
        <GridItem col='1/3' tablet1of1 mobile1of1>
          <SectionOverride>
            <Heading size='h5' weight='thin'>
              Bootcamp
            </Heading>
          </SectionOverride>
          <NextSessionSaleCard />
        </GridItem>
        <GridItem col='1/3' tablet1of1 mobile1of1>
          <SectionOverride>
            <Heading size='h5' weight='thin'>
              Next Event
            </Heading>
          </SectionOverride>
          <NextWebinarCard />
        </GridItem>
      </Grid>
    </Section>
  )
}

export default Learning
