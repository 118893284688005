import React from 'react'

import { FormSection } from 'form'
import { Button } from 'core'

const ContinueSection = ({ valid, asyncValidating, submitting }) => (
  <FormSection>
    <Button
      noMargin
      size='primary'
      type='submit'
      disabled={!valid || asyncValidating || submitting}
      isLoading={asyncValidating || submitting}
    >
      Continue
    </Button>
  </FormSection>
)

export default ContinueSection
