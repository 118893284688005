import React from 'react'

import { formatNewlines } from 'helpers'

const WebinarBio = ({ bio = '' }) => (
  <div dangerouslySetInnerHTML={{ __html: bio && formatNewlines(bio) }} />
)

WebinarBio.propTypes = {
  bio: React.PropTypes.string
}

export default WebinarBio
