import React from 'react'

import { Button, Card, Heading, Link } from 'core'
import { CMSSection } from '../components'

import css from './style.scss'

const ContentCards = (props) => (
  <CMSSection
    title={props.title}
    subtitle={props.subtitle}
  >
    {
      props.cards.map((card, index) => (
        <div key={index} className={css.cardWrapper}>
          <Card>
            <div className={css.card}>
              <div
                className={css.image}
                style={{ backgroundImage: `url(${card.image_url})` }}
              />
              <div className={css.content}>
                <div className={css.headerWrapper}>
                  <Heading size='h4' freight noMargin>{card.title}</Heading>
                </div>
                <div
                  className={css.copy}
                  dangerouslySetInnerHTML={{ __html: card.copy }}
                />
                {
                  (card.cta_url && card.cta_label) &&
                    <div className={css.buttonWrapper}>
                      <Link to={card.cta_url}>
                        <Button
                          size='secondary'
                          outline
                          noMargin
                        >
                          {card.cta_label}
                        </Button>
                      </Link>
                    </div>
                }
              </div>
            </div>
          </Card>
        </div>
      ))
    }
  </CMSSection>
)

ContentCards.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  cards: React.PropTypes.arrayOf(React.PropTypes.shape({
    title: React.PropTypes.string.isRequired,
    copy: React.PropTypes.string.isRequired,
    cta_url: React.PropTypes.string,
    cta_label: React.PropTypes.string,
    image_url: React.PropTypes.string.isRequired
  })).isRequired
}

export default ContentCards
