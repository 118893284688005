import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { middleware as authMiddleware } from 'auth'
import { middleware as chatMiddleware } from 'chat'
import { middleware as analyticsMiddleware } from 'analytics'
import { middleware as errorMiddleware } from 'errors'
import { browserHistory } from 'react-router'
import { routerMiddleware } from 'react-router-redux'
import persistState from 'redux-localstorage'

import config from 'config'
import rootReducer from './rootReducer'
import localstorageSlicer from './localstorageSlicer'

const composeEnhancers =
  process.env.NODE_ENV === 'development' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(config.reduxDevTools)
    : compose

const store = createStore(
  rootReducer,
  composeEnhancers(
    persistState(null, {
      slicer: (path) => localstorageSlicer
    }),
    applyMiddleware(
      thunk,
      chatMiddleware,
      authMiddleware,
      analyticsMiddleware,
      errorMiddleware,
      routerMiddleware(browserHistory),
      logger()
    )
  )
)

if (module.hot) {
  module.hot.accept('./rootReducer', () => {
    store.replaceReducer(require('./rootReducer').default)
  })
}

export default store
