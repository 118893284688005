import { differenceBy, find, get, intersectionBy, isEmpty, map } from 'lodash'
import { getFormValues } from 'redux-form'
import { createSelector, createStructuredSelector } from 'reselect'

import { FORM_NAME } from '../../constants'

import { formatPrice } from 'helpers'
import {
  genderOptionsSelector,
  ethnicityOptionsSelector,
  titleOptionsSelector,
  disciplineOptionsSelector,
  disciplineClassificationOptionsSelector,
  pnlOptionsSelector
} from 'settings'
import { getIndividualRegistrationOrderSummary } from '../../individual-registration/selectors'

export const reviewSectionsSelector = createSelector(
  createStructuredSelector({
    genderOptions: genderOptionsSelector,
    ethnicityOptions: ethnicityOptionsSelector,
    titleOptions: titleOptionsSelector,
    disciplineOptions: disciplineOptionsSelector,
    disciplineClassificationOptions: disciplineClassificationOptionsSelector,
    pnlOptions: pnlOptionsSelector
  }),
  getFormValues(FORM_NAME),
  (state, props) => props.membershipPlan,
  (state, props) => props.membershipPlan
    ? getIndividualRegistrationOrderSummary(state, props)
    : null,
  (options, values = {}, membershipPlan, orderSummary) => {
    const getOptionValue = (optionName, value) => {
      const option = find(get(options, optionName), { value })
      return option ? get(option, 'name') : value
    }

    const getOptionValues = (optionName, values = []) => {
      const selectedValues = values.map(value => ({ value, name: value }))
      const intersection = intersectionBy(options[optionName], selectedValues, 'value')
      intersection.push(...differenceBy(selectedValues, intersection, 'value')) // custom values
      return map(intersection, 'name')
    }

    const getValue = (fieldName) => values[fieldName]
    const hasValue = (field) => !isEmpty(field.value)
    const pnl = getOptionValue('pnlOptions', getValue('post_nominal_letters'))

    const reviewSections = [
      {
        title: 'Account Information',
        stepNum: 1,
        fields: [
          {
            name: 'Name',
            value: `${getValue('first_name')} ${getValue('last_name')}${pnl ? `, ${pnl}` : ''}`
          },
          {
            name: 'Nickname',
            value: getValue('nickname')
          },
          {
            name: 'Email',
            value: getValue('email')
          },
          {
            name: 'Phone Number',
            value: getValue('phone_number')
          },
          {
            name: 'Gender',
            value: getOptionValues('genderOptions', getValue('gender'))
          },
          {
            name: 'Ethnicities',
            value: getOptionValues('ethnicityOptions', getValue('ethnicities'))
          }
        ].filter(hasValue)
      },
      {
        title: 'Professional Information',
        stepNum: 2,
        fields: [
          {
            name: 'Title',
            value: getOptionValue('titleOptions', getValue('title'))
          },
          {
            name: 'Discipline',
            value: getOptionValue('disciplineOptions', getValue('discipline'))
          },
          {
            name: 'Discipline Classification',
            value: getOptionValue('disciplineClassificationOptions', getValue('discipline_classification'))
          },
          {
            name: 'University',
            value: getValue('university')
          },
          {
            name: 'Department',
            value: getValue('department')
          }
        ].filter(hasValue)
      }
    ]

    if (membershipPlan) {
      const orderDetailsSection = {
        title: 'Order Details',
        fields: [
          {
            name: 'Product Name',
            value: membershipPlan.name
          },
          {
            name: 'Price',
            value: formatPrice(orderSummary.subtotal)
          }
        ]
      }

      if (orderSummary.discountAmount > 0) {
        orderDetailsSection.fields.push(
          {
            name: 'Discount',
            value: formatPrice(orderSummary.discountAmount)
          },
          {
            name: 'Total',
            value: formatPrice(orderSummary.total)
          }
        )
      }

      reviewSections.unshift(orderDetailsSection)

      if (orderSummary.total > 0) {
        reviewSections.push({
          title: 'Billing Address',
          stepNum: 3,
          fields: [
            {
              name: 'Address Line 1',
              value: getValue('address_line_1')
            },
            {
              name: 'Address Line 2',
              value: getValue('address_line_2')
            },
            {
              name: 'Postal Code',
              value: getValue('postal_code')
            },
            {
              name: 'City',
              value: getValue('city')
            },
            {
              name: 'State',
              value: getValue('state')
            },
            {
              name: 'Country',
              value: getValue('country')
            }
          ].filter(hasValue)
        })
      }
    }

    return reviewSections
  }
)
