import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import MediaQuery from 'react-responsive'
import { Field, reduxForm } from 'redux-form'
import qs from 'qs'
import get from 'lodash/get'

import { load } from 'api'
import { Heading, HorizontalRule } from 'core'
import { validators } from 'form'
import { applyHOCs, paginationPageCount, paginationQueryBuilder } from 'helpers'
import { Grid, GridItem, Section, Spacer } from 'layout'
import { page } from 'page'

import Button from './components/Button'
import Input from './components/Input'
import ResultList from './components/ResultList'
import NoResultsFound from './components/NoResultsFound'
import css from './style.scss'
import { breakpoints } from 'styles'

const FORM_NAME = 'searchEntry'
const { mobileMax, tabletMin } = breakpoints

const REQUEST_LIMIT = 20

export const SearchResultsPage = (props) => {
  const { totalCount } = props.loadStatus.results

  return (
    <Section>
      <Spacer size='sm' />
      <form
        onSubmit={props.handleSubmit(({ term }) => {
          props.push(`${props.location.pathname}?term=${encodeURIComponent(term)}`)
        })}
      >
        <Grid>
          <GridItem col='1/4' mobile1of1 tablet1of3>
            <MediaQuery maxWidth={mobileMax}>
              <Heading size='h3' freight center>Search results for</Heading>
            </MediaQuery>
            <MediaQuery minWidth={tabletMin}>
              <Heading size='h3' freight>Search results for</Heading>
            </MediaQuery>
          </GridItem>
          <GridItem col='3/4' mobile1of1 tablet2of3>
            <div className={css.fieldContainer}>
              <Field
                name='term'
                component={Input}
                validate={[
                  validators.required('You must enter a search term'),
                  validators.minLength(3, 'Please enter at least 3 characters')
                ]}
              />
              <Button
                disabled={props.invalid}
              />
            </div>
          </GridItem>
        </Grid>
      </form>
      <Spacer size='xs' />
      <HorizontalRule full />
      <Spacer size='sm' />
      {
        props.results && props.results.length === 0 && !props.loadStatus.results.loading &&
          <NoResultsFound />
      }
      {
        props.results && props.results.length > 0 &&
          <ResultList
            results={props.results}
            total={props.loadStatus.results.totalCount}
            pages={paginationPageCount(totalCount, REQUEST_LIMIT)}
          />
      }
      <Spacer size='sm' />
    </Section>
  )
}

SearchResultsPage.propTypes = {
  results: React.PropTypes.array,
  handleSubmit: React.PropTypes.func.isRequired,
  push: React.PropTypes.func.isRequired,
  loadStatus: React.PropTypes.object.isRequired
}

const mapStateToProps = (state, props) => ({
  initialValues: {
    term: termSelector(props)
  }
})

const mapDispatchToProps = {
  push
}

const termSelector = props => get(props, 'location.query.term')

export default applyHOCs([
  page(),
  reduxForm({
    form: FORM_NAME
  }),
  connect(mapStateToProps, mapDispatchToProps),
  load({
    results: {
      urlSelector: (state, props) => {
        const term = termSelector(props)
        const { page } = props.location.query

        if (!term) return null

        const query = {
          filters: {
            term
          }
        }

        return `/v2/search?${qs.stringify(paginationQueryBuilder(page, REQUEST_LIMIT, query))}`
      }
    }
  })
])(SearchResultsPage)
