import { createSelector } from 'reselect'
import { denormalize } from 'normalizr'

import { entitiesSelector, schemas } from 'api'

export const reservationsSelector = createSelector(
  entitiesSelector,
  state => state.institutionReservations.sessionReservationIds,
  (entities, reservationIds) => {
    return reservationIds
      ? denormalize(reservationIds, [schemas.sessionReservation], entities)
      : undefined
  }
)
