import React, { PropTypes } from 'react'

import { CMSSection } from '../components'
import { Button, Link } from 'core'

import css from './style.scss'

const InstitutionClaimBanner = ({ institution, text, activate_button_text: buttonText }) => {
  return (
    <CMSSection>
      <div className={css.landingClaimCTAContainer}>
        <div className={css.landingClaimCTAItem}>
          <p>{text}</p>
        </div>
        <div className={css.landingClaimCTAItem}>
          <Link to={`/institutions/${institution.slug}/register`}>
            <Button size='primary'>
              {buttonText}
            </Button>
          </Link>
        </div>
      </div>
    </CMSSection>
  )
}

InstitutionClaimBanner.propTypes = {
  text: PropTypes.string.isRequired,
  activate_button_text: PropTypes.string.isRequired,

  // passed by page
  institution: PropTypes.shape({
    slug: PropTypes.string.isRequired
  }).isRequired
}

export default InstitutionClaimBanner
