import React from 'react'
import { compact } from 'lodash'

import css from './style.scss'

/**
 * The <Grid /> component will most often be the first
 * child of <Section /> and is responsible for holding
 * and properly templating all the <GridItem /> contents,
 * which it expects as children.
*/
const Grid = (props) => {
  const classes = [
    css.grid,
    props.middle ? css.middle : '',
    props.center ? css.center : '',
    props.stretch ? css.stretch : '',
    props.noGutter ? css.noGutter : '',
    props.rowReverse ? css.rowReverse : ''
  ]

  return (
    <div className={compact(classes).join(' ')} id={props.id}>
      {props.children}
    </div>
  )
}

Grid.propTypes = {
  /* This must always be a <GridItem /> */
  children: React.PropTypes.node,
  /* Centers the contents of the grid on the horizontal plane */
  middle: React.PropTypes.bool,
  /* Centers the contents of the grid on the vertical plane */
  center: React.PropTypes.bool,
  /* Ensures neighbouring gridItems run top-to-bottom */
  stretch: React.PropTypes.bool,
  /* Removes "gutters" (spacing between columns) from grid.
   * Use cautiously, as you'll have to manage padding in the used context yourself.
   */
  noGutter: React.PropTypes.bool,
  /*
   * Instead of rows appearing left-to-right, content populates from
   * right-to-left. Super useful if we have to have a single element
   * anchored right without relying on 🙅 floats 🙅.
   */
  rowReverse: React.PropTypes.bool
}

export default Grid
