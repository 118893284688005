import React from 'react'
import { compact } from 'lodash'

import css from './style.scss'

const LabelHelper = ({ children, inline }) => {
  const classes = [
    css.aside,
    inline ? css.inline : null
  ]
  return (
    <aside className={compact(classes).join(' ')}>
      {children}
    </aside>
  )
}

LabelHelper.propTypes = {
  children: React.PropTypes.string,
  /* Allows us to use LabelHelper inside of Label for short helper text */
  inline: React.PropTypes.bool
}

export default LabelHelper
