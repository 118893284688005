import React, { PropTypes } from 'react'
import { connect } from 'react-redux'

import { currentUserSelector } from 'auth'
import { Heading, Link } from 'core'
import { Modal } from 'modal'

import css from './style.scss'

const membershipEmail = 'Membership@ncfdd.org'

export const WelcomeModal = (props) => (
  <Modal>
    <div className={css.container}>
      <div>
        <Heading>Welcome, {props.user.first_name}!</Heading>
        <Heading size='h6' weight='light'>
          You now have access to your dashboard where you can browse our content, register for an upcoming event, or watch a training.
        </Heading>
      </div>
      <Heading size='h4'>What happens next?</Heading>
      <Heading size='h6' weight='light'>
        As a security measure, you'll need to activate your account by clicking the
        link in the email we just sent to you. If you didn't receive the email or
        if you have any questions, please contact us at <Link orange to={`mailto:${membershipEmail}`}>{membershipEmail}</Link>
      </Heading>
    </div>
  </Modal>
)

WelcomeModal.propTypes = {
  user: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: currentUserSelector(state)
})

export default connect(mapStateToProps)(WelcomeModal)
