import Immutable from 'seamless-immutable'
import { difference } from 'lodash'

import {
  LOAD_RESERVATIONS_SUCCESS,
  CLEAR_RESERVATIONS,
  CREATE_RESERVATIONS_SUCCESS,
  DELETE_RESERVATIONS_SUCCESS,
  UPDATE_USER_SEARCH_TERM
} from './types'

const initialState = Immutable.from({})

const reducersByType = {
  [LOAD_RESERVATIONS_SUCCESS]: (state, action) => {
    return state.set('sessionReservationIds', action.result)
  },

  [CLEAR_RESERVATIONS]: state => {
    return initialState
  },

  [CREATE_RESERVATIONS_SUCCESS]: (state, action) => {
    const existing = state.sessionReservationIds || []
    return state.set('sessionReservationIds', existing.concat(action.result))
  },

  [DELETE_RESERVATIONS_SUCCESS]: (state, action) => {
    const existing = state.sessionReservationIds
    if (!existing) return state
    return state.set('sessionReservationIds', difference(existing, action.deletedIds))
  },

  [UPDATE_USER_SEARCH_TERM]: (state, action) => {
    return state.set('userSearchTerm', action.searchTerm)
  }
}

const reducer = (state = initialState, action) => {
  const reducer = reducersByType[action.type]
  return reducer ? reducer(state, action) : state
}

export default reducer
