import React from 'react'
import moment from 'moment'

import MediaQuery from 'react-responsive'
import {
  Card,
  Heading,
  Link,
  Button
} from 'core'
import { Grid, GridItem, Spacer } from 'layout'

import { breakpoints } from 'styles'

const { mobileMax } = breakpoints

const News = (props) => {
  const { collection } = props

  return (
    <div>
      {
        collection.map((single, index) => (
          <Card key={index} spacing>
            <Heading size='h5'>{single.title}</Heading>
            <Grid>
              <GridItem col='2/3' mobile1of1>
                <Heading size='h6' noMargin weight='light'>
                  {moment(single.publish_date).format('MM/DD/YYYY')}
                </Heading>
                <MediaQuery maxWidth={mobileMax}>
                  <Spacer size='xs' />
                </MediaQuery>
              </GridItem>
              <GridItem col='1/3' mobile1of2>
                <Link to={`/news/${single.slug}`}>
                  <Button noMargin size='secondary'>Read More</Button>
                </Link>
              </GridItem>
            </Grid>
          </Card>
        ))
      }
    </div>
  )
}

News.propTypes = {
  collection: React.PropTypes.arrayOf(React.PropTypes.shape({
    title: React.PropTypes.string.isRequired,
    publish_date: React.PropTypes.string.isRequired
  })).isRequired
}

export default News
