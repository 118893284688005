import React from 'react'
import MediaQuery from 'react-responsive'
import { compact } from 'lodash'

import { Section } from 'layout'
import { Heading } from 'core'

import css from './style.scss'
import { breakpoints } from 'styles'

const { mobileMax } = breakpoints

const CMSSection = (props) => {
  const {
    title,
    subtitle,
    children,
    backgroundImage,
    backgroundMask,
    mobileBannerImage,
    noBorder,
    zIndexPriority
  } = props

  const classesWrapper = [
    css.wrapper,
    backgroundImage ? css.backgroundImage : null,
    backgroundMask ? css[backgroundMask] : css.noBkgMask,
    mobileBannerImage ? css.mobileBannerOverride : null,
    noBorder ? css.noBorder : null,
    zIndexPriority ? css.zIndexPriority : null
  ]

  const classesBanner = [
    css.bannerImage,
    // If Section generates padding, generate negative margin
    props.mobileNoPadding ? null : css.bannerImageMargin
  ]

  const style = {
    backgroundImage: `url(${backgroundImage})`
  }

  return (
    <div
      className={compact(classesWrapper).join(' ')}
      style={style}
    >
      <Section
        tabletNoPadding={props.tabletNoPadding}
        mobileNoPadding={props.mobileNoPadding}
        narrow={props.narrow}
      >
        {
          mobileBannerImage &&
            <MediaQuery maxWidth={mobileMax}>
              <div className={compact(classesBanner).join(' ')} style={style} />
            </MediaQuery>
        }
        {
          (title || subtitle) &&
            <div className={css.title}>
              {
                title &&
                  <Heading size='h4' freight noMargin>{title}</Heading>
              }
              {
                subtitle &&
                  <p>{subtitle}</p>
              }
            </div>
        }
        {children}
      </Section>
    </div>
  )
}

CMSSection.propTypes = {
  children: React.PropTypes.node,
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  backgroundImage: React.PropTypes.string,
  /**
   * If true, will turn background image into banner image on top
   * of section, and remove background image.
   */
  mobileBannerImage: React.PropTypes.bool,
  /*
   * Will set a mask overtop background image.
   * Currently available = 'gradientWhite'.
   */
  backgroundMask: React.PropTypes.string,
  /*
   * Passed to <Section />
   */
  tabletNoPadding: React.PropTypes.bool,
  mobileNoPadding: React.PropTypes.bool,
  narrow: React.PropTypes.bool,
  /*
   * Manual managing of the border (for CMSSections that don't dynamically set bkg image)
   */
  noBorder: React.PropTypes.bool,
  /*
   * Will elevate "priority" of z-index. Used specifically for SelectInstitution.
   */
  zIndexPriority: React.PropTypes.bool
}

export default CMSSection
