import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { load, schemas } from 'api'
import { applyHOCs, selectSlug } from 'helpers'
import { page, pageLoadSelectors, PageHead } from 'page'

import WebinarPage from './WebinarPage'
import { refreshWebinar } from './actions'

class WebinarRoute extends React.Component {
  componentWillReceiveProps (nextProps) {
    if (!nextProps.webinar ||
      this.webinarRefreshTimeout) return

    const buffer = moment.duration(2, 'minutes').asMilliseconds()
    const start = nextProps.webinar.start_at
    const refreshTime = moment(start).subtract(buffer)
    const now = moment()
    const diff = refreshTime.diff(now)
    // check if current time is more than 2 mins before webinar start time
    if (diff > 0) {
      const timeRemaining = moment(start).diff(now + buffer)
      // set timeout to trigger refresh 2 mins before the webinar starts
      this.webinarRefreshTimeout = setTimeout(this.props.onWebinarRefresh, timeRemaining)
    }
  }

  componentWillUnmount () {
    if (this.webinarRefreshTimeout) {
      clearTimeout(this.webinarRefreshTimeout)
    }
  }

  render () {
    const { webinar } = this.props
    return (
      <div>
        <PageHead title={webinar.title} />
        <WebinarPage webinar={webinar} />
      </div>
    )
  }
}

export default applyHOCs([
  page({
    ...pageLoadSelectors('webinar'),
    pageType: 'webinar',
    noPageHead: true
  }),
  load({
    webinar: {
      urlSelector: (state, props) => {
        return `/v2/webinars/slug/${props.slug}`
      },
      schema: schemas.webinar
    }
  }),
  connect(null, (dispatch, ownProps) => ({
    onWebinarRefresh: () => {
      dispatch(refreshWebinar(ownProps.slug))
    }
  })),
  selectSlug()
])(WebinarRoute)
