import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { Form } from 'form'

import RegistrationReviewSection from '../../components/RegistrationReviewSection'
import ContinueSection from '../../components/ContinueSection'
import { reviewSectionsSelector } from './selectors'

/* eslint-disable react/jsx-handler-names */
const Review = (props) => (
  <Form name={props.getStepFormName && props.getStepFormName()} onSubmit={props.continue}>
    {props.reviewSections.map((reviewSection, index) =>
      <RegistrationReviewSection
        {...reviewSection}
        key={index}
      />
    )}
    <ContinueSection {...props} />
  </Form>
)

Review.propTypes = {
  reviewSections: React.PropTypes.array
}

const mapStateToProps = createStructuredSelector({
  reviewSections: reviewSectionsSelector
})

export default connect(mapStateToProps)(Review)
