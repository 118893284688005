import React from 'react'
import MediaQuery from 'react-responsive'

import { Button } from '../Button'
import { Card } from '../Card'
import { Contact } from '../Contact'
import { Heading } from '../Heading'
import { Link } from '../Link'
import { Grid, GridItem } from 'layout'
import { formatWebinarDate } from 'helpers'

import css from './style.scss'
import { breakpoints } from 'styles'

const { laptopMin, mobileMax, tabletMax } = breakpoints

const WebinarSingleFooter = (props) => (
  <Grid center={props.center}>
    <GridItem col='1/2' tablet1of3>
      <p className={css.webinarType}>
        {props.webinarType}
      </p>
    </GridItem>
    <MediaQuery maxWidth={laptopMin} minWidth={mobileMax}>
      <GridItem col='0' tablet1of3 />
    </MediaQuery>
    <GridItem col='1/2' tablet1of3>
      <Link to={props.to}>
        <Button size='tertiary' noMargin>View Details</Button>
      </Link>
    </GridItem>
  </Grid>
)

WebinarSingleFooter.propTypes = {
  center: React.PropTypes.bool,
  webinarType: React.PropTypes.string.isRequired,
  to: React.PropTypes.string.isRequired
}

const typeLabels = {
  course: 'Multi-Week Course',
  guest: 'Guest Webinar'
}

const getTypeLabel = props => typeLabels[props.type]
const getWebinarLink = slug => `/webinars/${slug}`

const WebinarSingle = (props) => {
  const typeLabel = getTypeLabel(props)
  const link = getWebinarLink(props.slug)
  const webinarDate = formatWebinarDate(props.start)

  return (
    <Card>
      <Grid>
        <GridItem col='1/6' tablet1of1 mobile1of1>
          <p className={css.webinarDate}>
            {webinarDate}
          </p>
        </GridItem>
        <GridItem col='3/6' tablet1of1 mobile1of1>
          <Heading size='h4' weight='semibold'>{props.title}</Heading>
          {props.facilitator && <Contact size='xs' user={props.facilitator} />}
        </GridItem>
        <GridItem col='2/6' tablet1of1 mobile1of1>
          <div className={css.webinarRegister}>
            <MediaQuery
              children={<WebinarSingleFooter webinarType={typeLabel} to={link} />}
              minWidth={laptopMin}
            />
            <MediaQuery
              children={<WebinarSingleFooter center webinarType={typeLabel} to={link} />}
              maxWidth={tabletMax}
            />
          </div>
        </GridItem>
      </Grid>
    </Card>
  )
}

WebinarSingle.propTypes = {
  slug: React.PropTypes.string.isRequired,
  type: React.PropTypes.string,
  title: React.PropTypes.string.isRequired,
  start: React.PropTypes.string.isRequired,
  facilitator: React.PropTypes.object
}

export default WebinarSingle
