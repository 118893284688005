import React from 'react'
import { ChevronDownIcon } from 'icons'

import DesktopMenuDropdown from './DesktopMenuDropdown'
import { Link } from 'core'
import css from './style.scss'

const DesktopMenu = (props) => {
  return (
    <div className={css.menuSection}>
      {
        props.sections.map((section, index) => {
          return (
            <div key={index} className={css.dropdownHeader}>
              <Link to={section.to}>
                <div className={css.dropdownLink}>
                  {section.name}
                  {
                    section.children &&
                    section.children.length > 0 &&
                      <ChevronDownIcon className={css.chevron} />
                  }
                </div>
              </Link>
              {
                section.children &&
                section.children.length > 0 &&
                (<DesktopMenuDropdown items={section.children} />)
              }
            </div>
          )
        })
      }
    </div>
  )
}

DesktopMenu.propTypes = {
  sections: React.PropTypes.array.isRequired
}

export default DesktopMenu
