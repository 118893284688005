import React from 'react'

import css from './style.scss'

const InputWrapper = (props) => {
  let className = ''

  const shouldDisplayError =
    props.error && !props.active && (props.dirty || props.touched)

  if (shouldDisplayError) {
    className = 'inputHasError'
  }

  return (
    <div className={className}>
      {props.children}
      {!props.hideMeta &&
        <div className={css.errorText}>
          {shouldDisplayError && <span>{props.error}</span>}
        </div>}
    </div>
  )
}

const { PropTypes } = React

InputWrapper.propTypes = {
  children: PropTypes.node,
  hideMeta: PropTypes.bool,
  dirty: PropTypes.bool,
  active: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.string
}

export default InputWrapper
