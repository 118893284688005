import React from 'react'
import { Button, Heading, Link } from 'core'
import { Section } from 'layout'

import css from './style.scss'
import { injectHubspotForm } from 'hubspot/hubspotForm'
import { v4 as uuidv4 } from 'uuid'
import { ArrowRightIcon, DownloadIcon } from 'icons'
import classNames from 'classnames'

class HeaderPrimary extends React.Component {
  constructor (props) {
    super(props)
    this.uuid = uuidv4()
  }

  componentDidMount () {
    if (this.props.hubspot_form_id) {
      this.createHubspotForm()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.hubspot_form_id !== this.props.hubspot_form_id) {
      this.createHubspotForm()
    }
  }

  createHubspotForm () {
    const { hubspot_form_id: hubspotFormId } = this.props
    if (hubspotFormId) {
      injectHubspotForm({ wrapperElementId: `hubspot-form-${hubspotFormId}-${this.uuid}`, hubspotFormId })
    }
  }

  render () {
    const {
      copy,
      subtitle,
      img_url: imgUrl,
      cta_url: ctaUrl,
      cta_label: ctaLabel,
      cta_icon: ctaIcon,
      cta_url_secondary: secondaryCtaUrl,
      cta_label_secondary: secondaryCtaLabel,
      cta_icon_secondary: secondaryCtaIcon,
      hubspot_form_id: hubspotFormId
    } = this.props
    const style = {
      backgroundImage: `url(${imgUrl})`
    }

    return (
      <div className={css.wrapper}>
        <div className={css.backgroundImage} style={style} />
        <Section>
          <div className={css.heroWrapper}>
            <div className={css.hero}>
              <div className={css.heading}>
                <Heading size='h1' color='white' weight='medium' freight noMargin>
                  {copy}
                </Heading>
                {subtitle && <p className={css.subtitle}>{subtitle}</p>}
              </div>
              <div className={css.ctaWrapper}>
                {
                  (secondaryCtaUrl && secondaryCtaLabel) &&
                    <div className={css.cta}>
                      <Link to={secondaryCtaUrl}>
                        <Button size='primary' outline noMargin className={classNames(css.button, css.secondaryButton)}>
                          <span className={css.buttonLabel}>
                            {secondaryCtaLabel}
                            {secondaryCtaIcon && secondaryCtaIcon === 'arrow_right'
                              ? <ArrowRightIcon className={classNames(css.buttonIcon, css.arrowRightIcon)} />
                              : secondaryCtaIcon === 'download'
                                ? <DownloadIcon className={css.buttonIcon} />
                                : null}
                          </span>
                        </Button>
                      </Link>
                    </div>
                }
                {
                  (ctaUrl && ctaLabel) &&
                    <div className={css.cta}>
                      <Link to={ctaUrl}>
                        <Button size='primary' noMargin className={css.button}>
                          <span className={css.buttonLabel}>
                            {ctaLabel}
                            {ctaIcon && ctaIcon === 'arrow_right'
                              ? <ArrowRightIcon className={classNames(css.buttonIcon, css.arrowRightIcon)} />
                              : ctaIcon === 'download'
                                ? <DownloadIcon className={css.buttonIcon} />
                                : null}
                          </span>
                        </Button>
                      </Link>
                    </div>
                }
              </div>
            </div>
            {hubspotFormId &&
              <div>
                <form id={`hubspot-form-${hubspotFormId}-${this.uuid}`} />
              </div>}
          </div>
        </Section>
      </div>
    )
  }
}

HeaderPrimary.propTypes = {
  copy: React.PropTypes.string.isRequired,
  img_url: React.PropTypes.string.isRequired,
  cta_url: React.PropTypes.string,
  cta_label: React.PropTypes.string
}

export default HeaderPrimary
