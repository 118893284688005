import React from 'react'
import moment from 'moment'

import { Section } from 'layout'
import { Contact, Heading, HorizontalRule } from 'core'

import css from './style.scss'

const HeaderArticle = ({ title, date, author, type }) => {
  date = date || moment()
  const formattedDate = moment(date).format('MM/DD/YYYY')

  return (
    <Section narrow>
      <div className={css.wrapper}>
        <Heading size='h2' freight weight='medium'>
          {title}
        </Heading>
        {type !== 'monday_motivator' &&
          <p className={css.time}>
            {formattedDate}
          </p>}
        <HorizontalRule full />
        <div className={css.byline}>
          {author &&
            <Contact byline user={author} />}
        </div>
      </div>
    </Section>
  )
}

HeaderArticle.propTypes = {
  title: React.PropTypes.string.isRequired,
  date: React.PropTypes.string.isRequired,
  author: React.PropTypes.shape({
    name: React.PropTypes.string.isRequired,
    avatar_url: React.PropTypes.string
  })
}

export default HeaderArticle
