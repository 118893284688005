import React from 'react'
import moment from 'moment'

import { Button, Card, Heading, Link } from 'core'

import assetUrl from './assets/ladyLaptop.jpg'

import css from './style.scss'

/**
 * The `<CardBootcamp />` is an enhanced `<Card />`, formatting passed content
 * specifically for the card instance in the user dashboard. Note the specific
 * data structure expected.
 */
const CardBootcamp = (props) => {
  const { sessionSale } = props
  const date = moment(sessionSale.advertised_start_date).format('Do MMMM, YYYY')

  return (
    <div className={css.cardWrapper}>
      <Card img={assetUrl}>
        <div className={css.dashboard}>
          <div className={css.headingBootcamp}>
            <Heading size='h3' weight='thin' center noMargin>
              {sessionSale.title}
            </Heading>
          </div>
          <p className={css.detailsWhite}>{date}</p>
          <Link to={`/sessions/${sessionSale.slug}`} fullWidth>
            <Button size='secondary' noMargin>
              View Details
            </Button>
          </Link>
        </div>
      </Card>
    </div>
  )
}

CardBootcamp.propTypes = {
  sessionSale: React.PropTypes.shape({
    id: React.PropTypes.number,
    title: React.PropTypes.string,
    advertised_start_date: React.PropTypes.string
  }).isRequired
}

export default CardBootcamp
