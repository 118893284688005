import React from 'react'

import { Heading } from 'core'
import { Section } from 'layout'

import fspUI from './assets/fsp-ui.png'
import css from './style.scss'

const HeaderFSP = (props) => {
  const { session_name: sessionName, session_dates: sessionDates } = props

  return (
    <div className={css.wrapper}>
      <Section mobileNoPadding>
        <div className={css.heading}>
          <Heading size='h2' freight noMargin weight='medium'>
            The Faculty Success Program
          </Heading>
        </div>
        <div className={css.session}>
          <Heading size='h5' freight noMargin weight='thin'>
            The next session is the {sessionName} Session
          </Heading>
          <p>
            {sessionDates}
          </p>
        </div>
      </Section>
      <img
        src={fspUI}
        className={css.ui}
        alt='Gain access to the WriteNow platform through FSP.'
      />
    </div>
  )
}

HeaderFSP.propTypes = {
  session_name: React.PropTypes.string.isRequired,
  session_dates: React.PropTypes.string.isRequired
}

export default HeaderFSP
