import { trackFormStart, trackFormSubmit } from 'analytics'
import { hubspotPortalId } from 'config'

export const injectHubspotForm = ({ wrapperElementId, hubspotFormId }) => {
  if (wrapperElementId) {
    window.hbspt.forms.create({
      portalId: hubspotPortalId,
      formId: hubspotFormId,
      formInstanceId: `hubspot-form-${wrapperElementId}`,
      target: `#${wrapperElementId}`,
      onFormSubmit: () => trackFormSubmit({ formName: hubspotFormId })
    })
    const hubspotFormWrapper = document.querySelector(`#${wrapperElementId}`)

    let formStartTracked = false

    const observer = new window.MutationObserver(() => {
      const iframeDocument = hubspotFormWrapper.querySelector('iframe')?.contentWindow?.document
      const ncfddGlobalCSSStyle = document.querySelector('#ncfdd_global_css')

      if (iframeDocument) {
        const formObserver = new window.MutationObserver(() => {
          const form = iframeDocument.querySelector('form')
          if (form) {
            form.querySelectorAll('.hs-input')?.forEach(input => {
              input.addEventListener('focus', () => {
                if (formStartTracked) return

                trackFormStart({ formName: hubspotFormId })
                formStartTracked = true
              })
            })
            formObserver.disconnect()
          }
        })
        formObserver.observe(iframeDocument.body, { childList: true, subtree: true })
      }

      if (iframeDocument && ncfddGlobalCSSStyle) {
        const style = iframeDocument.createElement('style')
        style.id = 'ncfdd_global_css'
        style.innerHTML = ncfddGlobalCSSStyle.innerHTML
        iframeDocument.head.appendChild(style)
        observer.disconnect()
      }
    })
    observer.observe(hubspotFormWrapper, { childList: true })
  }
}
