import React from 'react'
import { withRouter } from 'react-router'
import MediaQuery from 'react-responsive'
import { compact } from 'lodash'

import { Link } from 'core'
import { Select } from 'form'
import { Section, Grid, GridItem } from 'layout'

import css from './style.scss'
import { breakpoints } from 'styles'

const { phabletMax, phabletMaxPlusOne } = breakpoints

const WebinarFilters = (props) => {
  const { types, activeType } = props

  const input = {
    value: activeType,
    onChange: props.onFilterChange
  }

  const dropdownTypes = types.map((type) => ({
    name: type.label,
    value: type.type
  }))

  const tabs = (
    <div className={css.wrapper}>
      <Section>
        <Grid noGutter>
          <GridItem col='1/3'>
            <Link to={{ pathname: '/library', query: { page: 1 } }}>
              <div className={`${css.tab} ${!activeType ? css.active : ''}`}>
                <span>
                  All Webinars
                </span>
              </div>
            </Link>
          </GridItem>
          {
            types.map((webinarType, index) => {
              const classes = [
                css.tab,
                webinarType.type === activeType ? css.active : ''
              ]

              const query = Object.assign({}, props.router.location.query, { type: webinarType.type, page: 1 })

              return (
                <GridItem col='1/3' key={index}>
                  <Link to={{ pathname: '/library', query }}>
                    <div className={compact(classes).join(' ')}>
                      <span>
                        {webinarType.label}
                      </span>
                    </div>
                  </Link>
                </GridItem>
              )
            })
          }
        </Grid>
      </Section>
    </div>
  )

  const dropdown = (
    <Select
      grey
      name='type'
      options={dropdownTypes}
      input={input}
      placeholder='Filter by Type'
    />
  )

  return (
    <div>
      <MediaQuery minWidth={phabletMaxPlusOne}>
        {tabs}
        <Section>
          <Grid rowReverse>
            <GridItem col='1/3' tablet1of2 mobile1of1>
              <div className={css.filterCategoryWrapper}>
                {props.children}
              </div>
            </GridItem>
          </Grid>
        </Section>
      </MediaQuery>

      <MediaQuery maxWidth={phabletMax}>
        <Section>
          <div className={css.filtersWrapper}>
            <Grid noGutter>
              <GridItem col='1/2'>
                {dropdown}
              </GridItem>
              <GridItem col='1/2'>
                <div className={css.filterCategoryWrapper}>
                  {props.children}
                </div>
              </GridItem>
            </Grid>
          </div>
        </Section>
      </MediaQuery>
    </div>
  )
}

WebinarFilters.propTypes = {
  types: React.PropTypes.arrayOf(React.PropTypes.shape({
    type: React.PropTypes.string,
    label: React.PropTypes.string
  })),
  activeType: React.PropTypes.string,
  onFilterChange: React.PropTypes.func.isRequired,
  children: React.PropTypes.element
}

export default withRouter(WebinarFilters)
