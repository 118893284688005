import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { LoadingScreen } from 'loading-screen'

import { logout } from 'auth'

export class ForumsSsoLogoutPage extends Component {
  componentWillMount () {
    this.props.logout()
  }

  render () {
    return (
      <LoadingScreen />
    )
  }
}

ForumsSsoLogoutPage.propTypes = {
  logout: PropTypes.func.isRequired
}

export default connect(null, { logout })(ForumsSsoLogoutPage)
