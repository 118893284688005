import React from 'react'

import { Heading, HorizontalRule } from 'core'
import { CMSSection } from '../components'

import css from './style.scss'

const HeaderSecondary = ({ title, subtitle }) => (
  <CMSSection>
    <div className={css.wrapper}>
      <Heading size='h1' weight='semibold' freight>
        {title}
      </Heading>
      <HorizontalRule />
      {
        subtitle &&
          <p className={css.subtitle}>{subtitle}</p>
      }
    </div>
  </CMSSection>
)

HeaderSecondary.propTypes = {
  title: React.PropTypes.string.isRequired,
  subtitle: React.PropTypes.string
}

export default HeaderSecondary
