import { createClient } from 'auth'
import { LOAD_SETTINGS_SUCCESS, LOAD_SETTINGS_ERROR } from './types'
import { handleError } from 'errors'

export const loadSettings = () => (dispatch, getState) => {
  return createClient(getState())
    .get('/v2/settings')
    .then((response) => response.json())
    .then((body) => {
      dispatch({
        type: LOAD_SETTINGS_SUCCESS,
        settings: body
      })
    })
    .catch(error => {
      dispatch({
        type: LOAD_SETTINGS_ERROR,
        error
      })
      dispatch(handleError(error))
    })
}
