import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { find } from 'lodash'

import { settingsDataSelector } from '../selectors'

const SettingOptionName = ({ name }) => <span>{name}</span>

SettingOptionName.propTypes = {
  name: React.PropTypes.string.isRequired,
  type: React.PropTypes.string.isRequired,
  value: React.PropTypes.string.isRequired
}

const typeToStateKey = {
  discipline: 'disciplines',
  disciplineClassification: 'discipline_classifications',
  ethnicity: 'ethnicities',
  gender: 'genders',
  title: 'titles',
  pnl: 'post_nominal_letters'
}

const mapStateToProps = createSelector(
  settingsDataSelector,
  (state, props) => props.type,
  (state, props) => props.value,
  (settings, type, value) => {
    const option = find(settings[typeToStateKey[type]], { value })
    return {
      name: option ? option.name : value
    }
  }
)

export default connect(mapStateToProps)(SettingOptionName)
