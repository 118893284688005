import { trackFormStart, trackFormSubmit } from 'analytics/form'
import React, { PropTypes } from 'react'

// A bit hacky but since we are on a very old React version, we can't use refs
// to track form start events so tha they are only fired on first focus.
// This tracks form names as keys in this map.
const trackedFormStartMap = {}

// A wrapper form element that tracks form start and submit analytic events
// and prevents default submission redirect behavior
//
// onSubmit: function to call when form is submitted, return an error or a Promise of an error
// to prevent form submission tracking
const Form = ({ onSubmit, onFocus, disableTracking, name, ...rest }) => {
  return <form
    name={name}
    onFocus={(e) => {
      if (name && !disableTracking && !trackedFormStartMap[name]) {
        trackFormStart({ formName: name })
        trackedFormStartMap[name] = true
      }
      if (onFocus) {
        onFocus(e)
      }
    }}
    onSubmit={async (e) => {
      try {
        e.preventDefault()
        let res
        if (onSubmit) {
          res = await onSubmit()
        }
        if (name && !disableTracking && !(res instanceof Error)) {
          trackFormSubmit({ formName: name })
          trackedFormStartMap[name] = false
        }
      } catch (e) {
        console.error('unhandled form error', e)
      }
    }}
    {...rest}
         />
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node
}

export default Form
