import Immutable from 'seamless-immutable'
import { values } from 'lodash'
import { filterActions } from 'redux-ignore'

import * as types from './types'
const {
  REGISTER_ELEMENTS,
  ELEMENTS_CHANGED,
  UNREGISTER_ELEMENTS
} = types

const initialState = Immutable({})

/* eslint-disable no-case-declarations */
const reducer = (state = initialState, action) => {
  const { form } = action
  switch (action.type) {
    case REGISTER_ELEMENTS:
      const { elements } = action

      const newStripeValidations = elements.reduce((newStripeValidations, element) => {
        newStripeValidations[element] = { complete: false }
        return newStripeValidations
      }, {})

      return state.set(form, newStripeValidations)
    case ELEMENTS_CHANGED:
      const { complete, error, value, key } = action

      return state.setIn([form, key], {
        error: error ? error.message : null,
        complete,
        value
      })
    case UNREGISTER_ELEMENTS:
      return state.without(form)
    default:
      return state
  }
}

export default filterActions(reducer, values(types))
