import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { currentUserMembershipSelector } from 'auth'
import { Card } from 'core'
import { Grid, GridItem } from 'layout'

import AccountHeading from '../components/AccountHeading'
import { AvatarForm } from './AvatarForm'
import MembershipRenewal from './membership-renewal/MembershipRenewal'
import AccountDetailsForm from './AccountDetailsForm'
import PersonalDetailsForm from './PersonalDetailsForm'
import ProfessionalDetailsForm from './ProfessionalDetailsForm'
import UserBillingAddressForm from './UserBillingAddressForm'
import { PageHead } from 'page/PageHead'

const AccountProfilePage = ({ membership }) => {
  return (
    <div>
      <PageHead title='Profile' />
      {membership && (
        <div>
          <AccountHeading>
            Membership Renewal
          </AccountHeading>
          <Card spacing>
            <MembershipRenewal membership={membership} />
          </Card>
        </div>
      )}
      <AccountHeading>
        Account Details
      </AccountHeading>
      <Card spacing>
        <Grid>
          <GridItem col='1/2' mobile1of1>
            <AccountDetailsForm />
          </GridItem>
          <GridItem col='1/2' mobile1of1>
            <AvatarForm />
          </GridItem>
        </Grid>
      </Card>
      <AccountHeading>
        Personal Details
      </AccountHeading>
      <Card spacing>
        <PersonalDetailsForm />
      </Card>
      <AccountHeading>
        Professional Details
      </AccountHeading>
      <Card spacing>
        <ProfessionalDetailsForm />
      </Card>
      <AccountHeading>
        Billing Address
      </AccountHeading>
      <Card spacing>
        <UserBillingAddressForm />
      </Card>
    </div>
  )
}

export default connect(
  createStructuredSelector({
    membership: currentUserMembershipSelector
  })
)(AccountProfilePage)
