import { orderBy, takeRight } from 'lodash'
import moment from 'moment'

import { priceOfReservation, priceOfReservations } from '../helpers'

export const orderByDeletionPriority = reservations => {
  const unassigned = reservation => reservation.user_id === null
  return orderBy(reservations, [unassigned, priceOfReservation], ['desc', 'desc'])
}

export const calculatePrice = (sessionSale, reservations, count) => {
  const { reservation_price: reservationPrice } = sessionSale
  let discountedPrice = reservationPrice
  if (sessionSale.discount) {
    discountedPrice -= sessionSale.discount.flat_amount
  }

  if (count > reservations.length) {
    return priceOfReservations(reservations) + ((count - reservations.length) * discountedPrice)
  } else if (count < reservations.length) {
    const mostExpensiveReservations = takeRight(orderByDeletionPriority(reservations), count)
    return priceOfReservations(mostExpensiveReservations)
  } else {
    return priceOfReservations(reservations)
  }
}

export const sessionReservationsAreAvailable = sessionSale => {
  const { institution_reservation_order: order } = sessionSale

  if (order && order.order_status !== 'open') {
    return false
  } else {
    return moment().isBefore(sessionSale.pre_registration_end_at)
  }
}
