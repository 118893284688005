import React from 'react'
import { connect } from 'react-redux'
import { closeModal } from './actions'

const ModalRoot = (props) => {
  const modal = props.modal

  if (!modal) return null

  return <modal.component {...modal.props} close={props.closeModal} />
}

ModalRoot.propTypes = {
  modal: React.PropTypes.shape({
    component: React.PropTypes.func.isRequired,
    props: React.PropTypes.object
  })
}

const mapStateToProps = (state) => ({
  modal: state.modal.modal
})

export default connect(mapStateToProps, { closeModal })(
  ModalRoot
)
