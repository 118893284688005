import React from 'react'
import { Link as ReactRouterLink } from 'react-router'
import { compact, isObject } from 'lodash'

import css from './style.scss'

/**
 * The <Link /> component is meant to abstract our linking
 * across the app, generating the appropriate element depending on
 * the type of link provided.
 *
 * If the user specifies an absolute path,
 * determined through a Regex match, an `<a>` is generated, w/ the
 * "to" prop generating a href attribute. Target is an optional
 * prop for absolute links.
 *
 * If the user specifies a relative path, a react-router
 * `<Link />` is generated.
 */
const Link = (props) => {
  const className = compact([
    props.className || '',
    css.link,
    props.sans ? css.sans : '',
    props.type ? css[props.type] : '',
    props.fullWidth ? css.fullWidth : '',
    props.orange ? css.orange : ''
  ]).join(' ')

  const { to } = props

  const pathname = isObject(props.to) ? props.to.pathname : props.to

  const external = pathname.match(/^(https?:\/\/|mailto:)/)
  const target = props.target || '_self'
  const download = props.download || false

  const commonProps = {
    className: className
  }

  if (props.onClick) {
    commonProps.onClick = props.onClick
  }

  if (external) {
    return (
      <a
        href={pathname}
        target={target}
        download={download}
        {...commonProps}
      >
        {props.children}
      </a>
    )
  }

  return (
    <ReactRouterLink
      activeClassName={props.activeClassName || ''}
      to={to}
      {...commonProps}
    >
      {props.children}
    </ReactRouterLink>
  )
}

Link.propTypes = {
  children: React.PropTypes.node.isRequired,
  to: React.PropTypes.oneOfType([
    React.PropTypes.string,
    React.PropTypes.object
  ]).isRequired,
  className: React.PropTypes.string,
  activeClassName: React.PropTypes.string,
  onClick: React.PropTypes.func,
  target: React.PropTypes.string,
  download: React.PropTypes.bool,
  sans: React.PropTypes.bool,
  fullWidth: React.PropTypes.bool,
  /* Can be set to `text` to render a text link style */
  type: React.PropTypes.string,
  /* If true, will render an orange link w/ underline */
  orange: React.PropTypes.bool
}

export default Link
