import React from 'react'

import css from './style.scss'

const ComboFirst = (props) => (
  <div className={css.first}>
    {props.children}
  </div>
)

ComboFirst.propTypes = {
  children: React.PropTypes.any.isRequired
}

export default ComboFirst
