import React from 'react'

import { Heading } from 'core'

const ErrorHeading = ({ children }) => (
  <Heading size='h2' weight='semibold' center>
    {children}
  </Heading>
)

ErrorHeading.propTypes = {
  children: React.PropTypes.node
}

export default ErrorHeading
