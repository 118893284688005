import { createClient } from 'auth'
import { handleError } from 'errors'

export const handleSsoRequest = (sso, sig) => (dispatch, getState) =>
  createClient(getState())
    .post('/auth/forums-sso', { body: { sso, sig } })
    .then(res => res.json())
    .then(body => {
      window.location.href = body.responseUrl
    })
    .catch(err => dispatch(handleError(err)))
