import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { currentUserMembershipSelector, isIAHSelector } from 'auth'
import { shouldRemindToRenewSelector } from './selectors'

import UserRenewalBanner from './UserRenewalBanner'
import IAHRenewalBanner from './IAHRenewalBanner'
import SubaccountRenewalBanner from './SubaccountRenewalBanner'

export const MembershipRenewalBanner = props => {
  const { membership, shouldRemindToRenew, isIAH } = props

  if (!shouldRemindToRenew || !membership) return null

  if (membership.member_type === 'user') {
    return <UserRenewalBanner membership={membership} />
  } else if (membership.member_type === 'institution') {
    if (isIAH) {
      return <IAHRenewalBanner membership={membership} />
    } else {
      return <SubaccountRenewalBanner membership={membership} />
    }
  } else {
    return null
  }
}

MembershipRenewalBanner.propTypes = {
  isIAH: PropTypes.bool,
  shouldRemindToRenew: PropTypes.bool,
  membership: PropTypes.shape({
    member_type: PropTypes.string.isRequired
  })
}

export default connect(
  createStructuredSelector({
    membership: currentUserMembershipSelector,
    shouldRemindToRenew: shouldRemindToRenewSelector,
    isIAH: isIAHSelector
  })
)(MembershipRenewalBanner)
