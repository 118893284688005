import { PropTypes } from 'react'
import { reduxForm, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import { get, size } from 'lodash'

import { load, schemas } from 'api'
import { formWizardFactory } from 'form-wizard'
import { withStripeElements } from 'stripe'
import { applyHOCs, selectSlug } from 'helpers'

import { submit } from './actions'
import steps from './steps'
import { FORM_NAME } from '../constants'
import { page, pageLoadSelectors } from 'page'

const SubaccountRegistrationPage = formWizardFactory({
  steps,
  getStepFormName: ({ props, step }) => {
    return `SUBACCOUNT JOIN - ${get(props, 'institution.slug')} - ${get(step, 'name')}`
  },
  completeAction: submit
})

SubaccountRegistrationPage.propTypes = {
  institution: PropTypes.object.isRequired
}

const formValuesSelector = getFormValues(FORM_NAME)

export default applyHOCs([
  reduxForm({ form: FORM_NAME }),
  connect(state => {
    // form values may be initialized from localstorage.
    // in this case, don't set initial values
    if (size(formValuesSelector(state)) > 0) {
      return {}
    } else {
      return {
        initialValues: { newsletter_opt_in: true }
      }
    }
  }),
  withStripeElements(),
  page({
    layout: 'form',
    title: 'Register',
    ...pageLoadSelectors('institution')
  }),
  load({
    institution: {
      urlSelector: (state, props) => {
        return `/v2/institutions/slug/${props.slug}`
      },
      schema: schemas.institution
    }
  }),
  selectSlug({ paramName: 'institutionSlug' })
])(SubaccountRegistrationPage)
