import React from 'react'

import { load, schemas } from 'api'

import { Link } from 'core'
import { ChevronLink } from 'icons'
import { CMSSection } from '../components'

import css from './style.scss'

export const InstitutionList = ({ title, subtitle, institutions = [] }) => {
  return (
    <CMSSection
      title={title}
      subtitle={subtitle}
    >
      <div className={css.institutions}>
        {
          institutions.map((institution, index) => {
            return (
              <p key={index}>
                <Link to={`/institutions/${institution.slug}`}>
                  {institution.name} <ChevronLink color='grey' />
                </Link>
              </p>
            )
          })
        }
      </div>
    </CMSSection>
  )
}

InstitutionList.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  institutions: React.PropTypes.arrayOf(React.PropTypes.shape({
    name: React.PropTypes.string.isRequired,
    slug: React.PropTypes.string.isRequired
  })).isRequired
}

export default load({
  institutions: {
    urlSelector: () => '/v2/institutions',
    schema: [schemas.institution]
  }
})(InstitutionList)
