export const BILLING_ADDRESS_FIELD_NAMES = [
  'address_line_1',
  'address_line_2',
  'city',
  'country',
  'state',
  'postal_code'
]
export const CA_POSTAL_CODE_PATTERN = /^[a-z]\d[a-z][ -]?\d[a-z]\d$/i
export const CA_STATE_NAME = 'Province/Territory'
export const US_POSTAL_CODE_LABEL = 'Zip Code'
export const US_POSTAL_CODE_PATTERN = /^\d{5}?$/
export const US_STATE_NAME = 'State'
