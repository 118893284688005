import React, { PropTypes } from 'react'

import { Button, Heading, CardCollection, Link } from 'core'
import { Grid, GridItem, Section, SectionOverride } from 'layout'

const Curriculum = props => {
  const webinars = props.coreCurriculumWebinars.map(coreCurriculumWebinar => {
    return {
      ...coreCurriculumWebinar.webinar,
      complete: !!coreCurriculumWebinar.is_completed,
      url: `/webinars/${coreCurriculumWebinar.webinar.slug}`
    }
  })

  const year = new Date().getFullYear()

  return (
    <Section mobileNoPadding>
      <SectionOverride>
        <Heading size='h6' weight='light'>
          If you would like the benefit of participating in the live webinars you
          can register for the upcoming {year} webinars or you can decide to watch
          our previous recordings below.
        </Heading>
        <Grid>
          <GridItem col='1/4' tablet1of3 mobile1of2>
            <Link to={{ pathname: '/events', query: { type: 'curriculum' } }}>
              <Button size='secondary'>
                View Upcoming Curriculum
              </Button>
            </Link>
          </GridItem>
        </Grid>
      </SectionOverride>
      <CardCollection col='1/2' collection={webinars} spacing />
    </Section>
  )
}

Curriculum.propTypes = {
  coreCurriculumWebinars: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Curriculum
