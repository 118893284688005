import React from 'react'
import { Grid, GridItem } from 'layout'
import { Link } from 'core'

const ResourceItem = props => {
  const { item } = props
  const center = {
    textAlign: 'center'
  }
  return (
    <Grid>
      <GridItem col='1/3'>{item.media.name}</GridItem>
      <GridItem col='1/3'>{item.media_type}</GridItem>
      <GridItem style={center} col='1/6'>
        <Link target='_blank' to={item.media.url} sans>
          Open
        </Link>
      </GridItem>
      <GridItem style={center} col='1/6'>
        <Link to={item.media.url} download sans>
          Download
        </Link>
      </GridItem>
    </Grid>
  )
}

ResourceItem.propTypes = {
  item: React.PropTypes.object.isRequired
}

export default ResourceItem
