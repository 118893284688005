import { startSubmit, stopSubmit, getFormValues } from 'redux-form'
import { BadRequestError, NotFoundError } from 'api'
import { createClient } from 'auth'
import { handleError } from 'errors'

import { FORM_NAME_REQUEST, FORM_NAME_RESET } from './constants'

export const requestPasswordReset = () => (dispatch, getState) => {
  const state = getState()
  const { email } = getFormValues(FORM_NAME_REQUEST)(state)
  dispatch(startSubmit(FORM_NAME_REQUEST))
  return createClient(state)
    .post('/v2/users/password/new', { body: { email, source: 'ncfdd' } })
    .then(response => response.json())
    .then(body => {
      dispatch(stopSubmit(FORM_NAME_REQUEST))
    })
    .catch(err => {
      dispatch(stopSubmit(FORM_NAME_REQUEST))
      dispatch(handleError(err))
    })
}

export const resetPassword = (token) => (dispatch, getState) => {
  const state = getState()
  const { password } = getFormValues(FORM_NAME_RESET)(state)

  dispatch(startSubmit(FORM_NAME_RESET))
  return createClient(state)
    .patch('/v2/users/password', {
      body: {
        reset_password_token: token,
        password
      }
    })
    .then(response => response.json())
    .then(body => {
      dispatch(stopSubmit(FORM_NAME_RESET))
    })
    .catch(err => {
      const errors = {}
      if (err instanceof BadRequestError) {
        errors._error = 'Your password reset token has expired.'
      } else if (err instanceof NotFoundError) {
        errors._error = 'Your password reset token is invalid.'
      } else {
        errors._error = 'Something went wrong. Check your internet connection and try again.'
      }

      dispatch(stopSubmit(FORM_NAME_RESET, errors))
    })
}
