import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { createSelector, createStructuredSelector } from 'reselect'
import { find } from 'lodash'
import MediaQuery from 'react-responsive'
import { Link } from 'core'

import { currentUserInstitutionIdSelector, currentUserMembershipSelector } from 'auth'
import { load } from 'api'
import { applyHOCs } from 'helpers'
import {
  genderOptionsSelector,
  titleOptionsSelector,
  ethnicityOptionsSelector
} from 'settings'

import GeneralMetrics from './general/GeneralMetrics'
import SessionReservationMetrics from './session-reservations/SessionReservationMetrics'
import BreakdownChart from './BreakdownChart'
import { MetricShape } from './propTypes'
import { PageHead } from 'page'

const getMetricData = (metrics = [], name) => {
  const metric = find(metrics, { name })
  return metric ? metric.data : []
}

export const InstitutionMetricsPage = props => {
  const { stats, membership, genderOptions, titleOptions, ethnicityOptions } = props

  if (!stats) return null

  const { metrics, display_config } = stats // eslint-disable-line camelcase

  const cumulativeDailyLogins = getMetricData(metrics, 'cumulative_daily_logins')
  const subaccountData = getMetricData(metrics, 'sub_accounts')
  const sessionReservationData = getMetricData(metrics, 'session_reservations')
  const dailyLoginData = getMetricData(metrics, 'daily_logins')
  const titleBreakdownData = getMetricData(metrics, 'title_breakdown')
  const genderBreakdownData = getMetricData(metrics, 'gender_breakdown')
  const ethnicityBreakdownData = getMetricData(metrics, 'ethnicity_breakdown')

  const totalSubaccounts = find(subaccountData, { name: 'total' }).value
  const totalDailyLogins = find(dailyLoginData, { name: 'current_membership_range' }).value

  // Display Config data
  const general = display_config.general
  const sessionReservations = display_config.session_reservations
  const titleBreakdown = display_config.title_breakdown
  const ethnicityBreakdown = display_config.ethnicity_breakdown
  const genderBreakdown = display_config.gender_breakdown

  return (
    <div>
      <PageHead title='Institution Metrics' />
      {general &&
        <GeneralMetrics
          membership={membership}
          totalSubaccounts={totalSubaccounts}
          totalDailyLogins={totalDailyLogins}
          cumulativeDailyLoginData={cumulativeDailyLogins}
        />}

      {sessionReservations &&
        <SessionReservationMetrics data={sessionReservationData} />}

      <MediaQuery minWidth='658px'>
        {titleBreakdown &&
          <BreakdownChart
            title='Title Breakdown'
            data={titleBreakdownData}
            options={titleOptions}
          />}

        {genderBreakdown &&
          <BreakdownChart
            title='Gender Breakdown'
            data={genderBreakdownData}
            options={genderOptions}
          />}

        {ethnicityBreakdown &&
          <BreakdownChart
            title='Ethnicity Breakdown'
            data={ethnicityBreakdownData}
            options={ethnicityOptions}
          />}
      </MediaQuery>
      <p> Would you like more information?
        <Link to='/contact-us'> <span style={{ color: '#0117DE', textDecoration: 'underline' }}> Reach out to us.</span></Link>
      </p>
    </div>
  )
}

const optionsShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
})

InstitutionMetricsPage.propTypes = {
  genderOptions: PropTypes.arrayOf(optionsShape).isRequired,
  titleOptions: PropTypes.arrayOf(optionsShape).isRequired,
  stats: PropTypes.shape({
    metrics: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(MetricShape).isRequired
    })).isRequired
  })
}

export default applyHOCs([
  connect(
    createStructuredSelector({
      membership: currentUserMembershipSelector,
      genderOptions: genderOptionsSelector,
      titleOptions: titleOptionsSelector,
      ethnicityOptions: ethnicityOptionsSelector
    })
  ),
  load({
    stats: {
      urlSelector: createSelector(
        currentUserInstitutionIdSelector,
        institutionId => `/stats/institutions/${institutionId}`
      )
    }
  })
])(InstitutionMetricsPage)
