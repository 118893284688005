import React, { PropTypes } from 'react'
import ReactSelect from 'react-select'
import 'react-select/dist/react-select.css'
import css from './style.scss'
import { SearchIcon } from 'icons'
import { InputWrapper } from 'form/InputWrapper'

const renderIcon = () => <SearchIcon className={css.searchIcon} />

const SearchSelect = (props) => {
  const { meta, hideMeta, ...otherProps } = props
  return (
    <div className={css.wrapper}>
      <InputWrapper hideMeta={hideMeta} {...meta}>
        <ReactSelect
          arrowRenderer={renderIcon}
          {...otherProps}
        />
      </InputWrapper>
    </div>
  )
}

export default SearchSelect

SearchSelect.propTypes = {
  // * redux-form meta object
  meta: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  // * react-select props
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  simpleValue: PropTypes.bool
}
