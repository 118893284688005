import React, { PropTypes } from 'react'
import { d3, LineChart } from '@thomasdashney/react-d3-components'
import moment from 'moment'

import MetricsTitle from '../components/MetricsTitle'
import css from './style.scss'

const label = 'Total Active Sessions'

const WIDTH = 480
const LEFT = 80
const RIGHT = 20

const CumulativeLoginChart = ({ data }) => {
  if (data.length < 30) return null

  const dataPoints = data.map(({ name, value }) => ({
    x: new Date(name),
    y: value
  }))

  const xScale = d3.time.scale().domain([
    dataPoints[0].x,
    dataPoints[dataPoints.length - 1].x
  ]).range([0, WIDTH - 100])

  return (
    <div className={css.chartWrapper}>
      <MetricsTitle>
        {label}
      </MetricsTitle>
      <div className={css.chart}>
        <LineChart
          data={{
            label,
            values: dataPoints
          }}
          width={WIDTH}
          height={400}
          margin={{ top: 30, bottom: 40, left: LEFT, right: RIGHT }}
          xScale={xScale}
          xAxis={{
            label: 'Time',
            tickValues: xScale.ticks(d3.time.months, 1),
            tickFormat: date => moment(date).format('MMM'),
            tickPadding: 10
          }}
          yAxis={{
            label,
            tickPadding: 5
          }}
        />
      </div>
    </div>
  )
}

CumulativeLoginChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  })).isRequired
}

export default CumulativeLoginChart
