import '@babel/polyfill'
import React from 'react'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import App from './App'
import ga4 from 'analytics/ga4'

import store from './store'
import { Helmet } from 'react-helmet'

let lastPath = null
let lastPathWithParams = null

const onHelmetChange = ({ metaTags, title }) => {
  if (
    typeof window !== 'undefined' &&
    lastPath !== window.location.pathname &&
    metaTags.filter(m => m.name === 'page-loaded' && m.content === 'true').length > 0
  ) {
    ga4.send({
      hitType: 'pageview',
      page_title: title,
      page_referrer: lastPathWithParams,
      page_location: window.location.href.split('#')[0],
      update: true
    })
    lastPath = window.location.pathname
    lastPathWithParams = window.location.href.split('#')[0]
  }
}
function renderApp (AppComponent) {
  render(
    <AppContainer>
      <div>
        <Helmet onChangeClientState={onHelmetChange}>
          {/* used to track when other react-helmet header changes finish */}
          <meta name='page-loaded' content='false' />
        </Helmet>
        <AppComponent store={store} />
      </div>
    </AppContainer>,
    document.getElementById('app')
  )
}

renderApp(App)

if (module.hot) {
  module.hot.accept('./App', () => {
    renderApp(require('./App').default)
  })
}
