import React from 'react'

import { Card } from '../Card'

import css from './style.scss'

const MondayMotivatorAside = () => (
  <aside className={css.aside}>
    <Card>
      <div className={css.note}>
        <p className={css.noteText}>
          The Monday Motivator is a weekly e-mail message that provides
          an electronic dose of positive energy, good vibes, and a weekly
          productivity tip. There are 48 Monday Motivators per year and
          they repeat annually. The purpose of the weekly message is to
          reinforce the ideas presented in our Core Curriculum webinars
          and provide support for individuals who are making the transition
          from graduate student to professor. Our core curriculum centers
          on four main concepts: strategic planning, explosive productivity,
          work-life balance, and healthy relationships.
        </p>
      </div>
    </Card>
  </aside>
)

export default MondayMotivatorAside
