import React, { PropTypes } from 'react'

import { load, schemas } from 'api'
import { applyHOCs, selectSlug } from 'helpers'
import { page, pageLoadSelectors, PageHead } from 'page'

import FSPLandingPage from './fsp/FSPLandingPage'
import GenericLandingPage from './generic/GenericLandingPage'
import NonFSPLandingPage from './non-fsp/NonFSPLandingPage'

export const SessionSalePage = (props) => {
  const { sessionSale } = props

  let content = null
  if (sessionSale.type === 'fsp') {
    content = <FSPLandingPage {...props} />
  } else if (sessionSale.type === 'generic') {
    content = <GenericLandingPage {...props} />
  } else {
    content = <NonFSPLandingPage {...props} />
  }

  return (
    <div>
      <PageHead title={sessionSale.title} />
      {content}
    </div>
  )
}

SessionSalePage.propTypes = {
  sessionSale: PropTypes.shape({
    type: PropTypes.string.isRequired
  }).isRequired
}

export default applyHOCs([
  page({
    ...pageLoadSelectors('sessionSale'),
    noPageHead: true,
    pageType: 'session sale'
  }),
  load({
    sessionSale: {
      urlSelector: (state, props) => {
        return `/v2/session_sales/slug/${props.slug}`
      },
      schema: schemas.sessionSale
    }
  }),
  selectSlug()
])(SessionSalePage)
