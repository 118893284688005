import React from 'react'

import { Banner, HeaderSecondary } from 'page-blocks'

export const InstitutionMembershipExpiredPage = ({ institution, isIAH }) => {
  return (
    <div>
      <HeaderSecondary
        title='Your membership is inactive'
        subtitle='A valid membership is required to view members-only content'
      />
      <Banner
        copy='Contact us in order to activate your membership'
        cta_url='/contact-us'
        cta_label='Contact us'
      />
    </div>
  )
}

export default InstitutionMembershipExpiredPage
