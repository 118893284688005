import React from 'react'

import { Section } from '../Section'

import css from './style.scss'

const PageWithSidebar = (props) => (
  <div className={css.page}>
    <Section mobileNoPadding>
      {props.children}
    </Section>
  </div>
)

export default PageWithSidebar
