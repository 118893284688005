import React from 'react'

import MembershipTypeForm from './MembershipTypeForm'
import { PageHead } from 'page/PageHead'

export const MembershipTypePage = () => {
  return (
    <div>
      <PageHead title='Membership Type' />
      <MembershipTypeForm />
    </div>
  )
}

export default MembershipTypePage
