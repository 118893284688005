import { get, pick } from 'lodash'
import { getFormValues } from 'redux-form'
import { createSelector } from 'reselect'

import {
  INVOICE_PAYMENT_FIELD_NAMES
} from './constants'

export const isInstitutionOrderSelector = (state, { order }) => {
  return get(order, 'account.type') === 'institution'
}

export const institutionAddressSelector = (state, { institution }) => {
  return pick(institution, [
    'address_line_1',
    'address_line_2',
    'city',
    'state',
    'postal_code',
    'country'
  ])
}

export const paymentAddressSelector = form => createSelector(
  getFormValues(form),
  (formValues) => ({
    ...pick(formValues, INVOICE_PAYMENT_FIELD_NAMES)
  })
)
