import React, { PropTypes } from 'react'
import { withRouter } from 'react-router'
import ui from 'redux-ui'
import { isArray } from 'lodash'

import { Heading, Link } from 'core'
import { PlusIcon, MinusIcon } from 'icons'

import { FormSection } from 'form'

import css from './style.scss'

export const RegistrationReviewSection = (props) => {
  const ToggleIcon = props.ui.isOpen ? MinusIcon : PlusIcon
  const { router: { location } } = props

  return (
    <div>
      <div className={css.reviewHeader}>
        <FormSection>
          <div className={css.reviewHeading}>
            <Heading size='h6' noMargin thin>
              {props.title} {props.stepNum &&
              (
                <Link
                  className={css.reviewEditLink}
                  to={{
                    pathname: location.pathname,
                    query: Object.assign({}, location.query, { step: props.stepNum })
                  }}
                  type='text'
                >
                  (edit)
                </Link>
              )}
            </Heading>
          </div>
          <button
            className={css.reviewToggle}
            type='button'
            onClick={() => props.updateUI({ isOpen: !props.ui.isOpen })}
            alt='Open activation menu'
          >
            <ToggleIcon className={css.reviewToggleIcon} />
          </button>
        </FormSection>
      </div>
      {props.ui.isOpen &&
        <FormSection grey>
          <table className={css.reviewTable}>
            <tbody>
              {props.fields.map(({ name, value }) => (
                <tr key={name}>
                  <td><strong>{name}</strong></td>
                  <td>
                    {isArray(value)
                      ? (
                        <ul>
                          {value.map((v, index) =>
                            <li key={index}>{v}</li>
                          )}
                        </ul>
                        )
                      : value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </FormSection>}
    </div>
  )
}

RegistrationReviewSection.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.node
  })),
  router: PropTypes.object.isRequired,
  stepNum: PropTypes.number,
  ui: PropTypes.object.isRequired,
  updateUI: PropTypes.func.isRequired
}

export default ui({
  state: {
    isOpen: true
  }
})(withRouter(RegistrationReviewSection))
