import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'

import { load, schemas } from 'api'
import { currentUserSelector, currentUserMembershipSelector } from 'auth'
import { Select, validators } from 'form'
import { Button } from 'core'
import { applyHOCs } from 'helpers'
import { Grid, GridItem } from 'layout'

import { MembershipPlanPricing } from '../../page-blocks/MembershipPlanPricing'

import { MEMBERSHIP_TYPE_FORM } from './constants'
import { submitSwitchToInstitution } from './actions'
import css from './style.scss'

export const MembershipTypeForm = props => {
  const {
    submitSwitchToInstitution,
    institutions,
    membership,
    user,
    loadStatus
  } = props

  if (loadStatus.institutions.loading || !institutions) {
    return null
  }

  if (membership.member_type === 'user') {
    // If not able to join an institution, don't een show it
    return (
      <form
        onSubmit={e => {
          e.preventDefault()
          submitSwitchToInstitution()
        }}
      >
        <div>
          <p className={css.secondaryExplainer}>
            You are currently on an individual membership. Select an institution from the
            list below to link your account to that active institution's membership.
          </p>
          <Grid>
            <GridItem col='1/1'>
              <Grid>
                <GridItem col='1/3'>
                  <Field
                    name='institution'
                    placeholder='Select an institution'
                    component={Select}
                    validate={validators.required('Please select an institution to link your account.')}
                    options={institutions.map((institution) => {
                      return {
                        name: institution.name,
                        value: institution.id
                      }
                    }
                    )}
                  />
                  <Button
                    type='submit'
                    size='primary'
                    noMargin
                  >
                    Join
                  </Button>
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
        </div>
      </form>
    )
  }

  return (
    <div>
      <p className={css.secondaryExplainer}>
        You are currently a member of {JSON.stringify(user.institution_relation.institution_name)},
        would you like to switch to an individual membership?
      </p>
      <Grid>
        <GridItem col='1/1'>
          <Grid>
            <GridItem col='1/1'>
              <MembershipPlanPricing
                embedded
              />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </div>
  )
}

MembershipTypeForm.propTypes = {
  // load
  institutions: PropTypes.array,
  loadStatus: PropTypes.object.isRequired,
  // connect
  initialValues: PropTypes.object,
  // redux-form
  submitSucceeded: PropTypes.bool
}

export default applyHOCs([
  reduxForm({
    form: MEMBERSHIP_TYPE_FORM,
    enableReinitialize: true
  }),
  connect(
    createStructuredSelector({
      membership: currentUserMembershipSelector,
      user: currentUserSelector
    }), { submitSwitchToInstitution }),
  load({
    institutions: {
      urlSelector: () => '/v2/users/current/available-institutions',
      schema: [schemas.institution]
    }
  })
])(MembershipTypeForm)
