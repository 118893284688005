import React from 'react'
import MediaQuery from 'react-responsive'

import { Select } from 'form'

import { breakpoints } from 'styles'

const { phabletMax, phabletMaxPlusOne } = breakpoints

const WebinarCategories = (props) => {
  const { categories, activeCategory, onCategoryUpdated } = props
  const categoryOptions = categories.map(category => ({ name: category.name, value: category.id }))
  const input = {
    value: activeCategory,
    onChange: onCategoryUpdated
  }

  return (
    <div>
      <MediaQuery maxWidth={phabletMax}>
        <Select
          grey
          name='category'
          options={categoryOptions}
          placeholder='Filter by Category'
          input={input}
        />
      </MediaQuery>
      <MediaQuery minWidth={phabletMaxPlusOne}>
        <Select
          name='category'
          options={categoryOptions}
          placeholder='Filter by Category'
          input={input}
        />
      </MediaQuery>
    </div>
  )
}

WebinarCategories.propTypes = {
  categories: React.PropTypes.arrayOf(React.PropTypes.shape({
    id: React.PropTypes.number.isRequired,
    name: React.PropTypes.string.isRequired
  })).isRequired,
  activeCategory: React.PropTypes.number,
  onCategoryUpdated: React.PropTypes.func.isRequired
}

export default WebinarCategories
