import React from 'react'
import MediaQuery from 'react-responsive'

import { Grid, GridItem, Section } from 'layout'

import { Pagination } from '../Pagination'

import MondayMotivatorAside from './MondayMotivatorAside'
import PostSingle from './PostSingle'

import css from './style.scss'
import { breakpoints } from 'styles'

const { laptopMin } = breakpoints

const PostCollection = (props) => {
  const { collection, mondayMotivator, buildUrl } = props

  const motivator = (
    <GridItem col='1/3' tablet1of1 mobile1of1>
      <MondayMotivatorAside />
    </GridItem>
  )
  return (
    <Section>
      <div className={css.wrapper}>
        <Grid middle>
          {
            mondayMotivator &&
              <MediaQuery minWidth={laptopMin}>
                {motivator}
              </MediaQuery>
          }
          <GridItem col='2/3' tablet1of1 mobile1of1>
            {
              collection.map((single) => (
                <PostSingle
                  url={buildUrl(single)}
                  key={single.id}
                  id={single.id}
                  title={single.title}
                  plaintextBody={single.plaintext_body}
                  mondayMotivator={mondayMotivator}
                  date={single.publish_date}
                  excerpt={single.excerpt}
                  type={single.type}
                  author={single.author}
                />
              ))
            }
            <Pagination pages={props.pages} />
          </GridItem>
          {
            mondayMotivator &&
              <MediaQuery maxWidth={laptopMin}>
                {motivator}
              </MediaQuery>
          }
        </Grid>
      </div>
    </Section>
  )
}

PostCollection.propTypes = {
  collection: React.PropTypes.arrayOf(React.PropTypes.shape({
    title: React.PropTypes.string,
    publish_date: React.PropTypes.string,
    excerpt: React.PropTypes.string,
    author: React.PropTypes.object
  })).isRequired,
  mondayMotivator: React.PropTypes.bool,
  buildUrl: React.PropTypes.func.isRequired
}

export default PostCollection
