import React from 'react'
import { ErrorPage } from 'error-page'
import { Heading } from 'core'
import { Page } from 'page'

const PaymentCompletePage = () => (
  <Page>
    <ErrorPage>
      <Heading size='h3' weight='semibold' center>
        Your payment has been processed successfully!
      </Heading>
      <Heading size='h6' weight='semibold' center>
        You'll receive an email receipt as confirmation for your records.
        Should you have any additional questions please send us an email at <a href='mailto: membership@ncfdd.org'>membership@ncfdd.org</a>
      </Heading>
    </ErrorPage>
  </Page>
)

export default PaymentCompletePage
