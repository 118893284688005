import React from 'react'
import { connect } from 'react-redux'

import { currentUserSelector } from 'auth'
import { NotificationBanner } from './components/NotificationBanner'
import { requestConfirmationEmail } from './actions'

const UnconfirmedEmailNotification = (props) => {
  let bannerMsg
  const buttonOptions = {
    children: props.emailSent ? 'Email has been sent' : 'Resend Email Confirmation',
    size: 'primary',
    color: 'green',
    noMargin: true,
    disabled: props.loading || props.emailSent,
    onClick: props.requestConfirmationEmail
  }

  if (props.user && !props.user.confirmed) {
    bannerMsg = `Email activation is required. An email has been sent to ${props.user.email}, please follow the instructions in the verification email to get access to our content.`
  } else {
    return null
  }

  return (
    <NotificationBanner
      message={bannerMsg}
      buttonOptions={buttonOptions}
    />
  )
}

UnconfirmedEmailNotification.propTypes = {
  user: React.PropTypes.object
}

const mapStateToProps = (state) => ({
  user: currentUserSelector(state),
  loading: state.dashboard.emailConfirmation.loading,
  emailSent: state.dashboard.emailConfirmation.emailSent
})

export default connect(mapStateToProps, { requestConfirmationEmail })(
  UnconfirmedEmailNotification
)
