import React from 'react'

import { Heading } from 'core'
import { Form, FormSection } from 'form'
import { OrderSummaryTable, OrderSummaryPropTypes, DiscountCodeForm } from 'order-summary'
import { Spacer } from 'layout'
import { PaymentForm } from 'stripe'

import { FORM_NAME } from '../../constants'
import ContinueSection from '../../components/ContinueSection'

const Payment = (props) => {
  const { membershipPlan: plan, orderSummary } = props

  /* eslint-disable react/jsx-handler-names */
  return (
    <Form name={props.getStepFormName && props.getStepFormName()} onSubmit={props.continue}>
      {plan &&
        <FormSection>
          <Heading size='h6'>Order Summary</Heading>
          <OrderSummaryTable {...orderSummary} />
          <Spacer size='xs' />
          <DiscountCodeForm
            parentForm={FORM_NAME}
            productId={plan.product_id}
          />
        </FormSection>}
      {orderSummary.total > 0 && (
        <FormSection>
          <PaymentForm form={FORM_NAME} stripeElements={props.stripeElements} />
        </FormSection>
      )}
      <ContinueSection {...props} />
    </Form>
  )
}

Payment.propTypes = {
  membershipPlan: React.PropTypes.object.isRequired,
  stripeElements: React.PropTypes.object.isRequired,
  orderSummary: React.PropTypes.shape(OrderSummaryPropTypes).isRequired
}

export default Payment
