import Immutable from 'seamless-immutable'
import { values } from 'lodash'
import { filterActions } from 'redux-ignore'

import * as types from './types'
const { OPEN_NAVIGATION, CLOSE_NAVIGATION } = types

const initialState = Immutable({
  navIsOpen: false
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NAVIGATION:
      return state.set('navIsOpen', true)
    case CLOSE_NAVIGATION:
      return state.set('navIsOpen', false)
    default:
      return state
  }
}

export default filterActions(reducer, values(types))
