import React from 'react'

import { Grid, GridItem } from 'layout'
import { CMSSection } from '../components'

import TeamMember from './TeamMember'

const Team = (props) => {
  const { title, subtitle, team_members: teamMembers } = props

  return (
    <CMSSection
      title={title}
      subtitle={subtitle}
    >
      <Grid stretch>
        {
          teamMembers.map((teamMember, index) => {
            return (
              <GridItem key={index} col='1/4' tablet1of3 phablet1of2 mobile1of1>
                <TeamMember {...teamMember} />
              </GridItem>
            )
          })
        }
      </Grid>
    </CMSSection>
  )
}

Team.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  team_members: React.PropTypes.arrayOf(React.PropTypes.shape({
    avatar_url: React.PropTypes.string,
    name: React.PropTypes.string.isRequired,
    title: React.PropTypes.string,
    university: React.PropTypes.string,
    about_me: React.PropTypes.string
  })).isRequired
}

export default Team
