import React, { PropTypes } from 'react'
import { connect } from 'react-redux'

import { Grid, GridItem } from 'layout'
import { Heading } from 'core'
import { Form, FormSection } from 'form'
import css from './style.scss'

import { validateAndContinue } from './actions'
import * as fields from './fields'
import ContinueSection from '../../components/ContinueSection'

const defaultWelcomeTitle = 'Welcome to NCFDD!'

const defaultWelcomeText = `
  Now we need to collect some basic information, nothing fancy, just a few
  things to fill out your profile and help us get to know you.
`

/* eslint-disable react/jsx-handler-names */
const Account = props => (
  <Form name={props.getStepFormName && props.getStepFormName()} onSubmit={props.validateAndContinue}>
    <FormSection>
      <p className={css.nearlyThere}>
        <strong>
          {props.welcomeTitle || defaultWelcomeTitle}
        </strong>
      </p>
      <p className={css.nearlyThereText}>
        {props.welcomeText || defaultWelcomeText}
      </p>
    </FormSection>
    <FormSection>
      <Heading size='h6'>Account Information</Heading>
      <Grid>
        <GridItem col='2/5' tablet1of1 mobile1of1>
          <fields.firstName.FieldGroup />
        </GridItem>
        <GridItem col='2/5' tablet1of1 mobile1of1>
          <fields.lastName.FieldGroup />
        </GridItem>
        <GridItem col='1/5' tablet1of1 mobile1of1>
          <fields.postNominalLetters.FieldGroup />
        </GridItem>
        <GridItem col='1/1'>
          <fields.nickname.FieldGroup />
        </GridItem>
        {!props.currentUser && (
          <GridItem col='1/1'>
            <fields.email.FieldGroup />
          </GridItem>
        )}
        {!props.currentUser && (
          <GridItem col='1/1'>
            <fields.emailConfirmation.FieldGroup />
          </GridItem>
        )}
        <GridItem col='1/1'>
          <fields.phoneNumber.FieldGroup />
        </GridItem>
        {!props.currentUser && (
          <GridItem col='1/1'>
            <fields.password.FieldGroup />
          </GridItem>
        )}
        {!props.currentUser && (
          <GridItem col='1/1'>
            <fields.passwordConfirmation.FieldGroup />
          </GridItem>
        )}
      </Grid>
    </FormSection>
    <ContinueSection {...props} />
  </Form>
)

Account.propTypes = {
  welcomeTitle: PropTypes.string,
  welcomeText: PropTypes.string
}

export default connect(
  null,
  (dispatch, props) => ({
    validateAndContinue: () => dispatch(validateAndContinue(props))
  })
)(Account)
