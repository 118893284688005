import { createClient } from 'auth'
import {
  SEND_CONFIRMATION_EMAIL_REQUEST,
  SEND_CONFIRMATION_EMAIL_SUCCESS,
  SEND_CONFIRMATION_EMAIL_FAIL
} from './types'
import { handleError } from 'errors'

export const requestConfirmationEmail = () => (dispatch, getState) => {
  dispatch({ type: SEND_CONFIRMATION_EMAIL_REQUEST })
  return createClient(getState())
    .post('/v2/users/email/confirmation')
    .then((response) => response.json())
    .then((data) => {
      dispatch({
        type: SEND_CONFIRMATION_EMAIL_SUCCESS
      })
    })
    .catch((error) => {
      dispatch({ type: SEND_CONFIRMATION_EMAIL_FAIL })
      dispatch(handleError(error))
    })
}
