import React from 'react'
import { get, compact } from 'lodash'

import css from './style.scss'

/**
 * The `<Avatar />` component can be used to generate a user's avatar.
 * If no avatar_url exists, the component will generate an element w/ the user's
 * first letter centered in a bkg-filled circle.
 */
const Avatar = (props) => {
  let src = get(props, 'user.avatar_url', '')
  const name = get(props, 'user.name', '')

  if (src === '/missing_w100h100.png') {
    src = null
  }

  const classes = [
    css.wrapper,
    props.size ? css[props.size] : css.sm,
    src ? '' : css.bgFill,
    props.lightFill ? css.lightFill : ''
  ]

  const style = {
    backgroundImage: `url('${src}')`
  }

  return (
    <div className={compact(classes).join(' ')}>
      {
        src &&
          <div className={css.avatar} style={style} />
      }
      {
        !src &&
          <span className={css.letter}>
            {name && name.charAt(0)}
          </span>
      }
    </div>
  )
}

Avatar.propTypes = {
  /**
   * The user object to pass w/ name and avatar_url (optional) passed
   */
  user: React.PropTypes.shape({
    name: React.PropTypes.string,
    avatar_url: React.PropTypes.string
  }).isRequired,
  /**
   * Available values: xs, sm, md, lg, lg-fixed, xl, avatarUpload, teamCustom
   */
  size: React.PropTypes.string,
  lightFill: React.PropTypes.bool
}

export default Avatar
