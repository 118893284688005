import { isInteger } from 'lodash'

export default (page = 1, limit, queries = {}) => {
  page = parseInt(page, 10)

  if (!isInteger(page) || page < 1) {
    page = 1
  }

  return {
    ...queries,
    limit,
    offset: ((page - 1) * limit)
  }
}
