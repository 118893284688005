import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import {
  isInvalid as isInvalidSelector,
  isSubmitting as isSubmittingSelector
} from 'redux-form'
import { createSelector } from 'reselect'

import { Button } from 'core'
import { Grid, GridItem } from 'layout'

import { editForm, closeForm } from './actions'
import { activeFormSelector } from './selectors'

const buttonProps = {
  size: 'secondary',
  noMargin: true
}

/**
 * Factory to create a FormGroupButtons component
 */

export const formGroupButtons = ({ form: FORM_NAME }) => {
  const FormGroupButtons = ({ editing, invalid, requestEdit, editingOtherForm, cancel, submitting }) => {
    return (
      <Grid>
        <GridItem col='1/2'>
          {editing &&
            <Button
              {...buttonProps}
              type='submit'
              disabled={invalid || submitting}
              isLoading={submitting}
            >
              Save
            </Button>}
          {!editing &&
            <Button
              {...buttonProps}
              type='button'
              onClick={requestEdit}
              disabled={editingOtherForm}
            >
              Edit
            </Button>}
        </GridItem>
        <GridItem col='1/2'>
          {editing &&
            <Button
              {...buttonProps}
              onClick={cancel}
              outline
            >
              Cancel
            </Button>}
        </GridItem>
      </Grid>
    )
  }

  FormGroupButtons.propTypes = {
    editing: PropTypes.bool,
    editingOtherForm: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    requestEdit: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired
  }

  return FormGroupButtons
}

export default (options = {}) => {
  const { form: FORM_NAME } = options

  return connect(
    createSelector(
      activeFormSelector,
      isInvalidSelector(FORM_NAME),
      isSubmittingSelector(FORM_NAME),
      (activeForm, invalid, submitting) => ({
        invalid,
        submitting,
        editing: activeForm === FORM_NAME,
        editingOtherForm: activeForm !== FORM_NAME && activeForm !== null
      })
    ),
    (dispatch, props) => ({
      requestEdit: () => dispatch(editForm(FORM_NAME)),
      cancel: () => dispatch(closeForm(FORM_NAME))
    })
  )(formGroupButtons(options))
}
