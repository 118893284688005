import React from 'react'
import { compact } from 'lodash'

import css from './style.scss'

/**
 * The <Heading /> component generates a heading component,
 * and maps to HTML heading elements, <h1> through <h6>
 */
const Heading = (props) => {
  const classes = [
    css.heading,
    props.freight ? css.freight : '',
    props.weight ? css[props.weight] : '',
    props.noMargin ? css.noMargin : '',
    props.center ? css.center : '',
    props.color ? css[props.color] : '',
    props.underline ? css.underline : ''
  ]

  return React.createElement(
    props.size,
    { className: compact(classes).join(' ') },
    props.children
  )
}

Heading.defaultProps = {
  size: 'h1'
}

Heading.propTypes = {
  children: React.PropTypes.node.isRequired,
  /* Size is specified as specific heading element, e.g., "h1" - "h6". The default is h1. */
  size: React.PropTypes.string,
  /**
   * If true, uses freight font family
   */
  freight: React.PropTypes.bool,
  /**
   * Available values: light (300), medium (500), semibold (600)
   */
  weight: React.PropTypes.string,
  /**
   * If true, sets margin-bottom: 0px
   */
  noMargin: React.PropTypes.bool,
  /**
   * If true, centers text
   */
  center: React.PropTypes.bool,
  /**
   * Available values: white, black
   */
  color: React.PropTypes.string,
  /**
   * If true, sets text-decoration: underline
   */
  underline: React.PropTypes.bool
}

export default Heading
