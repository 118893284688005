import { ForbiddenError, NotFoundError } from 'api'

/**
 * Generates opinionated pageWrapper selector params for api data
 * passed by load hoc
 */

export default (loadKeys) => {
  if (!Array.isArray(loadKeys)) {
    loadKeys = [loadKeys]
  }

  return {
    forbiddenSelector: (state, { loadStatus }) => {
      return loadKeys.some(key => loadStatus[key].error instanceof ForbiddenError)
    },
    loadingSelector: (state, { loadStatus }) => {
      return loadKeys.some(key => loadStatus[key].loading)
    },
    notFoundSelector: (state, { loadStatus }) => {
      return loadKeys.some(key => loadStatus[key].error instanceof NotFoundError)
    },
    unknownErrorSelector: (state, { loadStatus }) => {
      return loadKeys.some(key => loadStatus[key].error)
    }
  }
}
