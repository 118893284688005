import React from 'react'
import { connect } from 'react-redux'

import { currentUserSelector } from 'auth'
import { ncfddLogoOnDark } from 'images'
import { Section, Spacer } from 'layout'

import css from './style.scss'

import {
  FooterContactDetails,
  FooterContactUs,
  FooterSocial,
  FooterSubscribe
} from './components'

export const Footer = ({ user }) => (
  <div className={user ? css.authenticated : ''}>
    <div className={css.spacer} />
    <div className={css.footer}>
      <div className={css.footerTop}>
        <Section>
          <FooterContactUs />
          <Spacer size='xs' />
          {!user && (
            <div>
              <FooterSubscribe />
            </div>
          )}
        </Section>
      </div>
      <div className={css.footerBottom}>
        <Section>
          <div className={css.footerBottomGrid}>
            <div>
              <img className={css.logo} src={ncfddLogoOnDark} alt='NCFDD Logo' />
            </div>
            <div>
              <FooterContactDetails />
            </div>
            <div>
              <FooterSocial />
            </div>
          </div>
        </Section>
      </div>
    </div>
  </div>
)

Footer.propTypes = {
  user: React.PropTypes.object
}

export default connect(state => ({
  user: currentUserSelector(state)
}))(Footer)
