import React from 'react'

import {
  SuccessModal,
  ModalHeading,
  ModalSubheading
} from 'modal'
import DownloadInvoice from '../../account/components/DownloadInvoice'

const FSPMessage = () => (
  <div>
    <p>
      We are so excited that you will be joining us in the Faculty Success Program.
    </p>
    <p>
      Please be sure to add <a href='mailto:FSP@ncfdd.org'>FSP@ncfdd.org</a> to your address book to ensure that you receive our emails, and keep an eye out for our FSP Intake Survey closer to the start of the program.
    </p>
    <p>
      If you have any questions or concerns related to the program, please feel free to reach out to <a href='mailto:FSP@ncfdd.org'>FSP@ncfdd.org</a>.
    </p>
  </div>
)

const GenericMessage = ({ sessionSale }) => (
  <div>
    <p>
      We are so excited that you will be joining us in the {sessionSale.title}.
    </p>
    <p>
      Please be sure to add <a href='mailto:FSP@ncfdd.org'>FSP@ncfdd.org</a> to your address book to ensure that you receive our emails, and keep an eye out for more information from us before the program officially begins.
    </p>
    <p>
      If you have any questions or concerns related to the program, please feel free to reach out to <a href='mailto:FSP@ncfdd.org'>FSP@ncfdd.org</a>.
    </p>
  </div>
)

const IndividualReserveConfirmModal = ({ sessionSale, order, invoiceRequested }) => {
  const title = sessionSale.type === 'fsp'
    ? 'FSP Program'
    : sessionSale.title

  const message = sessionSale.type === 'fsp'
    ? <FSPMessage />
    : <GenericMessage sessionSale={sessionSale} />

  return (
    <SuccessModal>
      <ModalHeading>
        Thank you for joining the {title}
      </ModalHeading>

      <ModalSubheading>What happens next?</ModalSubheading>
      {message}
      {invoiceRequested
        ? <p>Invoice requested: <DownloadInvoice orderId={order.id} /></p>
        : null}
    </SuccessModal>
  )
}

export default IndividualReserveConfirmModal
