import { apiResponse, schemas } from 'api'
import { createClient } from 'auth'
import { catchError } from 'errors'

import {
  JOIN_CHAT_ROOM,
  LEAVE_CHAT_ROOM,
  LOAD_CHAT_MESSAGES,
  CREATE_CHAT_MESSAGE
} from './types'

export const joinRoom = (room) => (dispatch, getState) => {
  dispatch({ type: JOIN_CHAT_ROOM, room })

  return createClient(getState())
    .get('/v2/chat_messages', { query: { filters: { room } } })
    .then((response) => response.json())
    .then((chatMessages) => {
      dispatch(apiResponse({
        type: LOAD_CHAT_MESSAGES,
        schema: [schemas.chatMessage],
        data: chatMessages,
        room
      }))
    })
    .catch(catchError(dispatch))
}

export const leaveRoom = (room) => (dispatch) => {
  dispatch({ type: LEAVE_CHAT_ROOM, room })
}

export const postMessage = (messageBody, room) => (dispatch, getState) => {
  return createClient(getState())
    .post('/v2/chat_messages', {
      body: {
        room,
        body: messageBody
      }
    })
    .then((response) => response.json())
    .then((chatMessage) => {
      dispatch({
        type: CREATE_CHAT_MESSAGE,
        chatMessage
      })
    })
    .catch(catchError(dispatch))
}
