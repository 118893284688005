import { pick } from 'lodash'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { currentUserSelector } from 'auth'
import { formWizardFactory } from 'form-wizard'
import { applyHOCs } from 'helpers'
import { page } from 'page'

import { submit } from './actions'
import steps from './steps'
import { FORM_NAME } from '../constants'

const CompleteProfilePage = formWizardFactory({
  steps,
  getStepFormName: ({ step }) => {
    return step ? `COMPLETE YOUR PROFILE - ${step.name}` : 'COMPLETE YOUR PROFILE'
  },
  completeAction: submit,
  customProps: {
    welcomeTitle: 'We\'re delighted to have you as a part of NCFDD!',
    welcomeText: 'To support all of the features within our platform, we wanted to take a moment to confirm your information is accurate and up to date.'
  }
})

export default applyHOCs([
  reduxForm({ form: FORM_NAME }),
  connect(state => {
    const currentUser = currentUserSelector(state)

    if (!currentUser) return {}

    return {
      initialValues: {
        ...pick(currentUser, [
          'first_name',
          'last_name',
          'post_nominal_letters',
          'nickname',
          'phone_number',
          'title',
          'flags',
          'discipline',
          'discipline_classification',
          'university',
          'department'
        ]),
        ethnicities: currentUser.ethnicities || [],
        gender: currentUser.gender || [],
        newsletter_opt_in: true
      },
      currentUser
    }
  }),
  page({
    title: 'Complete Your Profile',
    layout: 'form'
  })
])(CompleteProfilePage)
