import Immutable from 'seamless-immutable'
import { values } from 'lodash'
import { filterActions } from 'redux-ignore'
import * as types from './types'
const {
  UNHANDLED_ERROR,
  DISMISS_UNHANDLED_ERROR
} = types

const initialState = Immutable({
  unhandledError: false
})

const DEFAULT_MESSAGE = 'Something went wrong. Try again.'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UNHANDLED_ERROR:
      return state.set('unhandledError', action.message || DEFAULT_MESSAGE)
    case DISMISS_UNHANDLED_ERROR:
      return state.set('unhandledError', false)
    default:
      return state
  }
}

export default filterActions(reducer, values(types))
