import { combineReducers } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import { reducer as formReducer } from 'redux-form'
import { routerReducer as routing } from 'react-router-redux'
import { reducer as ui } from 'redux-ui'

/* Form name imports for reduxForm.actionTypes.CHANGE listeners */
import { reduxFormReducer } from 'address-form'
import {
  reducer as account,
  ORDER_FORM_NAME,
  USER_BILLING_ADDRESS_FORM_NAME,
  INSTITUTION_BILLING_ADDRESS_FORM
} from 'account'
import { reducer as api } from 'api'
import { reducer as auth } from 'auth'
import { reducer as chat } from 'chat'
import { reducer as dashboard } from 'dashboard'
import { reducer as memberMailchimpSubscribeForm } from 'dashboard/components/MemberMailchimpSubscribeModal'
import { reducer as emailConfirmation } from 'email-confirmation'
import { reducer as errors } from 'errors'
import { reducer as header } from 'header'
import { reducer as formGroup } from 'form-group'
import { RENEWAL_FORM_NAME } from 'membership'
import { reducer as nonMemberSubscribeForm } from 'page-blocks/NonMemberSubscribe'
import { reducer as modal } from 'modal'
import { reducer as settings } from 'settings'
import { FORM_NAME as REGISTRATION_FORM_NAME } from 'registration'
import {
  institutionReservationsReducer as institutionReservations,
  individualReservationsReducer as individualReservations,
  INDIVIDUAL_RESERVATION_FORM_NAME,
  WAITLIST_FORM_NAME
} from 'session-sales'
import { reducer as institutionSearch } from 'select-institution'
import { reducer as stripe } from 'stripe'
import { reducer as webinars } from 'webinars'

const rootReducer = combineReducers({
  account,
  api,
  auth,
  chat,
  dashboard,
  emailConfirmation,
  errors,
  form: formReducer.plugin({
    [ORDER_FORM_NAME]: reduxFormReducer,
    [USER_BILLING_ADDRESS_FORM_NAME]: reduxFormReducer,
    [INSTITUTION_BILLING_ADDRESS_FORM]: reduxFormReducer,
    [RENEWAL_FORM_NAME]: reduxFormReducer,
    [REGISTRATION_FORM_NAME]: reduxFormReducer,
    [INDIVIDUAL_RESERVATION_FORM_NAME]: reduxFormReducer,
    [WAITLIST_FORM_NAME]: reduxFormReducer
  }),
  header,
  formGroup,
  institutionReservations,
  individualReservations,
  institutionSearch,
  modal,
  nonMemberSubscribeForm,
  memberMailchimpSubscribeForm,
  settings,
  routing,
  stripe,
  ui,
  webinars
})

export default enableBatching(rootReducer)
