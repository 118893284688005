
import { getFormValues, getFormSubmitErrors } from 'redux-form'
import { every, get, isEmpty, pick } from 'lodash'
import { createSelector } from 'reselect'
import { BILLING_ADDRESS_FIELD_NAMES } from 'address-form'

import { fieldErrorsSelectorFactory } from 'form'

export const paymentIsCompleteSelector = form => createSelector(
  (state) => get(state, ['stripe', form], {}),
  fieldErrorsSelectorFactory(form, BILLING_ADDRESS_FIELD_NAMES),
  (stripeValidations, billingAddressFields) => {
    return every(stripeValidations, 'complete') && isEmpty(billingAddressFields)
  }
)

export const billingAddressSelector = form => createSelector(
  getFormValues(form),
  (formValues) => ({
    ...pick(formValues, BILLING_ADDRESS_FIELD_NAMES)
  })
)

export const transactionErrorSelector = (state, props) => {
  return get(getFormSubmitErrors(props.form)(state), 'creditCard') === 'declined'
}
