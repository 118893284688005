import React, { PropTypes } from 'react'
import moment from 'moment'

import { load, schemas } from 'api'
import { applyHOCs, applyPropTypes, formatPrice } from 'helpers'
import { Banner, HeaderSecondary } from 'page-blocks'
import { Link } from 'core'
import css from './style.scss'

const formatDate = date => moment(date).format('MMM D, YYYY')

export const IndividualMembershipExpiredPage = ({ membership, membershipPlan }) => {
  return (
    <div>
      <HeaderSecondary
        title='Your membership has expired'
        subtitle={`
          Your membership expired on ${formatDate(membership.expires_at)}.
          Renew your membership now to regain access to NCFDD content.
        `}
      />
      <div className={css.link}>
        <Link
          to='account/membership-type'
          orange
        >
          Link your account to an active institution's membership.
        </Link>
      </div>

      {membershipPlan && (
        <Banner
          title='Renew Your Membership'
          copy={`${membershipPlan.name} - ${formatPrice(membershipPlan.price)} / year`}
          cta_url='/renew-membership'
          cta_label='Renew Now'
        />
      )}
    </div>
  )
}

IndividualMembershipExpiredPage.propTypes = {
  membership: PropTypes.shape({
    expires_at: PropTypes.string.isRequired
  }).isRequired,
  membershipPlan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })
}

export default applyHOCs([
  load({
    membershipPlan: {
      urlSelector: (state, { membership }) => {
        return `/v2/membership_plans/${membership.membership_plan_id}`
      },
      schema: schemas.membershipPlan
    }
  }),
  applyPropTypes({
    membership: PropTypes.shape({
      membership_plan_id: PropTypes.number.isRequired
    }).isRequired
  })
])(IndividualMembershipExpiredPage)
