import React, { PropTypes } from 'react'

import { News } from '../components/News'
import { load, schemas } from 'api'

const LatestNews = props => {
  return props.posts && props.posts.length ? <News collection={props.posts} /> : null
}

LatestNews.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    author_id: PropTypes.number,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    publish_date: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired
  }))
}

export default load({
  posts: {
    urlSelector: () => '/v2/posts?filters[type]=news&limit=2',
    schema: [schemas.post]
  }
})(LatestNews)
