import React, { PropTypes } from 'react'

import { MondayMotivator } from '../components/MondayMotivator'
import { load, schemas } from 'api'
import { kerryAnn as kerryAnnAvatar } from 'images'

const kerryAnn = {
  name: 'Kerry Ann Rockquemore',
  avatar_url: kerryAnnAvatar
}

const LatestMondayMotivator = (props) => {
  return props.posts && props.posts.length
    ? <MondayMotivator author={kerryAnn} content={props.posts[0]} />
    : null
}

LatestMondayMotivator.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    publish_date: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired
  }))
}

export default load({
  posts: {
    urlSelector: () => '/v2/posts/monday-motivator',
    schema: [schemas.post]
  }
})(LatestMondayMotivator)
