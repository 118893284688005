import Immutable from 'seamless-immutable'
import { values } from 'lodash'
import { filterActions } from 'redux-ignore'

import * as types from './types'
const {
  WEBINAR_COMPLETE_REQUEST,
  WEBINAR_COMPLETE_SUCCESS,
  WEBINAR_COMPLETE_FAIL,
  WEBINAR_REGISTER_REQUEST,
  WEBINAR_REGISTER_SUCCESS,
  WEBINAR_REGISTER_FAIL,
  WEBINAR_UNREGISTER_REQUEST,
  WEBINAR_UNREGISTER_SUCCESS,
  WEBINAR_UNREGISTER_FAIL
} = types

const INITIAL_STATE = Immutable({
  isRegistering: false,
  isUnregistering: false,
  isCompleting: false
})

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WEBINAR_REGISTER_REQUEST:
      return state.set('isRegistering', true)
    case WEBINAR_REGISTER_SUCCESS:
    case WEBINAR_REGISTER_FAIL:
      return state.set('isRegistering', false)
    case WEBINAR_UNREGISTER_REQUEST:
      return state.set('isUnregistering', true)
    case WEBINAR_UNREGISTER_SUCCESS:
    case WEBINAR_UNREGISTER_FAIL:
      return state.set('isUnregistering', false)
    case WEBINAR_COMPLETE_REQUEST:
      return state.set('isCompleting', true)
    case WEBINAR_COMPLETE_SUCCESS:
    case WEBINAR_COMPLETE_FAIL:
      return state.set('isCompleting', false)
    default:
      return state
  }
}

export default filterActions(reducer, values(types))
