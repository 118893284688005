import React, { PropTypes } from 'react'
import { Heading, Button } from 'core'
import { connect } from 'react-redux'

import { EnvelopeOpenCheckmarkIcon, SpinnerIcon } from 'icons'
import { applyHOCs } from 'helpers'
import { Form, Label, validators } from 'form'
import Input from './Input'
import { nonMemberSubscribe } from './actions'

import { NON_MEMBER_SUBSCRIBE_FORM } from './constants'
import { Field, reduxForm, getFormSubmitErrors } from 'redux-form'
import 'isomorphic-fetch'
import { Combo, ComboFirst, ComboLast } from 'layout'

import css from './style.scss'
export const NonMemberSubscribeForm = props => {
  const fallbackBulletPoints = [
    { copy: 'The monthly NCFDD Newsletter' },
    { copy: 'Important event reminders and notifications' },
    { copy: 'Information about upcoming programs and events' },
    { copy: 'Other exciting announcements from the NCFDD team' }
  ]

  const {
    submitErrors,
    nonMemberSubscribe,
    invalid,
    success,
    title,
    subtitle,
    title2,
    submitting,
    // eslint-disable-next-line camelcase
    bullet_points
  } = props

  // eslint-disable-next-line
  const bulletPointsWithFallback = (!bullet_points || bullet_points.length === 0) ? fallbackBulletPoints : bullet_points

  return (
    <div className={css.mainFormWrapper}>
      {success &&
        <div>
          <ul className={css.thankYouFlexContainer}>
            <Heading size='h3' weight='medium' noMargin center>Thanks for subscribing</Heading>
            <li><EnvelopeOpenCheckmarkIcon className={css.envelopeImage} /></li>
            <li className={css.subscriptionConfirmed}>Your subscription to our list has been confirmed</li>
          </ul>
        </div>}
      <div className={css.container}>
        {!success &&
          <div>
            <div className={css.titleWrapper}>
              <Heading size='h3' weight='medium' noMargin>{title}<br />{title2}{success}</Heading>
            </div>
            <div className={css.wrapper}>

              <div className={css.programUpdates}>
                <div className={css.programUpdatesTitle}>
                  <Heading size='h5' weight='medium'>{subtitle}</Heading>
                </div>
                <div className={css.programUpdatesBody}>
                  {
                  bulletPointsWithFallback.map((bulletPoint, index) => {
                    return (
                      <div key={index} className={css.checklistItem}>
                        <Combo>
                          <ComboFirst>
                            <div className={css.checkmark} />
                          </ComboFirst>
                          <ComboLast>
                            <div className={css.checklistText}>{bulletPoint.copy}</div>
                          </ComboLast>
                        </Combo>
                      </div>
                    )
                  })
                }
                  <br />
                </div>
              </div>

              <div className={css.subscribeForm}>
                <Form name='Subscribe join' onSubmit={nonMemberSubscribe}>
                  <Label htmlFor='firstName'>First Name</Label>
                  <Field
                    name='firstName'
                    id='firstName'
                    component={Input}
                    validate={validators.required('* Please enter your first name')}
                  />
                  <Label htmlFor='email'>Email</Label>
                  <Field
                    name='email'
                    id='email'
                    component={Input}
                    validate={validators.email('* Please enter a valid email address')}
                  />
                  {
                  submitErrors &&
                    <span className={css.errorMessageBox}>
                      {submitErrors.code}
                    </span>
                }
                  <div className={css.spacer} />
                  <Button
                    id='submitButton'
                    type='submit'
                    noMargin
                    color='green'
                    size='secondary'
                    disabled={invalid || submitting}
                  >
                    {!submitting && 'Subscribe'}
                    {submitting && <SpinnerIcon />}
                  </Button>
                  <div className={css.formFooterNote}>* You can always unsubscribe with just one click</div>
                </Form>
              </div>

            </div>
          </div>}
      </div>
    </div>
  )
}

NonMemberSubscribeForm.propTypes = {
  submitErrors: PropTypes.object,
  success: PropTypes.bool,
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  submitting: PropTypes.bool
}

const mapStateToProps = (state) => ({
  submitErrors: getFormSubmitErrors(NON_MEMBER_SUBSCRIBE_FORM)(state),
  success: state.nonMemberSubscribeForm.success
})

export default applyHOCs([
  reduxForm({
    form: NON_MEMBER_SUBSCRIBE_FORM,
    enableReinitialize: true
  }),
  connect(mapStateToProps, { nonMemberSubscribe })
])(NonMemberSubscribeForm)
