export class APIError extends Error {
  constructor (options) {
    super('API Error')

    const { request, response } = options
    this.request = request
    this.response = response
  }
}

/**
 * Client errors
 */

export class NotFoundError extends APIError {}
export class UnauthorizedError extends APIError {}
export class ForbiddenError extends APIError {}
export class BadRequestError extends APIError {}

/**
 * Server errors
 */

export class InternalServerError extends APIError {}
