/* global FormData */
import { getFormValues, startSubmit, stopSubmit } from 'redux-form'

import { createClient, currentUserSelector, loadCurrentUser } from 'auth'
import { handleError } from 'errors'
import { closeForm } from 'form-group'
import { BadRequestError } from 'api'
import { get } from 'lodash'

import {
  ACCOUNT_DETAILS_FORM_NAME,
  PERSONAL_DETAILS_FORM_NAME,
  PROFESSIONAL_DETAILS_FORM_NAME,
  USER_BILLING_ADDRESS_FORM_NAME,
  AVATAR_FORM_NAME
} from './constants'

export const saveUserFactory = (FORM_NAME, bodySelector = getFormValues(FORM_NAME)) =>
  () => (dispatch, getState) => {
    dispatch(startSubmit(FORM_NAME))
    const state = getState()
    const body = bodySelector(state)

    const { id } = currentUserSelector(state)

    return createClient(state)
      .patch(`/v2/users/${id}`, { body })
      .then(response => response.json())
      .then(updatedUser => {
        dispatch(loadCurrentUser(updatedUser))
        dispatch(stopSubmit(FORM_NAME))
        dispatch(closeForm(FORM_NAME))
      })
      .catch(err => {
        dispatch(stopSubmit(FORM_NAME))
        dispatch(handleError(err))
        return err
      })
  }

export const saveAccountDetails = saveUserFactory(ACCOUNT_DETAILS_FORM_NAME, state => {
  const values = getFormValues(ACCOUNT_DETAILS_FORM_NAME)(state)
  const body = { email: values.email }
  if (values.password) {
    body.password = values.password
  }
  return body
})

export const saveAvatar = file => (dispatch, getState) => {
  dispatch(startSubmit(AVATAR_FORM_NAME))

  const state = getState()
  const body = new FormData()
  body.append('avatar', file, file.name)

  const { id } = currentUserSelector(state)

  return createClient(state)
    .patch(`/v2/users/${id}/avatar`, { body })
    .then(response => response.json())
    .then(updatedUser => {
      dispatch(loadCurrentUser(updatedUser))
      dispatch(stopSubmit(AVATAR_FORM_NAME))
      dispatch(closeForm(AVATAR_FORM_NAME))
    })
    .catch(err => {
      dispatch(stopSubmit(AVATAR_FORM_NAME))
      if (err instanceof BadRequestError) {
        err.response.json()
          .then(err => {
            const message = get(err, 'message', 'Image could not be uploaded.')
            dispatch(handleError(err, message))
          })
      } else {
        dispatch(handleError(err))
      }
    })
}

export const savePersonalDetails = saveUserFactory(PERSONAL_DETAILS_FORM_NAME)
export const saveProfessionalDetails = saveUserFactory(PROFESSIONAL_DETAILS_FORM_NAME)
export const saveUserBillingAddress = saveUserFactory(USER_BILLING_ADDRESS_FORM_NAME)
