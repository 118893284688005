import { compact, get } from 'lodash'
import { createSelector } from 'reselect'

import { entitiesSelector } from 'api'

const chatRoomSelector = room => state => get(state, ['chat', 'rooms', room], {})

export const chatRoomEventsSelector = room => createSelector(
  entitiesSelector,
  chatRoomSelector(room),
  (entities, room) => {
    const events = get(room, 'events', [])
      .map(event => {
        switch (event.type) {
          case 'chatMessage':
            const chatMessage = entities.chat_messages[event.id] // eslint-disable-line no-case-declarations
            return {
              type: 'chatMessage',
              chatMessage,
              user: entities.users[chatMessage.user]
            }
          case 'userJoined':
          case 'userLeft':
            return {
              type: event.type,
              user: entities.users[event.id]
            }
          default:
            return null
        }
      })
    return compact(events)
  }
)

export const chatRoomActiveUsersSelector = room => createSelector(
  entitiesSelector,
  chatRoomSelector(room),
  (entities, room) => get(room, 'activeUserIds', [])
    .map(id => entities.users[id])
)
