import React from 'react'
import { connect } from 'react-redux'
import { dismissUnhandledError } from './actions'

import css from './style.scss'

class ErrorFlashMessage extends React.Component {
  componentDidMount () {
    // TODO: once we bring in more error flash messages, we will need a way of
    // restarting our timeout phase in the case of another error happening
    // during this timeout.
    this.timeout = setTimeout(this.props.clear, 5000)
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  render () {
    /* eslint-disable react/jsx-handler-names */
    return (
      <div className={css.flash}>
        <span>{this.props.message}</span>
        <button
          className={css.dismiss}
          onClick={this.props.clear}
        >
          x
        </button>
      </div>
    )
  }
}

const ErrorFlashRoot = (props) => {
  const { unhandledError } = props

  if (unhandledError) {
    return (
      <ErrorFlashMessage
        message={unhandledError}
        clear={props.dismissUnhandledError}
      />
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => ({
  unhandledError: state.errors.unhandledError
})

export default connect(mapStateToProps, { dismissUnhandledError })(ErrorFlashRoot)
