import React from 'react'

import {
  SuccessModal,
  ModalHeading,
  ModalSubheading
} from 'modal'

const OfferSuccessModal = () => {
  return (
    <SuccessModal>
      <ModalHeading>
        Thank you for joining the FSP Program
      </ModalHeading>

      <ModalSubheading>What happens next?</ModalSubheading>
      <p>
        You have 14 days to submit the remainder of your payment. If you do not complete
        your payment you will lose your spot and deposit.
      </p>
    </SuccessModal>
  )
}

export default OfferSuccessModal
