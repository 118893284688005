import { get } from 'lodash'
import { createClient } from 'auth'
import {
  SUBACCOUNT_EXPORT_REQUEST,
  SUBACCOUNT_EXPORT_SUCCESS,
  SUBACCOUNT_EXPORT_FAILED,
  SUBACCOUNT_EXPORT_EXPIRED,
  INVOICE_EXPORT_REQUEST,
  INVOICE_EXPORT_SUCCESS,
  INVOICE_EXPORT_FAILED,
  INVOICE_EXPORT_EXPIRED
} from './types'
import { handleError } from 'errors'

export const requestSubAccountExport = institutionId => (dispatch, getState) => {
  dispatch({ type: SUBACCOUNT_EXPORT_REQUEST })
  return createClient(getState())
    .post(`/v2/institutions/${institutionId}/users/export`)
    .then((response) => response.json())
    .then((data) => {
      dispatch({
        type: SUBACCOUNT_EXPORT_SUCCESS,
        data
      })

      if (data.expires) {
        setTimeout(() => {
          dispatch({ type: SUBACCOUNT_EXPORT_EXPIRED })
        }, data.expires * 1000)
      }
    })
    .catch((error) => {
      dispatch({ type: SUBACCOUNT_EXPORT_FAILED })
      dispatch(handleError(error))
    })
}

export const requestOrderInvoiceExport = orderId => (dispatch, getState) => {
  const state = getState()
  const fileUrl = get(state, `account.invoiceExport[${orderId}].data.url`, null)
  if (fileUrl) {
    window.location = fileUrl
  } else {
    dispatch({ type: INVOICE_EXPORT_REQUEST })
    return createClient(state)
      .get(`/v2/orders/${orderId}/invoice/export`)
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: INVOICE_EXPORT_SUCCESS,
          data,
          orderId
        })

        if (data.expires) {
          setTimeout(() => {
            dispatch({ type: INVOICE_EXPORT_EXPIRED })
          }, data.expires * 1000)
        }
        window.location = data.url
      })
      .catch((error) => {
        dispatch({ type: INVOICE_EXPORT_FAILED })
        dispatch(handleError(error))
      })
  }
}
