import React from 'react'
import { Button } from 'core'

const RegisterButton = props => {
  const commonProps = {
    size: 'primary',
    noMargin: true,
    isLoading: props.isLoading
  }

  if (props.isRegistered) {
    return (
      <Button
        {...commonProps}
        onClick={props.unregister} // eslint-disable-line react/jsx-handler-names
        color='green'
        success
      >
        Unregister
      </Button>
    )
  } else {
    return (
      <Button
        {...commonProps}
        onClick={props.register} // eslint-disable-line react/jsx-handler-names
      >
        Register
      </Button>
    )
  }
}

RegisterButton.propTypes = {
  isRegistered: React.PropTypes.bool.isRequired,
  isLoading: React.PropTypes.bool.isRequired,
  register: React.PropTypes.func.isRequired,
  unregister: React.PropTypes.func.isRequired
}

export default RegisterButton
