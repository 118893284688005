import React from 'react'
import { compact } from 'lodash'

import css from './style.scss'

export default ({ children, grey, width = 'third' }) => {
  const classNames = [
    css.metricsSubcard,
    grey ? css.metricsSubcardGrey : '',
    css[width]
  ]

  return (
    <div className={compact(classNames).join(' ')}>
      {children}
    </div>
  )
}
