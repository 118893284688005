import React from 'react'
import MediaQuery from 'react-responsive'
import arrow from './assets/arrow.png'

import { CMSSection } from '../components'
import { Heading } from 'core'

import css from './style.scss'
import { breakpoints } from 'styles'

const { phabletMax } = breakpoints

const Transformation = (props) => {
  const { title, subtitle, img } = props
  const steps = props.steps.slice(0, 4)
  const arrows = steps.length < 4 ? steps.slice(0, steps.length - 1) : steps

  const style = {
    backgroundImage: `url(${img})`
  }

  return (
    <CMSSection
      title={title}
      subtitle={subtitle}
    >
      <div className={css.wrapper}>
        <div className={css.circleCrop}>
          <div style={style} />
        </div>
        <div>
          {
            steps.map((step, index) => (
              <div key={index} className={css.step}>
                <Heading size='h5' freight noMargin>
                  {`${index + 1}. ${step.step_name}`}
                </Heading>
                <p className={css.stepDescription}>
                  {step.step_description}
                </p>
              </div>
            ))
          }
          <MediaQuery minWidth={phabletMax}>
            {
              arrows.map((step, index) => (
                <div key={index} className={css.arrow}>
                  <img src={arrow} alt='Arrow illustration.' />
                </div>
              ))
            }
          </MediaQuery>
        </div>
      </div>
    </CMSSection>
  )
}

Transformation.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  img: React.PropTypes.string.isRequired,
  steps: React.PropTypes.arrayOf(React.PropTypes.shape({
    step_name: React.PropTypes.string.isRequired,
    step_description: React.PropTypes.string.isRequired
  })).isRequired
}

export default Transformation
