import { getFormValues, startSubmit, stopSubmit } from 'redux-form'
import qs from 'qs'
import {
  createClient
} from 'auth'
import { handleError } from 'errors'
import { SUBSCRIPTION_SUCCESS } from './types'
import { NON_MEMBER_SUBSCRIBE_FORM } from './constants'
import { get } from 'lodash'

export const nonMemberSubscribe = () => (dispatch, getState) => {
  dispatch(startSubmit(NON_MEMBER_SUBSCRIBE_FORM))
  const state = getState()
  const client = createClient(state)

  const formValues = getFormValues(NON_MEMBER_SUBSCRIBE_FORM)(state)
  const query = qs.stringify({
    EMAIL: formValues.email,
    FNAME: formValues.firstName
  })

  client.get(`/v2/mailchimp/subscribe?${query}`)
    .then(response => response.json())
    .then((subResponse) => {
      const body = JSON.parse(subResponse.body)
      if (body.result === 'error') {
        dispatch(stopSubmit(NON_MEMBER_SUBSCRIBE_FORM, { code: body.msg }))
      } else {
        return client.post('/v2/hubspot/subscribe', {
          body: {
            firstname: formValues.firstName,
            email: formValues.email
          }
        }).then(response => response.json())
      }
    })
    .then((subResponse) => {
      if (subResponse) {
        if (get(subResponse, 'body.result') === 'error') {
          dispatch(stopSubmit(NON_MEMBER_SUBSCRIBE_FORM, { code: get(subResponse, 'body.msg') }))
        } else {
          dispatch({
            type: SUBSCRIPTION_SUCCESS
          })
        }
      }
    })
    .catch(err => {
      dispatch(stopSubmit(NON_MEMBER_SUBSCRIBE_FORM))
      dispatch(handleError(err))
    })
}
