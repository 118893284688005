import React from 'react'
import Spinner from './spinner.svg'

import css from './style.scss'

const SpinnerIcon = (props) =>
  <Spinner
    {...props}
    className={
      [css.spinner]
        .concat(props.className || '')
        .join(' ')
    }
  />

SpinnerIcon.propTypes = {
  className: React.PropTypes.string
}

export default SpinnerIcon
