import Immutable from 'seamless-immutable'
import { values } from 'lodash'
import { filterActions } from 'redux-ignore'

import * as types from './types'
const {
  SEND_CONFIRMATION_EMAIL_REQUEST,
  SEND_CONFIRMATION_EMAIL_SUCCESS,
  SEND_CONFIRMATION_EMAIL_FAIL
} = types

const INITIAL_STATE = Immutable({
  emailConfirmation: {
    loading: false,
    emailSent: false
  }
})

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEND_CONFIRMATION_EMAIL_REQUEST:
      return state.setIn(['emailConfirmation', 'loading'], true)
    case SEND_CONFIRMATION_EMAIL_SUCCESS:
      return state.merge({
        emailConfirmation: {
          loading: false,
          emailSent: true
        }
      })
    case SEND_CONFIRMATION_EMAIL_FAIL:
      return state.merge({
        emailConfirmation: {
          loading: false,
          emailSent: false
        }
      })
    default:
      return state
  }
}

export default filterActions(reducer, values(types))
