import React, { PropTypes } from 'react'

import { Button, Heading, HeroHeader, Link } from 'core'
import { Section } from 'layout'
import { InstitutionInactiveMessage } from '../InstitutionInactiveMessage'

import css from './style.scss'

const InstitutionLandingHeader = (props) => {
  const { expires_at: expiresAt, activated_at: activatedAt } = props
  const now = Date.now()
  const active = now < new Date(expiresAt) && now > new Date(activatedAt)

  return (
    <HeroHeader blueScreen imageUrl={props.heroUrl}>
      <Section>
        <div className={css.headerContainer}>
          <Heading freight center>
            {props.name}
          </Heading>
          {active && (
            <div className={css.activateButtonContainer}>
              <Link to={`/institutions/${props.slug}/register`}>
                <Button size='primary'>
                  Activate My Membership
                </Button>
              </Link>
            </div>
          )}
          {!active && <InstitutionInactiveMessage
            name={props.name}
            activated_at={activatedAt}
            expires_at={expiresAt}
                      />}
        </div>
      </Section>
    </HeroHeader>
  )
}

InstitutionLandingHeader.propTypes = {
  slug: PropTypes.string.isRequired,
  // api
  name: PropTypes.string.isRequired,
  heroUrl: PropTypes.string.isRequired
}

export default InstitutionLandingHeader
