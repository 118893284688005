import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { createSelector, createStructuredSelector } from 'reselect'
import moment from 'moment'

import { load, schemas } from 'api'
import { currentUserAddressSelector, currentUserSelector } from 'auth'
import { Button, Heading } from 'core'
import { FormContainer, FormSection, FormRule } from 'form'
import { applyHOCs, selectSlug } from 'helpers'
import { Spacer } from 'layout'
import { OrderSummaryTable, OrderSummaryPropTypes, DiscountCodeForm } from 'order-summary'
import { page, pageLoadSelectors, PageHead } from 'page'
import { PaymentForm, withStripeElements } from 'stripe'

import { INDIVIDUAL_RESERVATION_FORM_NAME as FORM_NAME } from '../constants'
import { reserve } from './actions'
import {
  formIsCompleteSelector,
  getIndividualReservationOrderSummary,
  invoiceRequestedSelector
} from './selectors'
import { InvoiceRequestForm } from 'invoice-request-form'
import InvoiceRequestSelector from './InvoiceRequestSelector'
import LoginOrCreateAccountForm from './LoginOrCreateAccountForm'

export const IndividualReservationPage = (props) => {
  const {
    sessionSale,
    submitting,
    formIsComplete,
    reserve,
    stripeElements,
    orderSummary,
    invoiceRequested,
    currentUser
  } = props

  const isInvoiceEnabled = sessionSale.enable_invoice_request

  return (
    <div>
      <PageHead title={`Register for ${sessionSale.title}`} />
      <Heading size='h6' freight center noMargin>
        {sessionSale.title}
      </Heading>
      <FormContainer name={`SESSION REGISTER - ${sessionSale.slug}`} onSubmit={reserve}>
        {isInvoiceEnabled ? <Field name='request_invoice' component={InvoiceRequestSelector} /> : ''}
        <FormSection>
          <Heading size='h6'>Order Summary</Heading>
          {currentUser
            ? <Heading size='h6' weight='medium'>Registration For: {currentUser.name}</Heading>
            : <LoginOrCreateAccountForm form={FORM_NAME} />}
          <FormRule key='form-rule' />
          <OrderSummaryTable {...orderSummary} />
          <Spacer size='xs' />
          <DiscountCodeForm
            parentForm={FORM_NAME}
            productId={sessionSale.reservation_product_id}
          />
          {orderSummary.total > 0 && [
            <FormRule key='form-rule' />,
            invoiceRequested
              ? <InvoiceRequestForm key='invoice-form' form={FORM_NAME} />
              : <PaymentForm key='payment-form' form={FORM_NAME} stripeElements={stripeElements} />
          ]}
        </FormSection>
        <FormSection>
          <Button
            type='submit'
            size='primary'
            noMargin
            disabled={(orderSummary.total > 0 && !formIsComplete) || submitting}
            isLoading={submitting}
          >
            {invoiceRequested ? 'Send Invoice' : 'Submit Payment'}
          </Button>
        </FormSection>
      </FormContainer>
    </div>
  )
}

IndividualReservationPage.propTypes = {
  sessionSale: PropTypes.shape({
    title: PropTypes.string.isRequired,
    reservation_price: PropTypes.number.isRequired,
    reservation_product_id: PropTypes.number.isRequired,
    isInvoiceEnabled: PropTypes.bool
  }).isRequired,
  reserve: PropTypes.func.isRequired,
  stripeElements: PropTypes.object.isRequired,
  formIsComplete: PropTypes.bool,
  orderSummary: PropTypes.shape(OrderSummaryPropTypes).isRequired,
  currentUser: PropTypes.object
}

export const reservationsAreAvailable = sessionSale => {
  const openRegistrationClosed = moment().isAfter(sessionSale.open_registration_end_at)

  if (openRegistrationClosed) {
    return false
  } else if (sessionSale.registration_status === 'reserved') {
    return false
  } else if (sessionSale.num_reservations_available === 0) {
    return false
  } else {
    return true
  }
}

export default applyHOCs([
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true
  }),
  connect(
    createStructuredSelector({
      initialValues: createSelector(
        currentUserAddressSelector,
        currentUserAddress => ({ ...currentUserAddress, request_invoice: false })
      ),
      orderSummary: getIndividualReservationOrderSummary,
      invoiceRequested: invoiceRequestedSelector,
      formIsComplete: formIsCompleteSelector,
      currentUser: currentUserSelector
    }),
    (dispatch, props) => ({
      reserve: () => dispatch(reserve(props))
    })
  ),
  withStripeElements(),
  page({
    layout: 'form',
    ...pageLoadSelectors('sessionSale'),
    redirectSelector: (state, { sessionSale }) => {
      if (sessionSale && !reservationsAreAvailable(sessionSale)) {
        return `/sessions/${sessionSale.slug}`
      }
    },
    noPageHead: true
  }),
  load({
    sessionSale: {
      urlSelector: (state, props) => {
        return `/v2/session_sales/slug/${props.slug}`
      },
      schema: schemas.sessionSale
    }
  }),
  selectSlug()
])(IndividualReservationPage)
