import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form'
import { countriesSelector } from 'settings'
import { get, find } from 'lodash'

import {
  CA_POSTAL_CODE_PATTERN,
  CA_STATE_NAME,
  US_POSTAL_CODE_LABEL,
  US_POSTAL_CODE_PATTERN,
  US_STATE_NAME
} from './constants'

export const addressFormPropsSelector = (formName, prefix = '') => createSelector(
  countriesSelector,
  (state, props) => formValueSelector(props.form || formName)(state, `${prefix}country`),
  (countryOptions, selectedCountry) => {
    const stateOptions = get(find(countryOptions, { country_abbr: selectedCountry }), 'country_states', [])
    const newProps = {
      countryOptions,
      countryPostalPattern: null,
      countryPostal: 'Postal Code',
      stateOptions: [...stateOptions],
      stateLabel: 'Location'
    }

    if (selectedCountry === 'CA') {
      newProps.countryPostalPattern = CA_POSTAL_CODE_PATTERN
      newProps.stateLabel = CA_STATE_NAME
    } else if (selectedCountry === 'US') {
      newProps.countryPostal = US_POSTAL_CODE_LABEL
      newProps.countryPostalPattern = US_POSTAL_CODE_PATTERN
      newProps.stateLabel = US_STATE_NAME
    }

    return newProps
  }
)
