import React from 'react'

import { Grid, GridItem } from 'layout'

import { CMSSection } from '../components'

import PieChart from './PieChart'

const Metrics = (props) => {
  const { title, subtitle, metrics } = props

  return (
    <CMSSection
      title={title}
      subtitle={subtitle}
    >
      <Grid middle>
        {
          metrics.map((metric, index) => {
            return (
              <GridItem key={index} col='1/3' phablet1of2 mobile1of1>
                <PieChart value={metric.metric_value} color={metric.metric_color} />
                {metric.metric_name}
              </GridItem>
            )
          })
        }
      </Grid>
    </CMSSection>
  )
}

Metrics.PropTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  metrics: React.PropTypes.arrayOf(React.PropTypes.shape({
    metric_name: React.PropTypes.string.isRequired,
    metric_value: React.PropTypes.string.isRequired,
    metric_color: React.PropTypes.string.isRequired
  })).isRequired
}

export default Metrics
