import React, { PropTypes } from 'react'
import css from './style.scss'

const DiscountCodeError = ({ children }) => (
  <p className={css.discountCodeError}>
    {children}
  </p>
)

DiscountCodeError.propTypes = {
  children: PropTypes.node
}

export default DiscountCodeError
