import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues } from 'redux-form'
import { createSelector } from 'reselect'

import { Button, Heading, Link } from 'core'
import { Label, Input, validators, Form } from 'form'
import { applyHOCs } from 'helpers'
import { Section } from 'layout'
import { Page } from 'page'

import css from './style.scss'

import { FORM_NAME } from './constants'
import { login } from './actions'

const LoginPage = (props) => {
  let error

  if (props.error === 'require_confirmation') {
    const link = (
      <Link
        to={{
          pathname: '/contact-us',
          query: {
            email: props.email,
            type: 'resend_confirmation'
          }
        }}
        className={css.contactUsLink}
      >
        Contact us
      </Link>
    )
    error = (
      <div>
        Email verification required. {link} to request a new confirmation email.
      </div>
    )
  } else if (props.error) {
    error = props.error
  }

  return (
    <Page title='Login'>
      <Section narrow>
        <div className={css.loginPageContainer}>
          <Heading size='h4' center>
            Sign In
          </Heading>
          <Form
            name='login'
            disableTracking
            onSubmit={() => {
              return props.login()
                .then((res) => {
                  if (!(res instanceof Error) && window.dataLayer) {
                    window.dataLayer.push({
                      event: 'login_event'
                    })
                  }
                  return res
                })
            }}
          >
            <div>
              <Label htmlFor='email'>Email</Label>
              <Field
                name='email'
                component={Input}
                validate={validators.required('Your email is required')}
                globalError={props.error}
              />
            </div>
            <div>
              <Label htmlFor='password'>Password</Label>
              <Field
                name='password'
                type='password'
                component={Input}
                validate={validators.required('Your password is required')}
                globalError={props.error}
              />
            </div>
            <div className={css.errorText}>{error}</div>
            <div>
              <Button
                type='submit'
                size='primary'
                disabled={props.submitting || props.invalid}
                isLoading={props.submitting}
              >
                Log In
              </Button>
            </div>
          </Form>
          <div className={css.link}>
            <Link to='/forgot-password' orange>Forgot your password?</Link>
          </div>
        </div>
      </Section>
    </Page>
  )
}

LoginPage.propTypes = {
  login: React.PropTypes.func.isRequired,
  /* Redux-form */
  error: React.PropTypes.string,
  invalid: React.PropTypes.bool,
  submitting: React.PropTypes.bool
}

export default applyHOCs([
  connect(
    createSelector(
      getFormValues(FORM_NAME),
      (values = {}) => ({ email: values.email })
    ),
    { login }
  ),
  reduxForm({ form: FORM_NAME })
])(LoginPage)
