import React from 'react'
import { compact } from 'lodash'

import css from './style.scss'

const GridItem = (props) => {
  const columns = props.col.split('/')
  const percent = (columns[0] / columns[1]) * 100

  const style = props.style || {}
  style.width = `${percent}%`

  const classes = [
    // Tablet Sizes
    props.tablet1of1 ? css.tablet1of1 : '',
    props.tablet1of2 ? css.tablet1of2 : '',
    props.tablet1of3 ? css.tablet1of3 : '',
    props.tablet2of3 ? css.tablet2of3 : '',
    // Tablet Utility Class
    props.tabletHide ? css.tabletHide : '',
    // "Phablet" Sizes
    props.phablet1of1 ? css.phablet1of1 : '',
    props.phablet1of2 ? css.phablet1of2 : '',
    props.phablet1of3 ? css.phablet1of3 : '',
    props.phablet2of3 ? css.phablet2of3 : '',
    // "Phablet"" Utility Class
    props.phabletHide ? css.phabletHide : '',
    // Mobile Sizes
    props.mobile1of1 ? css.mobile1of1 : '',
    props.mobile1of2 ? css.mobile1of2 : '',
    props.mobile1of3 ? css.mobile1of3 : '',
    props.mobile2of3 ? css.mobile2of3 : '',
    // Mobile Utility Class
    props.mobileHide ? css.mobileHide : ''
  ]

  return (
    <div style={style} className={compact(classes).join(' ')}>
      {props.children}
    </div>
  )
}

GridItem.propTypes = {
  children: React.PropTypes.node,
  col: React.PropTypes.string.isRequired,
  style: React.PropTypes.object,
  // Tablet Sizes
  tablet1of1: React.PropTypes.bool,
  tablet1of2: React.PropTypes.bool,
  tablet1of3: React.PropTypes.bool,
  tablet2of3: React.PropTypes.bool,
  // Tablet Utility Class
  tabletHide: React.PropTypes.bool,
  // "Phablet" Sizes
  phablet1of1: React.PropTypes.bool,
  phablet1of2: React.PropTypes.bool,
  phablet1of3: React.PropTypes.bool,
  phablet2of3: React.PropTypes.bool,
  // "Phablet" Utility Class
  phabletHide: React.PropTypes.bool,
  // Mobile Sizes
  mobile1of1: React.PropTypes.bool,
  mobile1of2: React.PropTypes.bool,
  mobile1of3: React.PropTypes.bool,
  mobile2of3: React.PropTypes.bool,
  // Mobile Utility Class
  mobileHide: React.PropTypes.bool
}

export default GridItem
