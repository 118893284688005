import React from 'react'
import MediaQuery from 'react-responsive'
import ui from 'redux-ui'

import { Avatar, Button, Card, HorizontalRule } from 'core'
import { formatNewlines } from 'helpers'
import { Combo, ComboFirst, ComboLast } from 'layout'
import { Modal } from 'modal'

import css from './style.scss'
import { breakpoints } from 'styles'

const { mobileMax } = breakpoints

export const TeamMember = (props) => {
  // eslint-disable-next-line camelcase
  const { avatar_url, name, post_nominal: postNominal, title, university, about_me: bio } = props

  const user = {
    name,
    avatar_url
  }

  const avatar = (
    <Avatar user={user} size='teamCustom' />
  )

  const nameAndPnlMarkup = (
    <p className={css.name}>
      {name}
      {
        postNominal &&
          <span>, {postNominal}</span>
      }
    </p>
  )

  const titleMarkup = title
    ? (<p className={css.title}>{title}</p>)
    : null
  const universityMarkup = university
    ? (<p className={css.university}>{university}</p>)
    : null

  const information = (
    <div className={css.information}>
      <div className={css.informationSpacing}>
        {nameAndPnlMarkup}
        {titleMarkup}
        {universityMarkup}
      </div>
      <div className={css.button}>
        {
          bio &&
            <Button
              size='secondary'
              outline
              noMargin
              onClick={() => props.updateUI({ bioModalIsOpen: true })}
            >
              View Full Bio
            </Button>
        }
      </div>
    </div>
  )

  const modalContent = (
    <div className={css.bioModal}>
      <Avatar user={user} size='xl' />
      {nameAndPnlMarkup}
      {titleMarkup}
      {universityMarkup}
      <div className={css.hr}>
        <HorizontalRule orange />
      </div>
      <div className={css.bio} dangerouslySetInnerHTML={{ __html: bio && formatNewlines(bio) }} />
    </div>
  )

  return (
    <Card fullHeight spacing spacingMobile>
      <MediaQuery maxWidth={mobileMax}>
        <Combo>
          <ComboFirst>
            {avatar}
          </ComboFirst>
          <ComboLast>
            {information}
          </ComboLast>
        </Combo>
      </MediaQuery>
      <MediaQuery minWidth={mobileMax}>
        <div className={css.wrapper}>
          {avatar}
          {information}
        </div>
      </MediaQuery>
      {
        props.ui.bioModalIsOpen &&
          <Modal
            onRequestClose={() => props.updateUI({ bioModalIsOpen: false })}
          >
            {modalContent}
          </Modal>
      }
    </Card>
  )
}

TeamMember.propTypes = {
  avatar_url: React.PropTypes.string,
  name: React.PropTypes.string.isRequired,
  post_nominal: React.PropTypes.string,
  title: React.PropTypes.string,
  university: React.PropTypes.string,
  about_me: React.PropTypes.string,
  /* React-UI */
  ui: React.PropTypes.object.isRequired,
  updateUI: React.PropTypes.func.isRequired
}

export default ui({
  state: {
    bioModalIsOpen: false
  }
})(TeamMember)
