import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { pnlOptionsSelector } from 'settings'
import { CMSSection } from 'page-blocks'
import css from './style.scss'

const defaultMessage = 'We were very excited to partner with the NCFDD, and are happy to have you join. Hope you enjoy these resources.'

const InstitutionLandingForward = ({ institution }) => {
  return (
    <CMSSection>
      <div className={css.landingForwardContent}>
        <span className={css.quote}>“</span>
        <p className={css.forward}>
          {institution.institution_personalized_message || defaultMessage}
        </p>
      </div>
    </CMSSection>
  )
}

InstitutionLandingForward.propTypes = {
  institution: PropTypes.object,
  pnlOptions: PropTypes.array.isRequired
}

export default connect(
  state => ({
    pnlOptions: pnlOptionsSelector(state)
  })
)(InstitutionLandingForward)
