import React from 'react'
import { compact } from 'lodash'

import { InputWrapper } from '../InputWrapper'
import css from './style.scss'

const Radio = ({ meta, input, options, disabled, flow }) => {
  const classNames = [
    css.radio,
    flow === 'vertical' ? css.vertical : css.horizontal
  ]

  return (
    <InputWrapper {...meta}>
      <div className={compact(classNames).join(' ')}>
        {options.map(option => (
          <label className={css.radioOption} key={`radio-option-${option.value}`}>
            <input
              {...input}
              type='radio'
              checked={option.value === input.value}
              value={option.value}
              disabled={disabled}
            />
            {' '}{option.name}
          </label>
        ))}
      </div>
    </InputWrapper>
  )
}

Radio.propTypes = {
  input: React.PropTypes.object,
  meta: React.PropTypes.object,
  disabled: React.PropTypes.bool,
  flow: React.PropTypes.oneOf(['vertical', 'horizontal'])
}

Radio.defaultProps = {
  flow: 'horizontal'
}

export default Radio
