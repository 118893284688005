import React from 'react'

import { Link } from 'core'

import css from './style.scss'

const DesktopMenuDropdown = ({ items }) => {
  const dropdownItems = (
    items.map((item, index) => (
      <li key={index} className={css.item}>
        <Link
          to={`/${item.slug}`}
          key={index}
        >
          {item.name}
        </Link>
        {
          item.children &&
            <ul>
              {
                item.children.map((child, index) => (
                  <li key={index} className={css.child}>
                    <Link
                      to={`/${child.slug}`}
                      key={index}
                    >
                      {child.name}
                    </Link>
                  </li>
                ))
              }
            </ul>
        }
      </li>
    ))
  )

  return (
    <div className={css.dropdown}>
      <ul>
        {dropdownItems}
      </ul>
    </div>
  )
}

const menuItem = {
  name: React.PropTypes.string,
  slug: React.PropTypes.string
}

DesktopMenuDropdown.propTypes = {
  items: React.PropTypes.arrayOf(React.PropTypes.shape(
    Object.assign({}, menuItem, {
      children: React.PropTypes.arrayOf(
        React.PropTypes.shape(menuItem)
      )
    })
  ))
}

export default DesktopMenuDropdown
