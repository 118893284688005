import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { createStructuredSelector } from 'reselect'

import { AddressForm } from 'address-form'
import { Heading } from 'core'
import { Label, LabelHelper } from 'form'
import { Grid, GridItem, Spacer } from 'layout'

import {
  stripeElementChange,
  registerStripeElements,
  unregisterStripeElements
} from '../actions'
import { transactionErrorSelector } from '../selectors'

import css from './style.scss'

import amexUrl from './assets/amex.png'
import dinersUrl from './assets/diners.png'
import discoverUrl from './assets/discover.png'
import jcbUrl from './assets/jcb.png'
import mastercardUrl from './assets/mastercard.png'
import visaUrl from './assets/visa.png'

const logos = {
  amex: amexUrl,
  diners: dinersUrl,
  discover: discoverUrl,
  jcb: jcbUrl,
  mastercard: mastercardUrl,
  visa: visaUrl
}

export class PaymentForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      focused: {
        cardNumber: false,
        cardVendor: null,
        cardExpiry: false,
        cardCvc: false
      }
    }

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.applyStateClass = this.applyStateClass.bind(this)
    this.applyErrorMessage = this.applyErrorMessage.bind(this)
  }

  idForField (fieldName) {
    return `${this.props.form}-${fieldName}`
  }

  componentDidMount () {
    const { cardNumber, cardExpiry, cardCvc } = this.props.stripeElements
    const stripeElements = [
      {
        name: 'cardNumber',
        input: cardNumber
      },
      {
        name: 'cardExpiry',
        input: cardExpiry
      },
      {
        name: 'cardCvc',
        input: cardCvc
      }
    ]

    const stripeElementNames = stripeElements.map(element => {
      return element.name
    })

    this.props.registerStripeElements(stripeElementNames)

    stripeElements.forEach((element) => {
      element.input.mount(`#${this.idForField(element.name)}`)
      element.input.on('focus', () => {
        this.onFocus(element.name)
      })
      element.input.on('blur', () => {
        this.onBlur(element.name)
      })
      element.input.on('change', (event) => {
        this.props.stripeElementChange(event, element.name)
        this.setState({ cardVendor: event.brand })
      })
    })
  }

  onFocus (element) {
    this.setState({
      focused: {
        [element]: true
      }
    })
  }

  onBlur (element) {
    this.setState({
      focused: {
        [element]: false
      }
    })
  }

  applyStateClass (element) {
    if (this.state.focused[element]) {
      return css.active
    } else if (get(this.props, ['stripeValidations', element, 'error'])) {
      return css.error
    }
  }

  applyErrorMessage (element) {
    const errorMsg = get(this.props, ['stripeValidations', element, 'error'], '')

    return <span>{errorMsg}</span>
  }

  componentWillUnmount () {
    const { cardNumber, cardExpiry, cardCvc } = this.props.stripeElements

    cardNumber.unmount()
    cardExpiry.unmount()
    cardCvc.unmount()
    this.props.unregisterStripeElements()
  }

  render () {
    let errorMessage

    if (this.props.transactionError) {
      errorMessage = (
        <p className={css.transactionErrorMessage}>
          Your credit card transaction was declined. Please double check
          the numbers and try again if there was a mistake. If not, contact
          the issuing bank for more information or try with a different card.
        </p>
      )
    }

    return (
      <div>
        <Heading size='h6'>Payment Details</Heading>
        <Grid>
          <GridItem col='1/2' tablet1of2 mobile1of1>
            <Label htmlFor={this.idForField('cardNumber')}>Card Number</Label>
            <div className={`${css.stripeWrapper} ${this.applyStateClass('cardNumber')}`}>
              <div id={this.idForField('cardNumber')} />
              {
                this.state.cardVendor &&
                  <figure className={css.brand} style={{ backgroundImage: `url(${logos[this.state.cardVendor]})` }} />
              }
            </div>
            <div className={css.stripeError}>
              {this.applyErrorMessage('cardNumber')}
            </div>
          </GridItem>
          <GridItem col='1/2' tablet1of2 mobileHide />
          <GridItem col='1/2' tablet1of2>
            <Label htmlFor={this.idForField('cardExpiry')}>
              Expiration Date
              <LabelHelper inline>(MM/YY)</LabelHelper>
            </Label>
            <div className={`${css.stripeWrapper} ${this.applyStateClass('cardExpiry')}`}>
              <div id={this.idForField('cardExpiry')} />
            </div>
            <div className={css.stripeError}>
              {this.applyErrorMessage('cardExpiry')}
            </div>
          </GridItem>
          <GridItem col='1/2' tablet2of3 mobile1of2 />
          <GridItem col='1/2' tablet1of2>
            <Label htmlFor={this.idForField('cardCvc')}>CVC</Label>
            <div className={`${css.stripeWrapper} ${this.applyStateClass('cardCvc')}`}>
              <div id={this.idForField('cardCvc')} />
            </div>
            <div className={css.stripeError}>
              {this.applyErrorMessage('cardCvc')}
            </div>
          </GridItem>
          <GridItem col='1/2' tablet2of3 mobile1of2 />
        </Grid>

        <Spacer size='xs' />

        <Heading size='h6'>Billing Address</Heading>
        <AddressForm form={this.props.form} />

        {errorMessage}
      </div>
    )
  }
}

PaymentForm.propTypes = {
  /* form name */
  form: React.PropTypes.string.isRequired,
  /* Stripe Elements passed down */
  stripeElements: React.PropTypes.object.isRequired,
  /* mapStateToProps */
  stripeValidations: React.PropTypes.object,
  transactionError: React.PropTypes.bool,
  /* mapDipstachToProps */
  stripeElementChange: React.PropTypes.func,
  registerStripeElements: React.PropTypes.func,
  unregisterStripeElements: React.PropTypes.func
}

const mapStateToProps = createStructuredSelector({
  stripeValidations: (state, props) => get(state, ['stripe', props.form], {}),
  transactionError: transactionErrorSelector
})

const mapDispatchToProps = (dispatch, { form }) => ({
  stripeElementChange: (event, element) => {
    dispatch(stripeElementChange(form, event, element))
  },
  registerStripeElements: (elements) => {
    dispatch(registerStripeElements(form, elements))
  },
  unregisterStripeElements: () => {
    dispatch(unregisterStripeElements(form))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm)
