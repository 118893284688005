import React from 'react'

import moment from 'moment'

import { load, schemas } from 'api'
import { formatDate, formatPercent, formatPrice } from 'helpers'

import { Pricing } from '../Pricing'

export const SessionSalePricing = ({ session_sale: sessionSale, earlyBirdDiscount, currentDate }) => {
  if (!sessionSale) return null

  const pricing = {
    item_name: sessionSale.title,
    item_price: formatPrice(sessionSale.reservation_price),
    item_unit: 'Per Faculty Member',
    item_detail: `Registration Ends ${formatDate(sessionSale.open_registration_end_at)}`
  }

  if (sessionSale.early_bird_price_start_at &&
    sessionSale.early_bird_price_end_at &&
    currentDate.isAfter(sessionSale.early_bird_price_start_at) &&
    currentDate.isBefore(sessionSale.early_bird_price_end_at) &&
    earlyBirdDiscount
  ) {
    const discountDisplay = earlyBirdDiscount.flat_amount
      ? formatPrice(earlyBirdDiscount.flat_amount)
      : formatPercent(earlyBirdDiscount.percent_amount)

    const discountValue = earlyBirdDiscount.flat_amount
      ? earlyBirdDiscount.flat_amount
      : (earlyBirdDiscount.percent_amount * sessionSale.reservation_price) / 100
    const discountedPrice = formatPrice(Math.max(sessionSale.reservation_price - discountValue, 0))

    const earlyBirdEndAt = formatDate(sessionSale.early_bird_price_end_at)

    pricing.item_detail = `${discountDisplay} Early Bird Discount Ends ${earlyBirdEndAt}`
    pricing.item_discounted_price = discountedPrice
  }

  return (
    <Pricing
      title='What Is the Tuition?'
      items={[pricing]}
    />
  )
}

SessionSalePricing.propTypes = {
  session_sale: React.PropTypes.shape({
    early_bird_discount_id: React.PropTypes.number,
    early_bird_price_start_at: React.PropTypes.string,
    early_bird_price_end_at: React.PropTypes.string,
    open_registration_end_at: React.PropTypes.string.required,
    reservation_price: React.PropTypes.number.required,
    title: React.PropTypes.string.required
  }),
  earlyBirdDiscount: React.PropTypes.shape({
    flat_amount: React.PropTypes.number,
    percent_amount: React.PropTypes.number
  }),
  currentDate: React.PropTypes.object
}

SessionSalePricing.defaultProps = {
  currentDate: moment()
}

export default load({
  earlyBirdDiscount: {
    urlSelector: (state, { session_sale: sessionSale }) => {
      if (!sessionSale || !sessionSale.early_bird_discount_id) {
        return null
      }

      return `/v2/discounts/${sessionSale.early_bird_discount_id}`
    },
    schema: schemas.discount
  }
})(SessionSalePricing)
