import React from 'react'
import { find } from 'lodash'

import MetricsCard from '../components/MetricsCard'
import MetricsSubcard from '../components/MetricsSubcard'
import MetricsSubcards from '../components/MetricsSubcards'
import MetricsTitle from '../components/MetricsTitle'
import MetricsSubtitle from '../components/MetricsSubtitle'
import MetricsNumber from '../components/MetricsNumber'
import AccountHeading from '../../components/AccountHeading'
import MetricsTitleWrapper from '../components/MetricsTitleWrapper'

const SessionReservationMetrics = ({ data }) => {
  const fspReservations = find(data, { name: 'fsp' }).value
  const alumniReservations = find(data, { name: 'alumni' }).value
  const writingChallengeReservations = find(data, { name: 'writing_challenge' }).value
  const currentWritingChallengeReservations = find(data, { name: 'current_writing_challenge' }).value

  return (
    <div>
      <AccountHeading>Session Reservations</AccountHeading>
      <MetricsCard>
        <MetricsSubcards>
          <MetricsSubcard width='half'>
            <MetricsTitleWrapper>
              <MetricsTitle>
                FSP Registrations
              </MetricsTitle>
            </MetricsTitleWrapper>
            <MetricsNumber>
              {fspReservations}
            </MetricsNumber>
          </MetricsSubcard>
          <MetricsSubcard width='half'>
            <MetricsTitleWrapper>
              <MetricsTitle>
                Alumni Registrations
              </MetricsTitle>
            </MetricsTitleWrapper>
            <MetricsNumber>
              {alumniReservations}
            </MetricsNumber>
          </MetricsSubcard>
        </MetricsSubcards>
        <MetricsSubcards>
          <MetricsSubcard width='half'>
            <MetricsTitleWrapper>
              <MetricsTitle>
                14-Day Writing Challenge Registrations
              </MetricsTitle>
            </MetricsTitleWrapper>
            <MetricsNumber>
              {writingChallengeReservations}
            </MetricsNumber>
          </MetricsSubcard>
          <MetricsSubcard width='half'>
            <MetricsTitleWrapper>
              <MetricsTitle>
                14-Day Writing Challenge Registrations
              </MetricsTitle>
              <MetricsSubtitle>
                for the most recent session
              </MetricsSubtitle>
            </MetricsTitleWrapper>
            <MetricsNumber>
              {currentWritingChallengeReservations}
            </MetricsNumber>
          </MetricsSubcard>
        </MetricsSubcards>
      </MetricsCard>
    </div>
  )
}

export default SessionReservationMetrics
