import React from 'react'

import { Link } from 'core'

import css from '../style.scss'

const FooterContactDetails = () => {
  return (
    <div className={css.contactDetails}>
      <div className={css.break}>
        <div className={css.legalWrapper}>
          <Link to='/legal' className={css.legal}>
            Legal
          </Link>
        </div>
        <div className={css.email}>
          Email: <a href='mailto:help@ncfdd.org' className={css.mailToLink}>Help@ncfdd.org</a>
        </div>
      </div>
      <div className={css.phone}>
        Phone: 313.347.8485
      </div>
    </div>
  )
}

export default FooterContactDetails
