import { apiResponse, schemas } from 'api'
import { createClient } from 'auth'
import {
  WEBINAR_COMPLETE_REQUEST,
  WEBINAR_COMPLETE_SUCCESS,
  WEBINAR_COMPLETE_FAIL,
  WEBINAR_START_REQUEST,
  WEBINAR_START_SUCCESS,
  WEBINAR_START_FAIL,
  WEBINAR_REGISTER_REQUEST,
  WEBINAR_REGISTER_SUCCESS,
  WEBINAR_REGISTER_FAIL,
  WEBINAR_UNREGISTER_REQUEST,
  WEBINAR_UNREGISTER_SUCCESS,
  WEBINAR_UNREGISTER_FAIL,
  WEBINAR_REFRESH_REQUEST,
  WEBINAR_REFRESH_SUCCESS,
  WEBINAR_REFRESH_FAIL,
  REFRESH_CORE_CURRICULUM
} from './types'
import { handleError } from 'errors'
import { trackFormSubmit, trackFormStart } from 'analytics'

export const registerForWebinar = (id, slug) => (dispatch, getState) => {
  trackFormStart({ formName: `webinar register - ${slug}` })
  dispatch({ type: WEBINAR_REGISTER_REQUEST })
  return createClient(getState())
    .post(`/v2/webinars/${id}/register`)
    .then((response) => response.json())
    .then((data) => {
      trackFormSubmit({ formName: `webinar register - ${slug}` })
      dispatch(apiResponse({
        type: WEBINAR_REGISTER_SUCCESS,
        data,
        schema: schemas.webinar
      }))
    })
    .catch((error) => {
      dispatch({ type: WEBINAR_REGISTER_FAIL })
      dispatch(handleError(error))
    })
}

export const unregisterForWebinar = (id, slug) => (dispatch, getState) => {
  dispatch({ type: WEBINAR_UNREGISTER_REQUEST })
  trackFormStart({ formName: `webinar unregister - ${slug}` })
  return createClient(getState())
    .post(`/v2/webinars/${id}/unregister`)
    .then((response) => response.json())
    .then((data) => {
      trackFormSubmit({ formName: `webinar unregister - ${slug}` })
      dispatch(apiResponse({
        type: WEBINAR_UNREGISTER_SUCCESS,
        data,
        schema: schemas.webinar
      }))
    })
    .catch((error) => {
      dispatch({ type: WEBINAR_UNREGISTER_FAIL })
      dispatch(handleError(error))
    })
}

export const startWebinar = id => (dispatch, getState) => {
  dispatch({ type: WEBINAR_START_REQUEST })
  const client = createClient(getState())

  return client
    .post(`/v2/webinars/${id}/start`)
    .then(response => response.json())
    .then(data => {
      dispatch(apiResponse({
        type: WEBINAR_START_SUCCESS,
        data,
        schema: schemas.webinar
      }))

      /* refresh core curriculum completeness */
      if (data.type === 'curriculum') {
        return client.get('/v2/core_curriculum')
          .then(res => res.json())
          .then(data => {
            dispatch(apiResponse({
              type: REFRESH_CORE_CURRICULUM,
              data,
              schema: [schemas.coreCurriculumWebinar]
            }))
          })
          .catch(() => { /* ignore */ })
      }
    })
    .catch(error => {
      dispatch({ type: WEBINAR_START_FAIL })
      dispatch(handleError(error))
    })
}

export const completeWebinar = id => (dispatch, getState) => {
  dispatch({ type: WEBINAR_COMPLETE_REQUEST })
  const client = createClient(getState())

  return client
    .post(`/v2/webinars/${id}/complete`)
    .then(response => response.json())
    .then(data => {
      dispatch(apiResponse({
        type: WEBINAR_COMPLETE_SUCCESS,
        data,
        schema: schemas.webinar
      }))

      /* refresh core curriculum completeness */
      if (data.type === 'curriculum') {
        return client.get('/v2/core_curriculum')
          .then(res => res.json())
          .then(data => {
            dispatch(apiResponse({
              type: REFRESH_CORE_CURRICULUM,
              data,
              schema: [schemas.coreCurriculumWebinar]
            }))
          })
          .catch(() => { /* ignore */ })
      }
    })
    .catch(error => {
      dispatch({ type: WEBINAR_COMPLETE_FAIL })
      dispatch(handleError(error))
    })
}

export const refreshWebinar = slug => (dispatch, getState) => {
  dispatch({ type: WEBINAR_REFRESH_REQUEST })
  return createClient(getState())
    .get(`/v2/webinars/slug/${slug}`)
    .then((res) => res.json())
    .then((data) => {
      dispatch(apiResponse({
        type: WEBINAR_REFRESH_SUCCESS,
        data,
        schema: schemas.webinar
      }))
    })
    .catch((error) => {
      dispatch({ type: WEBINAR_REFRESH_FAIL })
      dispatch(handleError(error))
    })
}
