import { createClient } from 'auth'
import {
  BadRequestError,
  ForbiddenError
} from 'api'
import {
  CONFIRM_EMAIL_FAIL,
  CONFIRM_EMAIL_SUCCESS
} from './types'
import { handleError } from 'errors'

const confirmEmailFail = () => ({
  type: CONFIRM_EMAIL_FAIL
})

const confirmEmailSuccess = () => ({
  type: CONFIRM_EMAIL_SUCCESS
})

export const confirmEmail = token => (dispatch, getState) =>
  createClient(getState())
    .post('/v2/users/email/confirm', { body: { token } })
    .then(res => res.json())
    .then(body => {
      dispatch(confirmEmailSuccess())
    })
    .catch(err => {
      if (err instanceof BadRequestError || err instanceof ForbiddenError) {
        dispatch(confirmEmailFail())
      } else {
        dispatch(handleError(err))
      }
    })
