import React from 'react'
import qs from 'qs'

import { Heading, EventCollection } from 'core'
import { Section, SectionOverride } from 'layout'
import { load, schemas } from 'api'

import EventsEmpty from './EventsEmpty'

const Events = ({ webinars, sessions }) => {
  let eventsElement

  if (!webinars || !sessions) {
    eventsElement = null
  } else if (!webinars.length && !sessions.length) {
    eventsElement = <EventsEmpty />
  } else {
    const webinarEvents = webinars.map(webinar => ({
      type: 'webinar',
      target: webinar
    }))

    const sessionEvents = sessions.map(session => ({
      type: 'session_sale',
      target: session
    }))

    const events = webinarEvents.concat(sessionEvents)

    eventsElement = <EventCollection collection={events} />
  }

  return (
    <Section mobileNoPadding>
      <SectionOverride>
        <Heading size='h4' weight='light'>
          Your Upcoming Events
        </Heading>
      </SectionOverride>
      {eventsElement}
    </Section>
  )
}

Events.propTypes = {
  webinars: React.PropTypes.arrayOf(React.PropTypes.object),
  sessions: React.PropTypes.arrayOf(React.PropTypes.object)
}

export default load({
  webinars: {
    urlSelector: () => {
      return `/v2/users/current/webinars?${qs.stringify({
        filters: {
          upcoming: true,
          registered: true
        }
      })}`
    },
    schema: [schemas.webinar]
  },
  sessions: {
    urlSelector: () => {
      return '/v2/users/current/sessions'
    },
    schema: [schemas.sessionSale]
  }
})(Events)
