import React, { PropTypes } from 'react'

import { load, schemas } from 'api'
import { loadFixedPageBlocks } from 'cms'
import { applyHOCs, selectSlug } from 'helpers'
import { page, pageLoadSelectors } from 'page'
import landingHeroUrl from './images/landingHero.jpg'

import InstitutionLandingHeader from './sections/InstitutionLandingHeader'

export const InstitutionLandingPage = (props) => {
  const { institution = [] } = props
  const heroImageUrl = institution.hero_image_url || landingHeroUrl

  // alert(JSON.stringify(institution.membership))

  return (
    <div>
      <InstitutionLandingHeader
        name={institution.name}
        slug={institution.slug}
        heroUrl={heroImageUrl}
        activated_at={institution.membership ? institution.membership.activated_at : null}
        expires_at={institution.membership ? institution.membership.expires_at : null}
      />
    </div>
  )
}

InstitutionLandingPage.propTypes = {
  // api
  institution: PropTypes.shape({
    name: PropTypes.string,
    primary_account_holder: PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      avatar_url: PropTypes.string
    })
  }).isRequired,
  fixedPageBlocks: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default applyHOCs([
  page(pageLoadSelectors(['institution'])),
  load({
    institution: {
      urlSelector: (state, props) => {
        return `/v2/institutions/slug/${props.slug}`
      },
      schema: schemas.institution
    }
  }),
  loadFixedPageBlocks({
    pageSelector: () => 'institution_landing_page'
  }),
  selectSlug({ paramName: 'institutionSlug' })
])(InstitutionLandingPage)
