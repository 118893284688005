import React from 'react'

import { Avatar } from '../Avatar'
import { HorizontalRule } from '../HorizontalRule'
import { Grid, GridItem, Section, Spacer } from 'layout'

import css from './style.scss'
import { kerryAnn } from 'images'
import signature from './assets/kerry-ann-signature.png'

const user = {
  name: 'Kerry Ann Rockquemore',
  avatar_url: kerryAnn
}

const MondayMotivatorFooter = () => {
  return (
    <Section narrow>
      <Spacer size='sm' />
      <HorizontalRule full />
      <Spacer size='sm' />
      <Grid>
        <GridItem col='2/3' tablet1of1 mobile1of1>
          <p className={css.mondayMotivatorFooterText}>
            Peace & Productivity,
            <br />
            <img src={signature} alt='Kerry Ann Rockquemore' />
            <br />
            Kerry Ann Rockquemore, PhD
            <br />
            Founder, National Center for Faculty Development & Diversity
          </p>
        </GridItem>
        <GridItem col='1/3' tabletHide mobileHide>
          <Avatar user={user} size='xxl' />
        </GridItem>
      </Grid>
    </Section>
  )
}

export default MondayMotivatorFooter
