import React from 'react'

import { Button, Card, Contact, Heading, Link } from 'core'
import { formatWebinarDate, formatTimeRange } from 'helpers'

import css from './style.scss'

const webinarTypes = {
  curriculum: 'Core Curriculum',
  guest: 'Guest Expert Webinar',
  course: 'Multi-Week Course'
}

const webinarType = type => webinarTypes[type] || type

/**
 * The `<CardWebinar />` is an enhanced `<Card />`, formatting passed content
 * specifically for the card instance in the user dashboard. Note the specific
 * data structure expected.
 */
const CardWebinar = (props) => {
  const { slug, title, type, facilitator, start_at: startAt, end_at: endTime } = props.webinar

  return (
    <div className={css.cardWrapper}>
      <Card>
        <div className={css.dashboard}>
          <div>
            <p className={css.details}>{webinarType(type)}</p>
            <Heading size='h4' weight='thin' center noMargin>
              {title}
            </Heading>
            <p className={css.details}>{formatWebinarDate(startAt)}</p>
            <p className={css.details}>{formatTimeRange(startAt, endTime)}</p>
          </div>
          <Contact user={facilitator} size='xs' center />
          <Link to={`/webinars/${slug}`} fullWidth>
            <Button size='secondary' noMargin>
              View Details
            </Button>
          </Link>
        </div>
      </Card>
    </div>
  )
}

CardWebinar.propTypes = {
  webinar: React.PropTypes.shape({
    slug: React.PropTypes.string.isRequired,
    title: React.PropTypes.string,
    type: React.PropTypes.string,
    start_at: React.PropTypes.string,
    end_at: React.PropTypes.string,
    facilitator: React.PropTypes.object
  }).isRequired
}

export default CardWebinar
