import React, { PropTypes } from 'react'
import { PieChart } from '@thomasdashney/react-d3-components'
import { find } from 'lodash'

import AccountHeading from '../components/AccountHeading'
import ChartTooltip from './components/ChartTooltip'
import MetricsCard from './components/MetricsCard'
import css from './style.scss'

const BreakdownChart = ({ title, data, options }) => {
  const values = data.map(({ name, value }) => {
    let x
    if (!name) {
      x = 'Unspecified'
    } else {
      const option = find(options, { value: name })
      x = option ? option.name : name
    }

    return { x, y: value }
  })

  return (
    <div>
      <AccountHeading>{title}</AccountHeading>
      <MetricsCard>
        <div className={css.breakdownChartWrapper}>
          <PieChart
            data={{ values }}
            width={600}
            height={270}
            margin={{ top: 30, bottom: 10, left: 120, right: 120 }}
            tooltipHtml={(name, value) => (
              <ChartTooltip>
                {name}: {value} {value > 1 ? 'users' : 'user'}
              </ChartTooltip>
            )}
          />
        </div>
      </MetricsCard>
    </div>
  )
}

BreakdownChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number.isRequired
  })).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired
}

export default BreakdownChart
