import React from 'react'

import css from './style.scss'

const NoticeBox = ({ children }) => (
  <div className={css.noticeBox}>
    {children}
  </div>
)

export default NoticeBox
