import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { isIAHSelector } from 'auth'
import { Link } from 'core'
import { Grid, GridItem } from 'layout'
import css from './style.scss'

const AccountNavigationItem = ({ children, to }) => (
  <li>
    <Link to={to} activeClassName={css.active}>
      {children}
    </Link>
  </li>
)

AccountNavigationItem.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
}

export const AccountNavigation = ({ isIAH }) => {
  return (
    <Grid>
      <GridItem col='1/1' tablet1of2>
        <ul className={css.navigation}>
          <AccountNavigationItem to='/account/profile'>
            Account Profile
          </AccountNavigationItem>
          <AccountNavigationItem to='/account/payments'>
            Payments
          </AccountNavigationItem>
          <AccountNavigationItem to='/account/buddy-match'>
            Buddy Match
          </AccountNavigationItem>
          <AccountNavigationItem to='/account/membership-type'>
            Membership Type
          </AccountNavigationItem>
        </ul>
      </GridItem>

      <GridItem col='1/1' tablet1of2>
        {isIAH && (
          <ul className={css.navigation}>
            <AccountNavigationItem to='/account/institution-profile'>
              Institution Profile
            </AccountNavigationItem>
            <AccountNavigationItem to='/account/institution-payments'>
              Institution Payments
            </AccountNavigationItem>
            <AccountNavigationItem to='/account/institution-metrics'>
              Institution Metrics
            </AccountNavigationItem>
            <AccountNavigationItem to='/account/institution-subaccounts'>
              Institution Subaccounts
            </AccountNavigationItem>
          </ul>
        )}
      </GridItem>
    </Grid>
  )
}

AccountNavigation.propTypes = {
  isIAH: PropTypes.bool
}

export default connect(
  createStructuredSelector({
    isIAH: isIAHSelector
  })
)(AccountNavigation)
