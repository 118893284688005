import React from 'react'

import { Heading, Pagination } from 'core'
import { Grid, GridItem } from 'layout'

import ResultSingle from './ResultSingle'

const ResultList = ({ results, total, pages }) => (
  <Grid>
    <GridItem col='1/4' mobile1of1>
      <Heading size='h4' weight='light'>
        {total === 1 ? '1 result found' : `${total} results found`}
      </Heading>
    </GridItem>
    <GridItem col='3/4' mobile1of1>
      {
        results.map((result, i) => (
          <ResultSingle key={i} result={result} />
        ))
      }
      <Pagination pages={pages} />
    </GridItem>
  </Grid>
)

ResultList.propTypes = {
  results: React.PropTypes.array.isRequired,
  total: React.PropTypes.number.isRequired,
  pages: React.PropTypes.number.isRequired
}

ResultList.defaultProps = {
  total: 0
}

export default ResultList
