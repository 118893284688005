import { startSubmit, stopSubmit } from 'redux-form'
import { browserHistory } from 'react-router'

import {
  createClient
} from 'auth'
import {
  createStripeChargeToken,
  handlePaymentError
} from 'stripe'

import { ORDER_FORM_NAME } from './constants'
import { isInstitutionOrderSelector, paymentAddressSelector } from './selectors'

export const makePayment = ({ order, institution, stripeElements }) => (dispatch, getState) => {
  const state = getState()
  const isInstitutionOrder = isInstitutionOrderSelector(state, { order })
  const formAddress = paymentAddressSelector(ORDER_FORM_NAME)(state)

  const client = createClient(state)
  const isAnon = window.location.href.includes(order.anon_url_uuid)

  const updatePromises = [
    createStripeChargeToken(stripeElements.cardNumber)
      .then(chargeToken => {
        if (!isAnon) {
          return client.post('/v2/payments', {
            body: {
              order_id: order.id,
              stripe_charge_token: chargeToken.id,
              payer_institution: formAddress.invoice_institution,
              payer_address_line_1: formAddress.address_line_1,
              payer_address_line_2: formAddress.address_line_2,
              payer_city: formAddress.city,
              payer_state: formAddress.state,
              payer_country: formAddress.country,
              payer_postal_code: formAddress.postal_code,
              payer_billing_name: formAddress.invoice_billing_name,
              payer_billing_email: formAddress.invoice_billing_email
            }
          })
        } else {
          return client.post('/v2/anon/payments', {
            body: {
              order_id: order.id,
              anon_url_uuid: order.anon_url_uuid,
              stripe_charge_token: chargeToken.id,
              payer_institution: formAddress.invoice_institution,
              payer_address_line_1: formAddress.address_line_1,
              payer_address_line_2: formAddress.address_line_2,
              payer_city: formAddress.city,
              payer_state: formAddress.state,
              payer_country: formAddress.country,
              payer_postal_code: formAddress.postal_code,
              payer_billing_name: formAddress.invoice_billing_name,
              payer_billing_email: formAddress.invoice_billing_email
            }
          })
        }
      })
  ]

  dispatch(startSubmit(ORDER_FORM_NAME))
  return Promise.all(updatePromises)
    .then(() => {
      dispatch(stopSubmit(ORDER_FORM_NAME))
      if (isAnon) {
        browserHistory.push('/anon/payment-complete')
      } else if (isInstitutionOrder) {
        browserHistory.push('/account/institution-payments')
      } else {
        browserHistory.push('/account/payments')
      }
    })
    .catch(err => dispatch(handlePaymentError(err, ORDER_FORM_NAME)))
}
