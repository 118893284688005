import React from 'react'

import { Heading } from 'core'
import { Section } from 'layout'

import css from './style.scss'

const HeaderTertiary = ({ title }) => (
  <div className={css.wrapper}>
    <Section>
      <Heading size='h3' color='white' noMargin>{title}</Heading>
    </Section>
  </div>
)

HeaderTertiary.propTypes = {
  title: React.PropTypes.string.isRequired
}

export default HeaderTertiary
