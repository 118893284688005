import React from 'react'
import MediaQuery from 'react-responsive'

import { Card } from 'core'
import { Combo, ComboFirst, ComboLast, Grid, GridItem } from 'layout'

import { CMSSection } from '../components'

import css from './style.scss'
import { breakpoints } from 'styles'

const { mobileMax } = breakpoints

const Checklist = (props) => {
  const listStyle = !props.img_url ? { margin: '0 auto' } : null
  const checklistItems = (
    props.checklist_items.map((item, index) => (
      <div key={index} className={css.item}>
        <Combo>
          <ComboFirst>
            <div className={css.checkmark} />
          </ComboFirst>
          <ComboLast>
            <div dangerouslySetInnerHTML={{ __html: item.checklist_item }} />
          </ComboLast>
        </Combo>
      </div>
    ))
  )

  return (
    <CMSSection
      title={props.title}
      subtitle={props.subtitle}
      backgroundImage={props.img_url}
      backgroundMask='gradientWhite'
      mobileBannerImage
      noBorder
    >
      <Grid>
        <GridItem col='1/2' tablet2of3 phablet1of1 mobile1of1 style={listStyle}>
          <MediaQuery minWidth={mobileMax}>
            <Card>
              {checklistItems}
            </Card>
          </MediaQuery>
          <MediaQuery maxWidth={mobileMax}>
            {checklistItems}
          </MediaQuery>
        </GridItem>
      </Grid>
    </CMSSection>
  )
}

Checklist.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  img_url: React.PropTypes.string,
  checklist_items: React.PropTypes.arrayOf(React.PropTypes.shape({
    checklist_item: React.PropTypes.string.isRequired
  }))
}

export default Checklist
