import { getFormValues, startSubmit, stopSubmit } from 'redux-form'

import { apiResponse, schemas } from 'api'
import { createClient, currentUserInstitutionIdSelector } from 'auth'
import { handleError } from 'errors'
import { closeForm } from 'form-group'

import { INSTITUTION_BILLING_ADDRESS_FORM } from './constants'
import { INSTITUTION_BILLING_ADDRESS_SUCCESS } from './types'

export const saveInstitutionBillingAddress = () => (dispatch, getState) => {
  dispatch(startSubmit(INSTITUTION_BILLING_ADDRESS_FORM))

  const state = getState()

  return createClient(state)
    .patch(`/v2/institutions/${currentUserInstitutionIdSelector(state)}`, {
      body: {
        ...getFormValues(INSTITUTION_BILLING_ADDRESS_FORM)(state)
      }
    })
    .then(response => response.json())
    .then(updatedInstitution => {
      dispatch(apiResponse({
        type: INSTITUTION_BILLING_ADDRESS_SUCCESS,
        data: updatedInstitution,
        schema: schemas.institution
      }))
      dispatch(stopSubmit(INSTITUTION_BILLING_ADDRESS_FORM))
      dispatch(closeForm(INSTITUTION_BILLING_ADDRESS_FORM))
    })
    .catch(err => {
      dispatch(stopSubmit(INSTITUTION_BILLING_ADDRESS_FORM))
      dispatch(handleError(err))
    })
}
