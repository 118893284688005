import { UserAuthWrapper } from 'redux-auth-wrapper'
import { createStructuredSelector } from 'reselect'

import { buildWrapper } from 'helpers'
import {
  loadingCurrentUserSelector,
  currentUserIsActiveMemberSelector,
  currentMembershipInvalidType,
  invalidStatuses
} from '../selectors'

const membershipRequiredPath = '/membership-expired'
const membershipNotActivePath = '/membership-not-active'

export default buildWrapper((options = {}) => {
  const membershipRedirect = UserAuthWrapper({
    wrapperDisplayName: 'RequireMembership',

    authenticatingSelector: loadingCurrentUserSelector,
    authSelector: createStructuredSelector({
      loading: loadingCurrentUserSelector,
      isActiveMember: currentUserIsActiveMemberSelector
    }),
    predicate: ({ isActiveMember, loading }) => !loading && isActiveMember,

    allowRedirectBack: false,
    failureRedirectPath: (state, ownProps) => {
      const membershipIssue = currentMembershipInvalidType(state)

      if (membershipIssue === invalidStatuses.notActive) {
        return membershipNotActivePath
      } else {
        return membershipRequiredPath
      }
    }
  })

  return WrappedComponent => membershipRedirect(WrappedComponent)
})
