import React from 'react'
import qs from 'qs'

import { load, schemas } from 'api'
import { PostCollection } from 'core'
import { applyHOCs, paginationPageCount, paginationQueryBuilder } from 'helpers'
import { HeaderTertiary } from 'page-blocks'
import { page } from 'page'

const url = '/monday-motivator'
const REQUEST_LIMIT = 6

const buildUrl = (post) => {
  return `${url}/${post.slug}`
}

const MondayMotivatorRoute = props => {
  const { totalCount } = props.loadStatus.posts

  return (
    <div>
      <HeaderTertiary title='The Monday Motivator' />
      <PostCollection
        mondayMotivator
        collection={props.posts}
        buildUrl={buildUrl}
        pages={paginationPageCount(totalCount, REQUEST_LIMIT)}
      />
    </div>
  )
}

MondayMotivatorRoute.defaultProps = {
  posts: []
}

export default applyHOCs([
  page({ title: 'Monday Motivator' }),
  load({
    posts: {
      urlSelector: (state, props) => {
        const { page } = props.location.query
        const queries = {
          filters: {
            type: 'monday_motivator'
          }
        }

        return `/v2/posts?${qs.stringify(paginationQueryBuilder(page, REQUEST_LIMIT, queries))}`
      },
      schema: [schemas.post]
    }
  })
])(MondayMotivatorRoute)
