import React from 'react'

import css from './style.scss'

const ChartTooltip = ({ children }) => {
  return (
    <div className={css.chartTooltip}>
      {children}
    </div>
  )
}

export default ChartTooltip
