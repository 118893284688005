import { has } from 'lodash'
import Immutable from 'seamless-immutable'

const initialState = Immutable.from({})

export default schema => (state = initialState, action) => {
  if (has(action, `entities.${schema}`)) {
    state = state.merge(action.entities[schema], { deep: true })
  }

  return state
}
