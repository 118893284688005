import React, { PropTypes } from 'react'
import moment from 'moment'

import css from './style.scss'

const MembershipProgress = ({ start, end }) => {
  const totalDays = moment(end).diff(start, 'days')
  const daysComplete = moment().diff(start, 'days')

  return (
    <progress
      className={css.progressBar}
      max={totalDays}
      value={daysComplete}
    />
  )
}

MembershipProgress.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired
}

export default MembershipProgress
