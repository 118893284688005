import React from 'react'

import css from '../style.scss'
import SearchIcon from './icons/search.svg'

const Button = props => {
  return (
    <button
      className={css.button}
      type='submit'
      {...props}
    >
      <SearchIcon />
    </button>
  )
}

export default Button
