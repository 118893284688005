import React from 'react'
import { connect } from 'react-redux'
import { compact } from 'lodash'

import { currentUserSelector, loadingCurrentUserSelector } from 'auth'
import { Link } from 'core'
import { Section } from 'layout'
import { openLoginModal } from 'login-modal'
import { SpinnerIcon } from 'icons'
import { ncfddLogoOnDark } from 'images'
import { resourcesMenuSelector, servicesMenuSelector, aboutUsMenuSelector } from 'settings'

import SearchIcon from './icons/search.svg'
import HamburgerIcon from './icons/hamburger.svg'

import { openNavigation } from './actions'
import GlobalNav from './menu/GlobalNav'
import MyAccountMenu from './menu/desktop/my-account-menu/MyAccountMenu'

import css from './style.scss'

export const Header = (props) => {
  const { openNavigation } = props
  const classNames = compact(
    [
      css.header,
      props.transparent ? css.transparent : null
    ]
  ).join(' ')

  if (props.loading) {
    return (
      <div className={classNames}>
        <SpinnerIcon className={css.loadingSpinner} />
      </div>
    )
  }

  const navigationClassNames = [
    css.navigation,
    !!props.user && css.loggedIn
  ]

  /* eslint-disable react/jsx-handler-names */
  return (
    <div className={classNames}>
      <Section>
        <div className={css.wrapper}>
          <div className={css.logo}>
            <Link to={props.user ? '/dashboard' : '/'}>
              <img
                src={ncfddLogoOnDark}
              />
            </Link>
          </div>
          <div className={compact(navigationClassNames).join(' ')}>
            <GlobalNav
              isLoggedIn={props.user}
              services={props.servicesMenu}
              resources={props.resourcesMenu}
              aboutUs={props.aboutUsMenu}
            />
          </div>
          {!props.navIsOpen && (
            <div className={css.right}>
              {
                props.user
                  ? [
                    <Link key='search_icon' to='/search'>
                      <div className={css.icon}>
                        <SearchIcon />
                      </div>
                    </Link>,
                    <MyAccountMenu key='my_account_menu' />
                    ]
                  : [
                    <Link key='join_link' to='/join'>
                      <button
                        className={css.joinButton}
                      >
                        Become a member
                      </button>
                    </Link>,
                    <button
                      key='sign_in_button'
                      type='button'
                      onClick={props.openLoginModal}
                      className={css.signInButton}
                    >
                      Sign In
                    </button>
                    ]
              }
              <div className={`${css.icon} ${css.hamburger}`} onClick={openNavigation}>
                <HamburgerIcon />
              </div>
            </div>
          )}
        </div>
      </Section>
    </div>
  )
}

Header.propTypes = {
  transparent: React.PropTypes.bool,
  user: React.PropTypes.shape({
    name: React.PropTypes.string
  }),
  servicesMenu: React.PropTypes.array,
  aboutUsMenu: React.PropTypes.array,
  resourcesMenu: React.PropTypes.array,
  openLoginModal: React.PropTypes.func.isRequired,
  navIsOpen: React.PropTypes.bool
}

const mapStateToProps = (state) => ({
  user: currentUserSelector(state),
  loading: loadingCurrentUserSelector(state),
  servicesMenu: servicesMenuSelector(state),
  aboutUsMenu: aboutUsMenuSelector(state),
  resourcesMenu: resourcesMenuSelector(state),
  navIsOpen: state.header.navIsOpen
})

const mapDispatchToProps = { openNavigation, openLoginModal }

export default connect(mapStateToProps, mapDispatchToProps)(Header)
