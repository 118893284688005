import { push } from 'react-router-redux'
import {
  getFormValues,
  startSubmit,
  stopSubmit
} from 'redux-form'

import { createClient, currentUserSelector, loadCurrentUser } from 'auth'
import { handleError } from 'errors'

import { FORM_NAME } from '../constants'

export const submit = () => (dispatch, getState) => {
  dispatch(startSubmit(FORM_NAME))

  const body = getFormValues(FORM_NAME)(getState())
  const { id } = currentUserSelector(getState())

  return createClient(getState())
    .patch(`/v2/users/${id}`, { body })
    .then(response => {
      return response.json()
    })
    .then(updatedUser => {
      dispatch(loadCurrentUser(updatedUser))
    })
    .then(() => {
      dispatch(stopSubmit(FORM_NAME))
      dispatch(push('/dashboard'))
    })
    .catch(err => {
      dispatch(stopSubmit(FORM_NAME))
      dispatch(handleError(err))
      return err
    })
}
