import moment from 'moment-timezone'

const DAY_MONTH_FORMAT = 'MMMM Do'
const DAY_MONTH_YEAR_FORMAT = `${DAY_MONTH_FORMAT}, YYYY`

export default (startDate, endDate, formatDM = DAY_MONTH_FORMAT, formatDMY = DAY_MONTH_YEAR_FORMAT) => {
  const start = moment.utc(startDate)
  const end = moment.utc(endDate)

  const formattedStart = start.year() === end.year()
    ? start.format(formatDM)
    : start.format(formatDMY)
  const formattedEnd = end.format(formatDMY)

  return `${formattedStart} - ${formattedEnd}`
}
