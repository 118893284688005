import React, { PropTypes } from 'react'

import css from './style.scss'

const AccountHeading = ({ children }) => (
  <h3 className={css.accountHeading}>
    {children}
  </h3>
)

AccountHeading.propTypes = {
  children: PropTypes.string
}

export default AccountHeading
