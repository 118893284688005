import { UserAuthWrapper } from 'redux-auth-wrapper'

import { buildWrapper } from 'helpers'
import {
  loadingCurrentUserSelector,
  isAuthenticatedSelector
} from '../selectors'

export default buildWrapper((options = {}) => {
  const { redirectPath = '/login' } = options
  return UserAuthWrapper({
    wrapperDisplayName: 'RequireAuth',

    authenticatingSelector: loadingCurrentUserSelector,
    authSelector: state => ({
      authenticated: isAuthenticatedSelector(state)
    }),
    predicate: ({ authenticated }) => authenticated,

    allowRedirectBack: true,
    failureRedirectPath: redirectPath
  })
})
