import React, { Component } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

import { NotFoundPage } from './NotFoundPage'
import { ForbiddenPage } from './ForbiddenPage'
import { InternalServerErrorPage } from './InternalServerErrorPage'
import { applyHOCs, getDisplayName } from 'helpers'

import Page from './Page'
import { FormPage } from './FormPage'

const pageWrapper = (options = {}) => WrappedComponent => {
  const { layout = 'site', pageType = 'page', title, noPageHead } = options

  let PageComponent

  if (layout === 'site') {
    PageComponent = Page
  } else if (layout === 'form') {
    PageComponent = FormPage
  } else {
    throw new Error('layout must be either "site" or "form"')
  }

  return class PageWrapper extends Component {
    componentWillMount () {
      const { redirectPath } = this.props
      if (redirectPath) {
        browserHistory.replace(redirectPath)
      }
    }

    componentWillReceiveProps (nextProps) {
      const { redirectPath } = nextProps
      if (redirectPath) {
        browserHistory.replace(redirectPath)
      }
    }

    render () {
      const { loading, forbidden, notFound, unknownError, ...otherProps } = this.props

      let pageContent

      if (loading) {
        pageContent = null // TODO add loading spinner
      } else if (forbidden) {
        pageContent = <ForbiddenPage pageType={pageType} />
      } else if (notFound) {
        pageContent = <NotFoundPage />
      } else if (unknownError) {
        pageContent = <InternalServerErrorPage />
      } else {
        pageContent = <WrappedComponent {...otherProps} />
      }

      return (
        <PageComponent showFooter={!!pageContent} noPageHead={noPageHead} title={title}>
          {pageContent}
        </PageComponent>
      )
    }
  }
}

export default (options = {}) => WrappedComponent => {
  const {
    loadingSelector = () => false,
    forbiddenSelector = () => false,
    notFoundSelector = () => false,
    unknownErrorSelector = () => false,
    redirectSelector = () => null
  } = options

  const WrappedPage = applyHOCs([
    pageWrapper(options),
    connect(
      (...selectorArgs) => {
        const loading = loadingSelector(...selectorArgs)
        return {
          loading,
          forbidden: loading ? false : forbiddenSelector(...selectorArgs),
          notFound: loading ? false : notFoundSelector(...selectorArgs),
          unknownError: loading ? false : unknownErrorSelector(...selectorArgs),
          redirectPath: loading ? null : redirectSelector(...selectorArgs)
        }
      }
    )
  ])(WrappedComponent)

  WrappedPage.displayName = `PageWrapper(${getDisplayName(WrappedComponent)})`

  return WrappedPage
}
