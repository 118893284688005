import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { push } from 'react-router-redux'
import { sortBy } from 'lodash'

import { Button, Heading } from 'core'
import { Select, validators } from 'form'
import { applyHOCs } from 'helpers'
import { CMSSection } from '../components'

import { load, schemas } from 'api'

import ladySmileLaptopUrl from './assets/ladySmileLaptop.jpg'
import css from './style.scss'

const FORM_NAME = 'selectInstitutionDropdown'

const SelectInstitution = (props) => {
  const { institutions = [] } = props
  return (
    <CMSSection
      backgroundImage={ladySmileLaptopUrl}
      title={props.title}
      subtitle={props.subtitle}
      noBorder
      zIndexPriority
    >
      <div className={css.selectInstitutionSection}>
        <p>{props.copy}</p>
        <form
          onSubmit={props.handleSubmit(({ institutionSlug }) => {
            props.push(`/institutions/${institutionSlug}`)
          })}
        >
          <Heading size='h5' weight='medium'>
            Select your institution
          </Heading>
          <Field
            name='institutionSlug'
            component={Select}
            hideMeta
            disableValidationState
            validate={validators.required('Institution id is required')}
            options={sortBy(institutions, 'name').map(institution => ({
              name: institution.name,
              value: institution.slug
            }))}
          />
          <Button
            type='submit'
            size='primary'
            noMargin
            disabled={props.invalid}
          >
            Continue
          </Button>
        </form>
      </div>
    </CMSSection>
  )
}

SelectInstitution.propTypes = {
  // api
  institutions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  // redux-form
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  // mapDispatchToProps
  push: PropTypes.func,
  // CMS Fields
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string
}

export default applyHOCs([
  reduxForm({
    form: FORM_NAME
  }),
  connect(null, { push }),
  load({
    institutions: {
      urlSelector: () => '/v2/institutions',
      schema: [schemas.institution]
    }
  })
])(SelectInstitution)
