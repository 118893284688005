import React from 'react'

import { Heading } from 'core'

const ModalSubheading = ({ children }) => (
  <Heading size='h6' noMargin>
    {children}
  </Heading>
)

ModalSubheading.propTypes = {
  children: React.PropTypes.node
}

export default ModalSubheading
