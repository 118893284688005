import React, { PropTypes } from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import moment from 'moment'
import { filter } from 'lodash'

import { Form, FormSection, Input, validators } from 'form'
import { applyHOCs, applyPropTypes } from 'helpers'

import css from './style.scss'
import { reserveInstitutionSpots } from './actions'
import { INSTITUTION_RESERVATION_FORM_NAME } from '../../constants'
import SubmitSection from '../components/SubmitSection'
import NoticeBox from '../components/NoticeBox'
import { calculatePrice, sessionReservationsAreAvailable } from './helpers'

const formatDueDate = date => moment(date).format('dddd, MMMM DD')

export const Reserve = props => {
  const {
    count,
    sessionSale,
    reservations,
    reserveInstitutionSpots,
    invalid,
    getStepFormName,
    submitting
  } = props

  if (!reservations) {
    return null
  }

  const {
    num_institution_reservations_available: numSpotsRemaining
  } = sessionSale

  const maxSpots = numSpotsRemaining + reservations.length
  const minSpots = Math.max(filter(reservations, 'user').length, 1)

  const dueDate = formatDueDate(sessionSale.pre_registration_end_at)

  const reservationsAreAvailable = sessionReservationsAreAvailable(sessionSale)

  return (
    <Form name={getStepFormName && getStepFormName()} onSubmit={reserveInstitutionSpots}>
      <FormSection>
        <div className={css.reserveSection}>
          <p>
            <strong>
              {reservationsAreAvailable
                ? `How many spots would you like to reserve? (Maximum ${maxSpots})`
                : 'The reservation period has ended'}
            </strong>
          </p>

          <Field
            component={Input}
            name='count'
            type='number'
            maxInputWidth='81px'
            validate={[
              validators.required('You must specify the number of spots to reserve'),
              validators.min(minSpots, 'You can not remove reservations with users assigned to them. Please unassign some users.'),
              validators.max(maxSpots, `You cannot reserve more than ${maxSpots} spots at this time`)
            ]}
            min={1}
            max={maxSpots}
            disabled={!reservationsAreAvailable}
          />
          {reservationsAreAvailable && (
            <NoticeBox>
              <p>
                <strong>Please note: </strong>
                Payment for these seats is not due at this time. We will reach out
                with the finalized invoice upon receiving the names of the faculty
                you would like to sponsor.
              </p>
              <p>
                Names for these seats are due by {dueDate}. If you do not submit
                names by that date, please be aware that these seats will be
                released to the public and will no longer be reserved.
              </p>
            </NoticeBox>
          )}
        </div>
      </FormSection>
      <SubmitSection
        price={invalid ? null : calculatePrice(sessionSale, reservations, count)}
        buttonText='Continue'
        disabled={invalid || submitting}
        loading={submitting}
      />
    </Form>
  )
}

Reserve.propTypes = {
  // redux-form
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  // connect
  count: PropTypes.number.isRequired,
  reserveInstitutionSpots: PropTypes.func.isRequired,
  reservations: PropTypes.arrayOf(PropTypes.shape({
    order_item: PropTypes.shape({
      item_price: PropTypes.number.isRequired
    }).isRequired
  })).isRequired,
  // api
  sessionSale: PropTypes.shape({
    num_institution_reservations_available: PropTypes.number.isRequired,
    institution_reservation_order: PropTypes.shape({
      order_status: PropTypes.string.isRequired
    })
  }).isRequired
}

const valueSelector = formValueSelector(INSTITUTION_RESERVATION_FORM_NAME)

export default applyHOCs([
  reduxForm({
    form: INSTITUTION_RESERVATION_FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }),
  connect(
    (state, { reservations }) => ({
      count: Number(valueSelector(state, 'count')),
      initialValues: {
        count: reservations ? reservations.length : 0
      }
    }),
    (dispatch, props) => ({
      reserveInstitutionSpots: () => {
        dispatch(reserveInstitutionSpots(props))
      }
    })
  ),
  applyPropTypes({
    sessionSale: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  })
])(Reserve)
