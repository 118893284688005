import React, { PropTypes } from 'react'
import { Link } from 'core'
import { formatPrice } from 'helpers'
import { orderIsPayable, orderStatus } from '../helpers'
import css from './style.scss'
import DownloadInvoice from './DownloadInvoice'

const OrderTable = ({ orders }) => {
  return (
    <table className={css.orderTable}>
      <thead>
        <tr>
          <th>Description</th>
          <th>ID</th>
          <th>Amount</th>
          <th>Paid</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {orders.map(order => {
          const {
            id,
            description,
            order_total: orderTotal,
            amount_paid: amountPaid,
            amount_refunded: amountRefunded
          } = order
          const totalAmountPaid = amountPaid - amountRefunded

          return (
            <tr key={id}>
              <td>{description || 'N/A'}</td>
              <td>{id}</td>
              <td>{formatPrice(orderTotal)}</td>
              <td>{formatPrice(totalAmountPaid)}</td>
              <td>
                <strong>
                  {orderStatus(order)}
                </strong>
              </td>
              <td>
                {(orderIsPayable(order) && order.order_status !== 'payment_failed') && (
                  <Link to={`/orders/${order.id}`} type='text'>
                    Make Payment
                  </Link>
                )}
                <DownloadInvoice orderId={order.id} />
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

OrderTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string,
    order_total: PropTypes.number.isRequired,
    amount_paid: PropTypes.number.isRequired,
    order_status: PropTypes.string.isRequired
  }))
}

export default OrderTable
