import React, { PropTypes } from 'react'

import { load, schemas } from 'api'
import { Link } from 'core'

import { timeUntilExpiry } from './helpers'
import { NotificationBanner } from '../components/NotificationBanner'

export const SubaccountRenewalBanner = props => {
  const { membership, institution } = props

  if (!institution) return null

  return (
    <NotificationBanner
      message={
        <span>
          The NCFDD membership for {institution.name} will expire {timeUntilExpiry(membership)}.
          Contact your institution's representative
          {institution.primary_account_holder
            ? (
              <span>
                , {institution.primary_account_holder.name} (
                <Link to={`mailto:${institution.primary_account_holder.email}`}>
                  {institution.primary_account_holder.email}
                </Link>),
              </span>)
            : null}
          to renew the membership for an additional year.
        </span>
      }
    />
  )
}

SubaccountRenewalBanner.propTypes = {
  membership: PropTypes.shape({
    expires_at: PropTypes.string.isRequired
  }).isRequired,
  institution: PropTypes.shape({
    name: PropTypes.string.isRequired,
    primary_account_holder: PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })
  })
}

export default load({
  institution: {
    urlSelector: (state, { membership }) => {
      return `/v2/institutions/${membership.member_id}`
    },
    schema: schemas.institution
  }
})(SubaccountRenewalBanner)
