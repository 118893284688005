import React, { PropTypes } from 'react'
import { connect } from 'react-redux'

import { loadFixedPageBlocks } from 'cms'
import { applyHOCs } from 'helpers'
import { PageBlockRenderer } from 'core/components/PageBlockRenderer'

import FSPHeader from './FSPHeader'
import { acceptOffer } from './actions'

export class FSPLandingPage extends React.Component {
  componentWillMount () {
    const { offerToken, acceptOffer, sessionSale } = this.props

    if (offerToken) {
      acceptOffer(sessionSale, offerToken)
    }
  }

  render () {
    const { sessionSale, fixedPageBlocks } = this.props
    const hubspotFormId = fixedPageBlocks.find(block => block.block_type === 'FixedPageHeaderHubSpotForm')?.block_content?.hubspot_form_id
    return (
      <div>
        <FSPHeader sessionSale={sessionSale} hubspotFormId={hubspotFormId} />
        <PageBlockRenderer blocks={fixedPageBlocks} sessionSale={sessionSale} />
      </div>
    )
  }
}

FSPLandingPage.propTypes = {
  sessionSale: PropTypes.shape({
    advertised_start_date: PropTypes.string.isRequired,
    advertised_end_date: PropTypes.string.isRequired
  }).isRequired,
  offerToken: PropTypes.string,
  acceptOffer: PropTypes.func.isRequired
}

const mapStateToProps = (state, { location }) => ({
  offerToken: location.query.offer_token
})

const WithHOCs = applyHOCs([
  connect(mapStateToProps, { acceptOffer }),
  loadFixedPageBlocks({ pageSelector: () => 'fsp' })
])(FSPLandingPage)

WithHOCs.displayName = 'FSPLandingPage'

export default WithHOCs
