import { startSubmit, stopSubmit } from 'redux-form'
import { get } from 'lodash'

import { apiResponse, schemas, ForbiddenError, BadRequestError } from 'api'
import { createClient, hydrateAuthentication } from 'auth'
import { handleError } from 'errors'
import { openModal } from 'modal'

import { INDIVIDUAL_RESERVATION_FORM_NAME, SESSION_REGISTRATION_BUTTON_FORM_NAME } from '../../constants'
import { LOAD_UPDATED_SESSION_SALE } from './types'
import NonFSPConfirmModal from './NonFSPConfirmModal'
import { trackFormSubmit, trackFormStart } from 'analytics'
import { registrationFormSelector } from 'session-sales/individual-reservations/selectors'
import { login } from 'registration/actions'
import { SET_USER_REGISTRATION_ERROR } from 'session-sales/individual-reservations/types'

export const createAccountThenRegister = (sessionSale) => (dispatch, getState) => {
  const registrationInfo = registrationFormSelector(INDIVIDUAL_RESERVATION_FORM_NAME)(getState())
  createClient(getState()).post('/v2/users', {
    body: {
      first_name: registrationInfo.registration_first_name,
      last_name: registrationInfo.registration_last_name,
      email: registrationInfo.registration_email,
      password: registrationInfo.registration_password,
      institution_id: registrationInfo.registration_institution_id,
      session_sale_id: sessionSale?.id
    }
  })
    .then(() => dispatch(login(registrationInfo.registration_email, registrationInfo.registration_password)))
    .then(() => dispatch(register(sessionSale, true)))
    .catch(err => {
      if (err instanceof BadRequestError) {
        return err.response.json()
          .then(body => {
            if (body?.errors) {
              dispatch({
                type: SET_USER_REGISTRATION_ERROR,
                errors: body.errors
              })
            }
          })
      } else {
        dispatch(handleError(err))
      }
    })
}

export const register = (sessionSale, newMembershipCreated = false) => (dispatch, getState) => {
  trackFormStart({ formName: `SESSION REGISTER - ${sessionSale.slug}` })

  dispatch(startSubmit(SESSION_REGISTRATION_BUTTON_FORM_NAME))
  const client = createClient(getState())
  return client.post(`/v2/session_sales/${sessionSale.id}/reservations`, { body: { newMembershipCreated } })
    // load the updated session sale to update the session sale header state
    .then(() => client.get(`/v2/session_sales/${sessionSale.id}`))
    .then(response => response.json())
    .then(updatedSessionSale => {
      trackFormSubmit({ formName: `SESSION REGISTER - ${sessionSale.slug}` })
      dispatch(apiResponse({
        type: LOAD_UPDATED_SESSION_SALE,
        schema: schemas.sessionSale,
        data: updatedSessionSale
      }))
      dispatch(hydrateAuthentication())
      dispatch(stopSubmit(SESSION_REGISTRATION_BUTTON_FORM_NAME))
      dispatch(openModal(NonFSPConfirmModal, { sessionSale: updatedSessionSale }))
    })
    .catch(err => {
      dispatch(stopSubmit(SESSION_REGISTRATION_BUTTON_FORM_NAME))
      if (err instanceof ForbiddenError) {
        err.response.json()
          .then(body => {
            let errorMessage
            if (get(body, 'message') === 'Only FSP alumni can register for this session') {
              errorMessage = 'Only FSP alumni can register for this session'
            } else {
              errorMessage = 'You are forbidden from registering for this session'
            }
            dispatch(handleError(err, errorMessage))
          })
      } else {
        dispatch(handleError(err))
      }
    })
}
