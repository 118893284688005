import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { pick } from 'lodash'

import { currentUserSelector } from 'auth'
import { applyHOCs } from 'helpers'
import { Input, Radio, Select, Label, validators, TextArea, Form } from 'form'
import { formGroup, formGroupButtons } from 'form-group'
import { Grid, GridItem } from 'layout'
import { disciplineOptionsSelector, disciplineClassificationOptionsSelector, titleOptionsSelector } from 'settings'
import SelectInstitution from 'select-institution'

import { saveProfessionalDetails } from './actions'
import { PROFESSIONAL_DETAILS_FORM_NAME, analyticsFormName } from './constants'

const FormGroupButtons = formGroupButtons({ form: PROFESSIONAL_DETAILS_FORM_NAME })

const ProfessionalDetailsForm = ({ editing, disciplineOptions, disciplineClassificationOptions, titleOptions, save }) => {
  return (
    <Form
      name={analyticsFormName(PROFESSIONAL_DETAILS_FORM_NAME)}
      onSubmit={save}
    >
      <Grid>
        <GridItem col='1/2' mobile1of1>
          <Grid>
            <GridItem col='2/3' tablet1of1>
              <Label htmlFor='title'>Title</Label>
              <Field
                name='title'
                component={Select}
                validate={validators.required('Your title is required')}
                options={titleOptions}
                disabled={!editing}
              />
            </GridItem>
            <GridItem col='1/3' tablet1of1>
              <Label htmlFor='flags.tenured'>Are you tenured?</Label>
              <Field
                name='flags.tenured'
                component={Radio}
                disabled={!editing}
                parse={value => value === 'yes'}
                format={value => value ? 'yes' : 'no'}
                options={[
                  { name: 'Yes', value: 'yes' },
                  { name: 'No', value: 'no' }
                ]}
              />
            </GridItem>
          </Grid>
          <Label htmlFor='discipline'>Discipline</Label>
          <Field
            name='discipline'
            component={Select}
            validate={validators.required('Discipline is required')}
            options={disciplineOptions}
            allowCustom
            disabled={!editing}
          />
          <Label htmlFor='discipline_classification'>Discipline Classification</Label>
          <Field
            name='discipline_classification'
            component={Select}
            validate={validators.required('Discipline Classification is required')}
            options={disciplineClassificationOptions}
            disabled={!editing}
          />
          <Label htmlFor='department'>Department</Label>
          <Field
            name='department'
            component={Input}
            type='text'
            validate={validators.required('Department is required')}
            disabled={!editing}
          />
        </GridItem>
        <GridItem col='1/2' mobile1of1>
          <Label htmlFor='university'>University</Label>
          <Field
            name='university'
            component={SelectInstitution}
            validate={validators.required('University is required')}
            disabled={!editing}
          />
          <Label htmlFor='about_me'>About Me</Label>
          <Field
            name='about_me'
            component={TextArea}
            height='245px'
            disabled={!editing}
          />
        </GridItem>
        <GridItem col='2/5' tablet1of2 mobile1of1>
          <FormGroupButtons form={PROFESSIONAL_DETAILS_FORM_NAME} />
        </GridItem>
      </Grid>
    </Form>
  )
}

export default applyHOCs([
  connect(
    createStructuredSelector({
      titleOptions: titleOptionsSelector,
      disciplineOptions: disciplineOptionsSelector,
      disciplineClassificationOptions: disciplineClassificationOptionsSelector
    }),
    { save: saveProfessionalDetails }),
  formGroup({
    form: PROFESSIONAL_DETAILS_FORM_NAME,
    initialValuesSelector: state => {
      return pick(currentUserSelector(state), [
        'title',
        'discipline',
        'discipline_classification',
        'department',
        'about_me',
        'flags',
        'university'
      ])
    }
  })
])(ProfessionalDetailsForm)
