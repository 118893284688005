import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormSubmitErrors } from 'redux-form'
import { EnvelopeOpenCheckIcon, SpinnerIcon, EnvelopeOpenCheckmarkIcon } from 'icons'
import { memberMailchimpSubscribeModal } from './actions'

import { Button, HorizontalRule } from 'core'
import { Form, validators } from 'form'
import { applyHOCs } from 'helpers'
import { Modal, closeModal } from 'modal'
import { Combo, ComboFirst, ComboLast } from 'layout'

import css from './style.scss'

import { FORM_NAME } from './constants'

const ANALYTICS_FORM_NAME = 'Subscribe membership'

export const MemberMailchimpSubscribeFormModal = props => {
  const {
    submitErrors,
    memberMailchimpSubscribeModal,
    invalid,
    success,
    submitting,
    closeModal
  } = props

  const checklistItems = [
    'The weekly Monday Motivator',
    'The monthly NCFDD Newsletter',
    'Important event reminders and notifications',
    'Information about upcoming programs and events',
    'Other exciting announcements from the NCFDD team'
  ]

  return (
    <div>
      {success &&
        <div>
          <Modal disableOverflowY cancelBtnCornerPosition='close' cancelBtnSize='small'>
            <ul className={css.thankYouFlexContainer}>
              <li><EnvelopeOpenCheckmarkIcon className={css.successEnvelopeImage} /></li>
              <li className={css.successThankYou}>Thanks for subscribing</li>
              <li className={css.subscriptionConfirmed}>Your subscription to our list has been confirmed</li>
              <Button
                id='closeButton'
                noMargin
                color='orange'
                size='secondary'
                onClick={closeModal}
              >
                Go back to NCFDD
              </Button>
            </ul>
          </Modal>
        </div>}

      {!success &&
        <Modal
          borderlessModal
          cancelBtnColor='white'
          cancelBtnSize='small'
          cancelBtnCornerPosition='close'
          disableOverflowY
        >
          <Form name={ANALYTICS_FORM_NAME} onSubmit={memberMailchimpSubscribeModal}>
            <div className={css.modalWrapper}>
              <div className={css.stayInTheLoopTopHalf}>
                <ul className={css.stayInformedFlexContainer}>
                  <li>
                    <div className={css.stayInTheLoopTitle}>
                      Don't miss our upcoming events and announcements
                    </div>
                    <div className={css.stayInformedText}>
                      Get academic career success content delivered straight to your inbox!
                    </div>
                  </li>
                  <li><EnvelopeOpenCheckIcon className={css.envelopeImage} /></li>
                </ul>
              </div>

              <div className={css.stayInTheLoopLowerHalf}>
                <div className={css.checklistPreText}>You will receive</div>
                {
                  checklistItems.map((item, index) => {
                    return (
                      <div key={index} className={css.checklistItem}>
                        <Combo>
                          <ComboFirst>
                            <div className={css.checkmark} />
                          </ComboFirst>
                          <ComboLast>
                            <div className={css.checklistText}>{item}</div>
                          </ComboLast>
                        </Combo>
                      </div>
                    )
                  })
                }
                <div className={css.firstDash}>
                  <HorizontalRule dashed />
                </div>
                <label htmlFor='newsletter_opt_in' className={css.subscribeText}>
                  <Field
                    id='newsletter_opt_in'
                    name='newsletter_opt_in'
                    type='checkbox'
                    component='input'
                    validate={validators.mustBeTrue('* We require your consent')}
                  />
                  Yes, I’d like to subscribe
                  <div className={css.secondDash}>
                    <HorizontalRule dashed />
                  </div>
                  <span className='newsletterCheckmark' />
                </label>
                {
                  submitErrors &&
                    <div className={css.errorMessageBox}>
                      {submitErrors.code}
                    </div>
                }
                <Button
                  id='submitButton'
                  type='submit'
                  noMargin
                  color='orange'
                  size='secondary'
                  disabled={invalid || submitting}
                >
                  {!submitting && 'Subscribe'}
                  {submitting && <SpinnerIcon />}
                </Button>
                <div className={css.footerNoteWrapper}>
                  <div className={css.formFooterNote}>* You can always unsubscribe with just one click</div>
                </div>
              </div>
            </div>
          </Form>
        </Modal>}
    </div>
  )
}

MemberMailchimpSubscribeFormModal.propTypes = {
  submitErrors: PropTypes.object,
  success: PropTypes.bool,
  email: React.PropTypes.string,
  /* Redux-form */
  submitting: PropTypes.bool
}

const mapStateToProps = (state) => ({
  submitErrors: getFormSubmitErrors(FORM_NAME)(state),
  success: state.memberMailchimpSubscribeForm.success
})

export default applyHOCs([
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true
  }),
  connect(mapStateToProps, { memberMailchimpSubscribeModal, closeModal })
])(MemberMailchimpSubscribeFormModal)
