import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues, hasSubmitSucceeded } from 'redux-form'
import { createSelector } from 'reselect'
import { get } from 'lodash'

import { Button, Heading } from 'core'
import { Label, Input, validators } from 'form'
import { applyHOCs } from 'helpers'
import { Section, Spacer } from 'layout'
import { Page } from 'page'

import { FORM_NAME_REQUEST } from './constants'
import { requestPasswordReset } from './actions'

export const ForgotPasswordRoute = (props) => {
  const { requestPasswordReset, emailValue, hasRequestedPasswordReset } = props
  return (
    <Page>
      <Section narrow>
        <Spacer size='md' />
        {
          !hasRequestedPasswordReset &&
            <div>
              <Heading size='h4' center>
                Forgot your password?
              </Heading>
              <p>
                Enter the email you used to sign up, and we'll send you
                a link to reset your account password.
              </p>
              <Spacer size='xs' />
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  requestPasswordReset()
                }}
              >
                <Label htmlFor='email'>Email</Label>
                <Field
                  name='email'
                  component={Input}
                  validate={[
                    validators.required('Your email is required'),
                    validators.email('You have not provided a valid email address')
                  ]}
                />
                <Spacer size='xs' />
                <Button
                  type='submit'
                  size='primary'
                  disabled={props.submitting || props.invalid}
                  isLoading={props.submitting}
                >
                  Send Password Reset Request
                </Button>
              </form>
            </div>
        }
        {
          hasRequestedPasswordReset &&
            <div>
              <Heading size='h4' center>
                Password reset link sent!
              </Heading>
              <p>
                We've sent an email to <em>{emailValue}</em> with a link to change your
                account password. Please check your inbox, and follow the instructions.
                See you soon!
              </p>
            </div>
        }
        <Spacer size='md' />
      </Section>
    </Page>
  )
}

ForgotPasswordRoute.propTypes = {
  requestPasswordReset: React.PropTypes.func,
  /* mapStateToProps */
  emailValue: React.PropTypes.string,
  hasRequestedPasswordReset: React.PropTypes.bool,
  /* Redux-form */
  submitting: React.PropTypes.bool,
  invalid: React.PropTypes.bool
}

const mapStateToProps = createSelector(
  getFormValues(FORM_NAME_REQUEST),
  hasSubmitSucceeded(FORM_NAME_REQUEST),
  (values, submitSuccess) => ({
    emailValue: get(values, 'email', ''),
    hasRequestedPasswordReset: submitSuccess
  })
)

export default applyHOCs([
  connect(
    mapStateToProps,
    { requestPasswordReset }
  ),
  reduxForm({ form: FORM_NAME_REQUEST })
])(ForgotPasswordRoute)
