import Immutable from 'seamless-immutable'
import { values } from 'lodash'
import { filterActions } from 'redux-ignore'

import * as types from './types'
const {
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAIL
} = types

const INITIAL_STATE = Immutable({ })

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONFIRM_EMAIL_SUCCESS:
      return state.set('emailConfirmed', true)
    case CONFIRM_EMAIL_FAIL:
      return state.set('emailConfirmed', false)
    default:
      return state
  }
}

export default filterActions(reducer, values(types))
