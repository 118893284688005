import React from 'react'
import { compact } from 'lodash'

import css from './style.scss'

const Label = ({ children, className = '', htmlFor }) => {
  const classNames = [css.label, className]
  return (
    <label className={compact(classNames).join(' ')} htmlFor={htmlFor}>
      {children}
    </label>
  )
}

Label.propTypes = {
  htmlFor: React.PropTypes.string,
  children: React.PropTypes.node
}

export default Label
