import React from 'react'

import { Card, Heading } from 'core'
import { Spacer } from 'layout'

const WebinarsEmptyState = ({ type }) => {
  const label = type || 'webinars'
  return (
    <Card>
      <Spacer size='md' />
      <Heading center size='h4' weight='light' noMargin>
        Sorry, there are currently no {label}. Check back soon!
      </Heading>
      <Spacer size='md' />
    </Card>
  )
}
WebinarsEmptyState.propTypes = {
  type: React.PropTypes.string
}

WebinarsEmptyState.defaultProps = {
  type: 'webinars'
}

export default WebinarsEmptyState
