import React from 'react'
import { withRouter } from 'react-router'
import { compact } from 'lodash'
import qs from 'qs'

import ReactPaginate from 'react-paginate'

import css from './style.scss'

class Pagination extends React.Component {
  constructor (props) {
    super(props)

    this.getLocation = this.getLocation.bind(this)
  }

  getLocation () {
    return this.props.router.location
  }

  componentWillMount () {
    const location = this.getLocation()
    if (!location.query.page) {
      this.props.router.push({
        ...location,
        query: { ...location.query, page: 1 }
      })
    }
  }

  render () {
    const classes = [
      css.wrapper,
      this.props.proxima ? css.proxima : null
    ]

    const location = this.getLocation()

    return (
      <ReactPaginate
        pageCount={this.props.pages}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        containerClassName={compact(classes).join(' ')}
        pageClassName={css.step}
        pageLinkClassName={css.stepNumber}
        activeClassName={css.active}
        previousClassName={css.previous}
        nextClassName={css.next}
        breakClassName={css.break}
        forcePage={Number(location.query.page) - 1}
        hrefBuilder={page => {
          return `${location.pathname}?${qs.stringify({
            ...location.query,
            page
          })}`
        }}
        onPageChange={page => {
          this.props.router.push({
            ...location,
            query: { ...location.query, page: page.selected + 1 }
          })
        }}
      />
    )
  }
}

Pagination.propTypes = {
  pages: React.PropTypes.number.isRequired,
  /* Received from withRouter HOC */
  router: React.PropTypes.object,
  /* If true, sets font-family proxima and centers numbers properly ;) */
  proxima: React.PropTypes.bool
}

export default withRouter(Pagination)
