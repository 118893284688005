import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { take, toInteger } from 'lodash'

import { FormContainer } from 'form'
import { applyHOCs } from 'helpers'

import { forceStep, handleContinue } from './actions'
import { WizardProgress } from './WizardProgress'

/**
 * Takes an object of options and returns a component
 *
 * configuration options:
 *   steps: array of objects containing
 *     name: name of the component to be displayed in wizard progress
 *     component: component to render when step is active
 *     isValidSelector: (optional) indicates whether or not the form is complete
 *   completeAction: action creator to be called run once last step is complete
 */

export const formWizardFactory = (options = {}) => {
  const { steps = [], customProps = {} } = options

  class FormWizard extends Component {
    /**
     * The current step may be invalid (not a valid step number, or previous step
     * validators are not passing). If this is the case, update the location to
     * either the first step, or the latest invalid step
     */

    validateCurrentPosition (props) {
      const { currentStepNum } = props
      if (!currentStepNum || currentStepNum < 1 || currentStepNum > steps.length) {
        return props.forceStep(1)
      }

      const { stepValidations } = props
      const pastStepValidations = take(stepValidations, currentStepNum - 1)

      const incompleteStepIndex = pastStepValidations.indexOf(false)
      if (incompleteStepIndex !== -1) {
        props.forceStep(incompleteStepIndex + 1)
      }
    }

    componentWillMount () { this.validateCurrentPosition(this.props) }
    componentWillReceiveProps (nextProps) { this.validateCurrentPosition(nextProps) }

    render () {
      const {
        currentStepNum,
        handleContinue,
        handleGetFormName,
        stepValidations
      } = this.props

      const valid = stepValidations[currentStepNum - 1]

      return (
        <div>
          <FormContainer>
            <WizardProgress steps={steps} />
            {steps.map((step, index) => (
              /*
                * Render each step, but only display the current step. This is
                * to ensure that that all redux-form Field components are mounted/registered,
                * allowing us to validate the whole form at any given point
                */
              <div
                key={index}
                style={{
                  display: index === currentStepNum - 1 ? 'block' : 'none'
                }}
              >
                <step.component
                  {...customProps}
                  {...this.props}
                  getStepFormName={() => handleGetFormName(currentStepNum)}
                  continue={() => handleContinue(currentStepNum)}
                  valid={valid}
                />
              </div>
            ))}
          </FormContainer>
        </div>
      )
    }
  }

  FormWizard.propTypes = {
    stepValidations: PropTypes.arrayOf(PropTypes.bool).isRequired,
    currentStepNum: PropTypes.number,
    forceStep: PropTypes.func.isRequired,
    handleContinue: PropTypes.func.isRequired
  }

  return FormWizard
}

export default (options = {}) => {
  const { steps, getStepFormName } = options

  const mapStateToProps = (state, props) => ({
    stepValidations: steps.map(step => {
      return step.isValidSelector ? step.isValidSelector(state) : true
    })
  })

  const mapDispatchToProps = (dispatch, props) => ({
    handleContinue: (currentStepNum) => {
      return dispatch(handleContinue(props, options))
    },
    handleGetFormName: (currentStepNum) => {
      if (getStepFormName) {
        return getStepFormName({ props, step: steps[currentStepNum - 1] })
      }
      return undefined
    },
    forceStep: num => dispatch(forceStep(num))
  })

  return applyHOCs([
    connect(mapStateToProps, mapDispatchToProps),
    WithRouter => {
      return (props) => (
        <WithRouter
          {...props}
          currentStepNum={toInteger(props.router.location.query.step)}
        />
      )
    },
    withRouter
  ])(formWizardFactory(options))
}
