import Immutable from 'seamless-immutable'
import { values } from 'lodash'
import { filterActions } from 'redux-ignore'
import * as types from './types'
const {
  AUTHENTICATION_FAILED,
  LOAD_AUTH_TOKEN,
  LOAD_CURRENT_USER,
  HYDRATION_ERROR
} = types

const initialState = Immutable({
  hydrated: false,
  token: null
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_FAILED:
      return state.merge({
        hydrated: true,
        token: null
      })
    case HYDRATION_ERROR:
      return state.merge({
        hydrated: false,
        hydrationError: action.error
      })
    case LOAD_AUTH_TOKEN:
      return state.merge({
        hydrated: true,
        token: action.token
      })
    case LOAD_CURRENT_USER:
      return state.set('userId', action.result)
    default:
      return state
  }
}

export default filterActions(reducer, values(types))
