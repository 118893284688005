// raw export from _colors.scss for use in JavaScript
export const colors = {
  blackHeading: 'black',
  blackPrimary: '#2e2e2e',
  greyText: '#dedede',
  greyTextMed: '#ababab',
  greyTextDark: '#838383',
  greyBkgDark: '#f3f3f3',
  greyBkgLight: '#fafafa',
  greyDark: '#474747',
  greyDarkHover: '#545454',
  greyDarkActive: '#3a3a3a',
  greenPrimary: '#3dc280',
  greenPrimaryHover: '#50c88d',
  greenPrimaryActive: '#37af73',
  greenPrimaryScreen: 'rgba(61, 194, 128, 0.2)',
  greenSecondary: '#34c37d',
  orangePrimary: '#e69342',
  orangePrimaryHover: '#e9a059',
  orangePrimaryActive: '#e3862b',
  redPrimary: '#fd4046',
  redPrimaryHover: '#fd595e',
  redPrimaryActive: '#fd272e',
  bluePrimary: '#3841b8',
  bluePrimaryHover: '#444dc6',
  bluePrimaryActive: '#323aa4',
  navyPrimary: '#0d1757',
  navyPrimaryHover: '#101d6d',
  navyPrimaryActive: '#0a1141',
  navySecondary: '#141c6e',
  navySecondaryHover: '#182184',
  navySecondaryActive: '#101758',
  lightBlue: '#383cbc',
  purplePrimary: '#1c2181',
  greyScreen: 'rgba(200, 200, 200, 0.3)',
  whiteScreen: 'rgba(255, 255, 255, 0.3)',
  boxShadowMenu: 'rgba(0, 0, 0, 0.3)',
  heroScreen: 'rgba(13, 23, 87, 0.7)'
}

// raw export from _breakpoints.scss for use in JavaScript
export const breakpoints = {
  tabletMinWidth: '425px',
  laptopMinWidth: '960px',
  desktopMinWidth: '1120px',
  phabletMaxWidth: '699px',
  paddingMobile: '16px',
  paddingTablet: '24px',
  paddingLaptop: '28px',
  paddingDesktop: '30px',
  mobileMax: '456px',
  phabletMin: '457px',
  phabletMax: '747px',
  phabletMaxPlusOne: '748px',
  tabletMin: '457px',
  tabletMax: '1007px',
  laptopMin: '1008px',
  laptopMax: '1179px',
  desktopMin: '1180px'
}
