import React from 'react'

import { Heading } from 'core'
import { Spacer } from 'layout'

const NoResultsFound = () => (
  <div>
    <Heading
      size='h3'
      freight
      center
    >
      No results found
    </Heading>
    <Spacer />
  </div>
)

export default NoResultsFound
