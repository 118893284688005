import React from 'react'

import { Button, Card, Contact, Heading, Link } from 'core'

import css from './style.scss'

/**
 * The `<CardCurriculum />` is an enhanced `<Card />`, formatting passed content
 * specifically for the card instance in the user dashboard. Note the specific
 * data structure expected.
 */
const CardCurriculum = (props) => {
  const { slug, title, facilitator } = props.webinar
  const { position } = props

  return (
    <div className={css.cardWrapper}>
      <Card>
        <div className={css.dashboard}>
          <Heading size='h4' weight='thin' center noMargin>
            {title}
          </Heading>
          <div className={css.countFraction}>{position} / 10</div>
          <Contact user={facilitator} size='xs' center />
          <Link to={`/webinars/${slug}`} fullWidth>
            <Button size='secondary' noMargin>
              Watch Now
            </Button>
          </Link>
        </div>
      </Card>
    </div>
  )
}

CardCurriculum.propTypes = {
  webinar: React.PropTypes.shape({
    slug: React.PropTypes.string.isRequired,
    title: React.PropTypes.string,
    count: React.PropTypes.number,
    facilitator: React.PropTypes.object
  }).isRequired,
  position: React.PropTypes.number.isRequired
}

export default CardCurriculum
