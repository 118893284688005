import React from 'react'
import { connect } from 'react-redux'
import { openSubscribeModal } from 'subscribe-modal'
import css from '../style.scss'

export const FooterSubscribe = ({ openSubscribeModal }) => {
  return (
    <a className={css.subscribe} onClick={openSubscribeModal}>
      Subscribe to receive NCFDD email updates
    </a>
  )
}

export default connect(null, { openSubscribeModal })(FooterSubscribe)
