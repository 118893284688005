import React from 'react'
import MediaQuery from 'react-responsive'

import LatestNews from './LatestNews'
import LatestMondayMotivator from './LatestMondayMotivator'

import { Heading } from 'core'
import { Grid, GridItem, Section, SectionOverride, Spacer } from 'layout'

import { breakpoints } from 'styles'

const { phabletMax, phabletMaxPlusOne } = breakpoints

const News = () => {
  const headingNews = (
    <Heading size='h4' weight='light'>Latest News</Heading>
  )

  const headingMotivator = (
    <Heading size='h4' weight='light'>The Monday Motivator</Heading>
  )

  return (
    <Section mobileNoPadding>
      <MediaQuery minWidth={phabletMaxPlusOne}>
        <Grid>
          <GridItem col='1/2'>
            {headingNews}
          </GridItem>
          <GridItem col='1/2'>
            {headingMotivator}
          </GridItem>
        </Grid>
      </MediaQuery>
      <Grid stretch>
        <MediaQuery maxWidth={phabletMax}>
          <GridItem col='1/1'>
            <SectionOverride>
              {headingNews}
            </SectionOverride>
          </GridItem>
        </MediaQuery>
        <GridItem col='1/2' phablet1of1 mobile1of1>
          <LatestNews />
        </GridItem>
        <MediaQuery maxWidth={phabletMax}>
          <Spacer size='sm' />
          <GridItem col='1/1'>
            <SectionOverride>
              {headingMotivator}
            </SectionOverride>
          </GridItem>
        </MediaQuery>
        <GridItem col='1/2' phablet1of1 mobile1of1>
          <LatestMondayMotivator />
        </GridItem>
      </Grid>
    </Section>
  )
}

export default News
