import Immutable from 'seamless-immutable'
import { values } from 'lodash'
import { filterActions } from 'redux-ignore'

import * as types from '../types'
const {
  LOAD_REQUEST,
  LOAD_SUCCESS,
  LOAD_ERROR
} = types

const initialState = Immutable({})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (LOAD_REQUEST):
      return state.setIn([action.url, 'loading'], true)
    case (LOAD_SUCCESS):
      return state.merge({
        [action.url]: {
          loading: false,
          result: action.result,
          totalCount: action.totalCount
        }
      })
    case (LOAD_ERROR):
      return state.merge({
        [action.url]: {
          loading: false,
          result: undefined,
          error: action.error
        }
      }, { deep: true })
    default:
      return state
  }
}

export default filterActions(reducer, values(types))
