import { omit } from 'lodash'
import { normalize } from 'normalizr'

import createClient from 'auth/createClient'
import { LOAD_REQUEST, LOAD_SUCCESS, LOAD_ERROR } from './types'

const totalCountHeader = 'x-total-count'

/**
 * Normalizes the response according the the given schema
 */

export const apiResponse = (action = {}) => {
  const {
    type,
    data,
    schema
  } = action

  const { entities, result } = normalize(data, schema)
  return {
    type,
    entities,
    result,
    ...omit(action, ['type', 'entities', 'result', 'data', 'schema'])
  }
}

export const loadData = ({ url, schema, errorHandler }) => (dispatch, getState) => {
  const state = getState()

  const queryState = state.api.queries[url] || {}
  if (queryState.loading) return Promise.resolve()

  dispatch({
    type: LOAD_REQUEST,
    url
  })

  return createClient(state)
    .get(url)
    .then(response => {
      const totalCount = response.headers.has(totalCountHeader)
        ? parseInt(response.headers.get(totalCountHeader), 10)
        : undefined

      return response.json()
        .then(data => {
          if (data && schema) {
            const { result, entities } = normalize(data, schema)
            dispatch({
              type: LOAD_SUCCESS,
              url,
              result,
              entities,
              totalCount
            })
          } else {
            dispatch({
              type: LOAD_SUCCESS,
              url,
              result: data,
              totalCount
            })
          }
        })
    })
    .catch(error => {
      if (errorHandler) {
        errorHandler(error, dispatch)
      } else {
        dispatch({
          type: LOAD_ERROR,
          url,
          error
        })
      }
    })
}
