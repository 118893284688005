import React, { PropTypes } from 'react'
import css from './style.scss'

const BannerNotice = (props) => (
  <div className={css.wrapper}>
    {props.children}
  </div>
)

BannerNotice.propTypes = {
  children: PropTypes.node
}

export default BannerNotice
