import React from 'react'
import MediaQuery from 'react-responsive'

import { forumsUrl } from 'config'
import { breakpoints } from 'styles'
import DesktopMenu from './desktop/DesktopMenu'
import MobileMenu from './mobile/MobileMenu'

const { tabletMax } = breakpoints

const GlobalNav = (props) => {
  const { isLoggedIn } = props

  let sections

  if (isLoggedIn) {
    sections = [
      {
        name: 'My Dashboard',
        to: '/dashboard'
      }, {
        name: 'Events',
        to: '/events'
      }, {
        name: 'Library',
        to: '/library'
      }, {
        name: 'Resources',
        to: '/resources',
        children: props.resources
      }, {
        name: 'Forums',
        to: forumsUrl
      }, {
        name: 'About Us',
        to: '/about-us',
        children: props.aboutUs
      }
    ]
  } else {
    sections = [
      {
        name: 'Home',
        to: '/home'
      }, {
        name: 'Services',
        to: '/services',
        children: props.services
      }, {
        name: 'About Us',
        to: '/about-us',
        children: props.aboutUs
      }
    ]
  }

  return (
    <MediaQuery maxWidth={tabletMax}>
      {(isMobile) => {
        if (isMobile) {
          return <MobileMenu sections={sections} />
        } else {
          return <DesktopMenu sections={sections} />
        }
      }}
    </MediaQuery>
  )
}

GlobalNav.propTypes = {
  services: React.PropTypes.array.isRequired,
  resources: React.PropTypes.array.isRequired,
  aboutUs: React.PropTypes.array
}

export default GlobalNav
