import React, { PropTypes } from 'react'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'

import { load, schemas } from 'api'
import { isAuthenticatedSelector, isIAHSelector } from 'auth'
import { writeNowUrl } from 'config'
import { applyHOCs } from 'helpers'

import RegisterButton from '../components/RegisterButton'
import SessionSaleHeader from '../components/SessionSaleHeader'
import SaleStateText from '../components/SaleStateText'

import { getDateState, formatCloseDate, participatingInSession } from '../helpers'

export const getRegistrationHeadingText = ({ sessionSale, isIAH, preRegistrationState, openRegistrationState, participating }) => {
  if (participating) {
    return sessionSale.title
  } else if (openRegistrationState === 'after') {
    return `Registration for the ${sessionSale.title} has finished`
  } else if (sessionSale.registration_status === 'none' && sessionSale.num_reservations_available === 0) {
    return `The ${sessionSale.title} is currently at full capacity`
  } else if (openRegistrationState === 'before') {
    return `Early registration for the ${sessionSale.title}`
  } else {
    return `Registration for the ${sessionSale.title}`
  }
}

export const FSPRegisterButton = ({ sessionSale, isIAH, preRegistrationState, openRegistrationState, participating }) => {
  if (openRegistrationState === 'after') {
    // Once open registration has closed, nobody can register
    return null
  }

  if (participating) {
    return (
      <RegisterButton onClick={() => window.open(writeNowUrl)}>
        Go to WriteNow
      </RegisterButton>
    )
  }

  if (sessionSale.registration_status === 'reserved') {
    return (
      <RegisterButton disabled>
        You are registered
      </RegisterButton>
    )
  } else if (sessionSale.registration_status === 'waitlisted') {
    return (
      <RegisterButton disabled>
        You are on the wait list
      </RegisterButton>
    )
  } else if (sessionSale.num_reservations_available === 0) {
    return (
      <RegisterButton
        onClick={() => {
          browserHistory.push(`/sessions/${sessionSale.slug}/join-waitlist`)
        }}
      >
        Join the waitlist
      </RegisterButton>
    )
  } else {
    return (
      <RegisterButton
        onClick={() => {
          browserHistory.push(`/sessions/${sessionSale.slug}/reserve`)
        }}
      >
        Register now
      </RegisterButton>
    )
  }
}

export const ManageReservationsButton = ({ sessionSale, isIAH, preRegistrationState }) => {
  if (isIAH && preRegistrationState === 'during') {
    return (
      <RegisterButton
        onClick={() => browserHistory.push(`/sessions/${sessionSale.slug}/institution-reserve`)}
      >
        Manage subaccount reservations
      </RegisterButton>
    )
  }

  return null
}

export const getRegistrationTimingText = ({ sessionSale, isIAH, preRegistrationState, openRegistrationState, participating }) => {
  if (participating) {
    return null
  } else if (isIAH && preRegistrationState === 'before') {
    return `Early registration starts on ${formatCloseDate(sessionSale.pre_registration_start_at)}`
  } else if (isIAH && preRegistrationState === 'during') {
    return `Early registration closes on ${formatCloseDate(sessionSale.pre_registration_end_at)}`
  } else if (openRegistrationState === 'before') {
    return `Registration starts on ${formatCloseDate(sessionSale.open_registration_start_at)}`
  } else if (openRegistrationState === 'during') {
    return `Registration closes on ${formatCloseDate(sessionSale.open_registration_end_at)}`
  } else if (openRegistrationState === 'after') {
    return `Registration closed on ${formatCloseDate(sessionSale.open_registration_end_at)}`
  }

  return null
}

export const FSPHeader = ({ isIAH, sessionSale, activeGroups, hubspotFormId }) => {
  const participating = participatingInSession(sessionSale, activeGroups)

  const openRegistrationState = getDateState(
    sessionSale.open_registration_start_at,
    sessionSale.open_registration_end_at
  )

  const preRegistrationState = getDateState(
    sessionSale.pre_registration_start_at,
    sessionSale.pre_registration_end_at
  )

  const headingText = getRegistrationHeadingText({
    sessionSale,
    isIAH,
    preRegistrationState,
    openRegistrationState,
    participating
  })

  const registerButton = (
    <FSPRegisterButton
      sessionSale={sessionSale}
      isIAH={isIAH}
      preRegistrationState={preRegistrationState}
      openRegistrationState={openRegistrationState}
      participating={participating}
    />
  )

  const manageReservationsButton = (
    <ManageReservationsButton
      sessionSale={sessionSale}
      isIAH={isIAH}
      preRegistrationState={preRegistrationState}
    />
  )

  const registrationTimingText = getRegistrationTimingText({
    sessionSale,
    isIAH,
    preRegistrationState,
    openRegistrationState,
    participating
  })

  return (
    <SessionSaleHeader sessionSale={sessionSale} title={headingText} hubspotFormId={hubspotFormId}>
      {registerButton}{manageReservationsButton}
      {registrationTimingText && (
        <SaleStateText>{registrationTimingText}</SaleStateText>
      )}
    </SessionSaleHeader>
  )
}

FSPHeader.propTypes = {
  isIAH: PropTypes.bool,
  hubspotFormId: PropTypes.string,
  sessionSale: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    num_reservations_available: PropTypes.number.isRequired,
    num_institution_reservations_available: PropTypes.number,
    registration_status: PropTypes.string.isRequired,
    open_registration_start_at: PropTypes.string.isRequired,
    open_registration_end_at: PropTypes.string.isRequired,
    pre_registration_start_at: PropTypes.string.isRequired,
    pre_registration_end_at: PropTypes.string.isRequired,
    advertised_start_date: PropTypes.string.isRequired,
    advertised_end_date: PropTypes.string.isRequired
  }).isRequired,
  activeGroups: PropTypes.arrayOf(PropTypes.shape({
    session: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }))
}

export default applyHOCs([
  connect(
    (state) => ({ isIAH: isIAHSelector(state) })
  ),
  load({
    activeGroups: {
      urlSelector: () => '/v2/users/current/groups',
      schema: [schemas.group],
      skipSelector: (state) => {
        return !isAuthenticatedSelector(state)
      }
    }
  })
])(FSPHeader)
