import {
  UNHANDLED_ERROR,
  DISMISS_UNHANDLED_ERROR
} from './types'

export const handleError = (error, message) => (dispatch) => {
  dispatch({ type: UNHANDLED_ERROR, error, message })
}

export const dismissUnhandledError = () => (dispatch) => {
  dispatch({ type: DISMISS_UNHANDLED_ERROR })
}
