import React from 'react'
import { compact } from 'lodash'

import css from './style.scss'

const FormSection = (props) => {
  const className = compact([
    css.formSection,
    props.grey ? css.grey : null,
    props.blue ? css.blue : null
  ]).join(' ')

  return (
    <div className={className}>
      {props.children}
    </div>
  )
}

FormSection.propTypes = {
  children: React.PropTypes.node
}

export default FormSection
