import React from 'react'

import moment from 'moment'

import { formatDate } from 'helpers'

import { Checklist } from '../Checklist'

export const SessionSaleDates = ({ session_sale: sessionSale, currentDate }) => {
  if (!sessionSale) return null

  const checklistItems = []

  checklistItems.push({
    checklist_item: `<p>${sessionSale.title} Registration Opens: ${formatDate(sessionSale.open_registration_start_at)}</p>`
  })

  if (sessionSale.early_bird_price_start_at &&
    sessionSale.early_bird_price_end_at &&
    currentDate.isAfter(sessionSale.early_bird_price_start_at)
  ) {
    const endText = currentDate.isBefore(sessionSale.early_bird_price_end_at) ? 'Ends' : 'Ended'
    checklistItems.push({
      checklist_item: `<p>${sessionSale.title} Early Bird Discount ${endText}: ${formatDate(sessionSale.early_bird_price_end_at)}</p>`
    })
  }

  checklistItems.push({
    checklist_item: `<p>${sessionSale.title} Registration Closes: ${formatDate(sessionSale.open_registration_end_at)}</p>`
  })

  return (
    <Checklist
      title='Important Dates to Remember'
      checklist_items={checklistItems}
    />
  )
}

SessionSaleDates.propTypes = {
  session_sale: React.PropTypes.shape({
    title: React.PropTypes.string.required,
    session_id: React.PropTypes.number.required,
    open_registration_start_at: React.PropTypes.string.required,
    open_registration_end_at: React.PropTypes.string.required,
    early_bird_price_end_at: React.PropTypes.string
  }),
  currentDate: React.PropTypes.object
}

SessionSaleDates.defaultProps = {
  currentDate: moment()
}

export default SessionSaleDates
