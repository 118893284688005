import emailValidator from 'email-validator'

export const required = (errorMessage = 'This field is required') => (value) => {
  if (value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0)) {
    return errorMessage
  }
}

export const mustBeTrue = (errorMessage = 'This field must be checked') => (value) => {
  if (value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0) || value === false) {
    return errorMessage
  }
}

export const minLength = (minLength, errorMessage = 'Value is too short') =>
  (value) => {
    if (value && value.length < minLength) {
      return errorMessage
    }
  }

export const equalToField = (fieldName, errorMessage = `This does not match ${fieldName}`) =>
  (value, allValues) => {
    if (value && value !== allValues[fieldName]) {
      return errorMessage
    }
  }

export const email = (errorMessage = 'That is not a valid email') => (value) => {
  if (!emailValidator.validate(value)) {
    return errorMessage
  }
}

export const max = (maxValue, errorMessage = 'Value is too large') => value => {
  if (value > maxValue) {
    return errorMessage
  }
}

export const min = (minValue, errorMessage = 'Value is too small') => value => {
  if (value < minValue) {
    return errorMessage
  }
}

const ZIP_CODE_PATTERN = /^\d{5}?$/

export const zipCode = (errorMessage = 'Must be a valid zip code') => value => {
  if (value && !value.match(ZIP_CODE_PATTERN)) {
    return errorMessage
  }
}

export const postalCode = (pattern, errorMessage = 'Must be a valid postal code') => value => {
  if (!pattern) return

  if (value && !value.match(pattern)) {
    return errorMessage
  }
}

export const stateSelected = (errorMessage) => (value, allValues) => {
  if (!value) {
    if (allValues.country === 'US' || allValues.country === 'CA') {
      return errorMessage
    }
  }
}
