import React from 'react'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { Button } from 'core'
import { FormContainer, FormRule, FormSection } from 'form'
import { applyHOCs } from 'helpers'
import { Grid, GridItem, Section } from 'layout'

import HeaderSecondary from '../HeaderSecondary/HeaderSecondary'
import { submitForm } from './actions'
import { FORM_NAME, DEFAULT_SUBJECT } from './constants'
import * as fields from './fields'

export const ContactForm = (props) => {
  const {
    title,
    subtitle,
    sent_title: sentTitle,
    sent_subtitle: sentSubtitle,
    submitSucceeded,
    submitting,
    invalid,
    onSubmit
  } = props

  if (submitSucceeded) {
    return (
      <Section narrow>
        <HeaderSecondary
          title={sentTitle}
          subtitle={sentSubtitle}
        />
      </Section>
    )
  }

  return (
    <div>
      <Section narrow>
        <HeaderSecondary
          title={title}
          subtitle={subtitle}
        />
      </Section>
      <FormContainer onSubmit={onSubmit}>
        <FormSection>
          <Grid>
            <GridItem col='1/1' tablet1of1 mobile1of1>
              <fields.Subject />
            </GridItem>

            <GridItem col='1/1' tablet1of1 mobile1of1>
              <FormRule />
            </GridItem>

            <GridItem col='1/2' tablet1of1 mobile1of1>
              <fields.FirstName />
            </GridItem>
            <GridItem col='1/2' tablet1of1 mobile1of1>
              <fields.LastName />
            </GridItem>
            <GridItem col='1/1' tablet1of1 mobile1of1>
              <fields.Email />
            </GridItem>
            <GridItem col='1/1' tablet1of1 mobile1of1>
              <fields.Telephone />
            </GridItem>
            <GridItem col='1/1' tablet1of1 mobile1of1>
              <fields.Address name='address_line_1' displayName='Address Line 1' />
            </GridItem>
            <GridItem col='1/1' tablet1of1 mobile1of1>
              <fields.Address name='address_line_2' displayName='Address Line 2' />
            </GridItem>
            <GridItem col='1/1' tablet1of1 mobile1of1>
              <fields.Country />
            </GridItem>
            <GridItem col='1/1' tablet1of1 mobile1of1>
              <fields.Message />
            </GridItem>
            <GridItem col='1/1' tablet1of1 mobile1of1>
              <Button
                type='submit'
                disabled={submitting || invalid}
                isLoading={submitting}
              >
                Submit
              </Button>
            </GridItem>
          </Grid>
        </FormSection>
      </FormContainer>
    </div>
  )
}

export default applyHOCs([
  reduxForm({ form: FORM_NAME }),
  connect(
    (state, props) => {
      const initialValues = {
        subject: DEFAULT_SUBJECT
      }

      if (props.location.query.email) {
        initialValues.email = props.location.query.email
      }

      if (props.location.query.type === 'resend_confirmation') {
        Object.assign(initialValues, {
          subject: 'account_support',
          message: 'Please resend my confirmation email so that I can verify my account.'
        })
      }

      return { initialValues }
    },
    { onSubmit: submitForm }
  ),
  withRouter
])(ContactForm)
