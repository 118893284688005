import React from 'react'

import { Page } from 'page'

import Curriculum from './Curriculum'
import Forward from './Forward'
import Events from './Events'
import Featured from './Featured'
import Learning from './Learning'
import News from './News'
import UserBanner from './UserBanner'
import UnconfirmedEmailNotification from './UnconfirmedEmailNotification'
import MissingProfileFieldsNotification from './MissingProfileFieldsNotification'
import MembershipRenewalBanner from './renewal-reminder/MembershipRenewalBanner'

import css from './style.scss'
import { PageHead } from 'page/PageHead'

const DashboardRoute = () => (
  <div>
    <PageHead title='Dashboard' />
    <UnconfirmedEmailNotification />
    <MissingProfileFieldsNotification />
    <MembershipRenewalBanner />
    <Page title='Dashboard'>
      <div className={css.banner}>
        <UserBanner />
      </div>
      <div className={css.forward}>
        <Forward />
      </div>
      <div className={css.learning}>
        <Learning />
      </div>
      <div className={css.featured}>
        <Featured />
      </div>
      <div className={css.events}>
        <Events />
      </div>
      <div className={css.curriculum}>
        <Curriculum />
      </div>
      <div className={css.news}>
        <News />
      </div>
    </Page>
  </div>
)

export default DashboardRoute
