/* global fetch Request */
import 'isomorphic-fetch'
import qs from 'qs'
import * as errors from './errors'
import * as httpCodes from './httpCodes'
import { apiUrl } from 'config'

const { FormData } = window

const errorMappings = {
  [httpCodes.NOT_FOUND]: errors.NotFoundError,
  [httpCodes.BAD_REQUEST]: errors.BadRequestError,
  [httpCodes.UNAUTHORIZED]: errors.UnauthorizedError,
  [httpCodes.FORBIDDEN]: errors.ForbiddenError,
  [httpCodes.INTERNAL_SERVER_ERROR]: errors.InternalServerError
}

export default class Client {
  constructor (authToken = null) {
    this.authToken = authToken
  }

  request (path, options = {}) {
    const {
      body = null,
      query = {},
      method = 'GET'
    } = options

    const requestOptions = {
      method,
      headers: {},
      credentials: 'include'
    }

    path = apiUrl + path
    if (Object.keys(query).length > 0) {
      path += `?${qs.stringify(query)}`
    }

    if (body !== null && typeof body === 'object') {
      if (options.body instanceof FormData) {
        requestOptions.body = options.body
      } else {
        requestOptions.body = JSON.stringify(options.body)
        requestOptions.headers['Content-Type'] = 'application/json'
      }
    }

    if (this.authToken) {
      requestOptions.headers.Authorization = `Bearer ${this.authToken}`
    }

    const request = new Request(path, requestOptions)

    return fetch(request)
      .then((response) => {
        if (response.ok) {
          return response
        } else {
          const ErrorClass = errorMappings[response.status] || errors.APIError
          return Promise.reject(new ErrorClass({ request, response }))
        }
      })
  }

  get (url, options = {}) {
    return this.request(url, Object.assign({ method: 'GET' }, options))
  }

  patch (url, options = {}) {
    return this.request(url, Object.assign({ method: 'PATCH' }, options))
  }

  post (url, options = {}) {
    return this.request(url, Object.assign({ method: 'POST' }, options))
  }

  put (url, options = {}) {
    return this.request(url, Object.assign({ method: 'PUT' }, options))
  }

  del (url, options = {}) {
    return this.request(url, Object.assign({ method: 'DELETE' }, options))
  }
}
