import React from 'react'

import { load, schemas } from 'api'

import AccountHeading from '../components/AccountHeading'
import OrderTable from '../components/OrderTable'
import { PageHead } from 'page/PageHead'

const AccountPaymentsPage = ({ orders, loadStatus }) => {
  if (loadStatus.orders.loading) {
    return null
  }

  if (orders.length === 0) {
    return <p>You do not have any payments.</p>
  }

  return (
    <div>
      <PageHead title='Payments' />
      <AccountHeading>
        Payments
      </AccountHeading>
      <OrderTable orders={orders} />
    </div>
  )
}

export default load({
  orders: {
    urlSelector: () => '/v2/users/current/orders',
    schema: [schemas.order]
  }
})(AccountPaymentsPage)
