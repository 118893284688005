import { handleError } from './actions'
export { handleError }

/**
 * Helper to reduce boilerplate
 * ex. .catch(catchError(dispatch))
 */
export const catchError = (dispatch) => (error) => dispatch(handleError(error))

export { default as ErrorFlashRoot } from './ErrorFlashRoot'
export { default as reducer } from './reducer'
export { UNHANDLED_ERROR } from './types'
export { default as middleware } from './middleware'
