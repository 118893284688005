import React, { PropTypes } from 'react'
import { connect } from 'react-redux'

import { loadFixedPageBlocks } from 'cms'
import { applyHOCs } from 'helpers'

import GenericHeader from './GenericHeader'
import { acceptOffer } from './actions'
import { PageBlockRenderer } from 'core/components/PageBlockRenderer'

export class GenericLandingPage extends React.Component {
  componentWillMount () {
    const { offerToken, acceptOffer, sessionSale } = this.props

    if (offerToken) {
      acceptOffer(sessionSale, offerToken)
    }
  }

  render () {
    const { sessionSale, fixedPageBlocks } = this.props
    return (
      <div>
        <GenericHeader sessionSale={sessionSale} />
        <PageBlockRenderer blocks={fixedPageBlocks} sessionSale={sessionSale} />
      </div>
    )
  }
}

GenericLandingPage.propTypes = {
  sessionSale: PropTypes.shape({
    advertised_start_date: PropTypes.string.isRequired,
    advertised_end_date: PropTypes.string.isRequired
  }).isRequired,
  offerToken: PropTypes.string,
  acceptOffer: PropTypes.func.isRequired
}

const mapStateToProps = (state, { location }) => ({
  offerToken: location.query.offer_token
})

const WithHOCs = applyHOCs([
  connect(mapStateToProps, { acceptOffer }),
  loadFixedPageBlocks({ pageSelector: () => 'generic' })
])(GenericLandingPage)

WithHOCs.displayName = 'GenericLandingPage'

export default WithHOCs
