import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { get } from 'lodash'

import { currentUserSelector } from 'auth'
import {
  MAX_EMAIL_LENGTH,
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH
} from 'consts'
import { Form, Input, Label, validators } from 'form'
import { formGroup, formGroupButtons } from 'form-group'
import { applyHOCs } from 'helpers'

import { saveAccountDetails } from './actions'
import { ACCOUNT_DETAILS_FORM_NAME, analyticsFormName } from './constants'

const PASSWORD_PLACEHOLDER = '••••••••••'

const FormGroupButtons = formGroupButtons({ form: ACCOUNT_DETAILS_FORM_NAME })

const AccountDetailsForm = ({ editing, save }) => {
  return (
    <Form
      name={analyticsFormName(ACCOUNT_DETAILS_FORM_NAME)}
      onSubmit={save}
    >
      <Label>Email</Label>
      <Field
        name='email'
        component={Input}
        validate={[
          validators.required('Your email is required'),
          validators.email('Not a valid email address')
        ]}
        maxLength={MAX_EMAIL_LENGTH}
        disabled={!editing}
      />
      <Label>Password</Label>
      <Field
        name='password'
        component={Input}
        type='password'
        placeholder={PASSWORD_PLACEHOLDER}
        maxLength={MAX_PASSWORD_LENGTH}
        validate={validators.minLength(MIN_PASSWORD_LENGTH, `Your password must be at least ${MIN_PASSWORD_LENGTH} characters`)}
        disabled={!editing}
      />
      <Label>Confirm Password</Label>
      <Field
        name='password_confirmation'
        component={Input}
        type='password'
        placeholder={PASSWORD_PLACEHOLDER}
        validate={(value, allValues) => {
          if (allValues.password && value !== allValues.password) {
            return 'Your passwords do not match'
          }
        }}
        maxLength={MAX_EMAIL_LENGTH}
        disabled={!editing}
      />
      <FormGroupButtons form={ACCOUNT_DETAILS_FORM_NAME} />
    </Form>
  )
}

export default applyHOCs([
  connect(null, { save: saveAccountDetails }),
  formGroup({
    form: ACCOUNT_DETAILS_FORM_NAME,
    initialValuesSelector: state => ({
      email: get(currentUserSelector(state), 'email', null)
    })
  })
])(AccountDetailsForm)
