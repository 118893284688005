import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { Button } from 'core'
import { Grid, GridItem } from 'layout'

import { requestSubAccountExport } from '../actions'

const Download = ({ requestExport, subaccountExport }) => {
  const isLoading = get(subaccountExport, 'isLoading') || false
  const data = get(subaccountExport, 'data') || null

  return (
    <Grid>
      <GridItem col='1/4'>
        <Button
          onClick={requestExport}
          isLoading={isLoading}
          disabled={isLoading || !!data}
          size='tertiary'
        >
          Export
        </Button>
      </GridItem>
      <GridItem col='3/4'>
        {
          data &&
            <a href={data.url}>
              {`Download ${data.filename}`}
            </a>
        }
      </GridItem>
    </Grid>
  )
}

Download.propTypes = {
  institutionId: React.PropTypes.number.isRequired,
  requestExport: React.PropTypes.func.isRequired,
  subaccountExport: React.PropTypes.object
}

const mapStateToProps = state => ({
  subaccountExport: state.account.subaccountExport
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  requestExport: () => {
    dispatch(requestSubAccountExport(ownProps.institutionId))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Download)
