import { googleTagManagerContainerID } from 'config'

const execHeadScript = () => {
  // google tag manager script for head
  const googleTagHeadScript = document.createElement('script')
  googleTagHeadScript.async = true
  googleTagHeadScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${googleTagManagerContainerID}')`

  document.head.appendChild(googleTagHeadScript)
}

const execBodyScript = () => {
  // google tag manager noscript for body
  const googleTagBodyScript = document.createElement('noscript')
  googleTagBodyScript.async = true
  googleTagBodyScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerContainerID}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`

  document.body.appendChild(googleTagBodyScript)
}

export const installGTM = () => {
  // prevent double loading
  if (document.head.querySelector('meta[name=gtm-loaded]')) return

  execHeadScript()
  execBodyScript()
  var meta = document.createElement('meta')
  meta.name = 'gtm-loaded'
  meta.content = 'true'
  document.head.appendChild(meta)
}
