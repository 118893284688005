import { getFormValues, startSubmit, stopSubmit } from 'redux-form'
import get from 'lodash/get'

import { apiResponse, schemas } from 'api'
import {
  createClient,
  currentUserSelector,
  loadCurrentUser
} from 'auth'
import { handleError } from 'errors'

import { BUDDY_REQUEST_FORM } from './constants'
import { BUDDY_REQUEST_SUCCESS } from './types'

export const submitBuddyRequest = () => (dispatch, getState) => {
  dispatch(startSubmit(BUDDY_REQUEST_FORM))

  const state = getState()
  const client = createClient(state)
  const currentUser = currentUserSelector(state)
  const formValues = getFormValues(BUDDY_REQUEST_FORM)(state)
  const title = get(formValues, 'title')

  let updateUser

  if (title) {
    updateUser = client.patch(`/v2/users/${currentUser.id}`, { body: { title } })
      .then(response => response.json())
      .then(updatedUser => {
        return dispatch(loadCurrentUser(updatedUser))
      })
  } else {
    updateUser = Promise.resolve()
  }

  updateUser.then(() => {
    return client
      .post('/v2/buddy_requests')
      .then(response => response.json())
      .then(buddyRequest => {
        dispatch(apiResponse({
          type: BUDDY_REQUEST_SUCCESS,
          data: buddyRequest,
          schema: schemas.buddyRequest
        }))
      })
  })
    .then(() => {
      dispatch(stopSubmit(BUDDY_REQUEST_FORM))
    })
    .catch(err => {
      dispatch(stopSubmit(BUDDY_REQUEST_FORM))
      dispatch(handleError(err))
    })
}
