import React from 'react'

import { FormPageHeader } from 'page'
import { Heading } from 'core'

import css from './style.scss'

const ServerIsDownPage = () => {
  return (
    <div>
      <FormPageHeader />
      <div className={css.serverIsDownPage}>
        <Heading size='h2'>
          Our Server is Down
        </Heading>
        <p>
          Please try again soon
        </p>
      </div>
    </div>
  )
}

export default ServerIsDownPage
