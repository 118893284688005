import React from 'react'

import { Heading } from 'core'
import css from './style.scss'

// Image Assets
import buddyUrl from './assets/iconBuddy.png'
import discussionUrl from './assets/iconDiscussion.png'
import motivatorUrl from './assets/iconMotivator.png'
import resourceUrl from './assets/iconResource.png'
import webinarUrl from './assets/iconWebinar.png'
import clockUrl from './assets/iconClock.png'

const imagesSrc = {
  buddy: buddyUrl,
  clock: clockUrl,
  discussion: discussionUrl,
  motivator: motivatorUrl,
  resource: resourceUrl,
  webinar: webinarUrl
}

const SingleCaption = (props) => (
  <div className={css.singleItem}>
    <div className={css.singleItemImageContainer}>
      <img
        className={css.image}
        alt={`Illustration of a ${props.caption_illustration}.`}
        src={imagesSrc[props.caption_illustration]}
      />
    </div>
    <Heading size='h5' freight noMargin>
      {props.caption_title}
    </Heading>
    <p className={css.copy}>{props.caption_copy}</p>
  </div>
)

SingleCaption.propTypes = {
  caption_illustration: React.PropTypes.string.isRequired,
  caption_title: React.PropTypes.string.isRequired,
  caption_copy: React.PropTypes.string.isRequired
}

export default SingleCaption
