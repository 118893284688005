import React from 'react'
import css from './components/style.scss'
const payableStatuses = [
  'awaiting_payment',
  'partial_payment_received',
  'payment_failed'
]

export const orderIsPayable = order => {
  return payableStatuses.includes(order.order_status) && order.order_balance > 0
}

export const orderStatus = order => {
  const {
    order_status: status,
    order_total: orderTotal,
    amount_paid: amountPaid,
    amount_refunded: amountRefunded
  } = order

  if (status === 'payment_failed') return 'Payment Failed'

  const totalAmountPaid = amountPaid - amountRefunded
  if (amountRefunded > 0 && totalAmountPaid === 0) return 'Refunded'
  if (amountRefunded > 0 && totalAmountPaid > 0) return 'Partially Refunded'
  if (amountPaid === 0) return 'Pending'
  if (totalAmountPaid >= orderTotal) {
    return <span className={css.paid}>Paid</span>
  } else {
    return 'Partial Payment'
  }
}
