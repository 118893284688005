import { get } from 'lodash'
import { stopSubmit } from 'redux-form'

import { BadRequestError } from 'api'
import { handleError } from 'errors'

import {
  REGISTER_ELEMENTS,
  ELEMENTS_CHANGED,
  UNREGISTER_ELEMENTS
} from './types'

export const registerStripeElements = (form, elements) => {
  return {
    form,
    type: REGISTER_ELEMENTS,
    elements
  }
}

export const stripeElementChange = (form, event, elementName) => {
  const { error, complete, value } = event
  return {
    form,
    type: ELEMENTS_CHANGED,
    key: elementName,
    complete,
    error,
    value
  }
}

export const unregisterStripeElements = (form) => ({
  form,
  type: UNREGISTER_ELEMENTS
})

export const handleUnexpectedError = (err, FORM_NAME) => dispatch => {
  dispatch(handleError(err))
  dispatch(stopSubmit(FORM_NAME))
}

export const handlePaymentError = (err, FORM_NAME) => dispatch => {
  if (err instanceof BadRequestError) {
    return err.response.json()
      .then(body => {
        if (get(body, 'errors.credit_card') === 'declined') {
          dispatch(stopSubmit(FORM_NAME, { creditCard: 'declined' }))
        } else {
          dispatch(handleUnexpectedError(err, FORM_NAME))
        }
      })
      .catch(err => {
        dispatch(handleUnexpectedError(err, FORM_NAME))
      })
  } else {
    dispatch(handleUnexpectedError(err, FORM_NAME))
    return Promise.resolve()
  }
}
