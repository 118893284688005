import React from 'react'
import moment from 'moment'

import { Link } from 'core'
import { Spacer } from 'layout'

import MetricsCard from '../components/MetricsCard'
import AccountHeading from '../../components/AccountHeading'
import CumulativeLoginChart from './CumulativeLoginChart'
import MetricsSubcards from '../components/MetricsSubcards'
import MetricsSubcard from '../components/MetricsSubcard'
import MetricsTitle from '../components/MetricsTitle'
import MetricsSubtitle from '../components/MetricsSubtitle'
import MetricsNumber from '../components/MetricsNumber'
import MetricsTitleWrapper from '../components/MetricsTitleWrapper'

const formatDate = date => moment(date).format('MMM D, YYYY')

const GeneralMetrics = ({ membership, totalSubaccounts, totalDailyLogins, cumulativeDailyLoginData }) => {
  const membershipStartDate = formatDate(membership.activated_at)
  const membershipExpiryDate = formatDate(membership.expires_at)
  const daysUntilExpiry = moment().to(membership.expires_at)

  return (
    <div>
      <AccountHeading>General</AccountHeading>
      <MetricsCard>
        <MetricsSubcards>
          <MetricsSubcard grey>
            <MetricsTitle>
              Institutional Membership
            </MetricsTitle>
            <p>
              <strong>Membership Start Date</strong>
              <br />
              {membershipStartDate}
            </p>
            <p>
              <strong>Membership Expiry</strong>
              <br />
              {membershipExpiryDate} ({daysUntilExpiry})
            </p>
            <Spacer size='xs' />
            <div>
              <Link orange to='/renew-membership'>
                Renew membership for 1 year
              </Link>
            </div>
          </MetricsSubcard>
          <MetricsSubcard>
            <MetricsTitleWrapper>
              <MetricsTitle>
                Institution Subaccounts
              </MetricsTitle>
            </MetricsTitleWrapper>
            <MetricsNumber>
              {totalSubaccounts.toLocaleString()}
            </MetricsNumber>
          </MetricsSubcard>
          <MetricsSubcard>
            <MetricsTitleWrapper>
              <MetricsTitle>
                Total Active Sessions
              </MetricsTitle>
              <MetricsSubtitle>
                for current membership year
              </MetricsSubtitle>
            </MetricsTitleWrapper>
            <MetricsNumber>
              {totalDailyLogins.toLocaleString()}
            </MetricsNumber>
          </MetricsSubcard>
        </MetricsSubcards>
        {cumulativeDailyLoginData.length > 0 && (
          <CumulativeLoginChart data={cumulativeDailyLoginData} />
        )}
      </MetricsCard>
    </div>
  )
}

export default GeneralMetrics
