import React from 'react'
import { Field } from 'redux-form'
import { Input, Label, Select, TextArea, validators } from 'form'
import subjects from './subjects'

export const Subject = () => (
  <div>
    <Label htmlFor='subject'>Subject</Label>
    <Field
      name='subject'
      component={Select}
      options={subjects}
      validate={validators.required('You must select a subject')}
    />
  </div>
)

export const FirstName = () => (
  <div>
    <Label htmlFor='first_name'>First Name</Label>
    <Field
      name='first_name'
      component={Input}
      validate={validators.required('Your first name is required')}
      type='text'
    />
  </div>
)

export const LastName = () => (
  <div>
    <Label htmlFor='last_name'>Last Name</Label>
    <Field
      validate={validators.required('Your last name is required')}
      name='last_name'
      component={Input}
      type='text'
    />
  </div>
)

export const Email = () => (
  <div>
    <Label htmlFor='email'>Email Address</Label>
    <Field
      name='email'
      component={Input}
      type='text'
      validate={[
        validators.required('Your email is required'),
        validators.email('You have not provided a valid email address')
      ]}
    />
  </div>
)

export const Telephone = () => (
  <div>
    <Label htmlFor='telephone'>Telephone</Label>
    <Field
      name='telephone'
      component={Input}
      validate={validators.required('Your phone number is required')}
      type='text'
    />
  </div>
)

export const Address = ({ name, displayName }) => (
  <div>
    <Label htmlFor={name}>{`${displayName} (Optional)`}</Label>
    <Field
      name={name}
      component={Input}
      type='text'
    />
  </div>
)

export const Country = () => (
  <div>
    <Label htmlFor='country'>Country (Optional)</Label>
    <Field
      name='country'
      component={Input}
      type='text'
    />
  </div>
)

export const Message = () => (
  <div>
    <Label htmlFor='message'>Comments / Questions</Label>
    <Field
      name='message'
      height='210px'
      validate={validators.required('You must specify a message')}
      component={TextArea}
      type='text'
    />
  </div>
)
