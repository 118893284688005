import React from 'react'

const WebinarDescription = ({ description = '' }) => (
  <div dangerouslySetInnerHTML={{ __html: description }} />
)

WebinarDescription.propTypes = {
  description: React.PropTypes.string
}

export default WebinarDescription
