import React, { PropTypes } from 'react'

import css from './style.scss'

const RemoveButton = ({ onClick }) => (
  <button
    type='button'
    onClick={onClick}
    className={css.removeButton}
  >
    –
  </button>
)

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default RemoveButton
