import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import SearchSelect from '../../../../core/components/SearchSelect'

import { searchUsers, assignUser } from './actions'
import { userSearchSelector } from './selectors'
import { reservationsSelector } from '../../selectors'
import css from './style.scss'

export const SelectInstitutionUser = props => {
  const {
    searchUsers,
    userSearch,
    assignUser,
    reservations,
    assignmentsAreAvailable
  } = props
  const spotsAvailable = reservations.filter(reservation => !reservation.user_id).length > 0

  return (
    <div className={css.wrapper}>
      <p className={css.instructions}>
        <strong>
          {assignmentsAreAvailable
            ? 'Select which participant(s) you would like to register for the bootcamp'
            : 'The assignment period has ended'}
        </strong>
      </p>

      {assignmentsAreAvailable && (
        <SearchSelect
          placeholder={
            spotsAvailable
              ? "Search your institution's members by name"
              : 'Every reservation has a user assigned'
          }
          value={null}
          onChange={(option) => assignUser(option.value)}
          onInputChange={searchUsers}
          options={userSearch.options}
          disabled={!spotsAvailable}
          isLoading={userSearch.loading}
        />
      )}
    </div>
  )
}

SelectInstitutionUser.propTypes = {
  searchUsers: PropTypes.func.isRequired,
  assignUser: PropTypes.func.isRequired,
  userSearch: PropTypes.object.isRequired,
  reservations: PropTypes.arrayOf(PropTypes.shape({
    user_id: PropTypes.number
  })).isRequired,
  assignmentsAreAvailable: PropTypes.bool
}

export default connect(
  createStructuredSelector({
    reservations: reservationsSelector,
    userSearch: userSearchSelector
  }),
  { searchUsers, assignUser }
)(SelectInstitutionUser)
