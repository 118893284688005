const vimeoUrlRegex = /\/\/(?:www\.)?vimeo\.com\/([0-9a-z\-_]+)/i

const videoId = (url) => {
  const matches = url.match(vimeoUrlRegex)
  return matches ? matches[1] : null
}

export const vimeoFormatter = (vimeoUrl) => {
  if (!vimeoUrl) {
    return null
  }

  const id = videoId(vimeoUrl)

  if (!id) {
    return null
  }

  return `//player.vimeo.com/video/${id}`
}

export const youtubeFormatter = (youtubeUrl) => {
  if (!youtubeUrl) {
    return null
  }

  return `${youtubeUrl}?showinfo=0&controls=1&modestbranding=0&rel=0&disablekb=1`
}
