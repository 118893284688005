import React from 'react'
import { ErrorPage, ErrorHeading, ErrorButton } from 'error-page'
import { PageHead } from 'page/PageHead'

const InternalServerErrorPage = () => (
  <ErrorPage>
    <PageHead />
    <ErrorHeading>Internal Server Error</ErrorHeading>
    <ErrorButton onClick={() => window.location.reload()}>Refresh to try again</ErrorButton>
  </ErrorPage>
)

export default InternalServerErrorPage
