import { get } from 'lodash'

import { loadData, schemas, apiResponse, BadRequestError } from 'api'
import { createClient } from 'auth'
import { handleError } from 'errors'

import { UPDATE_USER_SEARCH_TERM, LOAD_RESERVATION } from '../../types'
import { institutionUserSearchUrlSelector } from './selectors'
import { reservationsSelector } from '../../selectors'

/**
 * Updates the search term and makes the search query
 */

export const searchUsers = searchTerm => (dispatch, getState) => {
  dispatch({ type: UPDATE_USER_SEARCH_TERM, searchTerm })

  const url = institutionUserSearchUrlSelector(getState())

  if (url) {
    return dispatch(loadData({
      url,
      schema: [schemas.user]
    }))
  }
}

/**
 * Clears the search term and updates the reservation
 */

const isUserAlreadyInSession = err => {
  if (!(err instanceof BadRequestError)) return Promise.resolve(false)
  return err.response.json()
    .then(body => {
      return ['session_sale_id', 'user_id'].every(property => {
        return get(body, ['errors', property], []).includes('already exists')
      })
    })
}

export const assignUser = userId => (dispatch, getState) => {
  dispatch({ type: UPDATE_USER_SEARCH_TERM, searchTerm: '' })

  const state = getState()
  const reservations = reservationsSelector(state)

  const reservation = reservations.filter(reservation => !reservation.user_id)[0]

  return createClient(state)
    .patch(`/v2/session_reservations/${reservation.id}`, {
      body: {
        user_id: userId
      }
    })
    .then(res => res.json())
    .then(reservation => {
      dispatch(apiResponse({
        type: LOAD_RESERVATION,
        data: reservation,
        schema: schemas.sessionReservation
      }))
    })
    .catch(err => {
      return isUserAlreadyInSession(err)
        .then(userAlreadyInSession => {
          if (userAlreadyInSession) {
            return dispatch(handleError(err, 'This user has already registered for this session.'))
          } else {
            throw err
          }
        })
        .catch(err => dispatch(handleError(err)))
    })
}
