import React from 'react'
import { load, schemas } from 'api'
import { Grid, GridItem, Spacer } from 'layout'
import { Heading, HorizontalRule } from 'core'
import ResourceItem from './ResourceItem'

const center = {
  textAlign: 'center'
}

const WebinarResources = ({ resources, mediaTargets }) => {
  if (!resources && mediaTargets && mediaTargets.length === 0) {
    return (
      <div>
        This webinar does not have any associated resources.
      </div>
    )
  }

  return (
    <div>
      {mediaTargets && mediaTargets.length > 0 && (
        <div>
          <Grid>
            <GridItem col='1/3'>
              <Heading size='h6' weight='thin' noMargin>
                Resource
              </Heading>
            </GridItem>
            <GridItem col='1/3'>
              <Heading size='h6' weight='thin' noMargin>
                Media Type
              </Heading>
            </GridItem>
            <GridItem style={center} col='1/3'>
              <Heading size='h6' weight='thin' noMargin>
                View
              </Heading>
            </GridItem>
          </Grid>
          {mediaTargets.map((item, index) => {
            return (
              <div key={index}>
                <ResourceItem item={item} />
              </div>
            )
          })}
        </div>
      )}
      {resources && (
        <div>
          <Spacer size='xs' />
          <HorizontalRule fullWidth full />
          <Spacer size='xs' />
          <div dangerouslySetInnerHTML={{ __html: resources }} />
        </div>
      )}
    </div>
  )
}

WebinarResources.propTypes = {
  id: React.PropTypes.number.isRequired,
  resources: React.PropTypes.string,
  mediaTargets: React.PropTypes.array
}

export default load({
  mediaTargets: {
    urlSelector: (state, props) => `/v2/media_targets?target_type=webinar&target_id=${props.id}`,
    schema: [schemas.mediaTarget]
  }
})(WebinarResources)
