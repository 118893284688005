import { UserAuthWrapper } from 'redux-auth-wrapper'
import { createStructuredSelector } from 'reselect'

import { buildWrapper } from 'helpers'
import {
  loadingCurrentUserSelector,
  currentUserHasCompleteProfileSelector
} from '../selectors'

export default buildWrapper((options = {}) => {
  const { redirectPath = '/complete-profile' } = options

  return UserAuthWrapper({
    wrapperDisplayName: 'RequireCompleteUserProfile',

    authenticatingSelector: loadingCurrentUserSelector,
    authSelector: createStructuredSelector({
      loading: loadingCurrentUserSelector,
      isProfileComplete: currentUserHasCompleteProfileSelector
    }),
    predicate: ({ isProfileComplete, loading }) => !loading && isProfileComplete,

    allowRedirectBack: false,
    failureRedirectPath: redirectPath
  })
})
