import React from 'react'
import { connect } from 'react-redux'

import { currentUserSelector } from 'auth'
import { NotificationBanner } from './components/NotificationBanner'
import { requestConfirmationEmail } from './actions'

const MissingProfileFieldsNotification = (props) => {
  let bannerMsg
  const buttonOptions = {
    children: 'Edit User Profile',
    size: 'primary',
    color: 'green',
    noMargin: true,
    disabled: props.loading || props.emailSent,
    onClick: () => { window.location.pathname = '/account/profile' }
  }

  if (props.user) {
    const baseMsg = 'Tell us about yourself!'
    if (!props.user.title && !props.user.university) {
      bannerMsg = `${baseMsg} Please finish filling out Title and University in your account profile.`
    } else if (!props.user.title) {
      bannerMsg = `${baseMsg} Please finish filling out Title in your account profile.`
    } else if (!props.user.university) {
      bannerMsg = `${baseMsg} Please finish filling out University in your account profile.`
    } else {
      return null
    }
  } else {
    return null
  }

  return (
    <NotificationBanner
      message={bannerMsg}
      buttonOptions={buttonOptions}
    />
  )
}

MissingProfileFieldsNotification.propTypes = {
  user: React.PropTypes.object
}

const mapStateToProps = (state) => ({
  user: currentUserSelector(state),
  loading: state.dashboard.emailConfirmation.loading,
  emailSent: state.dashboard.emailConfirmation.emailSent
})

export default connect(mapStateToProps, { requestConfirmationEmail })(
  MissingProfileFieldsNotification
)
