import React, { Component } from 'react'
import { connect } from 'react-redux'

import { handleSsoRequest } from './actions'

export default connect(null, { handleSsoRequest })(
  class ForumsSsoPage extends Component {
    componentWillMount () {
      const { sso, sig } = this.props.location.query
      this.props.handleSsoRequest(sso, sig)
    }

    render () {
      return (
        <p>Loading...</p>
      )
    }
  }
)
