import React from 'react'
import MediaQuery from 'react-responsive'
import { compact } from 'lodash'

import { Link } from 'core'
import { Select } from 'form'
import { Section, Grid, GridItem } from 'layout'

import css from './style.scss'
import { breakpoints } from 'styles'

const { phabletMax, phabletMaxPlusOne } = breakpoints

const EventFilters = (props) => {
  const { types, activeType } = props
  const input = {
    value: activeType,
    onChange: props.onDropdownChange
  }

  const dropdownTypes = types.map((type) => ({
    name: type.label,
    value: type.type
  }))

  const tabs = (
    <div className={css.wrapper}>
      <Section>
        <Grid noGutter>
          <GridItem col='1/5'>
            <Link to='/events'>
              <div className={`${css.tab} ${!activeType ? css.active : ''}`}>
                <span>
                  All Events
                </span>
              </div>
            </Link>
          </GridItem>
          {
            types.map((type, index) => {
              const classes = [
                css.tab,
                type.type === activeType ? css.active : ''
              ]
              return (
                <GridItem col='1/5' key={index}>
                  <Link to={{ pathname: '/events', query: { type: type.type } }}>
                    <div className={compact(classes).join(' ')}>
                      <span>
                        {type.label}
                      </span>
                    </div>
                  </Link>
                </GridItem>
              )
            })
          }
        </Grid>
      </Section>
    </div>
  )

  const dropdown = (
    <div className={css.dropdownWrapper}>
      <Section>
        <Select
          grey
          name='type'
          options={dropdownTypes}
          input={input}
          placeholder='Filter by Type...'
        />
      </Section>
    </div>
  )

  return (
    <div>
      <MediaQuery minWidth={phabletMaxPlusOne}>
        {tabs}
      </MediaQuery>
      <MediaQuery maxWidth={phabletMax}>
        {dropdown}
      </MediaQuery>
    </div>
  )
}

EventFilters.propTypes = {
  types: React.PropTypes.arrayOf(React.PropTypes.shape({
    type: React.PropTypes.string,
    label: React.PropTypes.string
  })),
  activeType: React.PropTypes.string,
  onDropdownChange: React.PropTypes.func.isRequired
}

export default EventFilters
