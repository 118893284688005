import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import SearchSelect from '../core/components/SearchSelect'
import { searchInstitutions } from './actions'
import { institutionSearchSelector } from './selectors'
import { Input, Label } from 'form'
import { MAX_NAME_LENGTH } from 'consts'
import css from './style.scss'

const checkboxId = 'selectinstitution-input'

class SelectInstitution extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isFreeInput: false,
      selectValue: null
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
  }

  handleChange (nextValue) {
    this.setState({ selectValue: nextValue })
    const {
      input: { onChange }
    } = this.props
    onChange(nextValue?.value)

    const institution = this.props.institutionSearch?.getInstitutionForValue?.(nextValue?.value)
    this.props.onInstitutionSelect?.(institution)
  }

  componentWillUnmount () {
    this.props.onInstitutionSelect?.(null)
  }

  handleCheck (e) {
    const { checked } = e.target
    this.setState({ isFreeInput: checked })
  }

  render () {
    const {
      disabled,
      input,
      input: { onBlur, value },
      institutionSearch,
      searchInstitutions,
      ...otherProps
    } = this.props

    const { isFreeInput } = this.state

    return (
      <div className={css.container}>
        {isFreeInput
          ? (
            <Input
              placeholder='Please type your university name'
              input={input}
              maxLength={MAX_NAME_LENGTH}
              disabled={disabled}
              {...otherProps}
            />)
          : (
            <SearchSelect
              placeholder='Search for your university by name'
              value={this.state.selectValue}
              onBlur={() => onBlur(value)}
              onChange={this.handleChange}
              onInputChange={searchInstitutions}
              options={institutionSearch.options}
              disabled={disabled}
              isLoading={institutionSearch.loading}
              {...otherProps}
            />)}
        {!this.props.hideIDoNotSeeInstitution && (
          <div className={css.checkboxContainer}>
            <input
              className={css.checkbox}
              type='checkbox'
              id={checkboxId}
              disabled={disabled}
              checked={isFreeInput}
              onChange={this.handleCheck}
            />
            <Label className={css.label} htmlFor={checkboxId}>
              I do not see my institution
            </Label>
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  (state, ownProps) => {
    return {
      institutionSearch: institutionSearchSelector(ownProps)(state)
    }
  },
  (dispatch, ownProps) => ({
    searchInstitutions: q => dispatch(searchInstitutions(ownProps)(q))
  })
)(SelectInstitution)

SelectInstitution.propTypes = {
  disabled: PropTypes.bool.isRequired,
  input: PropTypes.shape({
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
  }),
  // * redux-form meta object
  meta: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  // * redux state and action creators
  institutionSearch: PropTypes.shape({}).isRequired,
  searchInstitutions: PropTypes.func.isRequired
}
