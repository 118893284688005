import { UserAuthWrapper } from 'redux-auth-wrapper'

import { buildWrapper } from 'helpers'
import {
  loadingCurrentUserSelector,
  isAuthenticatedSelector
} from '../selectors'

const selectLocation = state => state.routing.locationBeforeTransitions

export default buildWrapper((options = {}) => {
  const { redirectPath = '/' } = options
  return UserAuthWrapper({
    wrapperDisplayName: 'RequireNoAuth',

    authenticatingSelector: loadingCurrentUserSelector,
    authSelector: state => ({
      isAuthenticated: isAuthenticatedSelector(state)
    }),
    predicate: ({ isAuthenticated }) => !isAuthenticated,

    allowRedirectBack: false,
    failureRedirectPath: state => {
      const location = selectLocation(state)
      return location.query.redirect || redirectPath
    }
  })
})
