import Immutable from 'seamless-immutable'

import { UPDATE_SEARCH_TERM } from './types'

const initialState = Immutable.from({})

const reducersByType = {
  [UPDATE_SEARCH_TERM]: (state, action) => {
    return state.set('searchTerm', action.searchTerm)
  }
}

const reducer = (state = initialState, action) => {
  const reducer = reducersByType[action.type]
  return reducer ? reducer(state, action) : state
}

export default reducer
