import React from 'react'
import { createSelector } from 'reselect'
import qs from 'qs'

import { load } from 'api'

export default ({ pageSelector }) => {
  if (!pageSelector) {
    throw new Error('Page selector is required')
  }

  return WrappedComponent => {
    return load({
      cmsFixedPages: {
        urlSelector: createSelector(
          pageSelector,
          page => {
            if (!page) return null
            return `/v2/cms_fixed_pages?${qs.stringify({
              filters: { page }
            })}`
          }
        )
      }
    })(({ cmsFixedPages, ...otherProps }) => {
      const blocks = cmsFixedPages && cmsFixedPages.length > 0
        ? cmsFixedPages[0].blocks
        : []
      return <WrappedComponent {...otherProps} fixedPageBlocks={blocks} />
    })
  }
}
