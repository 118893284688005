import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues } from 'redux-form'
import { createSelector } from 'reselect'

import { Button, Heading, Link } from 'core'
import { Label, Input, validators, Form } from 'form'
import { applyHOCs } from 'helpers'
import { Modal, closeModal } from 'modal'

import css from './style.scss'

import { login } from './actions'
import { FORM_NAME } from './constants'

const LoginModal = (props) => {
  let error

  if (props.error === 'require_confirmation') {
    const link = (
      <Link
        to={{
          pathname: '/contact-us',
          query: {
            email: props.email,
            type: 'resend_confirmation'
          }
        }}
        className={css.contactUsLink}
        onClick={props.closeModal} // eslint-disable-line react/jsx-handler-names
      >

        Contact us
      </Link>
    )
    error = (
      <div>
        Email verification required. {link} to request a new confirmation email.
      </div>
    )
  } else if (props.error) {
    error = props.error
  }

  return (
    <Modal>
      <div className={css.loginModalContainer}>
        <Heading size='h4' weight='light' center>
          Sign In
        </Heading>
        <Form
          name='login'
          disableTracking
          onSubmit={() => {
            return props.login({ redirectToDashboard: props.redirectToDashboard, refresh: props.refresh })
              .then((res) => {
                if (!(res instanceof Error) && window.dataLayer) {
                  window.dataLayer.push({
                    event: 'login_event'
                  })
                }
              })
          }}
        >
          <div>
            <Label htmlFor='email'>Email</Label>
            <Field
              name='email'
              component={Input}
              validate={validators.required('Your email is required')}
              globalError={props.error}
            />
          </div>
          <div>
            <Label htmlFor='password'>Password</Label>
            <Field
              name='password'
              type='password'
              component={Input}
              validate={validators.required('Your password is required')}
              globalError={props.error}
            />
          </div>
          <div className={css.errorText}>
            {error}
          </div>
          <div>
            <Button
              type='submit'
              size='primary'
              disabled={props.submitting || props.invalid}
              isLoading={props.submitting}
            >
              Log In
            </Button>
          </div>
        </Form>
        <div className={css.link}>
          <Link
            onClick={() => props.closeModal()}
            to='/forgot-password'
            orange
          >
            Forgot your password?
          </Link>
        </div>
      </div>
    </Modal>
  )
}

LoginModal.propTypes = {
  login: React.PropTypes.func.isRequired,
  redirectToDashboard: React.PropTypes.bool,
  /* Redux-form */
  error: React.PropTypes.string,
  invalid: React.PropTypes.bool,
  submitting: React.PropTypes.bool,
  refresh: React.PropTypes.bool
}

export default applyHOCs([
  connect(
    createSelector(
      getFormValues(FORM_NAME),
      (values = {}) => ({ email: values.email })
    ),
    { login, closeModal }
  ),
  reduxForm({ form: FORM_NAME })
])(LoginModal)
