export const formatPrice = price => {
  const priceInt = Math.ceil(price)

  let priceNum
  if (priceInt >= 1000) {
    priceNum = `${priceInt / 1000}k`
  } else {
    priceNum = priceInt
  }

  return `$${priceNum}`
}
