import React, { PropTypes } from 'react'
import { find } from 'lodash'

import { load, schemas } from 'api'
import { Heading } from 'core'
import { CMSSection } from '../components'

import IndividualPlanCard from './IndividualPlanCard'
import InstitutionPlanCard from './InstitutionPlanCard'
import css from './style.scss'

const INSTITUTION_PLAN_CODE = 'MEMBERSHIP_INSTITUTIONAL'
const INDIVIDUAL_STUDENT_PLAN_CODE = 'MEMBERSHIP_GRADPOSTDOC'
const INDIVIDUAL_FACULTY_PLAN_CODE = 'MEMBERSHIP_FACULTY'

const MembershipPlanPricing = (props) => {
  const { membershipPlans, embedded } = props

  if (!membershipPlans) return null

  const institutionPlan = find(membershipPlans, { code: INSTITUTION_PLAN_CODE })
  const individualStudentPlan = find(membershipPlans, { code: INDIVIDUAL_STUDENT_PLAN_CODE })
  const individualFacultyPlan = find(membershipPlans, { code: INDIVIDUAL_FACULTY_PLAN_CODE })

  return (
    <CMSSection
      title={props.title}
      subtitle={props.subtitle}
    >
      <div className={css.pricingContainer}>
        {
          !embedded && institutionPlan &&
            <InstitutionPlanCard
              membershipPlanId={institutionPlan.id}
              price={institutionPlan.price}
              ctaUrl={props.institution_cta_url}
              ctaCopy={props.institution_cta_copy}
              description={props.institution_description}
            />
        }
        {(individualStudentPlan || individualFacultyPlan) &&
          <div>
            <Heading size='h4' freight weight='medium' center>
              Individual Pricing
            </Heading>
            {individualStudentPlan &&
              <IndividualPlanCard
                name='Graduate / Postdoc'
                embedded={embedded}
                membershipPlanId={individualStudentPlan.id}
                price={individualStudentPlan.price}
              />}
            {individualFacultyPlan &&
              <IndividualPlanCard
                name='Faculty Member'
                embedded={embedded}
                membershipPlanId={individualFacultyPlan.id}
                price={individualFacultyPlan.price}
              />}
          </div>}
      </div>
    </CMSSection>
  )
}

MembershipPlanPricing.propTypes = {
  membershipPlans: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired
  })),
  embedded: React.PropTypes.boolean,
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  /* Passed to InstitutionPlanCard */
  institution_description: React.PropTypes.string,
  institution_cta_url: React.PropTypes.string,
  institution_cta_copy: React.PropTypes.string
}

export default load({
  membershipPlans: {
    urlSelector: () => '/v2/membership_plans',
    schema: [schemas.membershipPlan]
  }
})(MembershipPlanPricing)
