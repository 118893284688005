import React from 'react'

import { Link } from 'core'
import { ChevronLink } from 'icons'
import { CMSSection } from '../components'

import css from './style.scss'

const Logos = (props) => (
  <CMSSection
    title={props.title}
    subtitle={props.subtitle}
  >
    <div className={css.wrapper}>
      {
        props.logos.map((logo, index) => {
          const logoAsset = (
            <img src={logo.img_url} alt={`Logo of ${logo.name}`} />
          )

          return (
            <div key={index} className={css.logoWrapper}>
              {
                logo.url
                  ? <Link to={logo.url}>{logoAsset}</Link>
                  : logoAsset
              }
            </div>
          )
        })
      }
      <div className={css.copy}>
        <p>{props.copy}</p>
        {
          (props.cta_url && props.cta_label) &&
            <Link to={props.cta_url} orange>
              {props.cta_label} <ChevronLink color='orange' />
            </Link>
        }
      </div>
    </div>
  </CMSSection>
)

Logos.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  cta_url: React.PropTypes.string,
  cta_label: React.PropTypes.string,
  copy: React.PropTypes.string,
  logos: React.PropTypes.arrayOf(React.PropTypes.shape({
    name: React.PropTypes.string.isRequired,
    url: React.PropTypes.string,
    img_url: React.PropTypes.string
  })).isRequired
}

export default Logos
