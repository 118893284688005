import React, { PropTypes } from 'react'
import { compact } from 'lodash'

import { formatPrice } from 'helpers'

import css from './style.scss'

const PriceSubtotal = ({ title, price, navy }) => {
  const classNames = [
    css.wrapper,
    navy ? css.navy : null
  ]

  return (
    <div className={compact(classNames).join(' ')}>
      <div className={css.title}>
        <span>{title}</span>
      </div>
      <div className={css.price}>
        <span>{formatPrice(price)}</span>
      </div>
    </div>
  )
}

PriceSubtotal.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  navy: PropTypes.bool
}

export default PriceSubtotal
