import { startSubmit, stopSubmit, getFormValues } from 'redux-form'
import { createClient } from 'auth'
import { handleError } from 'errors'
import { FORM_NAME } from './constants'

export const submitForm = () => (dispatch, getState) => {
  const state = getState()
  const form = getFormValues(FORM_NAME)(state)

  dispatch(startSubmit(FORM_NAME))

  return createClient(state)
    .post('/v2/contact_requests', { body: form })
    .then(() => dispatch(stopSubmit(FORM_NAME)))
    .catch(err => {
      dispatch(stopSubmit(FORM_NAME, { err }))
      dispatch(handleError(err))
    })
}
