import React from 'react'

import { loadFixedPageBlocks } from 'cms'

import NonFSPHeader from './NonFSPHeader'
import { PageBlockRenderer } from 'core/components/PageBlockRenderer'

const NonFSPLandingPage = ({ fixedPageBlocks, sessionSale }) => {
  return (
    <div>
      <NonFSPHeader sessionSale={sessionSale} />
      <PageBlockRenderer blocks={fixedPageBlocks} sessionSale={sessionSale} />
    </div>
  )
}

export default loadFixedPageBlocks({
  pageSelector: (state, { sessionSale }) => {
    return sessionSale.type
  }
})(NonFSPLandingPage)
