import React from 'react'

import css from './style.scss'

/**
 * The <Spacer /> component is used as a catch-all layout
 * element to apply whitespace in contexts where defined component
 * margining is not enough visual whitespace.
 */

const Spacer = (props) => {
  const classes = [
    css.spacer,
    props.size ? css[props.size] : css.md
  ]

  return (
    <div className={classes.join(' ')} />
  )
}

Spacer.propTypes = {
  /* Size expects string values of:
   * xxs, xs, sm, md, lg, xl
   */
  size: React.PropTypes.string
}

export default Spacer
