import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { Field, reduxForm, hasSubmitSucceeded } from 'redux-form'
import { createSelector } from 'reselect'
import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from 'consts'

import { Button, Heading, Link } from 'core'
import { Input, Label, validators } from 'form'
import { applyHOCs } from 'helpers'
import { Page } from 'page'
import { Section, Spacer } from 'layout'

import { FORM_NAME_RESET } from './constants'
import { resetPassword } from './actions'

export class ResetPassword extends React.Component {
  redirectWithoutToken (token) {
    if (!token) {
      browserHistory.push('/forgot-password')
    }
  }

  componentWillMount () {
    this.redirectWithoutToken(this.props.token)
  }

  componentWillReceiveProps (newProps) {
    this.redirectWithoutToken(newProps.token)
  }

  render () {
    const { resetPassword, hasResetPassword, token, error } = this.props

    return (
      <Page>
        <Section narrow>
          <Spacer size='md' />
          <Heading size='h4' center>Reset your password</Heading>
          {
            error &&
            (
              <div>
                <p>
                  There was an error resetting your password. {error} Try <Link to='/forgot-password' orange>requesting a new password reset token</Link>.
                </p>
                <Spacer size='xs' />
              </div>
            )
          }
          {
            !hasResetPassword &&
            (
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  resetPassword(token)
                }}
              >
                <Label htmlFor='password'>New Password</Label>
                <Field
                  name='password'
                  component={Input}
                  type='password'
                  maxLength={MAX_PASSWORD_LENGTH}
                  validate={[
                    validators.required('A new password is required'),
                    validators.minLength(MIN_PASSWORD_LENGTH, `Your password must be at least ${MIN_PASSWORD_LENGTH} characters`)
                  ]}
                />
                <Label htmlFor='password'>Confirm New Password</Label>
                <Field
                  name='password_confirmation'
                  component={Input}
                  type='password'
                  maxLength={MAX_PASSWORD_LENGTH}
                  validate={[
                    validators.required('Confirming your new password is required'),
                    validators.equalToField('password', 'Your passwords do not match')
                  ]}
                />
                <Spacer size='xs' />
                <Button
                  type='submit'
                  size='primary'
                  disabled={this.props.submitting || this.props.invalid}
                  isLoading={this.props.submitting}
                >
                  Reset Password
                </Button>
              </form>
            )
          }
          {
            hasResetPassword &&
            (
              <div>
                <p>
                  Your password has been successfully reset! You can now <Link to='login' orange>log in</Link> to your account with your new password.
                </p>
              </div>
            )
          }
          <Spacer size='md' />
        </Section>
      </Page>
    )
  }
}

ResetPassword.propTypes = {
  /* mapStateToProps */
  token: React.PropTypes.string,
  hasResetPassword: React.PropTypes.bool,
  /* mapDispatchToProps */
  resetPassword: React.PropTypes.func
}

const mapStateToProps = createSelector(
  (state, ownProps) => ownProps.router.location.query.reset_password_token,
  hasSubmitSucceeded(FORM_NAME_RESET),
  (token, hasResetPassword) => ({
    token,
    hasResetPassword
  })
)

export default applyHOCs([
  connect(
    mapStateToProps,
    { resetPassword }
  ),
  reduxForm({ form: FORM_NAME_RESET })
])(ResetPassword)
