import { combineReducers } from 'redux'
import { filterActions } from 'redux-ignore'
import { has } from 'lodash'

import buildEntitiesReducer from './buildEntitiesReducer'

const reducer = combineReducers({
  buddy_requests: buildEntitiesReducer('buddy_requests'),
  categories: buildEntitiesReducer('categories'),
  chat_messages: buildEntitiesReducer('chat_messages'),
  cms_pages: buildEntitiesReducer('cms_pages'),
  cms_fixed_pages: buildEntitiesReducer('cms_fixed_pages'),
  core_curriculum_webinars: buildEntitiesReducer('core_curriculum_webinars'),
  courses: buildEntitiesReducer('courses'),
  curriculums: buildEntitiesReducer('curriculums'),
  discounts: buildEntitiesReducer('discounts'),
  events: buildEntitiesReducer('events'),
  institutions: buildEntitiesReducer('institutions'),
  groups: buildEntitiesReducer('groups'),
  memberships: buildEntitiesReducer('memberships'),
  membership_plans: buildEntitiesReducer('membership_plans'),
  orders: buildEntitiesReducer('orders'),
  order_items: buildEntitiesReducer('order_items'),
  sessions: buildEntitiesReducer('sessions'),
  session_reservations: buildEntitiesReducer('session_reservations'),
  session_sales: buildEntitiesReducer('session_sales'),
  users: buildEntitiesReducer('users'),
  webinars: buildEntitiesReducer('webinars'),
  media: buildEntitiesReducer('media'),
  media_targets: buildEntitiesReducer('media_targets'),
  posts: buildEntitiesReducer('posts')
})

export default filterActions(reducer, action => has(action, 'entities'))
