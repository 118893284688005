import React from 'react'

import { Card, Heading, Link } from 'core'
import { Grid, GridItem } from 'layout'
import { ChevronLink } from 'icons'

import css from './style.scss'
import { formatPrice } from './helpers'

const InstitutionPlanCard = (props) => {
  const { price, ctaUrl, ctaCopy, description } = props
  return (
    <div className={css.cardWrapper}>
      <Card>
        <div className={css.cardContainer}>
          <Grid stretch rowReverse>
            <GridItem col='1/2' mobile1of1>
              <div className={css.institutionPricing}>
                <span className={css.pricingSubText}>
                  Most Popular
                </span>
                <span className={css.institutionPricingPrice}>
                  {formatPrice(price)}
                </span>
                <span className={css.pricingSubText}>
                  /year
                </span>
              </div>
            </GridItem>
            <GridItem col='1/2' mobile1of1>
              <div className={css.institutionalHeading}>
                <Heading size='h5' freight weight='medium' noMargin>
                  Institutional Membership
                </Heading>
              </div>
              {description && (
                <p className={css.institutionAbout}>
                  {description}
                </p>
              )}
              {
                (ctaUrl && ctaCopy) &&
                  <div className={css.institutionLink}>
                    <Link to={ctaUrl} orange>
                      {ctaCopy} <ChevronLink color='orange' />
                    </Link>
                  </div>
              }
            </GridItem>
          </Grid>
        </div>
      </Card>
    </div>
  )
}

InstitutionPlanCard.propTypes = {
  price: React.PropTypes.number.isRequired,
  ctaUrl: React.PropTypes.string,
  ctaCopy: React.PropTypes.string,
  description: React.PropTypes.string
}

export default InstitutionPlanCard
