import { UserAuthWrapper } from 'redux-auth-wrapper'

import { buildWrapper } from 'helpers'
import {
  loadingCurrentUserSelector,
  isIAHSelector
} from '../selectors'

export default buildWrapper((options = {}) => {
  const { redirectPath = '/' } = options
  return UserAuthWrapper({
    wrapperDisplayName: 'RequireIAH',

    authenticatingSelector: loadingCurrentUserSelector,
    authSelector: state => ({
      isIAH: isIAHSelector(state)
    }),
    predicate: ({ isIAH }) => isIAH,

    allowRedirectBack: false,
    failureRedirectPath: redirectPath
  })
})
