import React from 'react'
import { connect } from 'react-redux'
import { find } from 'lodash'

import { load, schemas } from 'api'
import { applyHOCs } from 'helpers'
import { titleOptionsSelector } from 'settings'

import { Team } from '../Team'

export const Coaches = (props) => {
  if (!props.coaches) return null

  const teamMembers = props.coaches.map((coach) => {
    let { title } = coach

    if (title) {
      const option = find(props.titleOptions, { value: title })
      title = option ? option.name : title
    }

    return {
      ...coach,
      title
    }
  })

  return (
    <Team
      title={props.title}
      subtitle={props.subtitle}
      team_members={teamMembers}
    />
  )
}

Coaches.propTypes = {
  title: React.PropTypes.string,
  subtitle: React.PropTypes.string,
  coaches: React.PropTypes.arrayOf(React.PropTypes.object)
}

export default applyHOCs([
  connect(
    state => ({ titleOptions: titleOptionsSelector(state) })
  ),
  load({
    coaches: {
      urlSelector: () => '/v2/users/coaches',
      schema: [schemas.user]
    }
  })
])(Coaches)
