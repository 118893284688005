import React from 'react'
import { compact } from 'lodash'
import { SpinnerIcon } from 'icons'

import css from './style.scss'

const Button = (props) => {
  const classes = [
    css.button,
    props.size ? css[props.size] : css.primary,
    props.color ? css[props.color] : css.orange,
    props.noMargin ? css.noMargin : '',
    props.outline ? css.outline : '',
    props.isLoading ? css.isLoading : '',
    props.success ? css.success : '',
    props.className
  ]

  return (
    <button
      className={compact(classes).join(' ')}
      type={props.type || 'button'}
      onClick={props.onClick}
      disabled={props.disabled || false}
    >
      {props.children}
      {
        props.isLoading &&
          <div className={css.spinner}>
            <SpinnerIcon />
          </div>
      }
    </button>
  )
}

Button.propTypes = {
  /**
   * This is the label of the button
   */
  children: React.PropTypes.node.isRequired,
  type: React.PropTypes.string,
  onClick: React.PropTypes.func,
  /**
   * Available values: primary, secondary, tertiary
   */
  size: React.PropTypes.string,
  /**
   * Available values: orange, green, white (default outline)
   */
  color: React.PropTypes.string,
  /**
   * If true, applies margin: 0px property
   */
  noMargin: React.PropTypes.bool,
  /**
   * If true, removes background fill, and instead sets
   * passed color as `border-color:` and `color:` properties.
   */
  outline: React.PropTypes.bool,
  /*
   * If true, the button will be disabled
   */
  disabled: React.PropTypes.bool,
  /**
   * If true, the button, when disabled, will override opacity styles to
   * have a "success + disabled" button
   */
  success: React.PropTypes.bool
}

export default Button
