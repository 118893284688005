import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { chatRefreshRate } from 'config'

import { Button } from 'core'
import { Input } from 'form'
import { Grid, GridItem } from 'layout'

import { ChatSingle } from './ChatSingle'

const form = reduxForm({
  form: 'webinarChat'
})

class WebinarChat extends React.Component {
  /**
   * Rerender periodically to update message times
   */
  componentDidMount () {
    this.updateInterval = setInterval(this.forceUpdate.bind(this), chatRefreshRate)
  }

  componentWillUnmount () {
    clearInterval(this.updateInterval)
  }

  render () {
    const inputFieldName = `chat-${this.props.room}`

    return (
      <div>
        <form
          onSubmit={this.props.handleSubmit((values) => {
            this.props.postMessage(values[inputFieldName])
            this.props.reset()
          })}
        >
          <Field name={inputFieldName} type='text' component={Input} disableAutocomplete />
          <Grid rowReverse>
            <GridItem col='1/4' tablet1of3 mobile1of1>
              <Button size='secondary' color='green' type='submit' noMargin>
                Post
              </Button>
            </GridItem>
          </Grid>
        </form>
        <ul style={{ maxHeight: '400px', overflow: 'scroll', paddingLeft: 0, marginTop: '10px' }}>
          {this.props.chatEvents
            .filter(event => event.type === 'chatMessage')
            .reverse()
            .map((event, index) => (
              <ChatSingle chatEvent={event} key={index} />
            ))}
        </ul>
      </div>
    )
  }
}

WebinarChat.propTypes = {
  chatEvents: React.PropTypes.arrayOf(React.PropTypes.object),
  postMessage: React.PropTypes.func.isRequired,
  /* From redux-form */
  handleSubmit: React.PropTypes.func.isRequired,
  reset: React.PropTypes.func
}

WebinarChat.defaultProps = {
  chatEvents: []
}

export default form(WebinarChat)
