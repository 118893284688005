import React from 'react'
import moment from 'moment'

import { Heading } from 'core'

import inactiveIcon from './assets/chat-inactive.png'

import css from './style.scss'

const ChatInactive = ({ start, end, isDisabled }) => {
  const now = moment()
  let cta

  if (now.isBefore(start)) {
    cta = 'To join in the conversation, come back when this webinar goes live!'
  } else if (now.isAfter(end)) {
    cta = 'To join in the conversation next time, tune in to a live webinar!'
  } else if (isDisabled) {
    cta = 'Chat has been disabled for this event.'
  }

  return (
    <div className={css.wrapper}>
      <img src={inactiveIcon} alt='Chat is not active.' />
      <Heading size='h4' noMargin>
        Chat is not currently active
      </Heading>
      <p>{cta}</p>
    </div>
  )
}

ChatInactive.propTypes = {
  start: React.PropTypes.string.isRequired,
  end: React.PropTypes.string.isRequired,
  isDisabled: React.PropTypes.bool.isRequired
}

export default ChatInactive
