import React from 'react'
import { Route, IndexRoute, IndexRedirect } from 'react-router'

import Root from './Root'
import { Redirector } from 'redirector'
import {
  Account,
  AccountProfilePage,
  AccountPaymentsPage,
  BuddyMatchPage,
  MembershipTypePage,
  InstitutionProfilePage,
  InstitutionPaymentsPage,
  InstitutionMetricsPage,
  InstitutionSubaccountsPage,
  OrderPaymentForm,
  PaymentCompletePage
} from 'account'
import { requireAuth, requireNoAuth, requireIAH, requireActiveMembership, requireCompleteUserProfile } from 'auth'
import { CMSRoute, CMSIndexRoute } from 'cms'
import { EventsPage } from 'eventindex'
import {
  CompleteProfilePage,
  IndividualRegistrationPage,
  SubaccountRegistrationPage
} from 'registration'
import { MembershipExpiredPage, MembershipRenewalPage, MembershipNotActivePage, MembershipRegistrationPage } from 'membership'
import { ConfirmEmailPage } from 'email-confirmation'
import {
  SessionSalePage,
  IndividualReservationPage,
  InstitutionReservationPage,
  WaitlistFormPage
} from 'session-sales'
import { WebinarRoute } from 'webinars'
import { ForumsSsoPage, ForumsSsoLogoutPage } from 'forums-sso'
import { NewsRoute } from 'news'
import { PostDetailPage } from 'post-detail'
import { MondayMotivatorRoute } from 'monday-motivator'

import { DashboardRoute } from 'dashboard'
import { InstitutionLandingPage } from 'institution-landing-page'
import { page, NotFoundPage } from 'page'
import { CoreCurriculumPage } from 'core-curriculum'
import { WebinarIndexPage } from 'webinar-index'
import { LoginPage } from 'login-page'
import { CreatePasswordRoute, ForgotPasswordRoute, ResetPasswordRoute } from 'password'
import { SearchResultsPage } from 'search'
import { ContactPage } from 'contact-page'

const onChange = (prevState, nextState) => {
  if (nextState.location.action !== 'POP') {
    window.scrollTo(0, 0)
  }
}

const buildContainer = wrapper => wrapper(({ children, ...props }) => React.cloneElement(children, props))
const RequireNoAuth = buildContainer(requireNoAuth)
const RequireAuth = buildContainer(requireAuth)
const RequireActiveMembership = buildContainer(requireActiveMembership)
const RequireCompleteUserProfile = buildContainer(requireCompleteUserProfile)

const routes = (
  <Route path='/' onChange={onChange} component={Root}>
    <Route component={Redirector}>
      <Route path='anon/orders/:orderUuid' component={OrderPaymentForm} />
      <Route path='anon/payment-complete' component={PaymentCompletePage} />
      <Route component={RequireNoAuth}>
        <Route path='login' component={LoginPage} />
        <Route
          path='register/:membershipPlanId'
          component={IndividualRegistrationPage}
        />
        <Route component={ForgotPasswordRoute} path='forgot-password' />
        <Route component={ResetPasswordRoute} path='reset-password' />
        <Route component={CreatePasswordRoute} path='create-password' />
        <Route path='institutions/:institutionSlug'>
          <IndexRoute component={InstitutionLandingPage} />
          <Route path='register' component={SubaccountRegistrationPage} />
        </Route>
      </Route>

      <Route path='webinars/:slug' component={WebinarRoute} />
      <Route path='sessions/:slug'>
        <IndexRoute component={SessionSalePage} />
        <Route path='reserve' component={IndividualReservationPage} />
        <Route path='institution-reserve' component={InstitutionReservationPage} />
        <Route path='join-waitlist' component={WaitlistFormPage} />
      </Route>

      <Route path='search' component={SearchResultsPage} />
      <Route path='confirm-email' component={ConfirmEmailPage} />
      <Route path='contact-us' component={ContactPage} />

      <Route component={RequireAuth}>
        <Route path='complete-profile' component={CompleteProfilePage} />

        <Route component={RequireCompleteUserProfile}>
          <Route path='membership-expired' component={MembershipExpiredPage} />
          <Route path='membership-not-active' component={MembershipNotActivePage} />
          <Route path='renew-membership' component={MembershipRenewalPage} />
          <Route path='register-membership/:membershipPlanId' component={MembershipRegistrationPage} />
          <Route path='account' component={Account}>
            <IndexRedirect to='profile' />
            <Route path='profile' component={AccountProfilePage} />
            <Route path='payments' component={AccountPaymentsPage} />
            <Route path='buddy-match' component={BuddyMatchPage} />
            <Route path='membership-type' component={MembershipTypePage} />
            <Route
              path='institution-profile'
              component={requireIAH({ redirectPath: '/account/profile' })(InstitutionProfilePage)}
            />
            <Route
              path='institution-payments'
              component={requireIAH({ redirectPath: '/account/profile' })(InstitutionPaymentsPage)}
            />
            <Route
              path='institution-metrics'
              component={requireIAH({ redirectPath: '/account/profile' })(InstitutionMetricsPage)}
            />
            <Route
              path='institution-subaccounts'
              component={requireIAH({ redirectPath: '/account/profile' })(InstitutionSubaccountsPage)}
            />
          </Route>
          <Route path='orders/:orderId' component={OrderPaymentForm} />
          <Route component={RequireActiveMembership}>
            <Route path='dashboard' component={DashboardRoute} />
            <Route path='events' component={EventsPage} />
            <Route path='library' component={WebinarIndexPage} />
            <Route path='core-curriculum' component={CoreCurriculumPage} />
            <Route path='news' component={NewsRoute} />
            <Route path='news/:slug' component={PostDetailPage} />
            <Route path='monday-motivator' component={MondayMotivatorRoute} />
            <Route path='monday-motivator/:slug' component={PostDetailPage} />
            <Route path='forums-sso' component={ForumsSsoPage} />
            <Route path='forums-sso-logout' component={ForumsSsoLogoutPage} />
          </Route>
        </Route>
      </Route>

      {/* CMS */}
      <IndexRoute
        component={requireNoAuth({
          redirectPath: '/dashboard'
        })(CMSIndexRoute)}
      />
      <Route path=':slug' component={CMSRoute} />

      <Route path='*' component={page()(NotFoundPage)} />
    </Route>
  </Route>
)

export default routes
