import React, { PropTypes } from 'react'
import moment from 'moment'

import MembershipRenewalLink from './MembershipRenewalLink'
import MembershipProgress from './MembershipProgress'
import css from './style.scss'

const formatDate = date => moment(date).format('Do MMMM YYYY')

const MembershipRenewal = ({ loading, membership }) => {
  return (
    <div className={css.wrapper}>
      <div className={css.joined}>
        <strong>Joined</strong><br />
        {formatDate(membership.activated_at)}
      </div>
      <div className={css.expires}>
        <strong>Expires</strong><br />
        {formatDate(membership.expires_at)}<br />
        <MembershipRenewalLink memberType={membership.member_type} />
      </div>
      <MembershipProgress start={membership.activated_at} end={membership.expires_at} />
    </div>
  )
}

MembershipRenewal.propTypes = {
  membership: PropTypes.shape({
    member_type: PropTypes.string.isRequired,
    activated_at: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    expires_at: PropTypes.string.isRequired
  }).isRequired
}

export default MembershipRenewal
