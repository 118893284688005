import React from 'react'
import { InfoIcon } from 'icons'
import moment from 'moment'

import css from './style.scss'

const InstitutionInactiveMessage = (props) => {
  const { name, activated_at: activatedAt, expires_at: expiresAt } = props

  let date
  let message

  if (activatedAt == null || expiresAt == null) {
    message = `${name}'s membership is currently not active.`
  } else if (Date.now() < new Date(activatedAt)) {
    message = `${name}'s membership is currently planned to activate on `
    date = moment(activatedAt).format('dddd, MMMM DD, YYYY')
  } else if (Date.now() > new Date(expiresAt)) {
    message = `${name}'s membership expired on `
    date = moment(expiresAt).format('dddd, MMMM DD, YYYY')
  }

  return (
    <div className={css.container}>
      <div className={css.infoIcon}>
        <InfoIcon />
      </div>
      <div className={css.textStyle}>{message}<strong>{date}</strong></div>
    </div>
  )
}

export default InstitutionInactiveMessage
