import React from 'react'
import { load, schemas } from 'api'
import RelatedWebinars from './RelatedWebinars'
import qs from 'qs'

export const RelatedCourseWebinars = ({ related, currentWebinarId }) => {
  if (!related) {
    return null
  }

  related = related.map(webinar => ({
    ...webinar,
    complete: webinar.completed_at !== null
  }))

  return (
    <RelatedWebinars
      related={related}
      title='The Multi-Week Course'
      currentWebinarId={currentWebinarId}
    />
  )
}

RelatedCourseWebinars.propTypes = {
  series_id: React.PropTypes.number.isRequired,
  related: React.PropTypes.array,
  currentWebinarId: React.PropTypes.number.isRequired
}

export default load({
  related: {
    urlSelector: (state, props) => `/v2/webinars?${
      qs.stringify({
        filters: {
          series_id: props.series_id,
          type: 'course'
        },
        order: 'asc'
      })
    }`,
    schema: [schemas.webinar]
  }
})(RelatedCourseWebinars)
