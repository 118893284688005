import React from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { homepageSlugSelector } from 'settings'
import CMSRoute from './CMSRoute'

class CMSIndexRoute extends React.Component {
  render () {
    return (
      <CMSRoute params={{ slug: this.props.homepageSlug }} />
    )
  }
}

const mapStateToProps = createStructuredSelector({
  homepageSlug: homepageSlugSelector
})

export default connect(mapStateToProps)(CMSIndexRoute)
